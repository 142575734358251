import moment from 'moment';
import React, { FC, useEffect, useMemo } from 'react';
import { Box, List, ListItem } from '@mui/material';
import { useAppSelector } from '../../../redux/hooks';
import SectionHeader from '../../../components/SectionHeader';
import { useGetCaloriesInDepthQuery } from '../queries/caloriesQueries';
import { Collapsible, FeelsEmpty, theme, useTimeframe } from '@hdcorner/ui-library';
import useAlert from '../../../hooks/useAlert';

type Props = { userId: string };

const CalorieDataInner: FC<Props> = ({ userId }) => {
  const { presentError } = useAlert();

  const { dateFilter, customDateFilter } = useAppSelector(state => state.layout);

  const timeframe = useTimeframe(
    dateFilter,
    customDateFilter?.start,
    customDateFilter?.end,
  );

  const { error, data: calorieRes } = useGetCaloriesInDepthQuery({
    userId,
    ...timeframe,
    params: { skip: 0, limit: 1000, sort: ['-createdAt'] },
  });

  useEffect(() => {
    if (error)
      presentError('An unexpected error occurred fetching in-depth calorie data');
  }, [error]);

  const inDepthData = useMemo(() => {
    if (!calorieRes || calorieRes.documents.length === 0) return [];

    return calorieRes.documents.map(it => {
      return {
        _id: it._id,
        title: Math.round(it.eaten),
        titleSub: 'kcal',
        data: {
          'Kcal consumed': { value: Math.round(it.eaten) },
          'Kcal burned': { value: Math.round(it.burned) },
          'Kcal left': { value: Math.round(it.left + it.burned) },
          Carbs: {
            value: `${Math.round(it.carbs.eaten)}/${Math.round(it.carbs.total)} gr`,
          },
          Protein: {
            value: `${Math.round(it.protein.eaten)}/${Math.round(it.protein.total)} gr`,
          },
          Fat: { value: `${Math.round(it.fat.eaten)}/${Math.round(it.fat.total)} gr` },
          'Time of calculation': { value: moment(it.updatedAt).format('h:mm A') },
          'Date of calculation': { value: moment(it.updatedAt).format('MMMM Do, YYYY') },
        },
      };
    });
  }, [calorieRes]);

  return (
    <Box flex={1} display={'flex'} flexDirection={'column'}>
      <SectionHeader title={'in-depth data'} />

      <List
        sx={{
          mt: 1,
          gap: 1,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
        disablePadding
      >
        {inDepthData.map((it: any) => (
          <ListItem key={it._id} disablePadding disableGutters>
            <Collapsible
              data={it.data}
              title={it.title}
              titleSub={it.titleSub}
              progress={it?.progress}
            />
          </ListItem>
        ))}

        {inDepthData.length === 0 && (
          <Box sx={{ marginBottom: theme.spacing(1), width: '100%' }}>
            <FeelsEmpty />
          </Box>
        )}
      </List>
    </Box>
  );
};

export default CalorieDataInner;
