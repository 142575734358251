import api from '../../../redux/api';
import { ApiPagination, Article, PaginationResult } from '@hdcorner/ui-library';

const articleQueries = api
  .enhanceEndpoints({ addTagTypes: ['Article'] })
  .injectEndpoints({
    endpoints: build => ({
      getArticles: build.query<
        PaginationResult<Article>,
        ApiPagination & { category?: string }
      >({
        query: ({ skip, limit, sort, category }) => ({
          url: '/hdCornerService/admin/content/article',
          method: 'GET',
          params: {
            skip,
            limit,
            sort,
            category,
            populate: 'image',
          },
        }),
        providesTags: ['Article'],
      }),

      postArticle: build.mutation<void, Article>({
        query: article => ({
          url: '/hdCornerService/admin/content/article',
          method: 'POST',
          body: article,
        }),
        invalidatesTags: ['Article'],
      }),

      editArticle: build.mutation<void, Article & { id: string }>({
        query: ({ id, ...rest }) => ({
          url: `/hdCornerService/admin/content/article/${id}`,
          method: 'PATCH',
          body: rest,
        }),
        invalidatesTags: ['Article'],
      }),

      updateSuggestedArticle: build.mutation<void, { id: string; suggested: boolean }>({
        query: ({ id, suggested }) => ({
          url: `/hdCornerService/admin/content/article/${id}`,
          method: 'PATCH',
          body: { suggested },
        }),
        invalidatesTags: ['Article'],
      }),

      deleteArticle: build.mutation<void, { id: string }>({
        query: ({ id }) => ({
          url: `/hdCornerService/admin/content/article/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Article'],
      }),

      getArticlesCategories: build.query<
        { categories: string[] },
        { search?: string } | void
      >({
        query: params => ({
          url: '/hdCornerService/admin/content/article/categories',
          method: 'GET',
          params: {
            search: params?.search,
          },
        }),
      }),
    }),
  });

export const {
  useGetArticlesQuery,
  usePostArticleMutation,
  useEditArticleMutation,
  useDeleteArticleMutation,
  useGetArticlesCategoriesQuery,
  useUpdateSuggestedArticleMutation,
} = articleQueries;

export default articleQueries;
