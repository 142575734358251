import React, { FC, useEffect, useState } from 'react';
import {
  useEditMedicineMutation,
  usePostMedicineMutation,
} from '../queries/medicineQueries';
import { v4 as uuidv4 } from 'uuid';
import { Box } from '@mui/material';
import useAlert from '../../../hooks/useAlert';
import { fileSize } from '../../../utils/bytes';
import ModalBaseContent from './ModalBaseContent';
import UploadedFileMarker from './UploadedFileMarker';
import ContentDropzone from '../../../components/ContentDropzone';
import {
  ConduitFile,
  CustomInput,
  DropdownMenu,
  Medicine,
  UploadResponse,
} from '@hdcorner/ui-library';
import {
  useReUploadFileMutation,
  useUploadFileMutation,
} from '../../../queries/fileQueries';

type Props = {
  open: boolean;
  data?: Medicine;
  setOpen: (open: boolean) => void;
};

const ModalAddEditMeds: FC<Props> = ({ data, open, setOpen }) => {
  const { presentSuccess, presentError } = useAlert();

  // STORE SELECTED FILES FOR UPLOAD
  const [attachedImagePreview, setAttachedImagePreview] = useState<File | null>(null);

  const [version, setVersion] = useState('en');
  const [med, setMed] = useState<Medicine>({
    _id: '',
    content: '',
    package: '',
    substance: '',
    description: '',
    medicineType: '',
    medicineName: '',
    imagePreview: '',
    version: version,
    prescribed: false,
  });

  const [upload] = useUploadFileMutation();
  const [reUpload] = useReUploadFileMutation();
  const [postMedicine] = usePostMedicineMutation();
  const [editMedicine] = useEditMedicineMutation();

  useEffect(() => {
    if (!data) return;
    setVersion(data.version ? data.version : 'en');
    setMed({
      _id: data._id,
      package: data.package,
      content: data.content,
      substance: data.substance,
      prescribed: data.prescribed,
      description: data.description,
      medicineType: data.medicineType,
      imagePreview: data.imagePreview,
      medicineName: data.medicineName,
    });

    if (data.imagePreview) {
      const conduitFile = data.imagePreview as ConduitFile;
      const file = new File([], conduitFile.name);
      setAttachedImagePreview(file);
    }
  }, [data]);

  const handleChange = (fieldName: string, value: any) => {
    setMed(oldValue => ({
      ...oldValue,
      [fieldName]: value,
    }));
  };
  const handleNewFiles = (files: File[]) => {
    const file = files[0];
    if (!file) return;

    setAttachedImagePreview(file);
  };

  const handleUpload = async () => {
    let uploadPromise;
    let resultImage: UploadResponse | void;
    const existingConduitFile = data?.imagePreview as ConduitFile;

    if (attachedImagePreview) {
      if (!existingConduitFile) {
        // Create new conduit file for video
        uploadPromise = upload({
          size: attachedImagePreview.size,
          name: `${uuidv4()}_${attachedImagePreview.name}`,
        }).unwrap();
      } else {
        uploadPromise = reUpload({
          _id: existingConduitFile._id,
          size: attachedImagePreview.size,
          name: `${uuidv4()}_${attachedImagePreview.name}`,
        }).unwrap();
      }

      resultImage = await uploadPromise.catch(error => {
        console.log(error);
        presentError('Error uploading creating image file');
        return;
      });

      if (!resultImage) {
        presentError('No file attached');
        return;
      }
    }

    const medData = {
      version: version,
      package: med.package,
      content: med.content,
      substance: med.substance,
      prescribed: med.prescribed,
      description: med.description,
      medicineType: med.medicineType,
      medicineName: med.medicineName,
      imagePreview: resultImage ? (resultImage as UploadResponse).file._id : undefined,
    };

    let promiseData;
    if (!data) {
      promiseData = postMedicine(medData).unwrap();
    } else {
      promiseData = editMedicine({ _id: med._id, ...medData }).unwrap();
    }

    promiseData
      .then(() => {
        presentSuccess('Medication created successfully');
        setAttachedImagePreview(null);
        setOpen(false);
        resetData();

        if (attachedImagePreview) {
          fetch((resultImage as UploadResponse).url, {
            method: 'PUT',
            body: attachedImagePreview,
            headers: {
              'x-ms-blob-type': 'BlockBlob',
              'Content-Type': attachedImagePreview.type,
            },
          });
        }
      })
      .catch(error => {
        console.log(error);
        presentError('Error creating image');
        setOpen(false);
        resetData();
      });
  };

  const resetData = () => {
    setMed({
      _id: '',
      package: '',
      content: '',
      substance: '',
      description: '',
      medicineType: '',
      medicineName: '',
      version: version,
      imagePreview: '',
      prescribed: false,
    });
  };

  return (
    <ModalBaseContent
      open={open}
      data={!!data}
      value={version}
      setOpen={setOpen}
      setValue={setVersion}
      category={'Medication'}
      handleSaveModal={handleUpload}
    >
      <Box gap={2} display={'flex'}>
        <CustomInput
          fullWidth
          label={'Medicine name'}
          value={med.medicineName}
          placeholder={'Enter name'}
          handleChange={(value: string) => handleChange('medicineName', value)}
        />
        <CustomInput
          fullWidth
          value={med.substance}
          label={'Active Substance'}
          placeholder={'Enter substance name'}
          handleChange={(value: string) => handleChange('substance', value)}
        />
      </Box>

      <Box gap={2} display={'flex'}>
        <CustomInput
          fullWidth
          label={'Package'}
          value={med.package}
          placeholder={'Enter package'}
          handleChange={(value: string) => handleChange('package', value)}
        />
        <Box flex={1}>
          <DropdownMenu
            fullWidth
            placeholder={'-'}
            label={'Prescribed'}
            menuItems={[
              { label: 'Yes', value: 'yes' },
              { label: 'No', value: 'no' },
            ]}
            value={med.prescribed ? 'yes' : 'no'}
            setValue={(value: string) => handleChange('prescribed', value === 'yes')}
          />
        </Box>
      </Box>

      <Box gap={2} display={'flex'}>
        <CustomInput
          fullWidth
          label={'Content'}
          value={med.content}
          placeholder={'Enter content'}
          handleChange={(value: string) => handleChange('content', value)}
        />
        <CustomInput
          fullWidth
          label={'Type'}
          value={med.medicineType}
          placeholder={'Enter type'}
          handleChange={(value: string) => handleChange('medicineType', value)}
        />
      </Box>

      {/*TODO: replace with Rich Text Editor*/}
      <CustomInput
        fullWidth
        rows={6}
        minRows={6}
        label={'Description'}
        value={med.description}
        placeholder={'Type something here'}
        handleChange={(value: string) => handleChange('description', value)}
      />

      <Box height={'187px'}>
        <ContentDropzone heading={'Attach image'} handleContentDrop={handleNewFiles} />
      </Box>

      {attachedImagePreview && (
        <UploadedFileMarker
          fileName={attachedImagePreview.name}
          handleRemoveFile={() => setAttachedImagePreview(null)}
          fileSize={fileSize(attachedImagePreview, data?.imagePreview as ConduitFile)}
        />
      )}
    </ModalBaseContent>
  );
};

export default ModalAddEditMeds;
