import api from '../../../redux/api';
import { ESHType } from '@hdcorner/ui-library';

const eshQueries = api
  .enhanceEndpoints({ addTagTypes: ['HypertensionLog'] })
  .injectEndpoints({
    endpoints: build => ({
      getESH: build.query<ESHType, { start: string; end: string; userId: string }>({
        query: ({ start, end, userId }) => ({
          method: 'GET',
          url: '/hdCornerService/user/esh',
          params: { start, end, populate: 'logs', userId },
        }),
        providesTags: ['HypertensionLog'],
      }),
    }),
  });

export const { useGetESHQuery } = eshQueries;

export default eshQueries;
