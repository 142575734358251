import { useMemo } from 'react';
import { CvdRiskLog } from '../types/CvdRiskLog';
import { PaginationResult } from '../types/ApiPagination';
import { useTranslation } from 'react-i18next';

const useIdealData = (currentMeasurement?: PaginationResult<CvdRiskLog>) => {
  const { t } = useTranslation();

  return useMemo(() => {
    if (!currentMeasurement || currentMeasurement.logs.length === 0) {
      return { idealRisk: '1%', overallRisk: t('general.labels.nA') };
    }

    const current = currentMeasurement.logs[0];

    if (current) {
      return {
        idealRisk: '1%',
        overallRisk: current.score + '%',
      };
    }

    return {
      idealRisk: '1%',
      overallRisk: t('general.labels.nA'),
    };
  }, [currentMeasurement]);
};

export default useIdealData;
