import React, { FC, useEffect, useState } from 'react';
import PageLayout from '../PageLayout';
import { useIonRouter } from '@ionic/react';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import UserHcpInfoToolbar from '../components/UserHcpInfoToolbar';
import { Route, RouteComponentProps, Switch } from 'react-router';
import DataPanelCVD from '../features/users/components/DataPanelCVD';
import { CalendarModal, ComponentsLayout } from '@hdcorner/ui-library';
import DataPanelLipid from '../features/users/components/DataPanelLipid';
import UserDataToolbar from '../features/users/components/UserDataToolbar';
import { setCustomDateFilter, setDateFilter } from '../redux/slices/appSlice';
import DataPanelFitness from '../features/users/components/DataPanelFitness';
import DataPanelDiabetes from '../features/users/components/DataPanelDiabetes';
import DataPanelHypertension from '../features/users/components/DataPanelHypertension';
import DataPanelMedication from '../features/users/components/DataPanelMedication';
import { useGetUserDetailsQuery } from '../features/users/queries/usersQueries';

interface Props extends RouteComponentProps<{ userId: string }> {}

const UserDataRoutes: FC<Props> = ({ match }) => {
  const router = useIonRouter();
  const dispatch = useAppDispatch();

  const { dateFilter, customDateFilter } = useAppSelector(state => state.layout);

  const [value, setValue] = useState<string>('Data');
  const [customEndDate, setCustomEndDate] = useState<string>('');
  const [calModalOpen, setCalModalOpen] = useState<boolean>(false);
  const [customStartDate, setCustomStartDate] = useState<string>('');

  const { data: userInfo } = useGetUserDetailsQuery({ id: match.params.userId });

  useEffect(() => {
    if (customDateFilter) {
      setCustomStartDate(customDateFilter.start);
      setCustomEndDate(customDateFilter.end);
    }
  }, [customDateFilter]);

  useEffect(() => {
    setValue('Data');
    if (value === 'Data') {
      return;
    } else {
      router.push(`/dashboard/user/info/${match.params.userId}`);
      // setValue('User Info');
    }
  }, [value]);

  const onDateFilterChange = (newFilter: string) => {
    if (newFilter === 'custom date' || newFilter === 'custom') {
      setCalModalOpen(true);
    }
    dispatch(setDateFilter(newFilter));
  };

  const onCustomDatesChange = (dates: string[]) => {
    let start, end;
    start = dates[0];
    end = dates[1];
    setCustomStartDate(start);
    setCustomEndDate(end);

    dispatch(setCustomDateFilter({ start: start, end: end }));
  };

  return (
    <PageLayout>
      <ComponentsLayout
        gap={3}
        display={'flex'}
        minHeight={'100%'}
        overflow={'scroll'}
        flexDirection={'column'}
        sx={{
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <UserHcpInfoToolbar
          value={value}
          hcpUser={false}
          setValue={setValue}
          userId={match.params.userId}
          premium={userInfo ? userInfo.data.premiumUser : false}
        />

        <UserDataToolbar
          activeFilter={dateFilter}
          userId={match.params.userId}
          changeFilter={onDateFilterChange}
        />

        <Switch>
          <Route
            exact
            path={'/dashboard/user/data/:userId/diabetes'}
            render={props => <DataPanelDiabetes {...props} />}
          />

          <Route
            exact
            path={'/dashboard/user/data/:userId/medication'}
            render={props => <DataPanelMedication {...props} />}
          />

          <Route
            exact
            path={'/dashboard/user/data/:userId/hypertension'}
            render={props => <DataPanelHypertension {...props} />}
          />

          <Route
            exact
            path={'/dashboard/user/data/:userId/fitness'}
            render={props => <DataPanelFitness {...props} />}
          />

          <Route
            exact
            path={'/dashboard/user/data/:userId/cvd'}
            render={props => <DataPanelCVD {...props} />}
          />

          <Route
            exact
            path={'/dashboard/user/data/:userId/lipid'}
            render={props => <DataPanelLipid {...props} />}
          />
        </Switch>
      </ComponentsLayout>

      <CalendarModal
        open={calModalOpen}
        setOpen={setCalModalOpen}
        saveDate={onCustomDatesChange}
        selectedDate={[customStartDate, customEndDate]}
      />
    </PageLayout>
  );
};

export default UserDataRoutes;
