import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: {
  page: string;
} = {
  page: 'all',
};

const lipidSlice = createSlice({
  name: 'lipid',
  initialState,
  reducers: {
    changeLipidPage: (state, action: PayloadAction<string>) => {
      state.page = action.payload;
    },
  },
});

export const { changeLipidPage } = lipidSlice.actions;
export default lipidSlice.reducer;
