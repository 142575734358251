import React, { FC, ReactNode } from 'react';
import { ArrowRight } from '@hdcorner/ui-library';
import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';

const CustomBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  height: '120px',
  cursor: 'pointer',
  flex: '1 1 350px',
  minWidth: '30%',
  maxWidth: '50%',
  borderRadius: '8px',
  boxSizing: 'border-box',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  padding: theme.spacing(1),
  '&:last-of-type': {
    margin: theme.spacing(0),
  },
  backgroundColor: theme.palette.secondary.main,
}));

const TypographyHeading = styled(Typography)<TypographyProps>(({ theme }) => ({
  maxWidth: '250px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  textOverflow: 'ellipsis',
  margin: theme.spacing(0),
  color: theme.palette.kmColorsCoolGrey.main,
  fontWeight: theme.typography.fontWeightRegular,
}));

type Props = {
  icon: ReactNode;
  heading: string;
  handleNavClick: () => void;
};

const NavFoodEquivalent: FC<Props> = ({ icon, heading, handleNavClick }) => {
  const theme = useTheme();

  return (
    <CustomBox onClick={handleNavClick}>
      <Box gap={1} display={'flex'} alignItems={'flex-start'}>
        {icon}
        <TypographyHeading variant={'body1'}>{heading}</TypographyHeading>
      </Box>
      <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} gap={1}>
        <ArrowRight fill={theme.palette.kmColorsCoolGrey.main} />
      </Box>
    </CustomBox>
  );
};

export default NavFoodEquivalent;
