import React, { useState } from 'react';
import PageLayout from '../PageLayout';
import { Redirect, Switch, Route } from 'react-router';
import { ComponentsLayout, FloatingActionButton } from '@hdcorner/ui-library';
import { FabListMenu } from '../features/content/constants';
import { CustomMenuItem } from '../components/CustomMenuItem';
import FABModal from '../features/content/components/FABModal';
import ContentArticlesPanel from '../features/content/ContentArticlesPanel';
import ContentMedsPanel from '../features/content/ContentMedsPanel';
import ModalAddEditArticle from '../features/content/components/ModalAddEditArticle';
import ContentToolbarMain from '../features/content/components/ContentToolbarMain';
import ModalAddEditMeds from '../features/content/components/ModalAddEditMeds';
import ContentTrafficLightPanel from '../features/content/ContentTrafficLightPanel';
import ModalAddEditTrafficLight from '../features/content/components/ModalAddEditTrafficLight';
import ModalAddEditFood from '../features/content/components/ModalAddEditFood';
import ContentFoodPanel from '../features/content/ContentFoodPanel';
import ModalAddEditActivity from '../features/content/components/ModalAddEditActivity';
import ContentActivityPanel from '../features/content/ContentActivityPanel';
import ModalAddEditDietPlan from '../features/content/components/ModalAddEditDietPlan';
import ContentDietPlanPanel from '../features/content/ContentDietPlanPanel';
import ModalAddEditPopup from '../features/content/components/ModalAddEditPopup';
import ContentPopPanel from '../features/content/ContentPopPanel';
import ContentQuotesPanel from '../features/content/ContentQuotesPanel';
import ModalAddEditQuote from '../features/content/components/ModalAddEditQuote';
import ContentFoodEquivPanel from '../features/content/ContentFoodEquivPanel';
import ContentVideoPanel from '../features/content/ContentVideoPanel';
import ModalAddEditVideoOther from '../features/content/components/ModalAddEditVideoOther';
import ModalAddEditVideoExercise from '../features/content/components/ModalAddEditVideoExercise';

const ContentRoutes = () => {
  const [openFood, setOpenFood] = useState<boolean>(false);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [openQuotes, setOpenQuotes] = useState<boolean>(false);
  const [openFabMenu, setOpenFabMenu] = useState<boolean>(false);
  const [openArticle, setOpenArticle] = useState<boolean>(false);
  const [openTraffic, setOpenTraffic] = useState<boolean>(false);
  const [openDietPlan, setOpenDietPlan] = useState<boolean>(false);
  const [openActivity, setOpenActivity] = useState<boolean>(false);
  const [openVideoOther, setOpenVideoOther] = useState<boolean>(false);
  const [openMedication, setOpenMedication] = useState<boolean>(false);
  const [openVideoExercise, setOpenVideoExercise] = useState<boolean>(false);

  const handleAddContent = (value: string) => {
    setOpenFabMenu(false);
    switch (value) {
      case 'articles':
        return setOpenArticle(true);
      case 'video-content':
        return setOpenVideoOther(true);
      case 'video-exercise':
        return setOpenVideoExercise(true);
      case 'diet-plan':
        return setOpenDietPlan(true);
      case 'foods':
        return setOpenFood(true);
      case 'medication':
        return setOpenMedication(true);
      case 'activity':
        return setOpenActivity(true);
      case 'traffic-lights':
        return setOpenTraffic(true);
      case 'popup-messages':
        return setOpenPopup(true);
      case 'inspirational-quotes':
        return setOpenQuotes(true);
    }
  };

  const renderRoutes = () => {
    return (
      <PageLayout>
        <ComponentsLayout
          gap={3}
          height={'100vh'}
          display={'flex'}
          overflow={'scroll'}
          flexDirection={'column'}
          sx={{
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            '::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <ContentToolbarMain />
          <Switch>
            <Route exact path="/dashboard/content/articles">
              <ContentArticlesPanel />
            </Route>
            <Route exact path={'/dashboard/content'}>
              <Redirect to={'/dashboard/content/articles'} />
            </Route>
            <Route exact path="/dashboard/content/medication">
              <ContentMedsPanel />
            </Route>
            <Route exact path="/dashboard/content/food-equivalents">
              <ContentFoodEquivPanel />
            </Route>
            <Route exact path="/dashboard/content/traffic-lights">
              <ContentTrafficLightPanel />
            </Route>
            <Route exact path="/dashboard/content/activity">
              <ContentActivityPanel />
            </Route>
            <Route exact path="/dashboard/content/foods">
              <ContentFoodPanel />
            </Route>
            <Route exact path="/dashboard/content/diet-plan">
              <ContentDietPlanPanel />
            </Route>
            <Route exact path="/dashboard/content/popup-messages">
              <ContentPopPanel />
            </Route>
            <Route exact path="/dashboard/content/inspirational-quotes">
              <ContentQuotesPanel />
            </Route>
            <Route exact path="/dashboard/content/video">
              <ContentVideoPanel />
            </Route>
          </Switch>

          <ModalAddEditFood open={openFood} setOpen={setOpenFood} />
          <ModalAddEditPopup open={openPopup} setOpen={setOpenPopup} />
          <ModalAddEditQuote open={openQuotes} setOpen={setOpenQuotes} />
          <ModalAddEditArticle open={openArticle} setOpen={setOpenArticle} />
          <ModalAddEditMeds open={openMedication} setOpen={setOpenMedication} />
          <ModalAddEditActivity open={openActivity} setOpen={setOpenActivity} />
          <ModalAddEditDietPlan open={openDietPlan} setOpen={setOpenDietPlan} />
          <ModalAddEditTrafficLight open={openTraffic} setOpen={setOpenTraffic} />
          <ModalAddEditVideoOther open={openVideoOther} setOpen={setOpenVideoOther} />
          <ModalAddEditVideoExercise
            open={openVideoExercise}
            setOpen={setOpenVideoExercise}
          />

          <FABModal open={openFabMenu} setOpen={setOpenFabMenu}>
            {FabListMenu.map((category, index) => (
              <CustomMenuItem
                key={index}
                value={category.value}
                onClick={() => handleAddContent(category.value)}
              >
                {category.icon}
                {category.name}
              </CustomMenuItem>
            ))}
          </FABModal>
          <FloatingActionButton onClick={() => setOpenFabMenu(true)} />
        </ComponentsLayout>
      </PageLayout>
    );
  };

  return renderRoutes();
};

export default ContentRoutes;
