import { Activity, ActivityLog, PaginationResult } from '@hdcorner/ui-library';
import api from '../../../redux/api';

const activitiesQueries = api
  .enhanceEndpoints({ addTagTypes: ['Activity', 'ActivityLog'] })
  .injectEndpoints({
    endpoints: build => ({
      getActivityLogs: build.query<
        PaginationResult<ActivityLog>,
        {
          end: string;
          skip: number;
          limit: number;
          start: string;
          sort: string[];
          userId: string;
        }
      >({
        query: ({ start, end, sort, skip, limit, userId }) => ({
          method: 'GET',
          url: '/database/function/getUserActivities',
          params: {
            end,
            skip,
            sort,
            limit,
            start,
            populate: 'activity',
            scope: `Exercise:${userId}`,
          },
        }),
        providesTags: ['ActivityLog'],
      }),

      getAllActivityLogs: build.query<
        PaginationResult<ActivityLog>,
        {
          end?: string;
          skip: number;
          limit: number;
          sort: string[];
          start?: string;
          userId: string;
        }
      >({
        query: ({ start, end, sort, skip, limit, userId }) => ({
          method: 'GET',
          url: '/database/function/getUserActivities',
          params: {
            skip,
            limit,
            start,
            end,
            sort,
            populate: 'activity',
            scope: `Exercise:${userId}`,
          },
        }),
        providesTags: ['ActivityLog'],
      }),
    }),
  });

export const { useGetActivityLogsQuery, useGetAllActivityLogsQuery } = activitiesQueries;

export default activitiesQueries;
