import { DropdownType } from '../content/types/DropdownType';
import {
  CVDRiskSQ,
  DiabetesSQ,
  FitnessHeartIcon,
  HypertensionSQ,
  LipidSQ,
  MedicationSQ,
  theme,
} from '@hdcorner/ui-library';

const UserDataDropdownList: DropdownType[] = [
  {
    name: 'Medication',
    value: 'medication',
    icon: <MedicationSQ color={theme.palette.kmColorsCoolGrey.main} />,
  },

  {
    name: 'Diabetes',
    value: 'diabetes',
    icon: <DiabetesSQ color={theme.palette.kmColorsCoolGrey.main} />,
  },
  {
    name: 'Hypertension',
    value: 'hypertension',
    icon: <HypertensionSQ color={theme.palette.kmColorsCoolGrey.main} />,
  },
  {
    name: 'Fitness',
    value: 'fitness',
    icon: <FitnessHeartIcon fill={theme.palette.kmColorsCoolGrey.main} />,
  },
  {
    name: 'CVD Risk',
    value: 'cvd',
    icon: <CVDRiskSQ color={theme.palette.kmColorsCoolGrey.main} />,
  },
  {
    name: 'Lipid Profile',
    value: 'lipid',
    icon: <LipidSQ color={theme.palette.kmColorsCoolGrey.main} />,
  },
];

const DiabetesFilterList: DropdownType[] = [
  {
    name: 'Default',
    value: 'default',
  },
  {
    name: 'Before meal',
    value: 'before_meal',
  },
  {
    name: 'With meal',
    value: 'with_meal',
  },
  {
    name: 'After meal',
    value: 'after_meal',
  },
  {
    name: 'Sports',
    value: 'sports',
  },
  {
    name: 'Ill',
    value: 'ill',
  },
  {
    name: 'Special',
    value: 'special',
  },
];

const HypertensionFilterList: DropdownType[] = [
  {
    name: 'All measurements',
    value: 'all',
  },
  {
    name: 'Morning measurements',
    value: 'morning',
  },
  {
    name: 'Afternoon measurements',
    value: 'afternoon',
  },
];

const HypertensionViewList: DropdownType[] = [
  {
    name: 'In-Depth Info',
    value: 'indepth',
  },
  {
    name: 'ESH Mode',
    value: 'esh',
  },
];

const LipidViewList: DropdownType[] = [
  {
    name: 'All',
    value: 'all',
  },
  {
    name: 'LPA',
    value: 'lpa',
  },
  {
    name: 'Total Chol',
    value: 'chol',
  },
  {
    name: 'Triglycerides',
    value: 'trg',
  },
  {
    name: 'HDL',
    value: 'hdl',
  },
  {
    name: 'LDL',
    value: 'ldl',
  },
];

const FitnessViewList: DropdownType[] = [
  {
    name: 'Nutrition',
    value: 'nutrition',
  },
  {
    name: 'Exercise',
    value: 'exercise',
  },
  {
    name: 'Wellness',
    value: 'wellness',
  },
];

export {
  LipidViewList,
  FitnessViewList,
  DiabetesFilterList,
  UserDataDropdownList,
  HypertensionViewList,
  HypertensionFilterList,
};
