import React, { useState } from 'react';
import { useIonRouter } from '@ionic/react';
import { Box, useTheme } from '@mui/material';
import PageLayout from '../../../PageLayout';
import { ForgotPasswordPanelGlobal } from '@hdcorner/ui-library';
import HeroImage from '../../../assets/illustrations/HeroImagePNG.png';
import LogoSignInPNG from '../../../assets/illustrations/LogoSignInPNG.png';
import { useForgotPasswordMutation } from '../queries/forgotPasswordQueries';
import useAlert from '../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const ForgotPassword = () => {
  const theme = useTheme();
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const [forgotPassword] = useForgotPasswordMutation();

  const [email, setEmail] = useState<string>('');
  const [emailSentBtn, setEmailSentBtn] = useState<boolean>(false);

  const handleNavigation = (route: string) => {
    router.push(route);
  };

  const handleSendEmail = async () => {
    try {
      setEmailSentBtn(true);
      await forgotPassword({ email }).unwrap();
    } catch (e) {
      console.log(e);
      setEmailSentBtn(false);
      presentError(t('authentication.forgotReset.error.errorSendLink'));
    }
  };

  return (
    <PageLayout>
      <Box display={'flex'} height={'100%'}>
        <Box
          boxSizing={'border-box'}
          bgcolor={theme.palette.secondary.main}
          padding={theme.spacing(7.25, 13, 3.5, 13)}
        >
          <ForgotPasswordPanelGlobal
            web
            email={email}
            setEmail={setEmail}
            emailSentBtn={emailSentBtn}
            handleSendEmail={handleSendEmail}
            logoContainer={
              <img
                width={'110px'}
                height={'92px'}
                src={LogoSignInPNG}
                alt={'company logo'}
              />
            }
            handleGoBack={() => handleNavigation('/sign-in')}
            handleCancelButton={() => handleNavigation('/sign-in')}
          />
        </Box>
        <Box
          flexGrow={'1'}
          sx={{
            backgroundSize: 'cover',
            backgroundPositionX: '-7px',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${HeroImage})`,
          }}
        ></Box>
      </Box>
    </PageLayout>
  );
};

export default ForgotPassword;
