import React, { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { QuoteCategories } from '../constants';
import useAlert from '../../../hooks/useAlert';
import ModalBaseContent from './ModalBaseContent';
import { convertLabeltoEnum } from '../utils/dataConversion';
import { CustomInput, DropdownMenu, Quote } from '@hdcorner/ui-library';
import { useEditQuoteMutation, usePostQuoteMutation } from '../queries/quoteQueries';

type Props = {
  open: boolean;
  data?: Quote;
  setOpen: (open: boolean) => void;
};

const ModalAddEditQuote: FC<Props> = ({ data, open, setOpen }) => {
  const { presentSuccess, presentError } = useAlert();

  const [version, setVersion] = useState('en');
  const [quote, setQuote] = useState<Quote>({
    _id: '',
    name: '',
    quote: '',
    author: '',
    lang: version,
    associatedGoal: 'weight',
  });

  const [postQuote] = usePostQuoteMutation();
  const [editQuote] = useEditQuoteMutation();

  useEffect(() => {
    if (!data) return;

    setVersion(data.lang ? data.lang : 'en');

    setQuote({
      _id: data._id,
      name: data.name,
      quote: data.quote,
      author: data.author,
      associatedGoal: convertLabeltoEnum(data.associatedGoal, QuoteCategories),
    });
  }, [data]);

  const handleChange = (fieldName: string, value: any) => {
    setQuote(oldValue => ({
      ...oldValue,
      [fieldName]: value,
    }));
  };
  const handleSaveModal = () => {
    postQuote({
      lang: version,
      name: quote.name,
      quote: quote.quote,
      author: quote.author,
      associatedGoal: quote.associatedGoal,
    })
      .unwrap()
      .then(() => {
        presentSuccess('Quote added');
      })
      .catch(() => {
        presentError('Something went wrong.');
      })
      .finally(() => {
        setOpen(false);
        resetData();
      });
  };

  const handleEdit = () => {
    if (!data) return;
    editQuote({
      lang: version,
      name: quote.name,
      quote: quote.quote,
      author: quote.author,
      id: quote._id ? quote._id : '',
      associatedGoal: quote.associatedGoal,
    })
      .unwrap()
      .then(() => {
        presentSuccess('Quote updated');
      })
      .catch(() => {
        presentError('Something went wrong.');
      })
      .finally(() => {
        setOpen(false);
        resetData();
      });
  };

  const resetData = () => {
    setQuote({
      _id: '',
      name: '',
      quote: '',
      author: '',
      associatedGoal: 'weight',
    });
  };

  return (
    <ModalBaseContent
      open={open}
      data={!!data}
      value={version}
      setOpen={setOpen}
      setValue={setVersion}
      category={'Inspirational quotes'}
      handleSaveModal={data ? handleEdit : handleSaveModal}
    >
      <CustomInput
        fullWidth
        label={'Quote name'}
        value={quote.name}
        placeholder={'Enter name'}
        handleChange={(value: string) => handleChange('name', value)}
      />

      {/*TODO: replace with Rich Text Editor*/}
      <CustomInput
        fullWidth
        rows={6}
        minRows={6}
        label={'Quote'}
        value={quote.quote}
        placeholder={'Type something here'}
        handleChange={(value: string) => handleChange('quote', value)}
      />

      <Box mb={3} gap={2} display={'flex'}>
        <CustomInput
          fullWidth
          label={'Author'}
          value={quote.author}
          placeholder={'Enter name'}
          handleChange={(value: string) => handleChange('author', value)}
        />
        <Box flex={1}>
          <DropdownMenu
            fullWidth
            placeholder={'Weight'}
            label={'Associated goal'}
            menuItems={QuoteCategories}
            value={quote.associatedGoal}
            setValue={(value: string) => handleChange('associatedGoal', value)}
          />
        </Box>
      </Box>
    </ModalBaseContent>
  );
};

export default ModalAddEditQuote;
