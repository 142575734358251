import React, { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import useAlert from '../../../hooks/useAlert';
import ModalBaseContent from './ModalBaseContent';
import { Activity, CustomInput } from '@hdcorner/ui-library';
import {
  useEditActivityMutation,
  usePostActivityMutation,
} from '../queries/activityQueries';

type Props = {
  open: boolean;
  data?: Activity;
  setOpen: (open: boolean) => void;
};

const ModalAddEditActivity: FC<Props> = ({ data, open, setOpen }) => {
  const { presentSuccess, presentError } = useAlert();

  const [version, setVersion] = useState<'en' | 'el'>('en');
  const [activity, setActivity] = useState<Activity>({
    _id: '',
    name: {
      en: '',
      el: '',
    },
    met: 0,
    description: {
      en: '',
      el: '',
    },
  });

  const [postActivity] = usePostActivityMutation();
  const [editActivity] = useEditActivityMutation();

  useEffect(() => {
    if (!data) return;

    setActivity({
      _id: data._id,
      name: data.name,
      met: data.met,
      description: data.description,
    });
  }, [data]);

  const handleChange = (fieldName: string, value: any) => {
    setActivity(oldValue => ({
      ...oldValue,
      [fieldName]: value,
    }));
  };
  const handleSaveModal = () => {
    postActivity({
      met: activity.met,
      name: activity.name,
      description: activity.description,
    })
      .unwrap()
      .then(() => {
        presentSuccess('Activity added');
      })
      .catch(() => {
        presentError('Something went wrong.');
      })
      .finally(() => {
        setOpen(false);
        resetData();
      });
  };

  const handleEdit = () => {
    if (!data) return;
    editActivity({
      met: activity.met,
      name: activity.name,
      description: activity.description,
      id: activity._id ? activity._id : '',
    })
      .unwrap()
      .then(() => {
        presentSuccess('Activity updated');
      })
      .catch(() => {
        presentError('Something went wrong.');
      })
      .finally(() => {
        setOpen(false);
        resetData();
      });
  };

  const resetData = () => {
    setActivity({
      _id: '',
      met: 0,
      name: {
        en: '',
        el: '',
      },
      description: {
        en: '',
        el: '',
      },
    });
  };

  return (
    <ModalBaseContent
      open={open}
      data={!!data}
      value={version}
      setOpen={setOpen}
      setValue={setVersion}
      category={'Activity'}
      handleSaveModal={data ? handleEdit : handleSaveModal}
    >
      <Box gap={2} display={'flex'}>
        <CustomInput
          fullWidth
          label={'Activity name'}
          value={activity.name[version]}
          placeholder={'Enter name here'}
          handleChange={(value: string) => {
            handleChange('name', { ...activity.name, [version]: value });
          }}
        />
        <CustomInput
          fullWidth
          label={'MET'}
          type={'number'}
          value={activity.met}
          placeholder={'Enter MET value'}
          handleChange={(value: string) => handleChange('met', value)}
        />
      </Box>
      <Box mb={3}>
        <CustomInput
          fullWidth
          rows={6}
          minRows={6}
          label={'Description'}
          placeholder={'Type something here'}
          value={activity.description?.[version]}
          handleChange={(value: string) => {
            handleChange('description', { ...activity.description, [version]: value });
          }}
        />
      </Box>
    </ModalBaseContent>
  );
};

export default ModalAddEditActivity;
