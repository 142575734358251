import {
  AppBarExpandedDarkIcon,
  AppBarExpandMoreDarkIcon,
  ChatMessagePanelWeb,
  ChatPanel,
  ChatTabs,
  theme,
} from '@hdcorner/ui-library';
import { useIonRouter } from '@ionic/react';
import { Box, IconButton, useMediaQuery } from '@mui/material';
import React, { FC, useCallback, useEffect, useState } from 'react';
import PageLayout from '../../PageLayout';
import { RouteComponentProps } from 'react-router';
import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import {
  useAcceptSupportChatRoomMutation,
  useGetChatMessagesQuery,
  useGetSupportChatRoomQuery,
  useGetSupportChatRoomsQuery,
  useResolveSupportChatRoomMutation,
} from './queries/chatQueries';
import {
  usePrepareChatRoomData,
  usePrepareChatRoomListData,
} from './hooks/usePrepareChatRoomData';
import { usePrepareChatMessageListData } from './hooks/usePrepareChatMessageData';
import { emitReadMsg, emitSendMsg } from './socket';
import useAlert from '../../hooks/useAlert';

interface Props
  extends RouteComponentProps<{
    chatType: string;
    supportChatId?: string;
  }> {}

const MESSAGES_PER_PAGE = 25;
const CHAT_ROOMS_PER_PAGE = 15;

const Chat: FC<Props> = ({
  match: {
    params: { supportChatId, chatType },
  },
}) => {
  const router = useIonRouter();
  const { presentError } = useAlert();
  const mediumScreens = useMediaQuery(theme.breakpoints.down('lg'));

  const userId = useAppSelector((state: RootState) => state.auth.data.user?._id);

  const [collapseChat, setCollapseChat] = useState<boolean>(mediumScreens);
  const [messageListSkip, setMessageListSkip] = useState<number>(0);
  const [chatRoomListSkip, setChatRoomListSkip] = useState<number>(0);

  /* SELECTED SUPPORT CHAT ROOM */
  const { data: selectedChatRoomData, error: errorSelectedChat } =
    useGetSupportChatRoomQuery({ roomId: supportChatId ?? '' }, { skip: !supportChatId });
  const selectedChatRoom = usePrepareChatRoomData(selectedChatRoomData);

  /* SUPPORT CHAT ROOM LIST */
  const {
    error: errorChatRoom,
    data: chatRoomListData,
    isFetching: chatRoomListFetching,
  } = useGetSupportChatRoomsQuery({
    skip: chatRoomListSkip,
    limit: CHAT_ROOMS_PER_PAGE,
    roomType: chatType as 'user' | 'hcp' | 'pending',
  });
  const chatRoomList = usePrepareChatRoomListData(chatRoomListData?.rooms);

  /* CHAT MESSAGE LIST */
  const {
    error: errorMessageList,
    data: chatMessageListData,
    isFetching: chatMessageListFetching,
  } = useGetChatMessagesQuery(
    {
      skip: messageListSkip,
      limit: MESSAGES_PER_PAGE,
      roomId: selectedChatRoomData?.chatRoom?._id ?? '',
    },
    { skip: !selectedChatRoomData?.chatRoom?._id },
  );
  const chatMessageList = usePrepareChatMessageListData(
    userId,
    chatMessageListData?.messages,
    selectedChatRoomData?.chatRoom?.participants,
  );

  /* SUPPORT CHAT ROOM */
  const [acceptSupportChat] = useAcceptSupportChatRoomMutation();
  const [resolveSupportChat] = useResolveSupportChatRoomMutation();

  const handleSendMessage = useCallback(
    (message: string) => {
      if (!selectedChatRoomData?.chatRoom?._id) return;
      console.log('Sending message', selectedChatRoomData.chatRoom._id, message);
      emitSendMsg(selectedChatRoomData.chatRoom._id, message);
    },
    [selectedChatRoomData?.chatRoom?._id],
  );

  const handleReadMessage = useCallback(() => {
    if (
      !selectedChatRoomData?.chatRoom?._id ||
      !chatMessageListData?.messages ||
      selectedChatRoomData.chatRoom._id !== chatMessageListData.messages?.[0]?.room
    )
      return;
    console.log('Reading message', selectedChatRoomData.chatRoom._id);
    emitReadMsg(selectedChatRoomData.chatRoom._id);
  }, [chatMessageListData?.messages, selectedChatRoomData?.chatRoom?._id]);

  useEffect(() => {
    handleReadMessage();
  }, [handleReadMessage]);

  const handleLoadMoreMessages = useCallback(() => {
    if (chatMessageListFetching) return;
    if (
      !chatMessageListData?.count ||
      chatMessageListData?.count <= messageListSkip + MESSAGES_PER_PAGE
    )
      return;
    setMessageListSkip(messageListSkip + MESSAGES_PER_PAGE);
  }, [chatMessageListData?.count, chatMessageListFetching, messageListSkip]);

  const handleLoadMoreChatRooms = useCallback(() => {
    if (chatRoomListFetching) return;
    if (
      !chatRoomListData?.count ||
      chatRoomListData?.count <= chatRoomListSkip + CHAT_ROOMS_PER_PAGE
    )
      return;
    setChatRoomListSkip(chatRoomListSkip + CHAT_ROOMS_PER_PAGE);
  }, [chatRoomListData?.count, chatRoomListFetching, chatRoomListSkip]);

  const handleResolveSupportChat = useCallback(() => {
    if (!selectedChatRoomData?._id) return;
    resolveSupportChat({ supportRoomId: selectedChatRoomData._id })
      .unwrap()
      .then(() => {
        router.push(`/dashboard/chat/${chatType}/`);
      });
  }, [resolveSupportChat, router, selectedChatRoomData?._id]);

  const handleAcceptPending = useCallback(() => {
    if (!selectedChatRoomData?._id) return;
    acceptSupportChat({ supportRoomId: selectedChatRoomData._id })
      .unwrap()
      .then(() => {
        const roomId = selectedChatRoomData._id;
        const roomType = selectedChatRoomData.ownerRole;
        router.push(`/dashboard/chat/${roomType}/${roomId}`);
      });
  }, [acceptSupportChat, selectedChatRoomData?._id]);

  useEffect(() => {
    if (errorChatRoom || errorSelectedChat || errorMessageList)
      presentError('An unexpected error occurred fetching the data');
  }, [errorChatRoom, errorSelectedChat, errorMessageList]);

  const handleOpenMessage = (supportChatId: string) => {
    setMessageListSkip(0);
    router.push(`/dashboard/chat/${chatType}/${supportChatId}`);
  };

  return (
    <PageLayout>
      <Box
        height={'100%'}
        display={'flex'}
        bgcolor={theme.palette.kmColorsDarkGrey.light}
      >
        <Box
          display={'flex'}
          overflow={'hidden'}
          flexDirection={'column'}
          width={collapseChat ? 72 : 360}
          minWidth={collapseChat ? 72 : 360}
          borderTop={`1px solid ${theme.palette.kmColorsDarkGrey.main}`}
          borderLeft={`1px solid ${theme.palette.kmColorsDarkGrey.main}`}
          borderRight={`1px solid ${theme.palette.kmColorsDarkGrey.main}`}
        >
          <Box padding={theme.spacing(1, 0.5, 2, 0.5)}>
            <ChatTabs
              value={chatType}
              shrink={collapseChat}
              setValue={value => {
                router.push(`/dashboard/chat/${value}/`);
              }}
              tabs={[
                { label: 'Users', value: 'user' },
                { label: 'HCPs', value: 'hcp' },
                { label: 'Pending', value: 'pending' },
              ]}
            />
          </Box>
          <ChatPanel
            web
            collapse={collapseChat}
            chatRooms={chatRoomList}
            selectedChatRoom={supportChatId}
            handleOpenMessage={handleOpenMessage}
            handleLoadMoreChatRooms={handleLoadMoreChatRooms}
          />
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'flex-end'}
            borderTop={`1px solid ${theme.palette.kmColorsDarkGrey.main}`}
          >
            <IconButton
              onClick={() => setCollapseChat(!collapseChat)}
              sx={{
                alignSelf: collapseChat ? 'center' : 'flex-end',
                margin: theme.spacing(1, 2),
              }}
            >
              {collapseChat ? <AppBarExpandedDarkIcon /> : <AppBarExpandMoreDarkIcon />}
            </IconButton>
          </Box>
        </Box>
        <Box
          flexGrow={1}
          display={'flex'}
          borderTop={`1px solid ${theme.palette.kmColorsDarkGrey.main}`}
        >
          {selectedChatRoom && (
            <ChatMessagePanelWeb
              admin
              support
              messages={chatMessageList}
              title={selectedChatRoom.title}
              avatar={selectedChatRoom.avatar}
              handleSendMessage={handleSendMessage}
              pending={!selectedChatRoomData?.chatRoom}
              handleAcceptPending={handleAcceptPending}
              handleLeaveChat={handleResolveSupportChat}
              supportMessage={selectedChatRoomData?.message}
              handleLoadMoreMessages={handleLoadMoreMessages}
            />
          )}
        </Box>
      </Box>
    </PageLayout>
  );
};

export default Chat;
