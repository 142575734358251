import { AuthState } from './types/AuthState';
import signInQueries from './queries/signInQueries';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  removeAccessToken,
  removeRefreshToken,
  storeAccessToken,
  storeRefreshToken,
} from '../../utils/TokenStorage';
import { AuthUser } from '@hdcorner/ui-library';

const initialState: AuthState = {
  data: {
    user: null,
    token: null,
    refreshToken: null,
  },
  meta: {
    error: null,
    status: 'idle',
    authResolved: false,
    isAuthenticated: false,
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.meta.authResolved = true;
      state.data.token = action.payload;
      storeAccessToken(action.payload);
    },
    setRefreshToken: (state, action: PayloadAction<string>) => {
      state.data.refreshToken = action.payload;
      storeRefreshToken(action.payload);
    },
    logout: state => {
      state.data.user = null;
      state.data.token = null;
      state.data.refreshToken = null;
      state.meta.authResolved = false;
      state.meta.isAuthenticated = false;

      removeAccessToken();
      removeRefreshToken();

      localStorage.clear();
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(signInQueries.endpoints.signIn.matchFulfilled, (state, { payload }) => {
        state.data.token = payload.accessToken;
        state.data.refreshToken = payload.refreshToken;
        storeAccessToken(payload.accessToken);
        storeRefreshToken(payload.refreshToken);
      })
      .addMatcher(
        signInQueries.endpoints.getAuthUser.matchFulfilled,
        (state, action: PayloadAction<AuthUser[]>) => {
          const user = action.payload[0];

          const isUser = user?.role === 'admin';
          if (!isUser) {
            state.data.user = null;
            state.data.token = null;
            state.data.refreshToken = null;
            state.meta.authResolved = false;
            state.meta.isAuthenticated = false;
            removeAccessToken().then(() => console.log('removeAccessToken: ok'));
            removeRefreshToken().then(() => console.log('removeRefreshToken: ok'));
            return;
          } else {
            state.meta.isAuthenticated = true;
          }
        },
      );
  },
});

export const { setAccessToken, logout, setRefreshToken } = authSlice.actions;
export default authSlice.reducer;
