import React, { FC, useEffect, useMemo, useState } from 'react';
import moment from 'moment/moment';
import { useGetESHQuery } from '../queries/eshQueries';
import { Table, TableBody, useMediaQuery } from '@mui/material';
import { ESHSortTableHeading, ESHSortTableRows, theme } from '@hdcorner/ui-library';
import { useAppSelector } from '../../../redux/hooks';
import { findUpdatedAt } from '../../../utils/dateTimeUtils';
import useAlert from '../../../hooks/useAlert';

type Order = 'asc' | 'desc';

type Props = {
  userId: string;
};
const ESHTableReadings: FC<Props> = ({ userId }) => {
  const { presentError } = useAlert();
  const web = useMediaQuery(theme.breakpoints.up('sm'));

  const { customDateFilter } = useAppSelector(state => state.layout);

  const startDate = moment().add(-6, 'days').utc(true).startOf('day');
  const endDate = moment().utc(true).endOf('day');

  const [params, setParams] = useState({
    start: startDate.toISOString(),
    end: endDate.toISOString(),
  });

  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState<Order>('asc');

  const { error, data: eshRes } = useGetESHQuery({
    userId,
    end: params.end,
    start: params.start,
  });

  useEffect(() => {
    if (error) presentError('An error occurred fetching the data');
  }, [error]);

  useEffect(() => {
    if (!customDateFilter) return;
    setParams({ start: customDateFilter.start, end: customDateFilter.end });
  }, [customDateFilter]);

  const rows = useMemo(() => {
    if (!eshRes || eshRes.logs.length === 0)
      return [
        {
          _id: '0',
          date: '-',
          systolic: '- mmHG',
          diastolic: '- mmHG',
          pulses: '- bpm',
          afib: '-',
        },
      ];
    return eshRes.logs.map((it: any) => {
      return {
        _id: it.id,
        date: findUpdatedAt(it.logDate),
        systolic: `${it.systolic} mmHG`,
        diastolic: `${it.diastolic} mmHG`,
        pulses: `${it.pulses} bpm`,
        afib: it.afib ? 'Yes' : '-',
      };
    });
  }, [eshRes]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createData = (row: any) => {
    return [row.date, row.systolic, row.diastolic, row.pulses, row.afib];
  };

  const headCells: {
    _id: string;
    label: string;
    align?: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
  }[] = [
    { _id: 'dates', label: 'Dates', align: 'left' },
    { _id: 'systolic', label: !web ? 'SYS' : 'Systolic', align: 'center' },
    { _id: 'diastolic', label: !web ? 'DIA' : 'Diastolic', align: 'center' },
    { _id: 'pulses', label: 'Pulses', align: 'center' },
    { _id: 'afib', label: 'Afib', align: 'center' },
  ];

  return (
    <Table>
      <ESHSortTableHeading
        order={order}
        orderBy={orderBy}
        headCells={headCells}
        onRequestSort={handleRequestSort}
      />
      <TableBody>
        {rows.map((it: any) => (
          <ESHSortTableRows key={it.id} rowCells={createData(it)} alignData />
        ))}
      </TableBody>
    </Table>
  );
};

export default ESHTableReadings;
