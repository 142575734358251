import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment/moment';
import useAlert from '../../hooks/useAlert';
import { Box, Table, TableBody } from '@mui/material';
import { convertEnumValue } from './utils/dataConversion';
import UsersTableRows from '../users/components/UsersTableRows';
import UsersTableHeading from '../users/components/UsersTableHeading';
import ModalAddEditTrafficLight from './components/ModalAddEditTrafficLight';
import {
  useDeleteTrafficLightMutation,
  useGetTrafficLightsQuery,
} from './queries/trafficLightQueries';
import {
  TrafficLightCategories,
  TrafficLightLevels,
  TrafficLightSections,
} from './constants';
import { tableSort } from '../../utils/tableSorting';
import {
  AdminTablePagination,
  FeelsEmpty,
  HeadCell,
  TipsTricks,
} from '@hdcorner/ui-library';
import ModalConfirmDelete from '../../components/ModalConfirmDelete';

const createData = (row: any) => {
  return [row.category, row.title, row.level, row.createdAt];
};

const headCells: HeadCell[] = [
  { _id: 'category', label: 'Category', align: 'center', sort: 'category' },
  { _id: 'title', label: 'Section', align: 'center', sort: 'title' },
  { _id: 'level', label: 'Level', align: 'center', sort: 'level' },
  { _id: 'createdAt', label: 'Date added', align: 'center', sort: 'createdAt' },
  { _id: 'actions', label: 'Actions', align: 'center' },
];
type Order = 'asc' | 'desc';

const skipAmount = 5;

const ContentTrafficLightPanel = () => {
  const { presentSuccess, presentError } = useAlert();

  const [rowId, setRowId] = useState<string>('');
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('category');
  const [skip, setSkip] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState<boolean>(false);
  const [modelData, setModelData] = useState<TipsTricks | undefined>(undefined);

  const [deleteTrafficLight] = useDeleteTrafficLightMutation();
  const {
    error,
    data: trafficLightRes,
    isFetching: dataFetch,
  } = useGetTrafficLightsQuery({
    limit: 12,
    skip: skip,
    sort: [tableSort(orderBy, order, headCells)],
  });

  useEffect(() => {
    if (error) presentError('An unexpected error occurred fetching tips & tricks data');
  }, [error]);

  const trafficRows = useMemo(() => {
    if (!trafficLightRes || trafficLightRes.documents.length === 0) return [];

    return trafficLightRes.documents.map((data: TipsTricks) => ({
      _id: data._id,
      lang: data.lang,
      description: data.description,
      level: convertEnumValue(data.level, TrafficLightLevels),
      createdAt: moment(data.createdAt).format('DD/MM/YYYY'),
      category: convertEnumValue(data.category, TrafficLightCategories),
      title: data.title ? convertEnumValue(data.title, TrafficLightSections) : '',
    }));
  }, [trafficLightRes]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleEditRow = (data: TipsTricks) => {
    setOpen(true);
    setModelData(data);
  };

  const handleDeleteRow = (id: string) => {
    deleteTrafficLight({
      id: id,
    })
      .unwrap()
      .then(() => {
        presentSuccess('Traffic Light deleted');
        setOpenDelete(false);
      })
      .catch(() => {
        presentError('Something went wrong.');
      });
  };

  const handleSkipClick = (amount: number) => {
    setCurrentPage(currentPage => currentPage + amount);
  };

  useEffect(() => {
    const apiSkip = (currentPage - 1) * 12;
    setSkip(apiSkip);
  }, [currentPage]);

  const numberOfPages = useMemo(() => {
    if (!trafficLightRes) return 0;
    return Math.ceil(trafficLightRes.count / 12);
  }, [trafficLightRes]);

  return (
    <Box gap={3} display={'flex'} flexDirection={'column'}>
      {trafficRows.length === 0 && <FeelsEmpty />}

      {trafficRows.length > 0 && (
        <>
          <Table>
            <UsersTableHeading
              content
              order={order}
              orderBy={orderBy}
              headCells={headCells}
              onRequestSort={handleRequestSort}
              totalRowsCount={trafficRows.length}
            />
            <TableBody>
              {trafficRows.map(row => (
                <UsersTableRows
                  content
                  alignData
                  rowCells={createData(row)}
                  key={row._id ? row._id : ''}
                  handleEditRow={() => handleEditRow(row)}
                  handleDeleteRow={() => {
                    setOpenDelete(true);
                    setRowId(row._id ? row._id : '');
                  }}
                />
              ))}
            </TableBody>
          </Table>
          <AdminTablePagination
            loading={dataFetch}
            skipAmount={skipAmount}
            currentPage={currentPage}
            numberOfPages={numberOfPages}
            onSkipClick={handleSkipClick}
          />
        </>
      )}

      <ModalConfirmDelete
        open={openDelete}
        setOpen={setOpenDelete}
        content={'traffic light'}
        handleDelete={() => handleDeleteRow(rowId)}
      />

      <ModalAddEditTrafficLight open={open} setOpen={setOpen} data={modelData} />
    </Box>
  );
};

export default ContentTrafficLightPanel;
