import api from '../../../redux/api';
import { DashLogs } from '../types/DashLogs';

const dashGraphQueries = api.injectEndpoints({
  endpoints: build => ({
    getDashGraph: build.query<
      { results: DashLogs[] },
      { end?: string; start?: string; gender?: string; timeframe?: string }
    >({
      query: ({ end, start, gender, timeframe }) => ({
        url: '/hdCornerService/admin/dashboard/graph',
        method: 'GET',
        params: { end, start, gender, timeframe },
      }),
    }),
  }),
});

export const { useGetDashGraphQuery } = dashGraphQueries;
export default dashGraphQueries;
