import React, { FC, useState } from 'react';
import {
  CustomButton,
  CustomInput,
  ModalHeading,
  ModalMobile,
  XCloseIcon,
} from '@hdcorner/ui-library';
import { Box, useTheme, styled, BoxProps } from '@mui/material';
import { usePostProgramMutation } from '../queries/programQueries';
import useAlert from '../../../hooks/useAlert';

const BoxMain = styled(Box)<BoxProps>(({ theme }) => ({
  width: '385px',
  display: 'flex',
  borderRadius: '8px',
  flexDirection: 'column',
  boxSizing: 'border-box',
  gap: theme.spacing(3),
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
}));

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  data: { bodyPart: string; workoutType: string; avatar: string; exercises: string[] };
};

const ModalCreateProgram: FC<Props> = ({ open, data, setOpen }) => {
  const theme = useTheme();
  const { presentSuccess, presentError } = useAlert();

  const [programName, setProgramName] = useState({
    name: '',
  });

  const [createProgram] = usePostProgramMutation();

  const handleChange = (fieldName: string, value: any) => {
    setProgramName(oldValue => ({
      ...oldValue,
      [fieldName]: value,
    }));
  };

  const handleCreateProgram = () => {
    createProgram({
      avatar: data.avatar,
      name: programName.name,
      bodyPart: data.bodyPart,
      exercises: data.exercises,
      workoutType: data.workoutType,
    })
      .unwrap()
      .then(() => {
        presentSuccess('Program created');
        setOpen(false);
      })
      .catch(() => {
        presentError('Something went wrong.');
      });
  };

  return (
    <ModalMobile open={open} setOpen={setOpen}>
      <BoxMain>
        <ModalHeading
          align={'left'}
          showCalendar={'flex'}
          sx={{ marginBottom: 0 }}
          title={'Create new program'}
          alternateIcon={<XCloseIcon />}
          handleCalendarClick={() => setOpen(false)}
        />
        <CustomInput
          fullWidth
          label={'Program name'}
          value={programName.name}
          placeholder={'Enter program name'}
          handleChange={(value: string) => handleChange('name', value)}
        />

        <Box display={'flex'} justifyContent={'center'}>
          <CustomButton
            variant={'contained'}
            onClick={handleCreateProgram}
            disabled={programName.name === ''}
            sx={{
              width: '212px',
              ':disabled': {
                opacity: 0.3,
                backgroundColor: theme.palette.kmColorsTurquoise.main,
              },
            }}
          >
            {'Create'}
          </CustomButton>
        </Box>
      </BoxMain>
    </ModalMobile>
  );
};

export default ModalCreateProgram;
