import React from 'react';
import { useAppSelector } from '../redux/hooks';
import { Redirect, Route } from 'react-router-dom';
import SignIn from '../features/authentication/signin/SignIn';
import ResetPassword from '../features/authentication/forgotPassword/ResetPassword';
import ForgotPassword from '../features/authentication/forgotPassword/ForgotPassword';

const NonProtectedRoutes = () => {
  const {
    meta: { isAuthenticated },
  } = useAppSelector(state => state.auth);

  const renderRoutes = () => {
    return (
      <>
        <Route exact path="/sign-in">
          {isAuthenticated ? <Redirect to={'/dashboard/home'} /> : <SignIn />}
        </Route>

        <Route exact path="/forgot-password">
          {isAuthenticated ? <Redirect to={'/dashboard/home'} /> : <ForgotPassword />}
        </Route>

        <Route
          exact
          path="/reset-password"
          render={() =>
            isAuthenticated ? <Redirect to={'/dashboard/home'} /> : <ResetPassword />
          }
        />
      </>
    );
  };

  return renderRoutes();
};

export default NonProtectedRoutes;
