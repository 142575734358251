import React, { useState } from 'react';
import { useIonRouter } from '@ionic/react';
import PageLayout from '../../../PageLayout';
import { Box, useTheme } from '@mui/material';
import { ResetPasswordPanelGlobal } from '@hdcorner/ui-library';
import HeroImage from '../../../assets/illustrations/HeroImagePNG.png';
import LogoSignInPNG from '../../../assets/illustrations/LogoSignInPNG.png';
import { useResetPasswordMutation } from '../queries/forgotPasswordQueries';
import useAlert from '../../../hooks/useAlert';
import useQuery from '../../../hooks/useQuery';
import { useTranslation } from 'react-i18next';

const ResetPassword = () => {
  const theme = useTheme();
  const router = useIonRouter();
  const { t } = useTranslation();
  const searchParams = useQuery();
  const { presentError } = useAlert();

  const resetToken = searchParams.get('reset_token') || '';

  const [resetPassword] = useResetPasswordMutation();

  const [count, setCount] = useState(0);
  const [password1, setPassword1] = useState<string>('');
  const [password2, setPassword2] = useState<string>('');

  const handleContinue = () => {
    setCount(count + 1);
  };

  const handleResetPassword = async () => {
    if (!resetToken) {
      presentError(t('authentication.forgotReset.error.errorReset'));
      return;
    }

    try {
      await resetPassword({
        password: password1,
        passwordResetToken: resetToken,
      }).unwrap();
      setCount(count + 1);
    } catch (e: any) {
      console.log(e);
      if (e.status === 403) {
        presentError(e.data.message);
        return;
      }
      presentError(t('authentication.forgotReset.error.errorReset'));
    }
  };

  const handleRouting = () => {
    setCount(0);
    router.push('/sign-in');
  };

  return (
    <PageLayout>
      <Box display={'flex'} height={'100%'}>
        <Box
          boxSizing={'border-box'}
          bgcolor={theme.palette.secondary.main}
          padding={theme.spacing(7.25, 13, 3.5, 13)}
        >
          <ResetPasswordPanelGlobal
            web
            count={count}
            password1={password1}
            password2={password2}
            setPassword1={setPassword1}
            setPassword2={setPassword2}
            handleContinue={handleContinue}
            handleGoToSignIn={handleRouting}
            handleCancelButton={handleRouting}
            handleResetPassword={handleResetPassword}
            logoContainer={
              <img
                width={'110px'}
                height={'92px'}
                src={LogoSignInPNG}
                alt={'company logo'}
              />
            }
          />
        </Box>

        <Box
          flexGrow={'1'}
          sx={{
            backgroundSize: 'cover',
            backgroundPositionX: '-7px',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${HeroImage})`,
          }}
        ></Box>
      </Box>
    </PageLayout>
  );
};

export default ResetPassword;
