import * as React from 'react';
import { SVGProps } from 'react';

const ContentSidebarIcon = ({ fill = '#222222', ...rest }: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={22}
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M20 1.63636C20 0.732625 19.2837 0 18.4 0H5.6C4.71634 0 4 0.732625 4 1.63636V2.6C4 2.82091 4.17909 3 4.4 3H5.2C5.42091 3 5.6 2.82091 5.6 2.6V2.45455C5.6 2.00268 5.95817 1.63636 6.4 1.63636H17.6C18.0418 1.63636 18.4 2.00268 18.4 2.45455V15.5455C18.4 15.9973 18.0418 16.3636 17.6 16.3636H17.4C17.1791 16.3636 17 16.5427 17 16.7636V17.6C17 17.8209 17.1791 18 17.4 18H18.4C19.2837 18 20 17.2674 20 16.3636V1.63636Z"
      fill={fill}
    />
    <path
      d="M6.4 8.41818C6.4 8.23743 6.25673 8.09091 6.08 8.09091H3.52C3.34327 8.09091 3.2 8.23743 3.2 8.41818V11.0364C3.2 11.2171 3.34327 11.3636 3.52 11.3636H6.08C6.25673 11.3636 6.4 11.2171 6.4 11.0364V8.41818Z"
      fill={fill}
    />
    <path
      d="M12.8 8.41818C12.8 8.23743 12.6567 8.09091 12.48 8.09091H9.92C9.74327 8.09091 9.6 8.23743 9.6 8.41818V11.0364C9.6 11.2171 9.74327 11.3636 9.92 11.3636H12.48C12.6567 11.3636 12.8 11.2171 12.8 11.0364V8.41818Z"
      fill={fill}
    />
    <path
      d="M9.6 14.9636C9.6 14.7829 9.74327 14.6364 9.92 14.6364H12.48C12.6567 14.6364 12.8 14.7829 12.8 14.9636V17.5818C12.8 17.7626 12.6567 17.9091 12.48 17.9091H9.92C9.74327 17.9091 9.6 17.7626 9.6 17.5818V14.9636Z"
      fill={fill}
    />
    <path
      d="M6.4 14.9636C6.4 14.7829 6.25673 14.6364 6.08 14.6364H3.52C3.34327 14.6364 3.2 14.7829 3.2 14.9636V17.5818C3.2 17.7626 3.34327 17.9091 3.52 17.9091H6.08C6.25673 17.9091 6.4 17.7626 6.4 17.5818V14.9636Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 5.63636C16 4.73263 15.2837 4 14.4 4H1.6C0.716344 4 0 4.73262 0 5.63636V20.3636C0 21.2674 0.716345 22 1.6 22H14.4C15.2837 22 16 21.2674 16 20.3636V5.63636ZM2.4 20.3636C1.95817 20.3636 1.6 19.9973 1.6 19.5455V6.45455C1.6 6.00268 1.95817 5.63636 2.4 5.63636H13.6C14.0418 5.63636 14.4 6.00268 14.4 6.45455V19.5455C14.4 19.9973 14.0418 20.3636 13.6 20.3636H2.4Z"
      fill={fill}
    />
  </svg>
);

export default ContentSidebarIcon;
