import { GraphDownRed, GraphUpGreen, StableTrend } from '@hdcorner/ui-library';
import React from 'react';

const handleTrendIcon = (icon: string) => {
  switch (icon) {
    case 'stable':
      return <StableTrend />;
    case 'up':
      return <GraphUpGreen />;
    case 'down':
      return <GraphDownRed />;
    default:
      return <StableTrend />;
  }
};

export default handleTrendIcon;
