import React, { CSSProperties, FC, useEffect, useMemo } from 'react';
import LogsList from './LogsList';
import { useAppSelector } from '../../../redux/hooks';
import SectionHeader from '../../../components/SectionHeader';
import { Box, BoxProps, styled, Typography, useTheme } from '@mui/material';
import {
  ChartNumbersInfo,
  FeelsEmpty,
  HypertensionGraphContainer,
  InDepthDataCard,
  LineChart,
  usePreparePressureGraph,
  usePreparePulsesGraph,
  useTimeframe,
} from '@hdcorner/ui-library';
import {
  useGetHypertensionOverallQuery,
  useGetLatestLogQuery,
  useGetPressureGraphDataQuery,
  useGetPulsesGraphDataQuery,
} from '../queries/hypertensionQueries';
import useAlert from '../../../hooks/useAlert';

const GraphDotContainer = styled(Box, {
  shouldForwardProp: propName => propName !== 'justify',
})<BoxProps & { justify: string }>(({ theme, justify }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginTop: theme.spacing(1),
  justifyContent: justify ? justify : 'unset',
}));

const GraphDot = styled('div', {
  shouldForwardProp: prop => prop !== 'customColor',
})<{ customColor?: CSSProperties['color'] }>(({ theme, customColor }) => ({
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  boxSizing: 'border-box',
  backgroundColor: customColor ? customColor : theme.palette.primary.main,
}));

type Props = { userId: string };
const HypertensionInfoPanel: FC<Props> = ({ userId }) => {
  const theme = useTheme();
  const { presentError } = useAlert();

  const { hypertension } = useAppSelector(state => state.userDataPage);

  const { dateFilter, customDateFilter } = useAppSelector(state => state.layout);

  const timeframe = useTimeframe(
    dateFilter,
    customDateFilter?.start,
    customDateFilter?.end,
  );

  const measurementCategory = useMemo(() => {
    return hypertension.filter;
  }, [hypertension]);

  const { error: errorPressureGraph, data: pressureGraphData } =
    useGetPressureGraphDataQuery({
      userId,
      ...timeframe,
    });
  const { error: errorPulsesGraph, data: pulsesGraphData } = useGetPulsesGraphDataQuery({
    userId,
    ...timeframe,
  });
  const { error: errorLatestHypLog, data: latestHypertensionLogs } = useGetLatestLogQuery(
    { userId },
  );

  const { error: errorOverall, data: overallData } = useGetHypertensionOverallQuery({
    userId,
    category: measurementCategory === 'all' ? undefined : measurementCategory,
    ...timeframe,
  });

  useEffect(() => {
    if (errorPulsesGraph || errorPressureGraph)
      presentError('An error occurred fetching graph data');
    if (errorOverall || errorLatestHypLog)
      presentError('An error occurred fetching data');
  }, [errorOverall, errorPulsesGraph, errorLatestHypLog, errorPressureGraph]);

  const prepareGraphDataPulses = usePreparePulsesGraph(pulsesGraphData);
  const prepareGraphDataPressure = usePreparePressureGraph(pressureGraphData);

  const systolicOverall = useMemo(() => {
    let systolicPressure = { value: '-', min: '-', max: '-', avg: '-' };

    if (overallData && overallData.systolic) {
      const systolic = overallData.systolic;
      systolicPressure = {
        ...systolicPressure,
        min: systolic.min ? systolic.min.toFixed().toString() : '-',
        max: systolic.max ? systolic.max.toFixed().toString() : '-',
        avg: systolic.avg ? systolic.avg.toFixed().toString() : '-',
      };
    }
    if (latestHypertensionLogs && latestHypertensionLogs.documents.length > 0) {
      const data = latestHypertensionLogs.documents[0];
      systolicPressure = {
        ...systolicPressure,
        value: data && data.systolic ? data.systolic.toFixed().toString() : '-',
      };
    }

    return systolicPressure;
  }, [overallData, latestHypertensionLogs, hypertension.filter]);

  const diastolicOverall = useMemo(() => {
    let diastolicPressure = { value: '-', min: '-', max: '-', avg: '-' };

    if (overallData && overallData.diastolic) {
      const diastolic = overallData.diastolic;
      diastolicPressure = {
        ...diastolicPressure,
        min: diastolic.min ? diastolic.min.toFixed().toString() : '-',
        max: diastolic.max ? diastolic.max.toFixed().toString() : '-',
        avg: diastolic.avg ? diastolic.avg.toFixed().toString() : '-',
      };
    }
    if (latestHypertensionLogs && latestHypertensionLogs.documents.length > 0) {
      const data = latestHypertensionLogs.documents[0];
      diastolicPressure = {
        ...diastolicPressure,
        value: data && data.diastolic ? data.diastolic.toFixed().toString() : '-',
      };
    }

    return diastolicPressure;
  }, [overallData, latestHypertensionLogs, hypertension.filter]);

  const pulsesOverall = useMemo(() => {
    let pulsesAll = { value: '-', min: '-', max: '-', avg: '-' };

    if (overallData && overallData.pulses) {
      const pulses = overallData.pulses;
      pulsesAll = {
        ...pulsesAll,
        min: pulses.min ? pulses.min.toFixed().toString() : '-',
        max: pulses.max ? pulses.max.toFixed().toString() : '-',
        avg: pulses.avg ? pulses.avg.toFixed().toString() : '-',
      };
    }
    if (latestHypertensionLogs && latestHypertensionLogs.documents.length > 0) {
      const data = latestHypertensionLogs.documents[0];
      pulsesAll = {
        ...pulsesAll,
        value: data && data.pulses ? data.pulses.toFixed().toString() : '-',
      };
    }

    return pulsesAll;
  }, [overallData, latestHypertensionLogs, hypertension.filter]);

  const overallDataCard = useMemo(() => {
    return [
      {
        name: 'Systolic',
        min: systolicOverall.min,
        max: systolicOverall.max,
        avg: systolicOverall.avg,
      },
      {
        name: 'Diastolic',
        min: diastolicOverall.min,
        max: diastolicOverall.max,
        avg: diastolicOverall.avg,
      },
      {
        name: 'Pulse',
        min: pulsesOverall.min,
        max: pulsesOverall.max,
        avg: pulsesOverall.avg,
      },
    ];
  }, [systolicOverall, diastolicOverall, pulsesOverall, hypertension.filter]);

  return (
    <Box>
      <Box>
        <Box display={'flex'} flex={1} gap={theme.spacing(3)}>
          <Box display={'flex'} flexDirection={'column'} flex={1}>
            <SectionHeader title={'systolic & diastolic pressure'} />
            <Box
              sx={{
                borderRadius: '8px',
                backgroundColor: theme.palette.secondary.main,
                padding: theme.spacing(2, 2, 1, 2),
              }}
            >
              <HypertensionGraphContainer
                gap={3}
                horizontal
                headers={['SYS', 'DIA']}
                systolicPressure={systolicOverall}
                diastolicPressure={diastolicOverall}
              />
              {prepareGraphDataPressure.length === 0 && <FeelsEmpty />}
              {prepareGraphDataPressure.length > 0 && (
                <LineChart chartData={prepareGraphDataPressure} />
              )}
            </Box>
          </Box>
          <Box display={'flex'} flexDirection={'column'} flex={1}>
            <SectionHeader title={'pulses'} />
            <Box
              sx={{
                borderRadius: '8px',
                backgroundColor: theme.palette.secondary.main,
                padding: theme.spacing(2, 2, 1, 2),
              }}
            >
              <ChartNumbersInfo
                isHorizontal
                header={'PUL'}
                numberOne={pulsesOverall.max}
                numberTwo={pulsesOverall.min}
                numberThree={pulsesOverall.avg}
              />
              <Box
                display={'flex'}
                alignItems={'center'}
                gap={theme.spacing(1)}
                marginTop={theme.spacing(3)}
                marginBottom={theme.spacing(3)}
              >
                <Typography
                  variant={'h1'}
                  textTransform={'uppercase'}
                  color={theme.palette.primary.main}
                >
                  latest PUL
                </Typography>
                <Typography
                  variant={'subtitle1'}
                  color={theme.palette.primary.light}
                  fontWeight={theme.typography.fontWeightMedium}
                >
                  {pulsesOverall.value} bpm
                </Typography>
              </Box>
              <GraphDotContainer justify={'unset'} marginTop={theme.spacing(3)}>
                <GraphDot customColor={theme.palette.lipid.main} />
                <Typography
                  variant={'body1'}
                  color={theme.palette.primary.main}
                  fontWeight={theme.typography.subtitle1.fontWeight}
                >
                  Pulses
                </Typography>
              </GraphDotContainer>
              {prepareGraphDataPulses.length === 0 && <FeelsEmpty />}
              {prepareGraphDataPulses.length > 0 && (
                <LineChart chartData={prepareGraphDataPulses} />
              )}
            </Box>
          </Box>
        </Box>
        <Box display={'flex'} flex={1} mt={theme.spacing(3)} gap={theme.spacing(3)}>
          <Box display={'flex'} flex={1} flexDirection={'column'} gap={theme.spacing(1)}>
            <SectionHeader title={'in-depth data'} />
            <InDepthDataCard
              profApp
              rows={overallDataCard}
              measurement={'mm HG'}
              headings={['Min.', 'Max.', 'Avg.']}
            />
            <LogsList
              userId={userId}
              endParam={timeframe?.end}
              startParam={timeframe?.start}
            />
          </Box>
          <Box display={'flex'} flex={1} />
        </Box>
      </Box>
    </Box>
  );
};

export default HypertensionInfoPanel;
