import { Box, Typography, useTheme } from '@mui/material';
import { PopupCategories } from '../constants';
import useAlert from '../../../hooks/useAlert';
import ModalBaseContent from './ModalBaseContent';
import React, { FC, useEffect, useState } from 'react';
import { convertLabeltoEnum } from '../utils/dataConversion';
import { CustomInput, DropdownMenu, Popup } from '@hdcorner/ui-library';
import { useEditPopupMutation, usePostPopupMutation } from '../queries/popupQueries';
import PopupSliders from './PopupSliders';

type Props = {
  open: boolean;
  data?: Popup;
  setOpen: (open: boolean) => void;
};

const ModalAddEditPopup: FC<Props> = ({ data, open, setOpen }) => {
  const theme = useTheme();
  const { presentSuccess, presentError } = useAlert();

  const [version, setVersion] = useState('en');
  const [popup, setPopup] = useState<Popup>({
    _id: '',
    title: '',
    message: '',
    lang: version,
    stable: false,
    cautious: false,
    dominant: false,
    communicative: false,
    category: 'did_you_know',
  });

  const [postPopup] = usePostPopupMutation();
  const [editPopup] = useEditPopupMutation();

  useEffect(() => {
    if (!data) return;

    setVersion(data.lang ? data.lang : 'en');

    setPopup({
      _id: data._id,
      title: data.title,
      stable: data.stable,
      message: data.message,
      cautious: data.cautious,
      dominant: data.dominant,
      communicative: data.communicative,
      category: convertLabeltoEnum(data.category, PopupCategories),
    });
  }, [data]);

  const handleChange = (fieldName: string, value: any) => {
    setPopup(oldValue => ({
      ...oldValue,
      [fieldName]: value,
    }));
  };

  const handleSaveModal = () => {
    postPopup({
      lang: version,
      title: popup.title,
      stable: popup.stable,
      message: popup.message,
      category: popup.category,
      cautious: popup.cautious,
      dominant: popup.dominant,
      communicative: popup.communicative,
    })
      .unwrap()
      .then(() => {
        presentSuccess('Message added');
      })
      .catch(() => {
        presentError('Something went wrong.');
      })
      .finally(() => {
        setOpen(false);
        resetData();
      });
  };

  const handleEdit = () => {
    if (!data) return;
    editPopup({
      lang: version,
      title: popup.title,
      stable: popup.stable,
      message: popup.message,
      category: popup.category,
      cautious: popup.cautious,
      dominant: popup.dominant,
      id: popup._id ? popup._id : '',
      communicative: popup.communicative,
    })
      .unwrap()
      .then(() => {
        presentSuccess('Message updated');
      })
      .catch(() => {
        presentError('Something went wrong.');
      })
      .finally(() => {
        setOpen(false);
        resetData();
      });
  };

  const resetData = () => {
    setPopup({
      _id: '',
      title: '',
      message: '',
      lang: version,
      stable: false,
      cautious: false,
      dominant: false,
      communicative: false,
      category: 'did_you_know',
    });
  };

  return (
    <ModalBaseContent
      open={open}
      data={!!data}
      value={version}
      setOpen={setOpen}
      setValue={setVersion}
      category={'Pop up messages'}
      handleSaveModal={data ? handleEdit : handleSaveModal}
      disabled={
        !popup.communicative && !popup.stable && !popup.cautious && !popup.dominant
      }
    >
      <Box gap={2} display={'flex'}>
        <CustomInput
          fullWidth
          label={'Title'}
          value={popup.title}
          placeholder={'Enter title'}
          handleChange={(value: string) => handleChange('title', value)}
        />
        <Box flex={1}>
          <DropdownMenu
            fullWidth
            label={'Category'}
            value={popup.category}
            menuItems={PopupCategories}
            setValue={(value: string) => handleChange('category', value)}
          />
        </Box>
      </Box>

      <Box gap={1} display={'flex'} flexDirection={'column'} width={'100%'}>
        <Box gap={1} display={'flex'} alignItems={'baseline'}>
          <Typography
            variant={'subtitle2'}
            sx={{ fontWeight: theme.typography.fontWeightRegular }}
          >
            Associated Personality Type
          </Typography>
          <Typography
            variant={'body1'}
            sx={{ color: theme.palette.kmColorsTurquoise.main }}
          >
            (Toggle at least 1)
          </Typography>
        </Box>
        <Box
          gap={2}
          flex={1}
          padding={2}
          display={'flex'}
          borderRadius={'8px'}
          boxSizing={'border-box'}
          bgcolor={theme.palette.secondary.main}
        >
          <Box gap={2} flex={1} display={'flex'} flexDirection={'column'}>
            <PopupSliders
              label={'Cautious'}
              checked={popup.cautious}
              setChecked={(value: boolean) => handleChange('cautious', value)}
            />
            <PopupSliders
              label={'Dominant'}
              checked={popup.dominant}
              setChecked={(value: boolean) => handleChange('dominant', value)}
            />
          </Box>
          <Box gap={2} flex={1} display={'flex'} flexDirection={'column'}>
            <PopupSliders
              label={'Stable'}
              checked={popup.stable}
              setChecked={(value: boolean) => handleChange('stable', value)}
            />
            <PopupSliders
              label={'Communicative'}
              checked={popup.communicative}
              setChecked={(value: boolean) => handleChange('communicative', value)}
            />
          </Box>
        </Box>
      </Box>

      {/*TODO: replace with Rich Text Editor*/}
      <Box mb={3}>
        <CustomInput
          fullWidth
          rows={6}
          minRows={6}
          label={'Message'}
          value={popup.message}
          placeholder={'Type something here'}
          handleChange={(value: string) => handleChange('message', value)}
        />
      </Box>
    </ModalBaseContent>
  );
};

export default ModalAddEditPopup;
