import moment from 'moment/moment';
import useAlert from '../../hooks/useAlert';
import { PopupCategories } from './constants';
import React, { useEffect, useMemo, useState } from 'react';
import { Box, Table, TableBody } from '@mui/material';
import ModalAddEditPopup from './components/ModalAddEditPopup';
import UsersTableRows from '../users/components/UsersTableRows';
import UsersTableHeading from '../users/components/UsersTableHeading';
import { useDeletePopupMutation, useGetPopupQuery } from './queries/popupQueries';
import { arrayToString, convertEnumValue } from './utils/dataConversion';
import { tableSort } from '../../utils/tableSorting';
import ModalConfirmDelete from '../../components/ModalConfirmDelete';
import { AdminTablePagination, FeelsEmpty, Popup, HeadCell } from '@hdcorner/ui-library';

const createData = (row: any) => {
  return [row.title, row.message, row.category, row.personality, row.createdAt];
};

const headCells: HeadCell[] = [
  { _id: 'title', label: 'Title', align: 'left', sort: 'title' },
  { _id: 'message', label: 'Message', align: 'center', sort: 'message' },
  { _id: 'category', label: 'Category', align: 'center', sort: 'category' },
  // COMMENT: cannot be properly sorted by personality as this field is a combo of 4
  { _id: 'personality', label: 'Personality type', align: 'center', sort: 'cautious' },
  { _id: 'createdAt', label: 'Date added', align: 'center', sort: 'createdAt' },
  { _id: 'actions', label: 'Actions', align: 'center' },
];
type Order = 'asc' | 'desc';

const skipAmount = 5;

const ContentPopPanel = () => {
  const { presentSuccess, presentError } = useAlert();

  const [rowId, setRowId] = useState<string>('');
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [orderBy, setOrderBy] = useState('title');
  const [order, setOrder] = useState<Order>('asc');
  const [open, setOpen] = useState<boolean>(false);
  const [skip, setSkip] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [modelData, setModelData] = useState<Popup | undefined>(undefined);

  const [deletePopup] = useDeletePopupMutation();
  const {
    error,
    data: popupRes,
    isFetching: dataFetch,
  } = useGetPopupQuery({
    limit: 10,
    skip: skip,
    sort: [tableSort(orderBy, order, headCells)],
  });

  useEffect(() => {
    if (error) presentError('An unexpected error occurred fetching pop-up data');
  }, [error]);

  const popupRows = useMemo(() => {
    if (!popupRes || popupRes.documents.length === 0) return [];

    return popupRes.documents.map((data: Popup) => ({
      _id: data._id,
      lang: data.lang,
      title: data.title,
      stable: data.stable,
      message: data.message,
      cautious: data.cautious,
      dominant: data.dominant,
      communicative: data.communicative,
      category: convertEnumValue(data.category, PopupCategories),
      createdAt: moment(data.createdAt).format('DD/MM/YYYY'),
      personality: arrayToString([
        data.stable ? 'Stable' : undefined,
        data.cautious ? 'Cautious' : undefined,
        data.dominant ? 'Dominant' : undefined,
        data.communicative ? 'Communicative' : undefined,
      ]),
    }));
  }, [popupRes]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleEditRow = (data: Popup) => {
    setOpen(true);
    setModelData(data);
  };

  const handleDeleteRow = (id: string) => {
    deletePopup({
      id: id,
    })
      .unwrap()
      .then(() => {
        presentSuccess('Message deleted');
        setOpenDelete(false);
      })
      .catch(() => {
        presentError('Something went wrong.');
      });
  };

  const handleSkipClick = (amount: number) => {
    setCurrentPage(currentPage => currentPage + amount);
  };

  useEffect(() => {
    const apiSkip = (currentPage - 1) * 10;
    setSkip(apiSkip);
  }, [currentPage]);

  const numberOfPages = useMemo(() => {
    if (!popupRes) return 0;
    return Math.ceil(popupRes.count / 10);
  }, [popupRes]);

  return (
    <Box gap={3} display={'flex'} flexDirection={'column'}>
      {popupRows.length === 0 && <FeelsEmpty />}

      {popupRows.length > 0 && (
        <>
          <Table>
            <UsersTableHeading
              content
              order={order}
              orderBy={orderBy}
              headCells={headCells}
              totalRowsCount={popupRows.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {popupRows.map(row => (
                <UsersTableRows
                  content
                  alignData
                  rowCells={createData(row)}
                  key={row._id ? row._id : ''}
                  handleEditRow={() => handleEditRow(row)}
                  handleDeleteRow={() => {
                    setOpenDelete(true);
                    setRowId(row._id ? row._id : '');
                  }}
                />
              ))}
            </TableBody>
          </Table>

          <AdminTablePagination
            loading={dataFetch}
            skipAmount={skipAmount}
            currentPage={currentPage}
            numberOfPages={numberOfPages}
            onSkipClick={handleSkipClick}
          />
        </>
      )}

      <ModalConfirmDelete
        open={openDelete}
        content={'message'}
        setOpen={setOpenDelete}
        handleDelete={() => handleDeleteRow(rowId)}
      />

      <ModalAddEditPopup open={open} setOpen={setOpen} data={modelData} />
    </Box>
  );
};

export default ContentPopPanel;
