import { io, Socket } from 'socket.io-client';
import { store } from '../../redux/store';
import chatQueries from './queries/chatQueries';
import moment from 'moment';
import { showAlert } from '../../redux/slices/appSlice';

const BASE_URL = process.env.REACT_APP_API_URL + 'chat/';

export let socketInstance: Socket;

export const initializeSocket = (token: string) => {
  socketInstance = io(BASE_URL, {
    path: '/realtime',
    extraHeaders: {
      authorization: `Bearer ${token}`,
    },
  });

  socketInstance.on('connect', () => {
    console.log('Connect!');
  });

  socketInstance.on('disconnect', () => {
    console.log('Disconnect!');
  });

  socketInstance.on('error', () => {
    console.log('Error!');
  });

  socketInstance.on('conduit_error', () => {
    console.log('conduit_error');
  });
};

export const disconnectSocket = () => {
  if (socketInstance) socketInstance.disconnect();
};

export const emitSendMsg = (roomId: string, msg: string) => {
  if (!socketInstance) {
    store.dispatch(
      showAlert({ message: 'Socket is not connected', severity: 'warning' }),
    );
  } else {
    socketInstance.emit('message', roomId, msg);
  }
  const state = store.getState();
  const currentDate = moment().toISOString();
  store.dispatch(
    chatQueries.util.updateQueryData(
      'getChatMessages',
      { roomId: roomId, skip: 0, limit: 1 },
      draft => {
        draft.messages.unshift({
          room: roomId,
          message: msg,
          createdAt: currentDate,
          updatedAt: currentDate,
          _id: `temp-${currentDate}`,
          readBy: [state.auth.data.user?._id ?? ''],
          senderUser: state.auth.data.user?._id ?? '',
        });
        draft.count += 1;
        return draft;
      },
    ),
  );
  store.dispatch(
    chatQueries.util.updateQueryData(
      'getSupportChatRooms',
      { skip: 0, limit: 1, roomType: 'user' },
      draft => {
        const room = draft.rooms?.find(room => room.chatRoom?._id === roomId);
        if (!room || !room.chatRoom) return draft;
        room.chatRoom.lastMessage = {
          room: roomId,
          message: msg,
          createdAt: currentDate,
          updatedAt: currentDate,
          _id: `temp-${currentDate}`,
          readBy: [state.auth.data.user?._id ?? ''],
          senderUser: state.auth.data.user?._id ?? '',
        };
        draft.rooms = draft.rooms.filter(r => {
          if (r.chatRoom) {
            return r.chatRoom._id !== roomId;
          }
          return true;
        });
        draft.rooms.unshift(room);
        return draft;
      },
    ),
  );
  store.dispatch(
    chatQueries.util.updateQueryData(
      'getSupportChatRooms',
      { skip: 0, limit: 1, roomType: 'hcp' },
      draft => {
        const room = draft.rooms?.find(room => room.chatRoom?._id === roomId);
        if (!room || !room.chatRoom) return draft;
        room.chatRoom.lastMessage = {
          room: roomId,
          message: msg,
          createdAt: currentDate,
          updatedAt: currentDate,
          _id: `temp-${currentDate}`,
          readBy: [state.auth.data.user?._id ?? ''],
          senderUser: state.auth.data.user?._id ?? '',
        };
        draft.rooms = draft.rooms.filter(r => {
          if (r.chatRoom) {
            return r.chatRoom._id !== roomId;
          }
          return true;
        });
        draft.rooms.unshift(room);
        return draft;
      },
    ),
  );
};

export const emitReadMsg = (roomId: string) => {
  if (!socketInstance) {
    store.dispatch(
      showAlert({ message: 'Socket is not connected', severity: 'warning' }),
    );
  } else {
    socketInstance.emit('messagesRead', roomId);
  }
  store.dispatch(
    chatQueries.util.updateQueryData(
      'getSupportChatRooms',
      { skip: 0, limit: 1, roomType: 'user' },
      draft => {
        const room = draft.rooms?.find(room => room.chatRoom?._id === roomId);
        if (!room || !room.chatRoom) return draft;
        room.chatRoom.unreadMessageCount = 0;
        return draft;
      },
    ),
  );
  store.dispatch(
    chatQueries.util.updateQueryData(
      'getSupportChatRooms',
      { skip: 0, limit: 1, roomType: 'hcp' },
      draft => {
        const room = draft.rooms?.find(room => room.chatRoom?._id === roomId);
        if (!room || !room.chatRoom) return draft;
        room.chatRoom.unreadMessageCount = 0;
        return draft;
      },
    ),
  );
};
