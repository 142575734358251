import React, { useEffect, useMemo, useState } from 'react';
import {
  useDeleteArticleMutation,
  useGetArticlesQuery,
  useUpdateSuggestedArticleMutation,
} from './queries/articleQueries';
import moment from 'moment';
import { Box } from '@mui/material';
import useAlert from '../../hooks/useAlert';
import { getThumbnail } from '../../utils/bytes';
import { useAppSelector } from '../../redux/hooks';
import {
  AdminTablePagination,
  Article,
  ArticleCard,
  FeelsEmpty,
} from '@hdcorner/ui-library';
import ModalAddEditArticle from './components/ModalAddEditArticle';
import DropdownTriggerMenu from './components/DropdownTriggerMenu';
import ModalConfirmDelete from '../../components/ModalConfirmDelete';

const skipAmount = 5;

const ContentArticlesPanel = () => {
  const { presentSuccess, presentError } = useAlert();

  const { sort, category } = useAppSelector(state => state.article);

  const [rowId, setRowId] = useState<string>('');
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  const [skip, setSkip] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState<boolean>(false);
  const [sorting, setSorting] = useState<string>('-createdAt');
  const [currentCategory, setCurrentCategory] = useState<string>('all');
  const [articleData, setArticleData] = useState<Article | undefined>(undefined);

  useEffect(() => {
    if (!sort) return;
    setSorting(sort);
  }, [sort]);

  useEffect(() => {
    if (!category) return;
    setCurrentCategory(category);
  }, [category]);

  const {
    error,
    data: articlesRes,
    isFetching: dataFetch,
  } = useGetArticlesQuery({
    limit: 12,
    skip: skip,
    sort: [sorting],
    category: currentCategory === 'all' ? undefined : currentCategory,
  });

  useEffect(() => {
    if (error) presentError('An unexpected error occurred fetching articles data');
  }, [error]);

  const [deleteArticle] = useDeleteArticleMutation();
  const [suggestedArticle] = useUpdateSuggestedArticleMutation();

  const articles = useMemo(() => {
    if (!articlesRes || articlesRes.documents.length === 0) return [];

    return articlesRes.documents.map((data: Article) => ({
      _id: data._id,
      lang: data.lang,
      title: data.title,
      author: data.author,
      subtitle: data.subtitle,
      category: data.category,
      description: data.description,
      image: data.image ? data.image : '',
      createdAt: moment(data.createdAt).format('DD/MM/YYYY'),
    }));
  }, [articlesRes]);

  const handleEdit = (data: Article) => {
    setArticleData(data);
    setOpen(true);
  };

  const handleDelete = (id: string) => {
    deleteArticle({
      id: id,
    })
      .unwrap()
      .then(() => {
        presentSuccess('Article deleted');
        setOpenDelete(false);
      })
      .catch(() => {
        presentError('Something went wrong.');
      });
  };

  const handleSetSuggested = (id: string) => {
    suggestedArticle({
      id: id,
      suggested: true,
    })
      .unwrap()
      .then(() => {
        presentSuccess('Set Article as suggested');
      })
      .catch(() => {
        presentError('Something went wrong.');
      });
  };

  const handleSkipClick = (amount: number) => {
    setCurrentPage(currentPage => currentPage + amount);
  };

  useEffect(() => {
    const apiSkip = (currentPage - 1) * 12;
    setSkip(apiSkip);
  }, [currentPage]);

  const numberOfPages = useMemo(() => {
    if (!articlesRes) return 0;
    return Math.ceil(articlesRes.count / 12);
  }, [articlesRes]);

  return (
    <Box
      gap={3}
      flex={1}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
    >
      {articles.length === 0 && <FeelsEmpty />}

      <Box gap={3} display={'flex'} flexWrap={'wrap'}>
        {articles.map(data => (
          <ArticleCard
            key={data._id}
            title={data.title}
            author={data.author}
            category={data.category}
            createdOn={data.createdAt}
            imageSrc={getThumbnail(data.image)}
            sx={{ width: '222px', minWidth: '222px' }}
            altIcon={
              <DropdownTriggerMenu
                handleEdit={() => handleEdit(data)}
                handleDelete={() => {
                  setOpenDelete(true);
                  setRowId(data._id ? data._id : '');
                }}
                handleSetSuggested={() => handleSetSuggested(data._id || '')}
              />
            }
          />
        ))}
      </Box>

      {articles.length > 0 && (
        <AdminTablePagination
          loading={dataFetch}
          skipAmount={skipAmount}
          currentPage={currentPage}
          numberOfPages={numberOfPages}
          onSkipClick={handleSkipClick}
        />
      )}

      <ModalConfirmDelete
        open={openDelete}
        content={'article'}
        setOpen={setOpenDelete}
        handleDelete={() => handleDelete(rowId)}
      />

      <ModalAddEditArticle open={open} setOpen={setOpen} data={articleData} />
    </Box>
  );
};

export default ContentArticlesPanel;
