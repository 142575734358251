import { FC, ReactNode } from 'react';
import { Box, BoxProps, styled } from '@mui/material';

const MainBox = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 1,
  height: '72px',
  display: 'flex',
  minHeight: '72px',
  maxHeight: '72px',
  overflowX: 'auto',
  borderRadius: '8px',
  alignItems: 'center',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  boxSizing: 'border-box',
  '::-webkit-scrollbar': {
    display: 'none',
  },
  gap: theme.spacing(2),
  justifyContent: 'flex-start',
  backgroundColor: theme.palette.secondary.main,
  padding: theme.spacing(1, 2),
  border: `5px solid ${theme.palette.secondary.main}`,
}));

type Props = {
  children: ReactNode;
};

const ToolbarContainer: FC<Props & BoxProps> = ({ children, ...boxProps }) => {
  return <MainBox {...boxProps}>{children}</MainBox>;
};

export default ToolbarContainer;
