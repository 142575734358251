import api from './api';
import { configureStore } from '@reduxjs/toolkit';
import authSlice from '../features/authentication/authSlice';
import videoSlice from '../features/content/slices/videoSlice';
import articleSlice from '../features/content/slices/articleSlice';
import userDataPageSlice from '../features/users/slices/userDataPageSlice';
import lipidSlice from '../features/users/slices/lipidSlice';
import searchSlice from '../features/content/slices/searchSlice';
import appSlice from './slices/appSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    layout: appSlice,
    video: videoSlice,
    lipid: lipidSlice,
    search: searchSlice,
    article: articleSlice,
    userDataPage: userDataPageSlice,
    [api.reducerPath]: api.reducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(api.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
