import api from '../../../redux/api';
import {
  ApiPagination,
  HypertensionLog,
  HypertensionOverall,
  PaginationResult,
} from '@hdcorner/ui-library';

const hypertensionQueries = api
  .enhanceEndpoints({ addTagTypes: ['HypertensionLog'] })
  .injectEndpoints({
    endpoints: build => ({
      getLatestLog: build.query<PaginationResult<HypertensionLog>, { userId: string }>({
        query: ({ userId }) => ({
          method: 'GET',
          url: `database/function/getHypertensionLogs`,
          params: { skip: 0, limit: 1, sort: '-logDate', scope: `BasicHealth:${userId}` },
        }),
      }),

      getHypertensionOverall: build.query<
        HypertensionOverall,
        { start?: string; end?: string; category?: string; userId: string }
      >({
        query: ({ category, start, end, userId }) => ({
          method: 'GET',
          params: { category, start, end, userId },
          url: `hdCornerService/user/hypertension/overall`,
        }),
      }),

      getPressureGraphData: build.query<
        { logs: HypertensionLog[] },
        { start?: string; end?: string; userId: string }
      >({
        query: ({ start, end, userId }) => ({
          method: 'GET',
          params: { start, end, userId },
          url: `hdCornerService/user/blood-pressure/graph`,
        }),
      }),

      getPulsesGraphData: build.query<
        { logs: HypertensionLog[] },
        { start?: string; end?: string; userId: string }
      >({
        query: ({ start, end, userId }) => ({
          method: 'GET',
          params: { start, end, userId },
          url: `hdCornerService/user/pulses/graph`,
        }),
      }),

      getHypertensionLogs: build.query<
        PaginationResult<HypertensionLog>,
        {
          params: ApiPagination;
          category?: string;
          start?: string;
          end?: string;
          userId: string;
        }
      >({
        query: ({ params, category, start, end, userId }) => ({
          url: `database/function/getHypertensionLogs`,
          method: 'GET',
          params: { ...params, category, start, end, scope: `BasicHealth:${userId}` },
        }),
        serializeQueryArgs: ({ queryArgs }) => {
          const { params, category, start, end } = queryArgs;
          const { skip, limit, ...otherParams } = params;
          return { params: { ...otherParams, category, start, end } };
        },
        merge: (currentCache, newItems, otherArgs) => {
          if (otherArgs.arg.params.skip === 0) {
            return newItems;
          } else {
            currentCache?.documents.push(...newItems?.documents);
          }
        },
        forceRefetch: ({ previousArg, currentArg }) =>
          previousArg?.params.skip !== currentArg?.params.skip ||
          previousArg?.params.limit !== currentArg?.params.limit ||
          previousArg?.params.sort !== currentArg?.params.sort ||
          previousArg?.category !== currentArg?.category ||
          previousArg?.start !== currentArg?.start ||
          previousArg?.end !== currentArg?.end,
        providesTags: result =>
          result?.documents
            ? [
                ...result.documents.map(({ _id: id }) => ({
                  type: 'HypertensionLog' as const,
                  id,
                })),
              ]
            : [],
      }),
    }),
  });

export const {
  useGetHypertensionOverallQuery,
  useGetPressureGraphDataQuery,
  useGetHypertensionLogsQuery,
  useGetPulsesGraphDataQuery,
  useGetLatestLogQuery,
} = hypertensionQueries;

export default hypertensionQueries;
