import Lottie from 'lottie-react';
import { Box } from '@mui/material';
import { darkTheme } from '@hdcorner/ui-library';
import animationData from '../../../assets/lotties/authloading.json';

const LoadingPage = () => {
  return (
    <Box
      flexGrow={1}
      width={'350px'}
      height={'100%'}
      display={'flex'}
      alignItems={'center'}
      alignContent={'center'}
      justifyContent={'center'}
      bgcolor={darkTheme.palette.secondary.main}
    >
      <Lottie animationData={animationData} width={'180px'} height={'180px'} />
    </Box>
  );
};

export default LoadingPage;
