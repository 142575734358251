import api from '../../../redux/api';
import { AuthResponse, AuthUser } from '@hdcorner/ui-library';

const signInQueries = api.injectEndpoints({
  endpoints: build => ({
    getAuthUser: build.query<AuthUser[], void>({
      query: () => ({
        url: '/database/function/getAuthUser',
        method: 'GET',
        params: { populate: 'profilePicture' },
      }),
      providesTags: ['AuthUser'],
    }),
    signIn: build.mutation<AuthResponse, { email: string; password: string }>({
      query: ({ email, password }) => ({
        url: '/authentication/local',
        method: 'POST',
        body: { email, password },
      }),
      invalidatesTags: ['AuthUser'],
    }),
  }),
});

export const { useSignInMutation, useGetAuthUserQuery, useLazyGetAuthUserQuery } =
  signInQueries;

export default signInQueries;
