import { FC } from 'react';
import ESHTableAverages from './ESHTableAverages';
import ESHTableReadings from './ESHTableReadings';
import { RouteComponentProps } from 'react-router';
import { useAppSelector } from '../../../redux/hooks';
import HypertensionInfoPanel from './HypertensionInfoPanel';

interface Props extends RouteComponentProps<{ userId: string }> {}
const DataPanelHypertension: FC<Props> = ({ match }) => {
  const { hypertension } = useAppSelector(state => state.userDataPage);

  return (
    <>
      {hypertension.page === 'indepth' && (
        <HypertensionInfoPanel userId={match.params.userId} />
      )}
      {hypertension.page === 'esh' && (
        <>
          <ESHTableAverages userId={match.params.userId} />
          <ESHTableReadings userId={match.params.userId} />
        </>
      )}
    </>
  );
};

export default DataPanelHypertension;
