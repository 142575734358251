import React, { FC } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { CustomButton, ModalMobile } from '@hdcorner/ui-library';

export type Props = {
  open: boolean;
  content: string;
  handleDelete: () => void;
  setOpen: (open: boolean) => void;
};

const ModalConfirmDelete: FC<Props> = ({ open, setOpen, content, handleDelete }) => {
  const theme = useTheme();

  return (
    <ModalMobile open={open} setOpen={setOpen}>
      <Box padding={theme.spacing(3)} boxSizing={'border-box'}>
        <Typography variant={'subtitle2'} align={'center'}>
          {`Are you sure you want to delete this ${content}?`} <br /> This action cannot
          be undone.
        </Typography>

        <Box
          gap={3}
          display={'flex'}
          justifyContent={'space-between'}
          marginTop={theme.spacing(3)}
        >
          <CustomButton
            fullWidth
            color={'primary'}
            children={'Cancel'}
            variant={'contained'}
            onClick={() => setOpen(false)}
          />
          <CustomButton
            fullWidth
            color={'secondary'}
            children={'Delete'}
            variant={'contained'}
            onClick={handleDelete}
            style={{
              height: '48px',
              maxHeight: '48px',
              minHeight: '48px',
              fontSize: theme.typography.subtitle2.fontSize,
            }}
          />
        </Box>
      </Box>
    </ModalMobile>
  );
};

export default ModalConfirmDelete;
