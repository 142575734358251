import React, { FC, useMemo } from 'react';
import { VideoExercise } from '../types/VideoExercise';
import { convertEnumValue } from '../utils/dataConversion';
import {
  ConduitFile,
  DeleteBucket,
  EditPencil,
  ToggleButtonGroupCu,
} from '@hdcorner/ui-library';
import { AllProgramsDropdown, BodyPartDropdown } from '../constants';
import { Box, BoxProps, IconButton, Typography, useTheme, styled } from '@mui/material';

const BoxMain = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 1,
  height: '96px',
  display: 'flex',
  borderRadius: '8px',
  boxSizing: 'border-box',
  alignItems: 'flex-start',
  padding: theme.spacing(2),
  justifyContent: 'space-between',
  backgroundColor: theme.palette.secondary.main,
}));

const ImageFrame = styled(Box)<BoxProps>(() => ({
  width: '112px',
  height: '64px',
  minWidth: '112px',
  objectFit: 'cover',
  borderRadius: '2px',
  backgroundColor: 'pink',
  justifyContent: 'space-between',
}));

type Props = {
  data: VideoExercise;
  handleEdit: () => void;
  handleDelete: () => void;
};

const ProgramVideoCard: FC<Props> = ({ data, handleEdit, handleDelete }) => {
  const theme = useTheme();

  const [version, setVersion] = React.useState<'en' | 'el'>('en');

  const getThumbnail = useMemo(() => {
    const thumbnail = data.thumbnail[version];
    if (thumbnail) {
      return (thumbnail as ConduitFile).url;
    } else {
      return (data.thumbnail as any).url;
    }
  }, [data.thumbnail, version]);

  const getTitle = useMemo(() => {
    if (typeof data.title === 'object') {
      return data.title[version];
    }
    return typeof data.title === 'string' ? data.title : '';
  }, [data.title, version]);

  return (
    <BoxMain>
      <Box gap={1} display={'flex'} alignItems={'center'}>
        <ImageFrame
          sx={{
            backgroundImage: `url(${getThumbnail})`,
          }}
        />
        <Box>
          <Typography
            variant={'subtitle1'}
            sx={{
              color: theme.palette.kmColorsCoolGrey.main,
              fontWeight: theme.typography.fontWeightRegular,
            }}
          >
            {getTitle}
          </Typography>
          <Typography
            variant={'body2'}
            sx={{
              color: theme.palette.kmColorsCoolGrey.main,
            }}
          >
            {`${convertEnumValue(
              data.workoutCategory,
              AllProgramsDropdown,
            )} • ${convertEnumValue(data.bodyPart, BodyPartDropdown)} • ${
              data.duration ? data.duration + ' min' : 'N/A'
            }`}
          </Typography>
        </Box>
      </Box>

      <Box
        gap={1}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
      >
        <Box gap={1} display={'flex'} justifyContent={'end'}>
          <IconButton sx={{ padding: 0 }} onClick={handleEdit}>
            <EditPencil
              width={15}
              height={15}
              color={theme.palette.kmColorsCoolGrey.main}
            />
          </IconButton>
          <IconButton sx={{ padding: 0 }} onClick={handleDelete}>
            <DeleteBucket
              width={15}
              height={15}
              color={theme.palette.kmColorsCoolGrey.main}
            />
          </IconButton>
        </Box>
        <Box>
          <ToggleButtonGroupCu
            value={version}
            headings={['en', 'el']}
            setValue={value => setVersion(value as 'en' | 'el')}
          />
        </Box>
      </Box>
    </BoxMain>
  );
};

export default ProgramVideoCard;
