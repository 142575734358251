import React, { useMemo } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  IonContent,
  IonMenu,
  IonRouterOutlet,
  IonSplitPane,
  useIonRouter,
} from '@ionic/react';
import { SideBar } from '@hdcorner/ui-library';
import { useAppSelector } from '../redux/hooks';
import ProtectedRoutes from './ProtectedRoutes';
import NonProtectedRoutes from './NonProtectedRoutes';
import { hideNavigation } from '../utils/hideNavigation';
import { Redirect, Route, useLocation } from 'react-router';
import UsersSideBarIcon from '../assets/icons/UsersSideBarIcon';
import ContentSidebarIcon from '../assets/icons/ContentSidebarIcon';
import DashboardSidebarIcon from '../assets/icons/DashboardSidebarIcon';
import HealthProfSidebarIcon from '../assets/icons/HealthProfSidebarIcon';

const MenuToMap = [
  { label: 'Dashboard', icon: DashboardSidebarIcon, link: '/dashboard/home' },
  {
    icon: HealthProfSidebarIcon,
    label: 'Health Care Professionals',
    link: '/dashboard/healthProfessionals',
  },
  {
    label: 'Users',
    icon: UsersSideBarIcon,
    link: '/dashboard/users',
  },
  {
    label: 'Content',
    icon: ContentSidebarIcon,
    link: '/dashboard/content',
  },
];

const userDataScreens: string[] = [
  'video',
  'foods',
  'articles',
  'activity',
  'diet-plan',
  'medication',
  'traffic-lights',
  'popup-messages',
  'food-equivalents',
  'inspirational-quotes',
];

const Routes = () => {
  const theme = useTheme();
  const router = useIonRouter();
  const { pathname } = useLocation();

  const shrunkSideBar = useAppSelector(state => state.layout.shrunkSideBar);

  const shrinkMenu = useMediaQuery(theme.breakpoints.down('md'));
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const {
    meta: { isAuthenticated },
  } = useAppSelector(state => state.auth);

  const handleClickRoute = (routeLink: string) => {
    router.push(routeLink, 'forward', 'replace');
  };

  const isCurrentScreen = useMemo(
    () => (link: string) => {
      if (pathname === link) {
        return true;
      } else if (link === '/dashboard/content') {
        const module = pathname.split('/')?.[3];
        return userDataScreens.includes(module);
      }
      return false;
    },
    [pathname],
  );

  return (
    <>
      <IonSplitPane
        when={true}
        contentId={'main'}
        className={'ion-split-pane'}
        disabled={hideNavigation(pathname)}
      >
        <IonMenu
          contentId={'main'}
          className={
            shrinkMenu || shrunkSideBar
              ? 'ion-split-pane-menu-shrink'
              : 'ion-split-pane-menu'
          }
        >
          <IonContent>
            <SideBar
              isAdminApp
              menuOptions={MenuToMap}
              isCurrentScreen={isCurrentScreen}
              handleClickRoute={handleClickRoute}
              shrunkSideBar={shrinkMenu || shrunkSideBar}
            />
          </IonContent>
        </IonMenu>
        <div className="ion-page" id="main">
          <IonRouterOutlet animated={smallScreen}>
            <NonProtectedRoutes />
            <Route path={'/dashboard'}>
              {isAuthenticated ? <ProtectedRoutes /> : <Redirect to={'/sign-in'} />}
            </Route>
            <Route exact path={'/'}>
              <Redirect to={'/dashboard/home'} />
            </Route>
          </IonRouterOutlet>
        </div>
      </IonSplitPane>
    </>
  );
};

export default Routes;
