import { HeadCell } from '@hdcorner/ui-library';

const tableSort = (value: string, direction: string, array: HeadCell[]) => {
  if (!value || array.length === 0) return '';

  const found = array.find((it: any) => it._id === value);
  if (found) {
    return `${direction === 'desc' ? '-' : ''}${found.sort}`;
  } else {
    return '';
  }
};

export { tableSort };
