import api from '../redux/api';
import { UploadResponse } from '@hdcorner/ui-library';

const fileQueries = api.enhanceEndpoints({ addTagTypes: [] }).injectEndpoints({
  endpoints: build => ({
    uploadFile: build.mutation<UploadResponse, { name: string; size: number }>({
      query: ({ name, size }) => ({
        method: 'POST',
        url: '/storage/upload',
        body: { name, size, container: 'hdcorner', isPublic: true },
      }),
    }),
    reUploadFile: build.mutation<
      UploadResponse,
      { _id: string; name: string; size: number }
    >({
      query: ({ _id, name, size }) => ({
        method: 'PATCH',
        body: { name, size },
        url: '/storage/upload/' + _id,
      }),
    }),
    getFileUrl: build.query<{ result: string }, { fileId: string }>({
      query: ({ fileId }) => ({
        method: 'GET',
        url: '/storage/getFileUrl/' + fileId,
      }),
    }),
  }),
});

export const { useUploadFileMutation, useReUploadFileMutation, useLazyGetFileUrlQuery } =
  fileQueries;

export default fileQueries;
