import { FC } from 'react';
import { CustomButton } from '@hdcorner/ui-library';
import { useTheme } from '@mui/material';

type Props = {
  verify: boolean;
  disabled: boolean;
  onClick: () => void;
};

const VerificationButton: FC<Props> = ({ verify, onClick, disabled }) => {
  const theme = useTheme();

  return (
    <CustomButton
      onClick={onClick}
      disabled={disabled}
      variant={verify ? 'contained' : 'outlined'}
      sx={{
        height: '40px',
        minWidth: '156px',
        whiteSpace: 'nowrap',
        padding: theme.spacing(1),
        '&:disabled': {
          opacity: 0.25,
          color: verify
            ? theme.palette.secondary.main
            : theme.palette.kmColorsTurquoise.main,
          background: verify ? theme.palette.kmColorsTurquoise.main : 'transparent',
          border: verify ? 'none' : `2px solid ${theme.palette.kmColorsTurquoise.main}`,
        },
      }}
    >
      {verify ? 'Verify' : 'Revoke verification'}
    </CustomButton>
  );
};

export default VerificationButton;
