import React, { FC, useEffect, useMemo, useState } from 'react';
import { VideoSortList } from '../constants';
import {
  AdminTablePagination,
  FeelsEmpty,
  MeasurementsIcon,
  Video,
} from '@hdcorner/ui-library';
import DropdownMenu from '../../../components/DropdownMenu';
import { Box, BoxProps, Typography, useTheme, styled } from '@mui/material';
import ToolbarContainer from '../../../components/ToolbarContainer';
import {
  useDeleteVideoMutation,
  useGetVideosQuery,
  useUpdateSuggestedVideoMutation,
} from '../queries/videoQueries';
import moment from 'moment/moment';
import useAlert from '../../../hooks/useAlert';
import VideoExerciseCard from './VideoExerciseCard';
import ModalAddEditVideoOther from './ModalAddEditVideoOther';
import ModalConfirmDelete from '../../../components/ModalConfirmDelete';
import { getThumbnail } from '../../../utils/bytes';

const Divider = styled(Box)<BoxProps>(({ theme }) => ({
  width: '2px',
  opacity: 0.1,
  height: '16px',
  backgroundColor: theme.palette.kmColorsCoolGrey.main,
}));

const skipAmount = 5;

const ContentOtherVideoPanel: FC = () => {
  const theme = useTheme();
  const { presentSuccess, presentError } = useAlert();

  const [rowId, setRowId] = useState<string>('');
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  const [skip, setSkip] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState<boolean>(false);
  const [sort, setSort] = useState<string>(VideoSortList[0].value);
  const [videoData, setVideoData] = useState<Video | undefined>(undefined);

  const {
    error,
    data: videoRes,
    isFetching: dataFetch,
  } = useGetVideosQuery({
    limit: 12,
    skip: skip,
    sort: [sort],
  });

  useEffect(() => {
    if (error) presentError('An unexpected error occurred fetching video data');
  }, [error]);

  const [deleteVideo] = useDeleteVideoMutation();
  const [suggestedVideo] = useUpdateSuggestedVideoMutation();

  const videos = useMemo(() => {
    if (!videoRes || videoRes.documents.length === 0) return [];

    return videoRes.documents.map((data: Video) => ({
      _id: data._id,
      title: data.title,
      notes: data.notes,
      version: data.version,
      obesity: data.obesity,
      diabetes: data.diabetes,
      suggested: data.suggested,
      hypertension: data.hypertension,
      dyslipidemia: data.dyslipidemia,
      thumbnail: data.thumbnail ? data.thumbnail : '',
      attachedVideo: data.attachedVideo ? data.attachedVideo : '',
      createdAt: moment(data.createdAt).format('DD/MM/YYYY'),
    }));
  }, [videoRes]);

  const handleSort = (event: any) => {
    const newState = VideoSortList.find(option => option.value === event.target.value);
    if (newState) {
      setSort(newState?.value);
    }
  };

  const handleEdit = (data: Video) => {
    setVideoData(data);
    setOpen(true);
  };

  const handleDelete = (id: string) => {
    deleteVideo({
      id: id,
    })
      .unwrap()
      .then(() => {
        presentSuccess('Video deleted');
        setOpenDelete(false);
      })
      .catch(() => {
        presentError('Something went wrong.');
      });
  };

  const handleSetSuggested = (id: string) => {
    suggestedVideo({
      id: id,
      suggested: true,
    })
      .unwrap()
      .then(() => {
        presentSuccess('Set Video as suggested');
      })
      .catch(() => {
        presentError('Something went wrong.');
      });
  };

  const handleSkipClick = (amount: number) => {
    setCurrentPage(currentPage => currentPage + amount);
  };

  useEffect(() => {
    const apiSkip = (currentPage - 1) * 12;
    setSkip(apiSkip);
  }, [currentPage]);

  const numberOfPages = useMemo(() => {
    if (!videoRes) return 0;
    return Math.ceil(videoRes.count / 12);
  }, [videoRes]);

  const measurementsIcon = (
    <MeasurementsIcon
      width={20}
      height={22}
      color={theme.palette.kmColorsCoolGrey.main}
    />
  );

  return (
    <Box
      gap={3}
      flex={1}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
    >
      <Box>
        <ToolbarContainer sx={{ justifyContent: 'space-between' }}>
          <Box gap={3} display={'flex'} alignItems={'center'}>
            <Typography
              variant={'subtitle2'}
              sx={{
                color: theme.palette.kmColorsCoolGrey.main,
                fontWeight: theme.typography.fontWeightBold,
              }}
            >
              {videos.length === 1 ? `${videos.length} video` : `${videos.length} videos`}
            </Typography>

            <Divider />
            <Box gap={1} display={'flex'} alignItems={'center'}>
              {measurementsIcon}
              <DropdownMenu
                value={sort}
                setValue={setSort}
                options={VideoSortList}
                handleDropdownChange={handleSort}
              />
            </Box>
          </Box>
        </ToolbarContainer>

        {videos.length === 0 && <FeelsEmpty />}

        <Box mt={3} gap={3} display={'flex'} flexWrap={'wrap'}>
          {videos.length > 0 &&
            videos.map(data => (
              <VideoExerciseCard
                otherVideo
                key={data._id}
                title={data.title}
                programType={data.createdAt}
                handleEdit={() => handleEdit(data)}
                previewImg={getThumbnail(data.thumbnail)}
                suggested={data.suggested ? data.suggested : false}
                handleDelete={() => {
                  setOpenDelete(true);
                  setRowId(data._id ? data._id : '');
                }}
                handleSetSuggested={() => handleSetSuggested(data._id ? data._id : '')}
              />
            ))}
        </Box>
      </Box>

      {videos.length > 0 && (
        <AdminTablePagination
          loading={dataFetch}
          skipAmount={skipAmount}
          currentPage={currentPage}
          numberOfPages={numberOfPages}
          onSkipClick={handleSkipClick}
        />
      )}

      <ModalConfirmDelete
        open={openDelete}
        content={'video'}
        setOpen={setOpenDelete}
        handleDelete={() => handleDelete(rowId)}
      />

      <ModalAddEditVideoOther open={open} setOpen={setOpen} data={videoData} />
    </Box>
  );
};

export default ContentOtherVideoPanel;
