import { ConduitFile } from '@hdcorner/ui-library';

const bytesToSize = (bytes: number) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
};

const fileSize = (attachedFile?: File, existingFile?: ConduitFile) => {
  if (!attachedFile || attachedFile.size === 0) {
    return bytesToSize(existingFile?.size || 0);
  }
  return bytesToSize(attachedFile.size);
};

const getThumbnail = (file: ConduitFile | string) => {
  if (!file) return '';
  if (typeof file === 'string') return file;

  return file.url;
};

export { bytesToSize, fileSize, getThumbnail };
