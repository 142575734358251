import React, { useEffect, useMemo, useState } from 'react';
import { Box, Table, TableBody } from '@mui/material';
import UsersTableRows from '../users/components/UsersTableRows';
import UsersTableHeading from '../users/components/UsersTableHeading';
import useAlert from '../../hooks/useAlert';
import ModalAddEditFood from './components/ModalAddEditFood';
import { useDeleteFoodMutation, useGetFoodsQuery } from './queries/foodQueries';
import moment from 'moment/moment';
import { tableSort } from '../../utils/tableSorting';
import ModalConfirmDelete from '../../components/ModalConfirmDelete';
import { AdminTablePagination, FeelsEmpty, Food, HeadCell } from '@hdcorner/ui-library';
import { useAppSelector } from '../../redux/hooks';

const createData = (row: any) => {
  return [row.name, row.calories, row.carbs, row.protein, row.fat, row.createdAt];
};

const headCells: HeadCell[] = [
  { _id: 'name', label: 'Food Name', align: 'left', sort: 'name' },
  { _id: 'calories', label: 'Calories', align: 'center', sort: 'calories' },
  { _id: 'carbs', label: 'Carbohydrates', align: 'center', sort: 'carbs' },
  { _id: 'protein', label: 'Protein', align: 'center', sort: 'protein' },
  { _id: 'fat', label: 'Fat', align: 'center', sort: 'fat' },
  { _id: 'createdAt', label: 'Date added', align: 'center', sort: 'createdAt' },
  { _id: 'actions', label: 'Actions', align: 'center' },
];
type Order = 'asc' | 'desc';

const skipAmount = 5;

const ContentFoodPanel = () => {
  const { presentSuccess, presentError } = useAlert();

  const { searchText } = useAppSelector(state => state.search);

  const [skip, setSkip] = useState<number>(0);
  const [orderBy, setOrderBy] = useState('name');
  const [rowId, setRowId] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [order, setOrder] = useState<Order>('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [modelData, setModelData] = useState<Food | undefined>(undefined);

  const [deleteFood] = useDeleteFoodMutation();

  const {
    error,
    data: foodRes,
    isFetching: dataFetch,
  } = useGetFoodsQuery({
    limit: 10,
    skip: skip,
    search: searchText,
    sort: [tableSort(orderBy, order, headCells)],
  });

  useEffect(() => {
    if (error) presentError('An unexpected error occurred fetching food data');
  }, [error]);

  const foodRows = useMemo(() => {
    if (!foodRes || foodRes.documents.length === 0) return [];

    return foodRes.documents.map((data: Food) => ({
      _id: data._id,
      fat: data.fat,
      carbs: data.carbs,
      protein: data.protein,
      calories: data.calories,
      description: data.description,
      createdAt: moment(data.createdAt).format('DD/MM/YYYY'),
      name:
        typeof data.name === 'string'
          ? data.name
          : data.name['en'] + ' / ' + data.name['el'],
    }));
  }, [foodRes]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleEditRow = (_id?: string) => {
    const data = foodRes?.documents.find((data: Food) => data._id === _id);
    if (!data) return;
    setOpen(true);
    setModelData(data);
  };

  const handleDeleteRow = (id: string) => {
    deleteFood({
      id: id,
    })
      .unwrap()
      .then(() => {
        presentSuccess('Food deleted');
        setOpenDelete(false);
      })
      .catch(() => {
        presentError('Something went wrong.');
      });
  };

  const handleSkipClick = (amount: number) => {
    setCurrentPage(currentPage => currentPage + amount);
  };

  useEffect(() => {
    const apiSkip = (currentPage - 1) * 10;
    setSkip(apiSkip);
  }, [currentPage]);

  const numberOfPages = useMemo(() => {
    if (!foodRes) return 0;
    return Math.ceil(foodRes.count / 10);
  }, [foodRes]);

  return (
    <Box gap={3} display={'flex'} flexDirection={'column'}>
      {foodRows.length === 0 && <FeelsEmpty />}

      {foodRows.length > 0 && (
        <>
          <Table>
            <UsersTableHeading
              content
              order={order}
              orderBy={orderBy}
              headCells={headCells}
              totalRowsCount={foodRows.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {foodRows.map(row => (
                <UsersTableRows
                  content
                  alignData
                  rowCells={createData(row)}
                  key={row._id ? row._id : ''}
                  handleEditRow={() => handleEditRow(row._id)}
                  handleDeleteRow={() => {
                    setOpenDelete(true);
                    setRowId(row._id ? row._id : '');
                  }}
                />
              ))}
            </TableBody>
          </Table>
          <AdminTablePagination
            loading={dataFetch}
            skipAmount={skipAmount}
            currentPage={currentPage}
            numberOfPages={numberOfPages}
            onSkipClick={handleSkipClick}
          />
        </>
      )}

      <ModalConfirmDelete
        open={openDelete}
        content={'food item'}
        setOpen={setOpenDelete}
        handleDelete={() => handleDeleteRow(rowId)}
      />

      <ModalAddEditFood open={open} setOpen={setOpen} data={modelData} />
    </Box>
  );
};

export default ContentFoodPanel;
