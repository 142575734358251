import { useAppDispatch } from '../redux/hooks';
import { showAlert } from '../redux/slices/appSlice';

const useAlert = () => {
  const dispatch = useAppDispatch();

  const presentSuccess = (message: string) => {
    dispatch(showAlert({ message, severity: 'success' }));
    return;
  };

  const presentError = (message: string) => {
    dispatch(showAlert({ message, severity: 'error' }));
    return;
  };

  const presentWarning = (message: string) => {
    dispatch(showAlert({ message, severity: 'warning' }));
    return;
  };

  const presentInfo = (message: string) => {
    dispatch(showAlert({ message, severity: 'info' }));
    return;
  };

  return { presentSuccess, presentError, presentWarning, presentInfo };
};

export default useAlert;
