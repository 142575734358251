import api from '../../../redux/api';
import { ApiPagination, ApiResult, NotificationsResponse } from '@hdcorner/ui-library';

const notificationQueries = api.injectEndpoints({
  endpoints: build => ({
    getNotifications: build.query<
      NotificationsResponse,
      ApiPagination & { read?: boolean; platform?: string }
    >({
      query: ({ skip, limit, read, platform }) => ({
        method: 'GET',
        url: '/pushNotifications/notifications',
        params: { skip, limit, read, platform, populate: 'user' },
      }),
    }),
    // read a single notification
    readNotification: build.mutation<ApiResult<string>, { id: string }>({
      query: ({ id }) => ({
        method: 'PATCH',
        body: { id },
        url: `/pushNotifications/notifications/`,
      }),
    }),
    // read notifications before a certain date
    readNotificationsBefore: build.mutation<ApiResult<string>, { before: string }>({
      query: ({ before }) => ({
        method: 'PATCH',
        body: { before },
        url: `/pushNotifications/notifications`,
      }),
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useReadNotificationMutation,
  useLazyGetNotificationsQuery,
  useReadNotificationsBeforeMutation,
} = notificationQueries;

export default notificationQueries;
