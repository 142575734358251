import React, { FC, useEffect, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import useAlert from '../../../hooks/useAlert';
import ModalBaseContent from './ModalBaseContent';
import {
  ConduitFile,
  CustomInput,
  DropdownMenu,
  UploadResponse,
  Video,
} from '@hdcorner/ui-library';
import ContentDropzone from '../../../components/ContentDropzone';
import {
  useReUploadFileMutation,
  useUploadFileMutation,
} from '../../../queries/fileQueries';
import { useEditVideoMutation, usePostVideoMutation } from '../queries/videoQueries';
import UploadedFileMarker from './UploadedFileMarker';
import { fileSize } from '../../../utils/bytes';
import PopupSliders from './PopupSliders';

type Props = {
  open: boolean;
  data?: Video;
  setOpen: (open: boolean) => void;
};

const ModalAddEditVideoOther: FC<Props> = ({ data, open, setOpen }) => {
  const { presentSuccess, presentError } = useAlert();
  const theme = useTheme();

  // STORE SELECTED FILES FOR UPLOAD
  const [attachedVideo, setAttachedVideo] = useState<File | undefined>(undefined);
  const [thumbnailFile, setThumbnailFile] = useState<File | undefined>(undefined);

  const [version, setVersion] = useState('en');
  const [video, setVideo] = useState<Video>({
    _id: '',
    title: '',
    notes: '',
    thumbnail: '',
    obesity: false,
    diabetes: false,
    version: version,
    attachedVideo: '',
    hypertension: false,
    dyslipidemia: false,
  });

  const [upload] = useUploadFileMutation();
  const [reUpload] = useReUploadFileMutation();
  const [postVideo] = usePostVideoMutation();
  const [editVideo] = useEditVideoMutation();

  useEffect(() => {
    if (!data) return;
    setVersion(data.version);
    setVideo({
      _id: data._id,
      version: version,
      title: data.title,
      notes: data.notes,
      obesity: data.obesity,
      diabetes: data.diabetes,
      thumbnail: data.thumbnail,
      hypertension: data.hypertension,
      dyslipidemia: data.dyslipidemia,
      attachedVideo: data.attachedVideo,
    });

    if (data.attachedVideo) {
      const conduitFile = data.attachedVideo as ConduitFile;
      const file = new File([], conduitFile.name);
      setAttachedVideo(file);
    }

    if (data.thumbnail) {
      const conduitFile = data.thumbnail as ConduitFile;
      const file = new File([], conduitFile.name);
      setThumbnailFile(file);
    }
  }, [data]);

  const handleChange = (fieldName: string, value: any) => {
    setVideo(oldValue => ({
      ...oldValue,
      [fieldName]: value,
    }));
  };

  const handleNewFiles = (files: File[]) => {
    const file = files[0];
    if (!file) return;
    setAttachedVideo(file);

    const videoElement = document.createElement('video');
    videoElement.src = URL.createObjectURL(file);
    videoElement.addEventListener('loadedmetadata', () => {
      if (videoElement.duration) {
        const duration = Math.round(videoElement.duration);
        handleChange('duration', duration);
      } else {
        presentError('Could not found duration of video!');
        handleChange('duration', 0);
      }

      // Take a snapshot of the video at a specific time (e.g., at 5 seconds)
      videoElement.currentTime = 5;
      videoElement.addEventListener('seeked', () => {
        const canvas = document.createElement('canvas');
        canvas.width = 222; // This is the width that we need for the Exercise card
        canvas.height = 115; // This is the height that we need for the Exercise card
        const context = canvas.getContext('2d');
        if (context) {
          context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
          // Convert the canvas image to a Blob
          if (!canvas) {
            presentError('Could not found canvas of video!');
            return;
          }
          canvas.toBlob(blob => {
            if (blob) {
              // Create a File from the Blob
              const file = new File([blob], 'thumbnail.jpg', {
                type: 'image/jpeg',
              });
              setThumbnailFile(file);
            } else {
              presentError('Could not found blob of video!');
            }
          }, 'image/jpeg');
        } else {
          presentError('Could not found context of video!');
          setThumbnailFile(undefined);
        }

        // Remove the video element from the DOM
        videoElement.removeAttribute('src');
        videoElement.load();
      });
    });
  };

  const handleUpload = async () => {
    const existingFile = data?.attachedVideo as ConduitFile;
    const existingThumbnail = data?.thumbnail as ConduitFile;

    if (!thumbnailFile) {
      presentError('An error occurred while creating the thumbnail. Please try again.');
      return;
    }

    try {
      const fileRes = await handleCreateFiles(attachedVideo as File, existingFile);
      const thumbnailRes = await handleCreateFiles(thumbnailFile, existingThumbnail);

      let videoData = {
        version: version,
        notes: video.notes,
        title: video.title,
        obesity: video.obesity,
        diabetes: video.diabetes,
        id: video._id ? video._id : '',
        dyslipidemia: video.dyslipidemia,
        hypertension: video.hypertension,
        thumbnail: thumbnailRes
          ? thumbnailRes.file._id
          : (video.thumbnail as ConduitFile)._id,
        attachedVideo: fileRes
          ? fileRes.file._id
          : (video.attachedVideo as ConduitFile)._id,
      };

      let exercisePromise;
      if (!data) {
        const { id, ...rest } = videoData;
        exercisePromise = postVideo(rest).unwrap();
      } else {
        exercisePromise = editVideo(videoData).unwrap();
      }

      await exercisePromise;
      uploadBlobData(fileRes, attachedVideo as File);
      uploadBlobData(thumbnailRes, thumbnailFile);
      presentSuccess('Video created successfully');
      setOpen(false);
      resetData();
    } catch (error) {
      console.log(error);
      presentError('Error creating video');
    }
  };

  const handleCreateFiles = async (
    selectedFile: File,
    existingFile?: ConduitFile,
  ): Promise<UploadResponse | void> => {
    let uploadPromise: Promise<UploadResponse> | undefined;
    if (!existingFile) {
      // Create new conduit file for attached file
      uploadPromise = upload({
        size: selectedFile.size,
        name: `${uuidv4()}_${selectedFile.name}`,
      }).unwrap();
    } else {
      if (selectedFile.size === 0 && selectedFile.name === existingFile.name) {
        return;
      } else {
        // Upload new conduit file for attached file
        uploadPromise = reUpload({
          _id: existingFile._id,
          size: selectedFile.size,
          name: `${uuidv4()}_${selectedFile.name}`,
        }).unwrap();
      }
    }
    return uploadPromise;
  };

  const uploadBlobData = async (
    uploadItem: UploadResponse | void,
    attachedFile: File,
  ) => {
    if (!uploadItem) return;
    return await fetch(uploadItem.url, {
      method: 'PUT',
      body: attachedFile,
      headers: {
        'x-ms-blob-type': 'BlockBlob',
        'Content-Type': attachedFile.type,
      },
    });
  };

  const resetData = () => {
    setVideo({
      _id: '',
      title: '',
      notes: '',
      thumbnail: '',
      obesity: false,
      diabetes: false,
      version: version,
      attachedVideo: '',
      hypertension: false,
      dyslipidemia: false,
    });
    setAttachedVideo(undefined);
    setThumbnailFile(undefined);
  };

  const extraInputs = (
    <Box gap={2} display={'flex'}>
      <DropdownMenu
        fullWidth
        readOnly
        label={'Type'}
        value={'Other video'}
        setValue={() => undefined}
        placeholder={'Other video'}
        menuItems={['Workout', 'Other video']}
        sx={{ width: '288px', height: '44px' }}
      />
    </Box>
  );

  return (
    <ModalBaseContent
      open={open}
      data={!!data}
      value={version}
      setOpen={setOpen}
      setValue={setVersion}
      optionalInput={extraInputs}
      category={'Content Videos'}
      handleSaveModal={handleUpload}
    >
      <Box gap={2} display={'flex'}>
        <CustomInput
          fullWidth
          label={'Title'}
          value={video.title}
          placeholder={'Enter title'}
          handleChange={(value: string) => handleChange('title', value)}
        />
      </Box>

      <Box
        gap={2}
        flex={1}
        padding={2}
        display={'flex'}
        borderRadius={'8px'}
        boxSizing={'border-box'}
        bgcolor={theme.palette.secondary.main}
      >
        <Box gap={2} flex={1} display={'flex'} flexDirection={'column'}>
          <PopupSliders
            label={'Diabetes'}
            checked={video.diabetes || false}
            setChecked={(value: boolean) => handleChange('diabetes', value)}
          />
          <PopupSliders
            label={'Dyslipidemia'}
            checked={video.dyslipidemia || false}
            setChecked={(value: boolean) => handleChange('dyslipidemia', value)}
          />
        </Box>
        <Box gap={2} flex={1} display={'flex'} flexDirection={'column'}>
          <PopupSliders
            label={'Hypertension'}
            checked={video.hypertension || false}
            setChecked={(value: boolean) => handleChange('hypertension', value)}
          />
          <PopupSliders
            label={'Obesity'}
            checked={video.obesity || false}
            setChecked={(value: boolean) => handleChange('obesity', value)}
          />
        </Box>
      </Box>

      <CustomInput
        fullWidth
        rows={6}
        minRows={6}
        label={'Notes'}
        value={video.notes}
        placeholder={'Type something here'}
        handleChange={(value: string) => handleChange('notes', value)}
      />

      <Box height={'187px'}>
        <ContentDropzone heading={'Attach MP4'} handleContentDrop={handleNewFiles} />
      </Box>

      {attachedVideo && (
        <UploadedFileMarker
          fileName={attachedVideo.name}
          handleRemoveFile={() => setAttachedVideo(undefined)}
          fileSize={fileSize(attachedVideo, data?.attachedVideo as ConduitFile)}
        />
      )}
    </ModalBaseContent>
  );
};

export default ModalAddEditVideoOther;
