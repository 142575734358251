import api from '../../../redux/api';
import { ApiResult } from '@hdcorner/ui-library';

const forgotPasswordQueries = api.injectEndpoints({
  endpoints: build => ({
    forgotPassword: build.mutation<ApiResult<string>, { email: string }>({
      query: ({ email }) => ({
        method: 'POST',
        url: '/authentication/forgot-password',
        body: {
          email,
          redirectUrl: process.env.REACT_APP_FORGOT_REDIRECT_URI,
        },
      }),
    }),
    resetPassword: build.mutation<
      ApiResult<string>,
      { passwordResetToken: string; password: string }
    >({
      query: ({ password, passwordResetToken }) => ({
        method: 'POST',
        url: '/authentication/reset-password',
        body: { password, passwordResetToken },
      }),
    }),
  }),
});

export const { useForgotPasswordMutation, useResetPasswordMutation } =
  forgotPasswordQueries;
