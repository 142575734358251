import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  AdminTablePagination,
  BusinessIcon,
  CalendarIcon,
  CalendarModal,
  ChartIconsType,
  ColumnsIcon,
  CustomButton,
  DeleteCircleIcon,
  FeelsEmpty,
  FinanceIcon,
  HeadCell,
} from '@hdcorner/ui-library';
import GraphToolbar from './GraphToolbar';
import SectionHeader from '../../../components/SectionHeader';
import AdminLineChart from '../../../components/AdminLineChart';
import {
  useGetHCPConnectedUsersQuery,
  useGetHCPUsersGraphQuery,
  useLazyDownloadHcpConnectedUsersSalesQuery,
} from '../queries/hcpQueries';
import UsersTableHeading from '../../users/components/UsersTableHeading';
import {
  Box,
  BoxProps,
  IconButton,
  styled,
  Table,
  TableBody,
  Typography,
  useTheme,
} from '@mui/material';
import moment from 'moment/moment';
import { UsersLog } from '../types/UsersLog';
import useAlert from '../../../hooks/useAlert';
import UsersTableRows from './UsersTableRows';
import ToolbarContainer from '../../../components/ToolbarContainer';
import SalesCalendarModal from '../../../components/SalesCalendarModal';
import { useLazyGetFileUrlQuery } from '../../../queries/fileQueries';
import DownloadButton from '../../../components/DownloadButton';

const DateBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  alignItems: 'center',
  boxSizing: 'border-box',
  gap: theme.spacing(1),
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
}));

const skipAmount = 5;

const headCells: HeadCell[] = [
  { _id: 'name', label: 'User', align: 'left' },
  { _id: 'gender', label: 'Gender', align: 'center', sort: 'gender' },
  { _id: 'sales', label: 'Sales', align: 'center' },
  {
    _id: 'clinicalCoach',
    label: 'Clinical Coach',
    align: 'center',
  },
  {
    _id: 'subscriptionType',
    label: 'Subscription Type',
    align: 'center',
  },
];

type Order = 'asc' | 'desc';

const dateBarIcons: ChartIconsType[] = [
  { name: 'business', icon: BusinessIcon },
  { name: 'finance', icon: FinanceIcon },
  { name: 'second business', icon: ColumnsIcon, rotate: true },
];

type Props = {
  hcpUserId: string;
};
const DataTabHcp: FC<Props> = ({ hcpUserId }) => {
  const theme = useTheme();
  const { presentError } = useAlert();

  const [skip, setSkip] = useState<number>(0);
  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState<Order>('asc');

  const [currentPage, setCurrentPage] = useState(1);
  const [chartType, setChartType] = useState<string>('business');
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
  const [selectedTimeframe, setSelectedTimeframe] = useState<string>('week');

  const [graphEndParam, setGraphEndParam] = useState<string>('');
  const [graphStartParam, setGraphStartParam] = useState<string>('');

  const [openCal, setOpenCal] = useState<boolean>(false);
  // Used for updating the params for the query
  const [endParam, setEndParam] = useState<string>('');
  const [startParam, setStartParam] = useState<string>('');
  const [subscriptionType, setSubscriptionType] = useState<string>('');

  const [getFileUrl] = useLazyGetFileUrlQuery();
  const [downloadFile] = useLazyDownloadHcpConnectedUsersSalesQuery();

  const {
    data: hcpUsersRes,
    error: errorUsers,
    isFetching: dataFetch,
  } = useGetHCPConnectedUsersQuery({
    limit: 6,
    skip: skip,
    id: hcpUserId,
    end: endParam ? endParam : undefined,
    start: startParam ? startParam : undefined,
    subscriptionType: subscriptionType ? subscriptionType : undefined,
  });

  const { data: graphRes, error: errorGraph } = useGetHCPUsersGraphQuery({
    id: hcpUserId,
    end: selectedTimeframe === 'custom date' ? graphEndParam : undefined,
    start: selectedTimeframe === 'custom date' ? graphStartParam : undefined,
    timeframe: selectedTimeframe !== 'custom date' ? selectedTimeframe : undefined,
  });

  useEffect(() => {
    if (errorUsers)
      presentError('An unexpected error occurred fetching connected users data');
    if (errorGraph) presentError('An unexpected error occurred fetching graph data');
  }, [errorUsers, errorGraph]);

  const getSubscriptionType = useMemo(() => {
    if (subscriptionType === 'monthly') {
      return 'Monthly';
    } else if (subscriptionType === 'annually') {
      return 'Annually';
    } else if (subscriptionType === 'bi-annually') {
      return 'Bi-Annually';
    }

    return '-';
  }, [subscriptionType]);

  const rowData = useMemo(() => {
    if (!hcpUsersRes || hcpUsersRes.count === 0) return [];

    return hcpUsersRes.results.map(data => ({
      userId: data._id,
      gender: data.gender || '-',
      sales: data.sales ? 'Yes' : 'No',
      cc: data.clinicalCoach ? 'Yes' : 'No',
      ccDate: data.clinicalCoach
        ? moment(data.clinicalCoach.date).format('DD/MM/YYYY')
        : '-',
      salesDate: data.sales ? moment(data.sales.date).format('DD/MM/YYYY') : '-',
      subscriptionType: data.subscription?.plan ? getSubscriptionType : '-',
      subscriptionDate: data.subscription
        ? moment(data.subscription.activationDate).format('DD/MM/YYYY')
        : '-',
    }));
  }, [getSubscriptionType, hcpUsersRes]);

  const graphData = useMemo(() => {
    const data =
      !graphRes || false
        ? [{ x: '', y: 0 }]
        : graphRes.results.map((it: UsersLog) => ({
            x: moment(it.date).toISOString(),
            y: it.count,
          }));

    return [
      {
        name: 'Users Joined',
        data: data,
      },
    ];
  }, [graphRes]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSkipClick = (amount: number) => {
    setCurrentPage(currentPage => currentPage + amount);
  };

  useEffect(() => {
    const apiSkip = (currentPage - 1) * 12;
    setSkip(apiSkip);
  }, [currentPage]);

  const numberOfPages = useMemo(() => {
    if (!hcpUsersRes) return 0;
    return Math.ceil(hcpUsersRes.count / 6);
  }, [hcpUsersRes]);

  const handleDateChange = (dateRange: string[]) => {
    setGraphStartParam(dateRange[0]);
    setGraphEndParam(dateRange[1]);
  };

  useEffect(() => {
    setCalendarOpen(selectedTimeframe === 'custom date');
  }, [selectedTimeframe]);

  const downloadSales = async () => {
    try {
      const res = await downloadFile({
        id: hcpUserId,
        end: endParam ? endParam : undefined,
        start: startParam ? startParam : undefined,
        subscriptionType: subscriptionType ? subscriptionType : undefined,
      }).unwrap();
      const res2 = await getFileUrl({ fileId: res.id }).unwrap();
      window.open(res2.result, '_blank');
    } catch (e) {
      console.log(e);
      presentError('An unexpected error occurred while downloading the file');
    }
  };

  return (
    <Box gap={3} display={'flex'} flexDirection={'column'}>
      <ToolbarContainer>
        <Box gap={2} display={'flex'} alignItems={'center'}>
          <CustomButton
            variant={'outlined'}
            onClick={() => setOpenCal(true)}
            style={{
              height: '40px',
              border: `1px solid ${theme.palette.kmColorsCoolGrey.main}`,
            }}
          >
            <Box gap={1} display={'flex'} alignItems={'center'}>
              <CalendarIcon color={theme.palette.kmColorsCoolGrey.main} />
              <Typography
                variant={'subtitle2'}
                sx={{
                  color: theme.palette.kmColorsCoolGrey.main,
                  fontWeight: theme.typography.fontWeightBold,
                }}
              >
                Custom Date
              </Typography>
            </Box>
          </CustomButton>

          {startParam !== '' && endParam !== '' && subscriptionType !== '' && (
            <DateBox>
              <Box>
                <Typography
                  variant={'body2'}
                  fontWeight={'bold'}
                  sx={{ color: theme.palette.kmColorsTurquoise.main }}
                >
                  {getSubscriptionType}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant={'body2'}
                  sx={{ color: theme.palette.kmColorsTurquoise.main }}
                >{`Starting date: ${moment(startParam).format(
                  'DD/MM/YYYY',
                )}`}</Typography>
                <Typography
                  variant={'body2'}
                  sx={{ color: theme.palette.kmColorsTurquoise.main }}
                >{`End date: ${moment(endParam).format('DD/MM/YYYY')}`}</Typography>
              </Box>
              <IconButton
                onClick={() => {
                  setEndParam('');
                  setStartParam('');
                  setSubscriptionType('');
                }}
                sx={{ padding: 0 }}
              >
                <DeleteCircleIcon color={theme.palette.kmColorsTurquoise.main} />
              </IconButton>
            </DateBox>
          )}
        </Box>
        <Box>
          <DownloadButton handleDownload={downloadSales} />
        </Box>
      </ToolbarContainer>
      <Box>
        <Box gap={1} display={'flex'} alignItems={'baseline'}>
          <SectionHeader title={'users'} />
          <Typography
            variant={'body1'}
            sx={{
              color: theme.palette.kmColorsTurquoise.main,
              fontWeight: theme.typography.fontWeightRegular,
            }}
          >
            {rowData.length} {rowData.length === 1 ? 'user' : 'users'}
          </Typography>
        </Box>

        {rowData.length === 0 && <FeelsEmpty />}

        {rowData.length > 0 && (
          <Box gap={3} display={'flex'} flexDirection={'column'}>
            <Table>
              <UsersTableHeading
                order={order}
                orderBy={orderBy}
                headCells={headCells}
                totalRowsCount={rowData.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {rowData.map(row => (
                  <UsersTableRows data={row} key={row.userId} />
                ))}
              </TableBody>
            </Table>
            <AdminTablePagination
              loading={dataFetch}
              skipAmount={skipAmount}
              currentPage={currentPage}
              numberOfPages={numberOfPages}
              onSkipClick={handleSkipClick}
            />
          </Box>
        )}
      </Box>

      <GraphToolbar
        type={chartType}
        icons={dateBarIcons}
        setType={setChartType}
        value={selectedTimeframe}
        setValue={setSelectedTimeframe}
      />

      <AdminLineChart label1={'Users Joined'} data={graphData} />

      <CalendarModal
        open={calendarOpen}
        setOpen={setCalendarOpen}
        saveDate={handleDateChange}
        selectedDate={[graphStartParam, graphEndParam]}
      />

      <SalesCalendarModal
        open={openCal}
        setOpen={setOpenCal}
        selectedSubscriptionType={subscriptionType}
        saveData={(data: any) => {
          setSubscriptionType(data.subscriptionType);
          setStartParam(data.startDate);
          setEndParam(data.endDate);
        }}
      />
    </Box>
  );
};

export default DataTabHcp;
