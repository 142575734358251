import { useEffect, useMemo } from 'react';
import moment from 'moment/moment';
import { hoursToMins } from '../utils/dataConversion';
import { MyDataComponent } from '@hdcorner/ui-library';
import { useGetUserAveragesQuery } from '../queries/userAverageQueries';
import useAlert from '../../../hooks/useAlert';

const DashDataCards = () => {
  const { presentError } = useAlert();

  const { error, data: trendRes } = useGetUserAveragesQuery();

  useEffect(() => {
    if (error) presentError('An unexpected error occurred fetching user averages data');
  }, [error]);

  const trendData = useMemo<any>(() => {
    const date = trendRes?.updatedAt
      ? moment(trendRes.updatedAt).format('MMM YYYY')
      : '-';

    const fitness = trendRes?.fitness;
    const fitnessData = {
      title: 'Fitness',
      twoColumn: true,
      date: date,
      data: [
        {
          label: 'Exercise duration',
          info: fitness?.duration ? hoursToMins(fitness.duration) : '0h 0mins',
        },
        {
          label: 'Steps ',
          info: fitness?.steps ? Math.round(fitness.steps) : '0',
        },
      ],
    };

    const hyp = trendRes?.hypertension;
    const hypertensionLogs = {
      title: 'Hypertension',
      twoColumn: true,
      date: date,
      data: [
        {
          label: 'Systolic Pressure',
          trend: hyp?.systolic.trend ? hyp.systolic.trend : 'stable',
          info: `${
            hyp?.systolic.measurement ? Math.round(hyp.systolic.measurement) : '-'
          } mmHG`,
        },
        {
          label: 'Diastolic Pressure',
          trend: hyp?.diastolic.trend ? hyp.diastolic.trend : 'stable',
          info: `${
            hyp?.diastolic.measurement ? Math.round(hyp.diastolic.measurement) : '-'
          } mmHG`,
        },
        {
          label: 'Pulses',
          trend: hyp?.pulses.trend ? hyp.pulses.trend : 'stable',
          info: `${
            hyp?.pulses.measurement ? Math.round(hyp.pulses.measurement) : '-'
          } BPM`,
        },
      ],
    };

    const diabetes = trendRes?.diabetes;
    const bloodGlucoseLogs = {
      title: 'Diabetes',
      date: date,
      data: [
        {
          label: 'Blood Glucose',
          trend: diabetes?.bloodGlucose.trend ? diabetes.bloodGlucose.trend : 'stable',
          info: `${
            diabetes?.bloodGlucose.measurement
              ? Math.round(diabetes.bloodGlucose.measurement)
              : '-'
          } mg/dl`,
        },
        {
          label: 'HbAc1',
          trend: diabetes?.hbac1.trend ? diabetes.hbac1.trend : 'stable',
          info: `${
            diabetes?.hbac1.measurement ? diabetes.hbac1.measurement.toFixed(1) : '-'
          } %`,
        },
      ],
    };

    const meds = trendRes?.medication;
    const medicationData = {
      title: 'Medication',
      users: `(${meds?.activeUsers} active users)`,
      date: date,
      data: [
        {
          label: 'Taken',
          trend: meds?.taken.trend ? meds.taken.trend : 'stable',
          info: `${
            meds?.taken.measurement || meds?.taken.measurement === 0
              ? Math.round(meds.taken.measurement)
              : '-'
          } %`,
        },
        {
          label: 'Not taken',
          trend: meds?.notTaken.trend ? meds.notTaken.trend : 'stable',
          info: `${
            meds?.notTaken.measurement ? Math.round(meds.notTaken.measurement) : '-'
          } %`,
        },
      ],
    };

    const nutrition = trendRes?.nutrition;
    const nutritionData = {
      title: 'Nutrition',
      date: date,
      users: `(${nutrition?.activeUsers ? nutrition.activeUsers : '0'} active users)`,
      data: [
        {
          label: 'Daily kcal intake',
          info: `${
            nutrition?.dailyIntake || nutrition?.dailyIntake === 0
              ? Math.round(nutrition.dailyIntake)
              : '-'
          }`,
        },
        {
          label: 'Real kcal intake',
          trend: nutrition?.realIntake.trend ? nutrition.realIntake.trend : 'stable',
          info: `${
            nutrition?.realIntake.measurement || nutrition?.realIntake.measurement === 0
              ? Math.round(nutrition.realIntake.measurement)
              : '-'
          }`,
        },
      ],
    };

    const cvd = trendRes?.cvd;
    const cvdData = {
      title: 'CVD',
      date: date,
      data: [
        {
          label: 'Overall Risk',
          trend: cvd?.risk.trend ? cvd.risk.trend : 'stable',
          info: `${cvd?.risk.measurement ? cvd.risk.measurement.toFixed(1) : '-'} %`,
        },
      ],
    };

    const lipid = trendRes?.lipidProfile;
    const lipidLogs = {
      title: 'Lipid Profile',
      twoColumn: true,
      date: date,
      data: [
        {
          label: 'Chol',
          trend: lipid?.chol.trend ? lipid.chol.trend : 'stable',
          info: `${
            lipid?.chol.measurement ? Math.round(lipid.chol.measurement) : '-'
          } mg/dl`,
        },
        {
          label: 'HDL',
          trend: lipid?.hdl.trend ? lipid.hdl.trend : 'stable',
          info: `${
            lipid?.hdl.measurement ? Math.round(lipid.hdl.measurement) : '-'
          } mg/dl`,
        },
        {
          label: 'LDL',
          trend: lipid?.ldl.trend ? lipid.ldl.trend : 'stable',
          info: `${
            lipid?.ldl.measurement ? Math.round(lipid.ldl.measurement) : '-'
          } mg/dl`,
        },
        {
          label: 'TRG',
          trend: lipid?.trg.trend ? lipid.trg.trend : 'stable',
          info: `${
            lipid?.trg.measurement ? Math.round(lipid.trg.measurement) : '-'
          } mg/dl`,
        },
        {
          label: 'LPA',
          trend: lipid?.lpa.trend ? lipid.lpa.trend : 'stable',
          info: `${
            lipid?.lpa.measurement ? Math.round(lipid.lpa.measurement) : '-'
          } mg/dl`,
        },
      ],
    };

    return [
      { fitness: fitnessData },
      { hypertension: hypertensionLogs },
      { diabetes: bloodGlucoseLogs },
      { medication: medicationData },
      { nutrition: nutritionData },
      { cvd: cvdData },
      { lipid: lipidLogs },
    ];
  }, [trendRes]);

  return <MyDataComponent data={trendData} />;
};

export default DashDataCards;
