import { FC } from 'react';
import ToolbarContainer from '../../../components/ToolbarContainer';
import { Box, BoxProps, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CustomButton } from '@hdcorner/ui-library';

const Divider = styled(Box)<BoxProps>(({ theme }) => ({
  width: '2px',
  opacity: 0.1,
  height: '16px',
  backgroundColor: theme.palette.kmColorsCoolGrey.main,
}));

type Props = {
  videoNum: number;
  handleCancel: () => void;
  handleNewProgram: () => void;
  handleAddToProgram: () => void;
};

const ChooseVideoToolbar: FC<Props> = ({
  videoNum,
  handleCancel,
  handleNewProgram,
  handleAddToProgram,
}) => {
  const theme = useTheme();
  return (
    <ToolbarContainer>
      <Typography
        variant={'subtitle2'}
        sx={{
          color: theme.palette.kmColorsCoolGrey.main,
          fontWeight: theme.typography.fontWeightBold,
        }}
      >
        {videoNum === 1 ? `${videoNum} video selected` : `${videoNum} videos selected`}
      </Typography>
      <CustomButton variant={'text'} onClick={handleCancel} style={{ padding: 0 }}>
        Cancel
      </CustomButton>

      <Divider />

      <CustomButton
        color={'secondary'}
        variant={'contained'}
        onClick={handleNewProgram}
        style={{
          width: '185px',
          boxShadow: 'none',
          background: 'rgba(0, 0, 0, 0.12)',
          color: theme.palette.kmColorsCoolGrey.main,
        }}
      >
        Create new program
      </CustomButton>
      <CustomButton
        color={'secondary'}
        variant={'contained'}
        onClick={handleAddToProgram}
        style={{
          width: '185px',
          boxShadow: 'none',
          background: 'rgba(0, 0, 0, 0.12)',
          color: theme.palette.kmColorsCoolGrey.main,
        }}
      >
        Add to program
      </CustomButton>
    </ToolbarContainer>
  );
};

export default ChooseVideoToolbar;
