import { Box } from '@mui/material';
import React, { useState } from 'react';
import NavFoodEquivalent from './components/NavFoodEquivalent';
import { FEData, FETableInner, ModalMobile } from '@hdcorner/ui-library';

const ContentFoodEquivPanel = () => {
  const [title, setTitle] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);

  const handleNextPage = (value: any) => {
    setTitle(value.title);
    setOpen(true);
  };

  return (
    <Box>
      <Box gap={1} display={'flex'} flexWrap={'wrap'} flexDirection={'row'}>
        {FEData().map((it, index) => (
          <NavFoodEquivalent
            icon={it.icon}
            heading={it.title}
            key={`${it.title}-${index}`}
            handleNavClick={() => handleNextPage(it)}
          />
        ))}
      </Box>
      <ModalMobile open={open} setOpen={setOpen}>
        <FETableInner admin header={title} />
      </ModalMobile>
    </Box>
  );
};

export default ContentFoodEquivPanel;
