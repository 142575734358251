import api from '../../../redux/api';
import { ApiPagination, PaginationResult, Quote } from '@hdcorner/ui-library';

const quoteQueries = api.enhanceEndpoints({ addTagTypes: ['Quote'] }).injectEndpoints({
  endpoints: build => ({
    getQuotes: build.query<PaginationResult<Quote>, ApiPagination>({
      query: ({ skip, limit, sort }) => ({
        url: '/hdCornerService/admin/content/quote',
        method: 'GET',
        params: { skip, limit, sort },
      }),
      providesTags: ['Quote'],
    }),

    postQuote: build.mutation<void, Quote>({
      query: quote => ({
        url: '/hdCornerService/admin/content/quote',
        method: 'POST',
        body: quote,
      }),
      invalidatesTags: ['Quote'],
    }),

    editQuote: build.mutation<void, Quote & { id: string }>({
      query: ({ id, ...rest }) => ({
        url: `/hdCornerService/admin/content/quote/${id}`,
        method: 'PATCH',
        body: rest,
      }),
      invalidatesTags: ['Quote'],
    }),

    deleteQuote: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/hdCornerService/admin/content/quote/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Quote'],
    }),
  }),
});

export const {
  useGetQuotesQuery,
  usePostQuoteMutation,
  useEditQuoteMutation,
  useDeleteQuoteMutation,
} = quoteQueries;

export default quoteQueries;
