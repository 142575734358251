import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
  useMediaQuery,
} from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useAppSelector } from '../../../redux/hooks';
import { useGetESHQuery } from '../queries/eshQueries';
import { convertDateFormat } from '../../../utils/dateTimeUtils';
import {
  capitalizeFirstLetter,
  ESHType,
  InDepthDataCard,
  theme,
} from '@hdcorner/ui-library';
import useAlert from '../../../hooks/useAlert';

const BoxMain = styled(Box)<BoxProps>(({ theme }) => ({
  width: '100%',
  display: 'flex',
  borderRadius: '8px',
  flexDirection: 'column',
  backgroundColor: theme.palette.secondary.light,
}));

const BoxTitle = styled(Box)<BoxProps & { web: boolean }>(({ web, theme }) => ({
  flex: 1,
  display: 'flex',
  borderRadius: '8px',
  boxSizing: 'border-box',
  padding: web ? 0 : theme.spacing(1),
  backgroundColor: web ? 'none' : theme.palette.secondary.light,
}));

const AveragesTypography = styled(Typography, {
  shouldForwardProp: propName => propName !== 'web',
})<TypographyProps & { web: boolean }>(({ web, theme }) => ({
  color: web ? theme.palette.primary.light : theme.palette.primary.main,
  fontWeight: web
    ? theme.typography.fontWeightRegular
    : theme.typography.fontWeightMedium,
}));

type Props = { userId: string };
const ESHTableAverages: FC<Props> = ({ userId }) => {
  const { presentError } = useAlert();
  const web = useMediaQuery(theme.breakpoints.up('sm'));

  const { customDateFilter } = useAppSelector(state => state.layout);

  const endDate = moment().utc(true).endOf('day');
  const startDate = moment().add(-6, 'days').utc(true).startOf('day');

  const [params, setParams] = useState({
    start: startDate.toISOString(),
    end: endDate.toISOString(),
  });

  const [displayDate, setDisplayDate] = useState({
    start: startDate.format('DD/MM/YYYY'),
    end: endDate.format('DD/MM/YYYY'),
  });

  const { error, data: eshRes } = useGetESHQuery({
    userId,
    end: params.end,
    start: params.start,
  });

  useEffect(() => {
    if (error) presentError('An error occurred fetching the data');
  }, [error]);

  useEffect(() => {
    if (!customDateFilter) return;

    setParams({ start: customDateFilter.start, end: customDateFilter.end });
    setDisplayDate({
      start: convertDateFormat(customDateFilter.start),
      end: convertDateFormat(customDateFilter.end),
    });
  }, [customDateFilter]);

  const findResults = useCallback(
    (value: keyof ESHType) => {
      const unit = value === 'pulses' ? 'bpm' : 'mmHG';

      if (!eshRes || Object.keys(eshRes.diastolic).length === 0 || value === 'logs')
        return {
          name: capitalizeFirstLetter(value),
          min: `- ${unit}`,
          max: `- ${unit}`,
          avg: `- ${unit}`,
        };

      const measurement = eshRes[value];

      return {
        name: capitalizeFirstLetter(value),
        min: `${measurement.min} ${unit}`,
        max: `${measurement.max} ${unit}`,
        avg: `${measurement.avg} ${unit}`,
      };
    },
    [eshRes],
  );

  const rows = useMemo(() => {
    return [findResults('systolic'), findResults('diastolic'), findResults('pulses')];
  }, [findResults]);

  return (
    <Box>
      <Box
        mb={1}
        flex={1}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <BoxTitle web={web}>
          <AveragesTypography web={web} variant={web ? 'subtitle1' : 'subtitle2'}>
            Averages
          </AveragesTypography>
        </BoxTitle>
      </Box>
      <BoxMain>
        <InDepthDataCard
          esh
          profApp
          rows={rows}
          headings={['Min.', 'Max.', 'Avg.']}
          altHeading={`${displayDate.start} - ${displayDate.end}`}
        />
      </BoxMain>
    </Box>
  );
};

export default ESHTableAverages;
