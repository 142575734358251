import React, { FC } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { CustomDropzoneArea } from '@hdcorner/ui-library';

type Props = {
  heading: string;
  handleContentDrop: (files: File[], base64Files: string[]) => void;
};

const ContentDropzone: FC<Props> = ({ heading, handleContentDrop }) => {
  const theme = useTheme();

  return (
    <Box
      gap={1}
      flex={1}
      height={'100%'}
      display={'flex'}
      flexDirection={'column'}
      sx={{ boxSizing: 'borderBox' }}
    >
      <Typography
        variant={'subtitle2'}
        sx={{
          color: theme.palette.kmColorsCoolGrey.main,
          fontWeight: theme.typography.fontWeightRegular,
        }}
      >
        {heading}
      </Typography>
      <CustomDropzoneArea
        onFilesSelected={handleContentDrop}
        textUp={'Drag & drop your files here'}
        children={
          <Typography
            variant={'subtitle2'}
            sx={{ fontWeight: theme.typography.fontWeightRegular }}
          >
            or <span style={{ color: theme.palette.kmColorsTurquoise.main }}>browse</span>{' '}
            your computer
          </Typography>
        }
      />
    </Box>
  );
};

export default ContentDropzone;
