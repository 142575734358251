import React, { useEffect, useMemo, useState } from 'react';
import {
  CalendarModal,
  HealthProfDashboardCard,
  SQIconContainer,
  theme,
  UsersSQ,
} from '@hdcorner/ui-library';
import moment from 'moment';
import PageLayout from '../../PageLayout';
import DateBar from './components/DateBar';
import { Box, useMediaQuery } from '@mui/material';
import DashDataCards from './components/DashDataCards';
import handleTrendIcon from '../../utils/handleTrendIcon';
import SectionHeader from '../../components/SectionHeader';
import AdminLineChart from '../../components/AdminLineChart';
import { useGetDashGraphQuery } from './queries/dashGraphQueries';
import { useGetTotalHPUsersQuery } from './queries/totalHPUsersQueries';
import HealthProfessionalSQ from '../../assets/icons/HealthProfessionalSQ';
import useAlert from '../../hooks/useAlert';

const icons = [
  <SQIconContainer color={theme.palette.kmColorsOrange.main}>
    <HealthProfessionalSQ />
  </SQIconContainer>,
  <UsersSQ />,
];

const Dashboard = () => {
  const { presentError } = useAlert();

  const web = useMediaQuery(theme.breakpoints.up('sm'));

  const [sex, setSex] = useState<string>('both');

  const [selectedTimeframe, setSelectedTimeframe] = useState<string>('week');
  const [startParam, setStartParam] = useState<string>('');
  const [endParam, setEndParam] = useState<string>('');
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);

  const { error: hPUsersError, data: usersRes } = useGetTotalHPUsersQuery();
  const { error: graphError, data: graphRes } = useGetDashGraphQuery({
    gender: sex === 'both' ? undefined : sex,
    end: selectedTimeframe === 'custom date' ? endParam : undefined,
    start: selectedTimeframe === 'custom date' ? startParam : undefined,
    timeframe: selectedTimeframe !== 'custom date' ? selectedTimeframe : undefined,
  });

  useEffect(() => {
    if (hPUsersError)
      presentError('An unexpected error occurred fetching healthcare professionals data');
    if (graphError) presentError('An unexpected error occurred fetching graph data');
  }, [graphError, hPUsersError]);

  const totalUsers = useMemo(() => {
    const date = usersRes ? moment(usersRes.latestUpdate).format('MMM YYYY') : 'N/A';
    return [
      {
        date: date,
        title: 'Health Care Professionals',
        count: usersRes ? usersRes.healthProfessionals.total : 0,
        direction: usersRes ? usersRes.healthProfessionals.trend : 'stable',
      },
      {
        date: date,
        title: 'Users',
        count: usersRes ? usersRes.users.total : 0,
        direction: usersRes ? usersRes.users.trend : 'stable',
      },
    ];
  }, [usersRes]);

  const prepareData = (log: string) => {
    if (!graphRes || graphRes.results.length === 0) return [{ x: '', y: 0 }];
    return graphRes?.results.map((it: any) => ({
      x: moment(it.date).toISOString(),
      y: it[log],
    }));
  };

  const graphData = useMemo(() => {
    const graphPlots = [
      {
        name: 'Users with  normal systolic pressure',
        data: prepareData('hypertension'),
      },
      {
        name: 'Users with normal glucose levels',
        data: prepareData('diabetes'),
      },
      {
        name: 'Users with both',
        data: prepareData('both'),
      },
    ];

    return graphPlots;
  }, [graphRes]);

  const handleDateChange = (dateRange: string[]) => {
    setStartParam(dateRange[0]);
    setEndParam(dateRange[1]);
  };

  useEffect(() => {
    setCalendarOpen(selectedTimeframe === 'custom date');
  }, [selectedTimeframe]);

  return (
    <PageLayout>
      <Box gap={3} padding={3} display={'flex'} flexDirection={'column'}>
        <Box display={'flex'} flex={1} alignItems={'center'} flexWrap={'wrap'} gap={2}>
          {totalUsers?.map((item, index) => (
            <HealthProfDashboardCard
              key={index}
              expanded={web}
              num={item.count}
              date={item.date}
              icon={icons[index]}
              heading={item.title}
              iconTwo={handleTrendIcon(item.direction)}
            />
          ))}
        </Box>

        <Box>
          <SectionHeader title={'user averages'} />
          <DashDataCards />
        </Box>

        <DateBar
          sex={sex}
          setSex={setSex}
          value={selectedTimeframe}
          setValue={setSelectedTimeframe}
        />

        <AdminLineChart
          data={graphData}
          label3={'Users with both'}
          label2={'Users with normal glucose levels'}
          label1={'Users with  normal systolic pressure'}
        />
      </Box>

      <CalendarModal
        open={calendarOpen}
        setOpen={setCalendarOpen}
        saveDate={handleDateChange}
        selectedDate={[startParam, endParam]}
      />
    </PageLayout>
  );
};

export default Dashboard;
