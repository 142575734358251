import api from '../../../redux/api';
import { ApiPagination, CvdRiskLog, PaginationResult } from '@hdcorner/ui-library';

const cvdRiskQueries = api
  .enhanceEndpoints({ addTagTypes: ['CvdRiskLog'] })
  .injectEndpoints({
    endpoints: build => ({
      getCvdRiskLogs: build.query<
        PaginationResult<CvdRiskLog>,
        ApiPagination & { start?: string; end?: string; userId: string }
      >({
        query: ({ skip, limit, start, end, userId }) => ({
          url: `hdCornerService/user/cvd-risk/in-depth`,
          method: 'GET',
          params: { skip, limit, sort: ['-logDate'], start, end, userId },
        }),
        serializeQueryArgs: ({ queryArgs }) => {
          const { skip, limit, start, end, sort } = queryArgs;
          return { params: { skip, limit, start, end, sort } };
        },
        merge: (currentCache, newItems, otherArgs) => {
          if (otherArgs.arg.skip === 0) {
            return newItems;
          } else {
            currentCache?.documents.push(...newItems?.documents);
          }
        },
        forceRefetch: ({ previousArg, currentArg }) =>
          previousArg?.skip !== currentArg?.skip ||
          previousArg?.limit !== currentArg?.limit ||
          previousArg?.start !== currentArg?.start ||
          previousArg?.end !== currentArg?.end,
        providesTags: result =>
          result?.documents
            ? [
                ...result.documents.map(({ _id: id }) => ({
                  type: 'CvdRiskLog' as const,
                  id,
                })),
              ]
            : [],
      }),

      getCvdCurrentMeasurement: build.query<
        PaginationResult<CvdRiskLog>,
        { userId: string }
      >({
        query: ({ userId }) => ({
          url: `hdCornerService/user/cvd-risk/in-depth`,
          method: 'GET',
          params: { skip: 0, limit: 1, sort: '-logDate', userId },
        }),
      }),

      getCvdGraphData: build.query<
        { logs: CvdRiskLog[] },
        { start?: string; end?: string; userId: string }
      >({
        query: ({ start, end, userId }) => ({
          url: `hdCornerService/user/cvd-risk/graph`,
          method: 'GET',
          params: { start, end, userId },
        }),
      }),
    }),
  });

export const {
  useGetCvdRiskLogsQuery,
  useGetCvdGraphDataQuery,
  useGetCvdCurrentMeasurementQuery,
} = cvdRiskQueries;

export default cvdRiskQueries;
