const hoursToMins = (num: number) => {
  const min = Math.round(num);
  const hours = Math.floor(min / 60);
  const minutes = min % 60;

  const hoursString = hours > 0 ? `${hours}h ` : '';
  const minutesString = minutes > 0 ? `${minutes}min` : '';

  if (min < 1) {
    return '0 mins';
  } else {
    return `${hoursString}${minutesString}`;
  }
};

export { hoursToMins };
