import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment/moment';
import PageLayout from '../../PageLayout';
import { useIonRouter } from '@ionic/react';
import ModalAddHCP from './components/ModalAddHCP';
import { professionCategories } from './constants';
import { Table, TableBody, useTheme } from '@mui/material';
import { convertEnumValue } from '../content/utils/dataConversion';
import {
  useGetHealthProfessionalsQuery,
  useLazyDownloadHcpSalesQuery,
} from './queries/hcpQueries';
import HealthProfMainToolbar from './components/HealthProfMainToolbar';
import {
  AdminTablePagination,
  ComponentsLayout,
  FloatingActionButton,
  HeadCell,
} from '@hdcorner/ui-library';
import HealthProfessionalsTableRows from './components/HealthProfessionalsTableRows';
import HealthProfessionalsTableHeading from './components/HealthProfessionalsTableHeading';
import { tableSort } from '../../utils/tableSorting';
import useAlert from '../../hooks/useAlert';
import SalesCalendarModal from '../../components/SalesCalendarModal';
import { useLazyGetFileUrlQuery } from '../../queries/fileQueries';

const createData = (row: any) => {
  return [row.occupation, row.activationDate, row.sales, row.clinicalCoach, row.combo];
};

const headCells: HeadCell[] = [
  { _id: 'user', label: 'User', align: 'left' },
  { _id: 'occupation', label: 'Occupation', align: 'left' },
  { _id: 'activationDate', label: 'Activation date', align: 'left' },
  { _id: 'sales', label: 'Sales', align: 'left' },
  { _id: 'clinicalCoach', label: 'Clinical Coach', align: 'left' },
  { _id: 'combo', label: 'Combo', align: 'left' },
];

type Order = 'asc' | 'desc';

const HealthProfessionals: React.FC = () => {
  const theme = useTheme();
  const router = useIonRouter();
  const { presentError } = useAlert();

  const [skip, setSkip] = useState<number>(0);
  const [order, setOrder] = useState<Order>('asc');
  const [open, setOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selected, setSelected] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState('activationDate');

  const [openCal, setOpenCal] = useState<boolean>(false);
  // Used for updating the params for the query
  const [endParam, setEndParam] = useState<string>('');
  const [startParam, setStartParam] = useState<string>('');
  const [subscriptionType, setSubscriptionType] = useState<string>('');

  const {
    error,
    data: hcpRes,
    isFetching: dataFetch,
  } = useGetHealthProfessionalsQuery({
    limit: 15,
    skip: skip,
    end: endParam ? endParam : undefined,
    start: startParam ? startParam : undefined,
    sort: [tableSort(orderBy, order, headCells)],
    subscriptionType: subscriptionType ? subscriptionType : undefined,
  });

  const [getFileUrl] = useLazyGetFileUrlQuery();
  const [downloadFile] = useLazyDownloadHcpSalesQuery();

  useEffect(() => {
    if (error)
      presentError('An unexpected error occurred fetching healthcare professionals data');
  }, [error]);

  const hcpRows = useMemo(() => {
    if (!hcpRes || hcpRes.results.length === 0) return [];

    return hcpRes.results.map(data => ({
      _id: data.authUser._id,
      fullName: data.authUser
        ? `${data.authUser.firstName} ${data.authUser.lastName}`
        : '',
      status: data.verified,
      email: data.authUser ? data.authUser.email : '',
      activationDate: moment(data.createdAt).format('DD/MM/YYYY'),
      occupation: convertEnumValue(data.speciality, professionCategories),
      avatar:
        data.authUser.profilePicture && typeof data.authUser.profilePicture === 'object'
          ? data.authUser.profilePicture.url
          : '',
      sales: data.sales ? data.sales : '-',
      combo: data.combo ? data.combo : '-',
      clinicalCoach: data.clinicalCoach ? data.clinicalCoach : '-',
      authUserId: data.authUser ? data.authUser._id : '',
    }));
  }, [hcpRes]);

  const handleSelectAll = () => {
    if (selected.length === hcpRows.length) {
      setSelected([]);
    } else {
      setSelected(hcpRows.map(row => row.authUserId || ''));
    }
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const handleAvatarClick = (id: string) => {
    const newSelected = [...selected];
    if (selected.includes(id)) {
      const itemIndex = selected.findIndex(selectedId => selectedId === id);
      newSelected.splice(itemIndex, 1);
    } else {
      newSelected.push(id);
    }
    setSelected(newSelected);
  };

  const handleRowClick = (id: string) => {
    router.push(`/dashboard/healthProfessional/${id}`);
  };
  const handleSkipClick = (amount: number) => {
    setCurrentPage(currentPage => currentPage + amount);
  };

  useEffect(() => {
    const apiSkip = (currentPage - 1) * 12;
    setSkip(apiSkip);
  }, [currentPage]);

  const numberOfPages = useMemo(() => {
    if (!hcpRes) return 0;
    return Math.ceil(hcpRes.count / 12);
  }, [hcpRes]);

  const downloadSales = async () => {
    try {
      const res = await downloadFile({
        end: endParam ? endParam : undefined,
        start: startParam ? startParam : undefined,
        subscriptionType: subscriptionType ? subscriptionType : undefined,
      }).unwrap();
      const res2 = await getFileUrl({ fileId: res.id }).unwrap();
      window.open(res2.result, '_blank');
    } catch (e) {
      console.log(e);
      presentError('An unexpected error occurred while downloading the file');
    }
  };

  return (
    <PageLayout>
      <ComponentsLayout
        height={'100%'}
        display={'flex'}
        overflow={'scroll'}
        flexDirection={'column'}
        gap={theme.spacing(3)}
        sx={{
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <HealthProfMainToolbar
          selected={selected}
          subscriptionType={subscriptionType}
          handleClearSelection={handleSelectAll}
          handleCustomDate={() => setOpenCal(true)}
          dates={{
            endDate: endParam,
            startDate: startParam,
          }}
          handleDownload={downloadSales}
          handleClearDate={() => {
            setEndParam('');
            setStartParam('');
            setSubscriptionType('');
          }}
        />
        <Table>
          <HealthProfessionalsTableHeading
            order={order}
            orderBy={orderBy}
            headCells={headCells}
            totalRowsCount={hcpRows.length}
            onRequestSort={handleRequestSort}
            onSelectAllClick={handleSelectAll}
            totalRowsSelected={selected.length}
            onCancelClick={() => setSelected([])}
          />
          <TableBody>
            {hcpRows.map(row => (
              <HealthProfessionalsTableRows
                key={row._id}
                avatar={row.avatar}
                name={row.fullName}
                rowCells={createData(row)}
                verified={row.status || false}
                selected={selected.length >= 1}
                email={row.email ? row.email : ''}
                checked={isSelected(row._id || '')}
                handleAvatarClick={() => handleAvatarClick(row.authUserId || '')}
                handleRowClick={() =>
                  handleRowClick(row.authUserId ? row.authUserId : '')
                }
              />
            ))}
          </TableBody>
        </Table>
        <AdminTablePagination
          skipAmount={skip}
          loading={dataFetch}
          currentPage={currentPage}
          numberOfPages={numberOfPages}
          onSkipClick={handleSkipClick}
        />
        <FloatingActionButton onClick={() => setOpen(true)} />
        <ModalAddHCP open={open} setOpen={setOpen} />
        <SalesCalendarModal
          open={openCal}
          setOpen={setOpenCal}
          selectedSubscriptionType={subscriptionType}
          saveData={(data: any) => {
            setSubscriptionType(data.subscriptionType);
            setStartParam(data.startDate);
            setEndParam(data.endDate);
          }}
        />
      </ComponentsLayout>
    </PageLayout>
  );
};

export default HealthProfessionals;
