import React, { FC, useEffect, useMemo } from 'react';
import { CircularChart, SmallMacros, theme, useTimeframe } from '@hdcorner/ui-library';
import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
  useMediaQuery,
} from '@mui/material';
import useAlert from '../../../hooks/useAlert';
import { useAppSelector } from '../../../redux/hooks';
import SectionHeader from '../../../components/SectionHeader';
import { useGetCaloriesInDepthQuery } from '../queries/caloriesQueries';

const BoxCard = styled(Box, { shouldForwardProp: propName => propName !== 'web' })<
  BoxProps & { web: boolean }
>(({ web, theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  position: 'relative',
  boxSizing: 'border-box',
  flexDirection: 'column',
  minWidth: web ? '517px' : 'none',
  backgroundColor: theme.palette.secondary.main,
  padding: theme.spacing(2, 3),
}));

const BoxSecondary = styled(Box)<BoxProps>(() => ({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const BoxGraph = styled(Box, { shouldForwardProp: propName => propName !== 'web' })<
  BoxProps & { web: boolean }
>(({ web }) => ({
  order: web ? 2 : 1,
  marginRight: '-30px',
  width: web ? '212px' : '180px',
  marginLeft: web ? '-30px' : '38px',
}));

const BoxColumn = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  minWidth: '82px',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const TypographySubText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.kmColorsDarkGrey.main,
  fontSize: theme.typography.subtitle2.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
}));

type SectionGraphProps = {
  userId: string;
  heading?: boolean;
};
const CaloriesSectionGraph: FC<SectionGraphProps> = ({ userId, heading }) => {
  const { presentError } = useAlert();
  const { dateFilter, customDateFilter } = useAppSelector(state => state.layout);

  const web = useMediaQuery(theme.breakpoints.up('sm'));

  const timeframe = useTimeframe(
    dateFilter,
    customDateFilter?.start,
    customDateFilter?.end,
  );

  const { error, data: calorieRes } = useGetCaloriesInDepthQuery({
    userId,
    ...timeframe,
    params: { skip: 0, limit: 1 },
  });

  useEffect(() => {
    if (error) {
      presentError('Error fetching calories');
    }
  }, [error]);

  const graphData = useMemo(() => {
    const data = calorieRes?.documents[0];

    return {
      caloriesLeft: data?.left ? Math.round(data.left) : 0,
      caloriesConsumed: data?.eaten ? Math.round(data.eaten) : 0,
      caloriesBurned: data?.burned ? Math.round(data.burned) : 0,
      caloriesTotal: data?.calculated ? Math.round(data.calculated + data.burned) : 0,
    };
  }, [calorieRes]);

  const prepareMacros = useMemo(() => {
    const data = calorieRes?.documents[0];

    return [
      {
        measure: 'gr',
        category: 'Carbs',
        total: data ? Math.round(data.carbs.total) : 0,
        consumed: data ? Math.round(data.carbs.eaten) : 0,
      },
      {
        measure: 'gr',
        category: 'Protein',
        total: data ? Math.round(data.protein.total) : 0,
        consumed: data ? Math.round(data.protein.eaten) : 0,
      },
      {
        measure: 'gr',
        category: 'Fat',
        total: data ? Math.round(data.fat.total) : 0,
        consumed: data ? Math.round(data.fat.eaten) : 0,
      },
    ];
  }, [calorieRes]);

  return (
    <Box width={'100%'} display={'flex'} flexDirection={'column'}>
      <Box mb={1} display={heading ? 'block' : 'none'}>
        <SectionHeader title={'Daily calories intake'} />
      </Box>
      <BoxCard web={web}>
        <Box display={web ? 'flex' : 'block'}>
          <BoxSecondary>
            <BoxGraph web={web} width={web ? '50%' : 'none'}>
              <CircularChart
                left={graphData.caloriesLeft}
                total={graphData.caloriesTotal}
              />
            </BoxGraph>
            <BoxColumn order={web ? 1 : 2} width={web ? '50%' : 'none'}>
              <Box>
                <Typography
                  variant={'h1'}
                  sx={{ color: theme.palette.kmColorsCoolGrey.main }}
                >
                  {graphData.caloriesConsumed} kcal
                </Typography>
                <TypographySubText>Consumed</TypographySubText>
              </Box>
              <Box>
                <Typography
                  variant={'h1'}
                  sx={{ color: theme.palette.kmColorsCoolGrey.main }}
                >
                  {graphData.caloriesBurned} kcal
                </Typography>
                <TypographySubText>Burned</TypographySubText>
              </Box>
            </BoxColumn>
          </BoxSecondary>
          <BoxSecondary
            width={'100%'}
            gap={web ? 1 : 3}
            maxWidth={web ? '33%' : 'none'}
            flexDirection={web ? 'column' : 'row'}
          >
            {prepareMacros.map(it => (
              <Box flex={1} key={it.category} display={'flex'} width={'100%'}>
                <SmallMacros
                  max={it.total}
                  value={it.consumed}
                  title={it.category}
                  isProgressBarWide={web}
                  measure={`${it.consumed} / ${it.total} ${it.measure}`}
                />
              </Box>
            ))}
          </BoxSecondary>
        </Box>
      </BoxCard>
    </Box>
  );
};

export default CaloriesSectionGraph;
