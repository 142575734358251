import { Box, useTheme } from '@mui/material';
import ChartSteps from './ChartSteps';
import SectionStepsData from './SectionStepsData';
import SectionActivitiesData from './SectionActivitiesData';
import SectionActivitiesCal from './SectionActivitiesCal';
import { FC } from 'react';

type Props = { userId: string };

const FitnessPanelExercise: FC<Props> = ({ userId }) => {
  const theme = useTheme();
  return (
    <Box display={'flex'} flex={1} gap={theme.spacing(3)}>
      <Box flex={1}>
        <Box display={'flex'} gap={theme.spacing(3)} flexDirection={'column'}>
          <ChartSteps userId={userId} />
          <SectionStepsData userId={userId} />
        </Box>
      </Box>
      <Box flex={1}>
        <Box display={'flex'} gap={theme.spacing(3)} flexDirection={'column'}>
          <SectionActivitiesCal userId={userId} />
          <SectionActivitiesData userId={userId} />
        </Box>
      </Box>
    </Box>
  );
};

export default FitnessPanelExercise;
