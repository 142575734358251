import React, { FC } from 'react';
import Modal from '@mui/material/Modal';
import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomBox = styled(Box)<BoxProps>(({ theme }) => ({
  bottom: '-74px',
  right: '-74px',
  borderRadius: '8px',
  position: 'absolute',
  boxSizing: 'border-box',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.secondary.main,
}));

export type ModalMobileProps = {
  open: boolean;
  children: React.ReactNode;
  setOpen: (open: boolean) => void;
};

const FABModal: FC<ModalMobileProps> = ({ open, setOpen, children }) => {
  const handleClose = () => setOpen(false);

  return (
    <Modal open={open} onClose={handleClose}>
      <CustomBox>{children}</CustomBox>
    </Modal>
  );
};

export default FABModal;
