import api from '../../../redux/api';
import { UsersLog } from '../types/UsersLog';
import { ConnectedUsers } from '../types/ConnectedUsers';
import { ApiPagination } from '@hdcorner/ui-library';
import { HCPDetails } from '../types/HealthCareProfessional';
import { AuthUser } from '../../../../../../libraries/ui-library/src';

type HcpSales = {
  sales: number;
  combo: number;
  clinicalCoach: number;

  createdAt: string;
  verified: boolean;
  speciality: string;
  authUser: AuthUser;
};

const hcpQueries = api
  .enhanceEndpoints({ addTagTypes: ['HealthProfessionalsData'] })
  .injectEndpoints({
    endpoints: build => ({
      getHealthProfessionals: build.query<
        {
          count: number;
          results: HcpSales[];
        },
        ApiPagination & { start?: string; end?: string; subscriptionType?: string }
      >({
        query: ({ skip, limit, sort, end, start, subscriptionType }) => ({
          method: 'GET',
          url: '/hdCornerService/admin/health-professional/sales',
          params: {
            end,
            skip,
            sort,
            limit,
            start,
            subscriptionType,
            populate: ['authUser', 'authUser.profilePicture'],
          },
        }),
        providesTags: ['HealthProfessionalsData'],
      }),

      postHCPVerification: build.mutation<void, { ids: string[]; revoke: boolean }>({
        query: ({ ids, revoke }) => ({
          method: 'POST',
          body: { ids, revoke },
          url: '/hdCornerService/admin/health-professional/verification',
        }),
        invalidatesTags: ['HealthProfessionalsData'],
      }),

      getHCPDetails: build.query<
        HCPDetails,
        { id: string; skip?: number; limit?: number }
      >({
        query: ({ id, skip, limit }) => ({
          method: 'GET',
          url: `/hdCornerService/admin/health-professional/${id}/details`, // authUser _id needed - NOT HCP _id
          params: {
            id,
            skip,
            limit,
            populate: ['authUser', 'authUser.profilePicture'],
          },
        }),
        providesTags: ['HealthProfessionalsData'],
      }),

      getHCPConnectedUsers: build.query<
        ConnectedUsers,
        {
          id: string;
          skip: number;
          limit: number;
          start?: string;
          end?: string;
          subscriptionType?: string;
        }
      >({
        query: ({ id, skip, limit, start, end, subscriptionType }) => ({
          method: 'GET',
          params: { id, skip, limit, start, end, subscriptionType },
          url: `/hdCornerService/admin/health-professional/${id}/connected-users`,
        }),
      }),

      getHCPUsersGraph: build.query<
        { results: UsersLog[] },
        { id: string; end?: string; start?: string; timeframe?: string }
      >({
        query: ({ id, end, start, timeframe }) => ({
          method: 'GET',
          params: { id, end, start, timeframe },
          url: `/hdCornerService/admin/health-professional/${id}/graph/connected-users`,
        }),
      }),

      downloadHcpSales: build.query<
        { id: string },
        { start?: string; end?: string; subscriptionType?: string }
      >({
        query: ({ start, end, subscriptionType }) => ({
          method: 'GET',
          params: { start, end, subscriptionType },
          url: '/hdCornerService/admin/health-professional/sales/file',
        }),
      }),

      downloadHcpConnectedUsersSales: build.query<
        { id: string },
        { id: string; start?: string; end?: string; subscriptionType?: string }
      >({
        query: ({ id, start, end, subscriptionType }) => ({
          method: 'GET',
          params: { id, start, end, subscriptionType },
          url: `/hdCornerService/admin/health-professional/${id}/connected-users/file`,
        }),
      }),
    }),
  });

export const {
  useGetHCPDetailsQuery,
  useGetHCPUsersGraphQuery,
  useGetHCPConnectedUsersQuery,
  useLazyDownloadHcpSalesQuery,
  useGetHealthProfessionalsQuery,
  usePostHCPVerificationMutation,
  useLazyDownloadHcpConnectedUsersSalesQuery,
} = hcpQueries;

export default hcpQueries;
