import React, { FC, useEffect, useMemo } from 'react';
import moment from 'moment';
import { Box } from '@mui/material';
import { useAppSelector } from '../../../redux/hooks';
import SectionHeader from '../../../components/SectionHeader';
import { useGetSleepLogsQuery } from '../queries/sleepQueries';
import { FeelsEmpty, SleepDataCard, theme, useTimeframe } from '@hdcorner/ui-library';
import useAlert from '../../../hooks/useAlert';

type Props = { userId: string };

const SectionSleepData: FC<Props> = ({ userId }) => {
  const { presentError } = useAlert();

  const { dateFilter, customDateFilter } = useAppSelector(state => state.layout);

  const timeframe = useTimeframe(
    dateFilter,
    customDateFilter?.start,
    customDateFilter?.end,
  );

  const { error, data: sleepLogs } = useGetSleepLogsQuery({
    userId,
    ...timeframe,
    params: { skip: 0, limit: 1000, sort: ['-createdAt'] },
  });

  useEffect(() => {
    if (error) presentError('An unexpected error occurred fetching sleep data');
  }, [error]);

  const inDepthData = useMemo(() => {
    if (!sleepLogs || sleepLogs.documents.length === 0) return [];

    return sleepLogs.documents.map(it => {
      return {
        _id: it._id,
        hours: it.quantity.toString(),
        date: moment(it.createdAt).format('MMMM Do, YYYY'),
      };
    });
  }, [sleepLogs]);

  return (
    <Box>
      <SectionHeader title={'in-depth data'} />

      <Box mt={1} gap={1} display={'flex'} flexDirection={'column'}>
        {inDepthData.map(it => (
          <SleepDataCard unit={'hours'} date={it.date} title={it.hours} key={it._id} />
        ))}
      </Box>

      {inDepthData.length === 0 && (
        <Box sx={{ marginBottom: theme.spacing(1), width: '100%' }}>
          <FeelsEmpty />
        </Box>
      )}
    </Box>
  );
};

export default SectionSleepData;
