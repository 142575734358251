import { FC, useEffect, useMemo, useState } from 'react';
import moment from 'moment/moment';
import PageLayout from '../../PageLayout';
import { useIonRouter } from '@ionic/react';
import { styled } from '@mui/material/styles';
import { RouteComponentProps } from 'react-router';
import { Box, BoxProps, useTheme } from '@mui/material';
import SectionHeader from '../../components/SectionHeader';
import { useGetUserDetailsQuery } from './queries/usersQueries';
import UserHcpInfoToolbar from '../../components/UserHcpInfoToolbar';
import {
  AuthUser,
  capitalizeFirstLetter,
  ComponentsLayout,
  InfoCards,
} from '@hdcorner/ui-library';
import useAlert from '../../hooks/useAlert';

const SectionBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
}));

interface Props extends RouteComponentProps<{ userId: string }> {}

const UserInfo: FC<Props> = ({ match }) => {
  const theme = useTheme();
  const router = useIonRouter();
  const { presentError } = useAlert();

  const [value, setValue] = useState<string>('User Info');

  const { error, data: userInfo } = useGetUserDetailsQuery({ id: match.params.userId });

  useEffect(() => {
    if (error) presentError('An unexpected error occurred fetching the users data');
  }, [error]);

  const details = useMemo(() => {
    const data = userInfo?.data;
    const auth = userInfo?.data.authUser as AuthUser;
    const date = moment(data?.updatedAt).format('MMMM Do, HH:mm');
    return {
      premium: data?.premiumUser ? data?.premiumUser : false,
      personal: [
        {
          category: 'Year of birth',
          text: data ? moment(auth.birthDate).format('YYYY') : '-',
        },
        {
          category: 'Sex',
          text: userInfo && auth.gender ? capitalizeFirstLetter(auth.gender) : '-',
        },
      ],
      health: [
        {
          category: 'How are you feeling?',
          text: userInfo?.mood
            ? capitalizeFirstLetter(userInfo.mood.mood.replace('_', ' '))
            : '-',
          date: userInfo?.mood
            ? `Today at ${moment(userInfo.mood.updatedAt).format('HH:mm')}`
            : '-',
        },
        {
          category: 'Personality type',
          text: data?.questionnaireOutcome
            ? capitalizeFirstLetter(data.questionnaireOutcome)
            : '-',
          date: date,
        },
        { category: 'Hypertension', text: data?.hypertension ? 'Yes' : '-', date: date },
        {
          category: 'Dyslipidemia',
          text: data?.dyslipidemia ? 'Yes' : '-',
          date: date,
        },
        {
          category: 'Under medication',
          text: data?.underMedication ? 'Yes' : '-',
          date: date,
        },
        { category: 'Smoke', text: data?.smoker ? 'Yes' : '-', date: date },
        {
          category: 'Diabetes',
          text: data?.diabetes
            ? capitalizeFirstLetter(data.diabetes.replace('_', ' '))
            : '-',
          date: date,
        },
      ],
      referral: [
        {
          category: 'Health Care Professional',
          text: 'Arnold Rimmer',
          date: 'January 1st, 9:43 AM',
        },
        { category: 'Clinical Coach', text: 'Dave Lister', date: 'January 1st, 9:43 AM' },
      ],
      account: [
        {
          category: 'Subscription type',
          text: data?.subscriptionType
            ? `${capitalizeFirstLetter(data.subscriptionType)} user`
            : '-',
        },
        {
          category: 'Subscription exp. date',
          text: userInfo?.subscriptionActiveUntil
            ? moment(userInfo?.subscriptionActiveUntil).format('DD/MM/YYYY')
            : '-',
        },
        {
          category: 'Account created',
          text:
            userInfo && auth.createdAt
              ? moment(auth.createdAt).format('DD/MM/YYYY')
              : '-',
        },
        {
          category: 'Last login',
          text: userInfo?.lastLogin
            ? moment(userInfo.lastLogin).format('DD/MM/YYYY')
            : '-',
        },
      ],
    };
  }, [userInfo, match.params.userId]);

  useEffect(() => {
    setValue('User Info');
    if (value === 'User Info') {
      return;
    } else {
      router.push(`/dashboard/user/data/${match.params.userId}/diabetes`);
    }
  }, [value]);

  return (
    <PageLayout>
      <ComponentsLayout display={'flex'} flexDirection={'column'} gap={theme.spacing(3)}>
        <UserHcpInfoToolbar
          value={value}
          hcpUser={false}
          setValue={setValue}
          premium={details?.premium}
          userId={match.params.userId}
        />
        <Box>
          <SectionHeader title={'personal info'} />
          <SectionBox>
            {details &&
              details.personal.map((value, index) => (
                <InfoCards key={index} text={value.text} category={value.category} />
              ))}
          </SectionBox>
        </Box>
        <Box>
          <SectionHeader title={'health info'} />
          <SectionBox>
            {details &&
              details.health.map((value, index) => (
                <InfoCards
                  key={index}
                  text={value.text}
                  date={value.date}
                  category={value.category}
                />
              ))}
          </SectionBox>
        </Box>
        <Box>
          <SectionHeader title={'health care professional referral info'} />
          <SectionBox>
            {details &&
              details.referral.map((value, index) => (
                <InfoCards
                  key={index}
                  text={value.text}
                  date={value.date}
                  category={value.category}
                />
              ))}
          </SectionBox>
        </Box>
        <Box>
          <SectionHeader title={'account info'} />
          <SectionBox>
            {details &&
              details.account.map((value, index) => (
                <InfoCards key={index} text={value.text} category={value.category} />
              ))}
          </SectionBox>
        </Box>
      </ComponentsLayout>
    </PageLayout>
  );
};

export default UserInfo;
