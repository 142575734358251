import api from '../../../redux/api';
import { VideoExercise } from '../types/VideoExercise';
import { ApiPagination, PaginationResult } from '@hdcorner/ui-library';

const videoExerciseQueries = api
  .enhanceEndpoints({ addTagTypes: ['VideoExercise'] })
  .injectEndpoints({
    endpoints: build => ({
      getVideoExercise: build.query<
        PaginationResult<VideoExercise>,
        ApiPagination & { bodyPart?: string; workoutCategory?: string; avatar: string }
      >({
        query: ({ skip, limit, sort, bodyPart, workoutCategory, avatar }) => ({
          url: '/hdCornerService/admin/content/exercise',
          method: 'GET',
          params: {
            skip,
            limit,
            sort,
            bodyPart,
            workoutCategory,
            avatar,
            populate: [
              'thumbnail.en',
              'thumbnail.el',
              'attachedVideo.en',
              'attachedVideo.el',
            ],
          },
        }),
        providesTags: ['VideoExercise'],
      }),

      postVideoExercise: build.mutation<void, VideoExercise>({
        query: videoExercise => ({
          url: '/hdCornerService/admin/content/exercise',
          method: 'POST',
          body: videoExercise,
        }),
        invalidatesTags: ['VideoExercise'],
      }),

      editVideoExercise: build.mutation<void, VideoExercise & { id: string }>({
        query: ({ id, ...rest }) => ({
          url: `/hdCornerService/admin/content/exercise/${id}`,
          method: 'PATCH',
          body: rest,
        }),
        invalidatesTags: ['VideoExercise'],
      }),

      deleteVideoExercise: build.mutation<void, { id: string }>({
        query: ({ id }) => ({
          url: `/hdCornerService/admin/content/exercise/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['VideoExercise'],
      }),
    }),
  });

export const {
  useGetVideoExerciseQuery,
  usePostVideoExerciseMutation,
  useEditVideoExerciseMutation,
  useDeleteVideoExerciseMutation,
} = videoExerciseQueries;

export default videoExerciseQueries;
