import React, { FC, useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import {
  BarChart,
  ChartCard,
  FeelsEmpty,
  prepareGraphData,
  useTimeframe,
} from '@hdcorner/ui-library';
import { useAppSelector } from '../../../redux/hooks';
import SectionHeader from '../../../components/SectionHeader';
import { useGetStepsGraphDataQuery } from '../queries/stepsQueries';
import useAlert from '../../../hooks/useAlert';
import { weekMonthYearCustom } from '../../toggleHeadings';

type Props = { userId: string };
const ChartSteps: FC<Props> = ({ userId }) => {
  const { presentError } = useAlert();

  const { dateFilter, customDateFilter } = useAppSelector(state => state.layout);

  const timeframe = useTimeframe(
    dateFilter,
    customDateFilter?.start,
    customDateFilter?.end,
  );

  const decideTimeframe = useMemo(() => {
    if (dateFilter === 'day') {
      return { timeframe: 'day' };
    } else if (dateFilter === 'week') {
      return { timeframe: 'week' };
    } else if (dateFilter === 'month') {
      return { timeframe: 'month' };
    } else if (dateFilter === 'year') {
      return { timeframe: 'year' };
    } else {
      return { ...timeframe };
    }
  }, [dateFilter, timeframe]);

  const { data: graphRes, error: graphError } = useGetStepsGraphDataQuery({
    userId,
    ...decideTimeframe,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  useEffect(() => {
    if (graphError) presentError('An unexpected error occurred fetching graph data');
  }, [graphError]);

  const stepsData = prepareGraphData(graphRes?.graphData, 'steps');

  return (
    <Box gap={1} display={'flex'} flex={1} flexDirection={'column'}>
      <SectionHeader title={'steps'} />
      <Box>
        <ChartCard
          admin
          unit={'steps'}
          headings={weekMonthYearCustom.slice(1)}
          amount={
            dateFilter === 'day'
              ? graphRes?.total?.toFixed() || 0
              : graphRes?.avg?.toFixed() || 0
          }
        >
          {stepsData.length === 0 || !stepsData ? (
            <FeelsEmpty />
          ) : (
            <BarChart chartData={stepsData} unitOfTime={dateFilter as any} />
          )}
        </ChartCard>
      </Box>
    </Box>
  );
};

export default ChartSteps;
