import api from '../../../redux/api';
import {
  LipidLog,
  LipidOverall,
  PaginationResult,
  ApiPagination,
  LipidGoal,
} from '@hdcorner/ui-library';

const lipidQueries = api
  .enhanceEndpoints({ addTagTypes: ['LipidLog', 'LipidGoal'] })
  .injectEndpoints({
    endpoints: build => ({
      getLipidLogs: build.query<
        PaginationResult<LipidLog>,
        { params: ApiPagination; start?: string; end?: string; userId: string }
      >({
        query: ({ params, start, end, userId }) => ({
          url: `database/function/getLipidLogs`,
          method: 'GET',
          params: {
            ...params,
            startingDate: start,
            endingDate: end,
            scope: `BasicHealth:${userId}`,
          },
        }),
        providesTags: ['LipidLog'],
      }),
      getLipidFirstLog: build.query<
        PaginationResult<LipidLog>,
        { startingDate: string; userId: string }
      >({
        query: ({ userId, startingDate }) => ({
          url: `database/function/getLipidLogs`,
          method: 'GET',
          params: {
            skip: 0,
            limit: 1,
            startingDate,
            sort: 'logDate',
            scope: `BasicHealth:${userId}`,
          },
        }),
        providesTags: ['LipidLog'],
      }),
      getLipidLastLog: build.query<PaginationResult<LipidLog>, { userId: string }>({
        query: ({ userId }) => ({
          url: `database/function/getLipidLogs`,
          method: 'GET',
          params: { skip: 0, limit: 1, sort: '-logDate', scope: `BasicHealth:${userId}` },
        }),
        providesTags: ['LipidLog'],
      }),

      getOverallLipid: build.query<
        LipidOverall,
        { start?: string; end?: string; userId: string }
      >({
        query: ({ start, end, userId }) => ({
          method: 'GET',
          params: { start, end, userId },
          url: `hdCornerService/user/lipid/overall`,
        }),
        providesTags: ['LipidLog'],
      }),

      getLipidGoal: build.query<LipidGoal[], string>({
        query: goalType => ({
          method: 'GET',
          params: { goalType },
          url: `database/function/getUserGoal`,
        }),
        providesTags: ['LipidGoal'],
      }),
      getLipidGraphData: build.query<
        { logs: LipidLog[] },
        {
          params: {
            metric: string | undefined;
            start?: string;
            end?: string;
          };
          userId: string;
        }
      >({
        query: ({ params, userId }) => ({
          url: `hdCornerService/user/lipid/graph`,
          method: 'GET',
          params: { ...params, userId },
        }),
        providesTags: ['LipidLog'],
      }),
    }),
  });

export const {
  useGetLipidLogsQuery,
  useGetLipidGoalQuery,
  useGetLipidLastLogQuery,
  useGetOverallLipidQuery,
  useGetLipidFirstLogQuery,
  useGetLipidGraphDataQuery,
} = lipidQueries;

export default lipidQueries;
