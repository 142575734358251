import React, { FC } from 'react';
import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import { MedicationStateEnum, RescheduleIcon } from '@hdcorner/ui-library';

const CustomBox = styled(Box)<BoxProps>(({ theme }) => ({
  height: '35px',
  display: 'flex',
  cursor: 'pointer',
  borderRadius: '8px',
  alignItems: 'center',
  boxSizing: 'border-box',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.secondary.main,
}));

const CustomTypographyAmount = styled(Typography)<
  TypographyProps & { type: MedicationStateEnum }
>(({ theme, type }) => ({
  margin: theme.spacing(0),
  fontSize: theme.typography.body1.fontSize,
  fontWeight: theme.typography.body1.fontWeight,
  padding: theme.spacing(0, 1),
  color:
    type === 'rescheduled-from'
      ? theme.palette.primary.light
      : theme.palette.kmColorsCoolGrey.main,
  opacity: type === 'rescheduled-from' ? '0.5' : 1,
}));

const CustomTypographyTime = styled(Typography)<TypographyProps>(({ theme }) => ({
  textTransform: 'uppercase',
  margin: theme.spacing(0),
  fontSize: theme.typography.body1.fontSize,
  color: theme.palette.kmColorsCoolGrey.main,
  fontWeight: theme.typography.body1.fontWeight,
  padding: theme.spacing(0, 1),
}));

type CheckboxNavCardProps = {
  time: string;
  amount: string;
  medicineName: string;
  medicationState: MedicationStateEnum;
};

const MedNavCard: FC<CheckboxNavCardProps> = ({
  time,
  amount,
  medicineName,
  medicationState,
}) => {
  const theme = useTheme();

  const formatTime = (time: string) => {
    return moment(time).utc(false).format('HH:mm');
  };

  return (
    <CustomBox>
      <Box
        gap={1}
        display={'flex'}
        alignItems={'center'}
        position={'relative'}
        justifyContent={'flex-start'}
      >
        <Typography
          variant={'subtitle2'}
          sx={{
            color: theme.palette.kmColorsCoolGrey.main,
            fontWeight: theme.typography.fontWeightRegular,
          }}
        >
          {medicineName}
        </Typography>
        <CustomTypographyAmount type={medicationState}>{amount}</CustomTypographyAmount>
        {medicationState === 'rescheduled-from' && (
          <Box top={0} position={'absolute'} left={theme.spacing(3)}>
            <RescheduleIcon color={theme.palette.error.main} />
          </Box>
        )}
      </Box>

      <CustomTypographyTime>{formatTime(time)}</CustomTypographyTime>
    </CustomBox>
  );
};

export default MedNavCard;
