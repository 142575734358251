import {
  Collapsible,
  FeelsEmpty,
  InDepthDataCard,
  LipidChart,
  ListItemMain,
  ListMain,
} from '@hdcorner/ui-library';
import { Box, Typography, TypographyProps, styled, BoxProps } from '@mui/material';
import moment from 'moment';
import React, { FC, useCallback, useMemo } from 'react';
import SectionHeader from '../../../components/SectionHeader';
import { weekMonthYearCustom } from '../../toggleHeadings';

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  lineHeight: '24px',
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightMedium,
}));

const BoxColumn = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 1,
  display: 'flex',
  minWidth: '400px',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const BoxSection = styled(Box)<BoxProps>(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
}));

type Props = {
  goalData: {
    goal?: number;
    latestMeasurement?: number;
    startingMeasurement?: number;
  };
  chartData: any;
  inDepthData: any;
  overallData: any;
  sectionName: string;
};

const LipidGroupDetails: FC<Props> = ({
  goalData,
  chartData,
  inDepthData,
  overallData,
  sectionName,
}) => {
  const getChartData = useMemo((): any[] => {
    if (!chartData) return [];
    return chartData.map((item: { date: string; value: any }) => {
      return {
        x: moment(item.date).toISOString(),
        y: item.value,
      };
    });
  }, [chartData]);

  const getChartGoalData = useMemo<
    | {
        goal?: number;
        latestMeasurement?: number;
        startingMeasurement?: number;
      }
    | undefined
  >(() => {
    if (!goalData) return undefined;

    return {
      goal: goalData.goal,
      latestMeasurement: goalData.latestMeasurement,
      startingMeasurement: goalData.startingMeasurement,
    };
  }, [goalData]);

  const getChartOverallData = useMemo(() => {
    return {
      min: overallData ? overallData.min : 'N/A',
      max: overallData ? overallData.max : 'N/A',
      avg: overallData ? overallData.avg : 'N/A',
      latestMeasurement: goalData.latestMeasurement,
    };
  }, [overallData, goalData]);

  const getOverallData = useCallback(() => {
    return [
      {
        name: overallData ? overallData.title.toUpperCase() : 'N/A',
        min: overallData ? overallData.min : 'N/A',
        max: overallData ? overallData.max : 'N/A',
        avg: overallData ? overallData.avg : 'N/A',
      },
    ];
  }, [overallData]);

  return (
    <Box gap={3} flex={1} display={'flex'} flexWrap={'wrap'}>
      <BoxColumn>
        {/*CHART*/}
        <BoxSection>
          <Title variant={'subtitle1'} textTransform={'uppercase'}>
            {sectionName}
          </Title>
          {/*Chart area*/}
          <LipidChart
            chartData={getChartData}
            goalData={getChartGoalData}
            headings={weekMonthYearCustom}
            inDepthData={getChartOverallData}
          />
        </BoxSection>
      </BoxColumn>

      <BoxColumn>
        {/*IN-DEPTH DATA*/}
        <BoxSection>
          <SectionHeader title={'in-depth data'} />
          {/*In Depth Card area*/}
          <InDepthDataCard
            measurement={'mg/dL'}
            rows={getOverallData()}
            headings={['Min.', 'Max.', 'Avg.']}
          />
          {/*In depth list area*/}
          <Box overflow={'hidden'} borderRadius={'8px'}>
            {inDepthData.length > 0 && (
              <ListMain>
                {inDepthData.map((item: any, index: number) => (
                  <ListItemMain key={`${index}`}>
                    <Collapsible
                      data={item.data}
                      title={item.title}
                      titleSub={item.titleSub}
                      progress={item?.progress}
                    />
                  </ListItemMain>
                ))}
              </ListMain>
            )}
            {inDepthData.length === 0 && <FeelsEmpty />}
          </Box>
        </BoxSection>
      </BoxColumn>
    </Box>
  );
};

export default LipidGroupDetails;
