import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import DropdownMenu from '../../../components/DropdownMenu';
import { AllProgramsList, AllWorkoutCategories, BodyPartList } from '../constants';
import { Box, BoxProps, Typography, useTheme } from '@mui/material';
import ToolbarContainer from '../../../components/ToolbarContainer';
import { CustomButton, MeasurementsIcon, ToggleButtons } from '@hdcorner/ui-library';
import { genderOptions } from '../../toggleHeadings';

const Divider = styled(Box)<BoxProps>(({ theme }) => ({
  width: '2px',
  opacity: 0.1,
  height: '16px',
  backgroundColor: theme.palette.kmColorsCoolGrey.main,
}));

type Props = {
  sex: string;
  program: string;
  bodyParts: string;
  disabled?: boolean;
  videoQuantity?: number;
  versionProgram?: boolean;
  handleChooseVideos?: () => void;
  setSex: (sex: string) => void;
  setProgram: (program: string) => void;
  setBodyParts: (bodyParts: string) => void;
};

const WorkoutToolbar: FC<Props> = ({
  sex,
  setSex,
  program,
  disabled,
  bodyParts,
  setProgram,
  setBodyParts,
  videoQuantity,
  versionProgram = false,
  handleChooseVideos,
}) => {
  const theme = useTheme();

  const handleBodyParts = (event: any) => {
    const newState = BodyPartList.find(option => option.value === event.target.value);
    if (newState) {
      setBodyParts(newState?.value);
    }
  };

  const handlePrograms = (event: any) => {
    if (versionProgram) {
      const newState = AllProgramsList.find(
        option => option.value === event.target.value,
      );
      if (newState) {
        setProgram(newState?.value);
      }
    }
    if (!versionProgram) {
      const newState = AllWorkoutCategories.find(
        option => option.value === event.target.value,
      );
      if (newState) {
        setProgram(newState?.value);
      }
    }
  };

  const handleSexChange = (arg: string) => {
    setSex(arg);
  };

  const measurementsIcon = (
    <MeasurementsIcon
      width={20}
      height={22}
      color={theme.palette.kmColorsCoolGrey.main}
    />
  );

  return (
    <ToolbarContainer sx={{ justifyContent: 'space-between' }}>
      <Box gap={3} display={'flex'} alignItems={'center'}>
        {!versionProgram && (
          <>
            {' '}
            <Typography
              variant={'subtitle2'}
              sx={{
                color: theme.palette.kmColorsCoolGrey.main,
                fontWeight: theme.typography.fontWeightBold,
              }}
            >
              {videoQuantity === 1 ? `${videoQuantity} video` : `${videoQuantity} videos`}
            </Typography>
            <Divider />
          </>
        )}
        <Box gap={1} display={'flex'} alignItems={'center'}>
          {!versionProgram && measurementsIcon}
          <DropdownMenu
            value={bodyParts}
            options={BodyPartList}
            setValue={setBodyParts}
            handleDropdownChange={handleBodyParts}
          />
        </Box>

        <Divider />
        <Box gap={1} display={'flex'} alignItems={'center'}>
          {!versionProgram && measurementsIcon}
          <DropdownMenu
            value={program}
            setValue={setProgram}
            handleDropdownChange={handlePrograms}
            options={versionProgram ? AllWorkoutCategories : AllProgramsList}
          />
        </Box>

        <Divider />
        <ToggleButtons
          value={sex}
          size={'large'}
          sx={{
            width: 'auto',
            textTransform: 'uppercase',
            '&.Mui-selected, &.Mui-selected:hover': {
              backgroundColor: 'rgba(0,0,0,0.22)',
              color: theme.palette.kmColorsCoolGrey.main,
            },
          }}
          handleChange={handleSexChange}
          headings={genderOptions.filter(it => it.value !== 'both')}
        />
      </Box>

      {!versionProgram && (
        <CustomButton
          variant={'text'}
          disabled={disabled}
          onClick={handleChooseVideos}
          sx={{
            '&:disabled': {
              opacity: 0.5,
              backgroundColor: 'transparent',
              color: theme.palette.kmColorsTurquoise.main,
            },
          }}
        >
          Choose videos
        </CustomButton>
      )}
    </ToolbarContainer>
  );
};

export default WorkoutToolbar;
