const monthYearCustomThis = [
  { label: 'all data', value: 'all' },
  { label: 'this month', value: 'month' },
  { label: 'this year', value: 'year' },
  { label: 'custom', value: 'custom' },
];

const weekMonthYearCustom = [
  { label: 'All', value: 'all' },
  { label: 'Day', value: 'day' },
  { label: 'Week', value: 'week' },
  { label: 'Month', value: 'month' },
  { label: 'Year', value: 'year' },
  { label: 'Custom Date', value: 'custom' },
];

const subscriptionTypes = [
  { label: 'Monthly subscriptions', value: 'monthly' },
  { label: 'Annual subscriptions', value: 'annually' },
  { label: 'Bi-annual subscriptions', value: 'bi-annually' },
];

const genderOptions = [
  { label: 'Both', value: 'both' },
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
];

export { genderOptions, subscriptionTypes, monthYearCustomThis, weekMonthYearCustom };
