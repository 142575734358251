import { DropdownType } from './types/DropdownType';
import VideoPlayIcon from '../../assets/icons/VideoPlayIcon';
import {
  ArticleIcon,
  ExclamationIcon,
  ExerciseIcon,
  KnifeForkIcon,
  NutritionBowl,
  PillIcon,
  QuotesIcon,
  theme,
} from '@hdcorner/ui-library';
import LightbulbIcon from '../../assets/icons/LightbulbIcon';
import FEDropdownIcon from '../../assets/icons/FEDropdownIcon';

const DropdownList: DropdownType[] = [
  {
    name: 'Article',
    value: 'articles',
    icon: (
      <ArticleIcon
        width={'22px'}
        height={'18px'}
        color={theme.palette.kmColorsCoolGrey.main}
      />
    ),
  },
  { name: 'Video', value: 'video', icon: <VideoPlayIcon /> },
  {
    name: 'Diet Plan',
    value: 'diet-plan',
    icon: (
      <NutritionBowl
        width={'22px'}
        height={'22px'}
        color={theme.palette.kmColorsCoolGrey.main}
      />
    ),
  },
  {
    name: 'Foods',
    value: 'foods',
    icon: (
      <KnifeForkIcon
        width={'24px'}
        height={'24px'}
        fill={theme.palette.kmColorsCoolGrey.main}
      />
    ),
  },
  {
    name: 'Medication',
    value: 'medication',
    icon: (
      <PillIcon
        width={'20px'}
        height={'20px'}
        color={theme.palette.kmColorsCoolGrey.main}
      />
    ),
  },
  {
    name: 'Activity',
    value: 'activity',
    icon: (
      <ExerciseIcon
        width={'20px'}
        height={'20px'}
        color={theme.palette.kmColorsCoolGrey.main}
      />
    ),
  },
  { name: 'Traffic lights', value: 'traffic-lights', icon: <LightbulbIcon /> },
  {
    name: 'Popup messages',
    value: 'popup-messages',
    icon: (
      <ExclamationIcon
        width={'22px'}
        height={'22px'}
        color={theme.palette.kmColorsCoolGrey.main}
      />
    ),
  },
  { name: 'Inspirational quotes', value: 'inspirational-quotes', icon: <QuotesIcon /> },
  { name: 'Food equivalents', value: 'food-equivalents', icon: <FEDropdownIcon /> },
];

const FabListMenu = [
  {
    name: 'Article',
    value: 'articles',
    icon: (
      <ArticleIcon
        width={'22px'}
        height={'18px'}
        color={theme.palette.kmColorsCoolGrey.main}
      />
    ),
  },
  { name: 'Content Video', value: 'video-content', icon: <VideoPlayIcon /> },
  { name: 'Exercise Video', value: 'video-exercise', icon: <VideoPlayIcon /> },
  {
    name: 'Diet Plan',
    value: 'diet-plan',
    icon: (
      <NutritionBowl
        width={'22px'}
        height={'22px'}
        color={theme.palette.kmColorsCoolGrey.main}
      />
    ),
  },
  {
    name: 'Foods',
    value: 'foods',
    icon: (
      <KnifeForkIcon
        width={'24px'}
        height={'24px'}
        fill={theme.palette.kmColorsCoolGrey.main}
      />
    ),
  },
  {
    name: 'Medication',
    value: 'medication',
    icon: (
      <PillIcon
        width={'20px'}
        height={'20px'}
        color={theme.palette.kmColorsCoolGrey.main}
      />
    ),
  },
  {
    name: 'Activity',
    value: 'activity',
    icon: (
      <ExerciseIcon
        width={'20px'}
        height={'20px'}
        color={theme.palette.kmColorsCoolGrey.main}
      />
    ),
  },
  { name: 'Traffic lights', value: 'traffic-lights', icon: <LightbulbIcon /> },
  {
    name: 'Popup messages',
    value: 'popup-messages',
    icon: (
      <ExclamationIcon
        width={'22px'}
        height={'22px'}
        color={theme.palette.kmColorsCoolGrey.main}
      />
    ),
  },
  { name: 'Inspirational quotes', value: 'inspirational-quotes', icon: <QuotesIcon /> },
  { name: 'Food equivalents', value: 'food-equivalents', icon: <FEDropdownIcon /> },
];

const VideoSortList: DropdownType[] = [
  {
    name: 'Latest',
    value: '-createdAt',
  },
  {
    name: 'A-B order',
    value: 'title',
  },
];

const BodyPartList: DropdownType[] = [
  {
    name: 'All body parts',
    value: 'all',
  },
  {
    name: 'Arms',
    value: 'arms',
  },
  {
    name: 'Legs',
    value: 'legs',
  },
  {
    name: 'Torso',
    value: 'torso',
  },
  {
    name: 'Whole Body',
    value: 'whole_body',
  },
];

const BodyPartDropdown = [
  {
    label: 'Arms',
    value: 'arms',
  },
  {
    label: 'Legs',
    value: 'legs',
  },
  {
    label: 'Torso',
    value: 'torso',
  },
  {
    label: 'Whole Body',
    value: 'whole_body',
  },
];

const AllWorkoutCategories: DropdownType[] = [
  {
    name: 'All workout categories',
    value: 'all',
  },
  {
    name: 'Warm up',
    value: 'warm_up',
  },
  {
    name: 'Main',
    value: 'main',
  },
  {
    name: 'Recovery',
    value: 'recovery',
  },
];

const AllProgramsList: DropdownType[] = [
  {
    name: 'All programs',
    value: 'all',
  },
  {
    name: 'Warm up',
    value: 'warm_up',
  },
  {
    name: 'Main',
    value: 'main',
  },
  {
    name: 'Recovery',
    value: 'recovery',
  },
];

const AllProgramsDropdown = [
  {
    label: 'Warm up',
    value: 'warm_up',
  },
  {
    label: 'Main',
    value: 'main',
  },
  {
    label: 'Recovery',
    value: 'recovery',
  },
];

const SortingList: DropdownType[] = [
  {
    name: 'Latest',
    value: '-createdAt',
  },
  {
    name: 'A-B order',
    value: 'title',
  },
  {
    name: 'Author name',
    value: 'author',
  },
];

const CategoriesList: DropdownType[] = [
  {
    name: 'All Categories',
    value: 'all',
  },
  {
    name: 'HD Corner Benefits',
    value: 'benefits',
  },
  {
    name: 'HD Corner News',
    value: 'news',
  },
  {
    name: 'Exercise',
    value: 'exercise',
  },
  {
    name: 'Nutrition',
    value: 'nutrition',
  },
  {
    name: 'Hypertension',
    value: 'hypertension',
  },
  {
    name: 'Diabetes',
    value: 'diabetes',
  },
  {
    name: 'Obesity',
    value: 'obesity',
  },
  {
    name: 'Dyslipidemia',
    value: 'dyslipidemia',
  },
];

const ArticleCategories = [
  { label: 'HD Corner Benefits', value: 'benefits' },
  { label: 'HD Corner News', value: 'news' },
  { label: 'Exercise', value: 'exercise' },
  { label: 'Nutrition', value: 'nutrition' },
  { label: 'Hypertension', value: 'hypertension' },
  { label: 'Diabetes', value: 'diabetes' },
  { label: 'Obesity', value: 'obesity' },
  { label: 'Dyslipidemia', value: 'dyslipidemia' },
];

const QuoteCategories = [
  { label: 'Activities', value: 'activities' },
  { label: 'CVD', value: 'cvd' },
  { label: 'Diabetes', value: 'diabetes' },
  { label: 'Hypertension', value: 'hypertension' },
  { label: 'Lipid Profile', value: 'lipid_profile' },
  { label: 'Nutrition', value: 'nutrition' },
  { label: 'Steps', value: 'steps' },
  { label: 'Weight', value: 'weight' },
];

const PopupCategories = [
  { label: 'Did you know?', value: 'did_you_know' },
  { label: 'Other message', value: 'other_message' },
];

const DietPlanCategories = [
  {
    label: 'Cardiovascular',
    value: 'cardiovascular',
  },
  {
    label: 'Cholesterol',
    value: 'cholesterol',
  },
  {
    label: 'Hypertension',
    value: 'hypertension',
  },
  {
    label: 'Diabetes',
    value: 'diabetes',
  },
];

const TrafficLightCategories = [
  { label: 'Dyslipidemia', value: 'dyslipidemia' },
  { label: 'Obesity', value: 'obesity' },
  { label: 'Hypertension', value: 'hypertension' },
  { label: 'Diabetes', value: 'diabetes' },
];

const TrafficLightLevels = [
  { label: 'Frequent consumption', value: 'frequent' },
  { label: 'Moderate consumption', value: 'moderate' },
  { label: 'Avoid consumption', value: 'avoid' },
];

const TrafficLightSections = [
  { label: 'Fish & Meats', value: 'fish_meats' },
  { label: 'Dairy Products', value: 'dairy_products' },
  { label: 'Eggs', value: 'eggs' },
  { label: 'Fruits & Vegetables', value: 'fruits_vegetables' },
  { label: 'Bread, Grains, Legumes, Cereals', value: 'bread_grains_legumes_cereals' },
  { label: 'Drinks', value: 'drinks' },
  { label: 'Fats & Oils', value: 'fats_oils' },
  { label: 'Miscellaneous', value: 'miscellaneous' },
];

export {
  SortingList,
  FabListMenu,
  BodyPartList,
  DropdownList,
  VideoSortList,
  CategoriesList,
  QuoteCategories,
  PopupCategories,
  AllProgramsList,
  BodyPartDropdown,
  ArticleCategories,
  DietPlanCategories,
  TrafficLightLevels,
  AllProgramsDropdown,
  TrafficLightSections,
  AllWorkoutCategories,
  TrafficLightCategories,
};
