import Routes from './routes';
import React, { useEffect } from 'react';
import { getAccessToken, getRefreshToken } from './utils/TokenStorage';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { setAccessToken, setRefreshToken } from './features/authentication/authSlice';
import LoadingPage from './features/authentication/components/LoadingPage';
import { useLazyGetAuthUserQuery } from './features/authentication/queries/signInQueries';
import { disconnectSocket, initializeSocket } from './features/chat/socket';

const AppStack = () => {
  const dispatch = useAppDispatch();

  const {
    data: { token },
    meta: { authResolved },
  } = useAppSelector(state => state.auth);

  const [
    getAuthUser,
    { isLoading: authUserLoading, isUninitialized: authUserIsUninitialized },
  ] = useLazyGetAuthUserQuery();

  useEffect(() => {
    if (token) {
      getAuthUser();
      initializeSocket(token);
    }
    if (!token) {
      getAccessToken().then(token => {
        dispatch(setAccessToken(token));
      });

      getRefreshToken().then(accessToken => {
        dispatch(setRefreshToken(accessToken));
      });
    }

    return () => {
      disconnectSocket();
    };
  }, [token]);

  const render = () => {
    let isLoading;

    if (!authResolved) {
      isLoading = true;
    } else {
      if (!token) {
        isLoading = false;
      } else {
        if (authUserIsUninitialized) {
          isLoading = true;
        } else isLoading = !!authUserLoading;
      }
    }

    if (isLoading) {
      return <LoadingPage />;
    } else {
      return <Routes />;
    }
  };

  return render();
};

export default AppStack;
