import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: {
  sex: string;
  sort: string;
  program: string;
  section: string;
  bodyPart: string;
} = {
  sex: 'male',
  program: 'all',
  bodyPart: 'all',
  section: 'other',
  sort: '-createdAt',
};

const videoSlice = createSlice({
  name: 'video',
  initialState,
  reducers: {
    changeVideoSection: (state, action: PayloadAction<string>) => {
      state.section = action.payload;
    },
    changeVideoSort: (state, action: PayloadAction<string>) => {
      state.sort = action.payload;
    },
    updateSex: (state, action: PayloadAction<string>) => {
      state.sex = action.payload;
    },
    updateProgram: (state, action: PayloadAction<string>) => {
      state.program = action.payload;
    },
    updateBodyPart: (state, action: PayloadAction<string>) => {
      state.bodyPart = action.payload;
    },
  },
});

export const {
  updateSex,
  updateProgram,
  updateBodyPart,
  changeVideoSort,
  changeVideoSection,
} = videoSlice.actions;

export default videoSlice.reducer;
