import React, { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import useAlert from '../../../hooks/useAlert';
import ModalBaseContent from './ModalBaseContent';
import { CustomInput, Food } from '@hdcorner/ui-library';
import { useEditFoodMutation, usePostFoodMutation } from '../queries/foodQueries';

type Props = {
  open: boolean;
  data?: Food;
  setOpen: (open: boolean) => void;
};

const ModalAddEditFood: FC<Props> = ({ data, open, setOpen }) => {
  const { presentSuccess, presentError } = useAlert();

  const [version, setVersion] = useState<'en' | 'el'>('en');
  const [food, setFood] = useState<Food>({
    fat: 0,
    _id: '',
    carbs: 0,
    name: {
      en: '',
      el: '',
    },
    protein: 0,
    calories: 0,
    description: '',
  });

  const [postFood] = usePostFoodMutation();
  const [editFood] = useEditFoodMutation();

  useEffect(() => {
    if (!data) return;

    setFood({
      _id: data._id,
      fat: data.fat,
      name: data.name,
      carbs: data.carbs,
      protein: data.protein,
      calories: data.calories,
      description: data.description,
    });
  }, [data]);

  const handleChange = (fieldName: string, value: any) => {
    setFood(oldValue => ({
      ...oldValue,
      [fieldName]: value,
    }));
  };

  const handleSaveModal = () => {
    if (food.name.en === '' || food.name.el === '') {
      presentError('You need to fill the names for both languages.');
      return;
    }

    postFood({
      fat: food.fat,
      name: food.name,
      carbs: food.carbs,
      protein: food.protein,
      calories: food.calories,
      description: food.description,
    })
      .unwrap()
      .then(() => {
        presentSuccess('Food added');
      })
      .catch(() => {
        presentError('Something went wrong.');
      })
      .finally(() => {
        setOpen(false);
        resetData();
      });
  };

  const handleEdit = () => {
    if (!data) {
      presentError('Something went wrong.');
      return;
    }
    if (food.name.en === '' || food.name.el === '') {
      presentError('You need to fill the names for both languages.');
      return;
    }
    editFood({
      fat: food.fat,
      name: food.name,
      carbs: food.carbs,
      protein: food.protein,
      calories: food.calories,
      id: food._id ? food._id : '',
      description: food.description,
    })
      .unwrap()
      .then(() => {
        presentSuccess('Food updated');
      })
      .catch(() => {
        presentError('Something went wrong.');
      })
      .finally(() => {
        setOpen(false);
        resetData();
      });
  };

  const resetData = () => {
    setFood({
      _id: '',
      fat: 0,
      carbs: 0,
      name: {
        en: '',
        el: '',
      },
      protein: 0,
      calories: 0,
      description: '',
    });
  };

  return (
    <ModalBaseContent
      open={open}
      data={!!data}
      value={version}
      setOpen={setOpen}
      category={'Foods'}
      setValue={setVersion}
      handleSaveModal={data ? handleEdit : handleSaveModal}
    >
      <CustomInput
        fullWidth
        label={'Food name'}
        value={food.name[version]}
        placeholder={'Enter name'}
        handleChange={(value: string) => {
          handleChange('name', { ...food.name, [version]: value });
        }}
      />

      <Box gap={2} display={'flex'}>
        <CustomInput
          fullWidth
          type={'number'}
          label={'Calories'}
          value={food.calories}
          placeholder={'Enter amount'}
          handleChange={(value: string) => handleChange('calories', value)}
        />
        <CustomInput
          fullWidth
          type={'number'}
          value={food.carbs}
          label={'Carbohydrates'}
          placeholder={'Enter amount'}
          handleChange={(value: string) => handleChange('carbs', value)}
        />
      </Box>

      <Box gap={2} display={'flex'}>
        <CustomInput
          fullWidth
          type={'number'}
          label={'Protein'}
          value={food.protein}
          placeholder={'Enter amount'}
          handleChange={(value: string) => handleChange('protein', value)}
        />
        <CustomInput
          fullWidth
          label={'Fat'}
          type={'number'}
          value={food.fat}
          placeholder={'Enter amount'}
          handleChange={(value: string) => handleChange('fat', value)}
        />
      </Box>

      <Box mb={3}>
        <CustomInput
          fullWidth
          rows={6}
          minRows={6}
          label={'Description'}
          value={food.description}
          placeholder={'Type something here'}
          handleChange={(value: string) => {
            handleChange('description', value);
          }}
        />
      </Box>
    </ModalBaseContent>
  );
};

export default ModalAddEditFood;
