import api from '../../../redux/api';
import { ApiPagination, Medicine, PaginationResult } from '@hdcorner/ui-library';

const medicineQueries = api
  .enhanceEndpoints({ addTagTypes: ['Medicine'] })
  .injectEndpoints({
    endpoints: build => ({
      getMedicines: build.query<
        PaginationResult<Medicine>,
        ApiPagination & { search?: string }
      >({
        query: ({ skip, limit, sort, search }) => ({
          url: '/hdCornerService/admin/content/medicine',
          method: 'GET',
          params: { skip, limit, sort, search, populate: 'imagePreview' },
        }),
        providesTags: ['Medicine'],
      }),

      postMedicine: build.mutation<void, Omit<Medicine, '_id'>>({
        query: medicine => ({
          url: '/hdCornerService/admin/content/medicine',
          method: 'POST',
          body: medicine,
        }),
        invalidatesTags: ['Medicine'],
      }),

      editMedicine: build.mutation<void, Medicine>({
        query: ({ _id, ...rest }) => ({
          url: `/hdCornerService/admin/content/medicine/${_id}`,
          method: 'PATCH',
          body: rest,
        }),
        invalidatesTags: ['Medicine'],
      }),

      deleteMedicine: build.mutation<void, { id: string }>({
        query: ({ id }) => ({
          url: `/hdCornerService/admin/content/medicine/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Medicine'],
      }),
    }),
  });

export const {
  useGetMedicinesQuery,
  usePostMedicineMutation,
  useEditMedicineMutation,
  useDeleteMedicineMutation,
} = medicineQueries;

export default medicineQueries;
