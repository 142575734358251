import * as React from 'react';
import { FC } from 'react';
import {
  Box,
  BoxProps,
  styled,
  TableCellProps,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  useTheme,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { ArrowDown, ArrowUp, HeadCell } from '@hdcorner/ui-library';

const TableCellHeadings = styled(TableCell, {
  shouldForwardProp: propName => propName !== 'content',
})<TableCellProps & { content?: boolean }>(({ theme, content }) => ({
  flexGrow: '1',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  textOverflow: 'ellipsis',
  color: theme.palette.primary.light,
  fontSize: theme.typography.body1.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  backgroundColor: theme.palette.secondary.main,
  borderBottomColor: theme.palette.background.default,
  padding: theme.spacing(1),
  '.Mui-active': {
    color: theme.palette.primary.light,
  },
  '&:first-of-type': {
    borderTopLeftRadius: '8px',
    width: content ? 'auto' : '52px',
  },
  '&:last-of-type': {
    borderTopRightRadius: '8px',
  },
}));

const MarginBox = styled(Box)<BoxProps>(({ theme }) => ({
  margin: theme.spacing(0, 0, 0, 0.75),
}));

const SortOrderDesc = () => (
  <MarginBox>
    <ArrowDown />
  </MarginBox>
);

const SortOrderAsc = () => {
  const theme = useTheme();
  return (
    <MarginBox>
      <ArrowUp fill={theme.palette.kmColorsCoolGrey.main} />
    </MarginBox>
  );
};

type Order = 'asc' | 'desc';

export type UsersTableHeadingProps = {
  order: Order;
  orderBy: string;
  content?: boolean;
  totalRowsCount: number;
  headCells: HeadCell[];
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
};

const UsersTableHeading: FC<UsersTableHeadingProps> = ({
  order,
  content,
  orderBy,
  headCells,
  onRequestSort,
}) => {
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx={{ height: '40px' }}>
        {!content && <TableCellHeadings align={'center'}></TableCellHeadings>}

        {headCells &&
          headCells.map(headCell => (
            <TableCellHeadings
              key={headCell._id}
              align={headCell.align}
              sortDirection={orderBy === headCell._id ? order : false}
            >
              {!headCell.sort ? (
                headCell.label
              ) : (
                <TableSortLabel
                  active={orderBy === headCell._id}
                  onClick={createSortHandler(headCell._id)}
                  direction={orderBy === headCell._id ? order : 'asc'}
                  IconComponent={
                    orderBy === headCell._id
                      ? order === 'asc'
                        ? SortOrderAsc
                        : SortOrderDesc
                      : SortOrderDesc
                  }
                >
                  {headCell.label}
                  {orderBy === headCell._id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              )}
            </TableCellHeadings>
          ))}
      </TableRow>
    </TableHead>
  );
};

export default UsersTableHeading;
