import React, { FC } from 'react';
import {
  Avatar,
  Box,
  Checkbox,
  CheckboxProps,
  IconButton,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { TableCellProps } from '@mui/material/TableCell';
import AwaitingVerificationIcon from '../../../assets/icons/AwaitingVerificationIcon';
import { VerifiedIcon } from '@hdcorner/ui-library';

const CustomCheckbox = styled(Checkbox)<CheckboxProps>(({ theme }) => ({
  width: '20px',
  borderRadius: '4px',
  margin: theme.spacing(0),
  padding: theme.spacing(0),
  color: theme.palette.kmColorsCoolGrey.main,
  '&.Mui-checked': {
    color: theme.palette.kmColorsTurquoise.main,
  },
  '&.MuiCheckbox-indeterminate': {
    color: theme.palette.kmColorsTurquoise.main,
  },
}));

const TableCellData = styled(TableCell)<TableCellProps>(({ theme }) => ({
  flexGrow: '1',
  height: '72px',
  lineHeight: '10px',
  textAlign: 'center',
  boxSizing: 'border-box',
  padding: theme.spacing(1),
  color: theme.palette.primary.main,
  fontSize: theme.typography.subtitle2.fontSize,
  fontWeight: theme.typography.subtitle2.fontWeight,
  borderBottomColor: theme.palette.background.default,
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.secondary.main
      : theme.palette.secondary.light,
  '.Mui-active': {
    color: theme.palette.primary.light,
  },
  '&:first-of-type': {
    width: '52px',
  },
  '&:nth-of-type(2)': {
    textAlign: 'left',
  },
}));

export type HealthProfTableRowsProps = {
  name: string;
  email: string;
  avatar: string;
  checked: boolean;
  verified: boolean;
  selected: boolean;
  rowCells: string[];
  handleRowClick: () => void;
  handleAvatarClick: () => void;
};

const HealthProfessionalTableRows: FC<HealthProfTableRowsProps> = ({
  name,
  email,
  avatar,
  checked,
  verified,
  rowCells,
  selected,
  handleRowClick,
  handleAvatarClick,
}) => {
  const theme = useTheme();

  return (
    <TableRow
      hover
      tabIndex={-1}
      role="checkbox"
      selected={checked}
      aria-checked={checked}
      onClick={handleRowClick}
      sx={{
        '&:hover': { opacity: 0.5 },
        '&:last-child th, &:last-child td': {
          borderBottom: 0,
          '&:first-of-type': {
            borderBottomLeftRadius: '8px',
          },
          '&:last-of-type': {
            borderBottomRightRadius: '8px',
          },
        },
      }}
    >
      <TableCellData align={'center'}>
        <IconButton
          onClick={event => {
            event.stopPropagation();
            handleAvatarClick();
          }}
          sx={{ padding: 0 }}
        >
          {selected || checked ? (
            <CustomCheckbox checked={checked} />
          ) : (
            <Box position={'relative'}>
              <Avatar
                src={avatar}
                variant={'rounded'}
                sx={{
                  width: 24,
                  height: 24,
                  margin: '0 auto',
                }}
              />
              <Box right={'-8px'} bottom={'-11px'} position={'absolute'}>
                {verified && <VerifiedIcon />}
                {!verified && <AwaitingVerificationIcon />}
              </Box>
            </Box>
          )}
        </IconButton>
      </TableCellData>
      <TableCellData>
        <Typography
          variant={'subtitle1'}
          sx={{ fontWeight: theme.typography.fontWeightRegular }}
        >
          {name}
        </Typography>
        <br />
        {email}
      </TableCellData>
      {rowCells.map((cell, index) => (
        <TableCellData align={'center'} key={index}>
          {cell}
        </TableCellData>
      ))}
    </TableRow>
  );
};

export default HealthProfessionalTableRows;
