import React, { FC, useEffect } from 'react';
import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import SectionHeader from '../../../components/SectionHeader';
import { useGetDietPlanQuery } from '../queries/dietPlanQueries';
import {
  CardiovascularIcon,
  FileCard,
  theme,
  usePrepareDietPlan,
} from '@hdcorner/ui-library';
import useAlert from '../../../hooks/useAlert';

const IconBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: 48,
  height: 48,
  borderRadius: 4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.error.main,
}));

type Props = { userId: string };

const SectionDietPlan: FC<Props> = ({ userId }) => {
  const { presentError } = useAlert();

  const { error, data: dietPlanRes } = useGetDietPlanQuery({ userId });

  useEffect(() => {
    if (error) presentError('An unexpected error occurred fetching diet plan data');
  }, [error]);

  const prepareDietPlan = usePrepareDietPlan(dietPlanRes);

  const handleOpenFile = () => {
    const file = prepareDietPlan?.file;
    if (file) {
      window.open(file, '_blank');
    }
  };

  return (
    <Box gap={1} width={'100%'} display={'flex'} flexDirection={'column'}>
      <SectionHeader title={'diet plan'} />

      {prepareDietPlan && (
        <FileCard
          onClick={handleOpenFile}
          date={prepareDietPlan.date}
          title={prepareDietPlan.title}
          subtitle={prepareDietPlan.subtitle}
          style={{ backgroundColor: theme.palette.secondary.main }}
          icon={
            <IconBox>
              <CardiovascularIcon />
            </IconBox>
          }
        />
      )}
    </Box>
  );
};

export default SectionDietPlan;
