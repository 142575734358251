import React, { FC, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import useAlert from '../../../hooks/useAlert';
import ModalBaseContent from './ModalBaseContent';
import { Box, Typography, useTheme } from '@mui/material';
import ContentDropzone from '../../../components/ContentDropzone';
import {
  useReUploadFileMutation,
  useUploadFileMutation,
} from '../../../queries/fileQueries';
import { AllProgramsDropdown, BodyPartDropdown } from '../constants';
import {
  ConduitFile,
  CustomInput,
  DropdownMenu,
  ToggleButtonGroupCu,
  UploadResponse,
} from '@hdcorner/ui-library';
import {
  useEditVideoExerciseMutation,
  usePostVideoExerciseMutation,
} from '../queries/videoExerciseQueries';
import { fileSize } from '../../../utils/bytes';
import UploadedFileMarker from './UploadedFileMarker';
import { VideoExercise } from '../types/VideoExercise';

type Props = {
  open: boolean;
  data?: VideoExercise;
  setOpen: (open: boolean) => void;
};
const ModalAddEditVideoExercise: FC<Props> = ({ data, open, setOpen }) => {
  const theme = useTheme();
  const { presentSuccess, presentError } = useAlert();

  const [attachedVideo, setAttachedVideo] = useState<{
    el: File | undefined;
    en: File | undefined;
  }>({ el: undefined, en: undefined });
  const [thumbnailFile, setThumbnailFile] = useState<{
    el: File | undefined;
    en: File | undefined;
  }>({ el: undefined, en: undefined });

  const [version, setVersion] = useState<'en' | 'el'>('en');
  const [video, setVideo] = useState<VideoExercise>({
    _id: '',
    title: {
      en: '',
      el: '',
    },
    notes: {
      en: '',
      el: '',
    },
    kcalAvg: 0,
    duration: 0,
    thumbnail: {
      en: '',
      el: '',
    },
    description: {
      en: '',
      el: '',
    },
    avatar: 'female',
    attachedVideo: {
      en: '',
      el: '',
    },
    bodyPart: 'whole_body',
    workoutCategory: 'warm_up',
  });

  const [upload] = useUploadFileMutation();
  const [reUpload] = useReUploadFileMutation();
  const [postVideo] = usePostVideoExerciseMutation();
  const [editVideo] = useEditVideoExerciseMutation();

  useEffect(() => {
    if (!data) return;
    setVideo({
      _id: data._id,
      notes: data.notes,
      title: data.title,
      avatar: data.avatar,
      kcalAvg: data.kcalAvg,
      bodyPart: data.bodyPart,
      duration: data.duration,
      thumbnail: data.thumbnail,
      description: data.description,
      attachedVideo: data.attachedVideo,
      workoutCategory: data.workoutCategory,
    });

    if (data.attachedVideo) {
      const conduitFileEn = data.attachedVideo.en as ConduitFile;
      const conduitFileEl = data.attachedVideo.el as ConduitFile;
      const fileEn = new File([], conduitFileEn.name);
      const fileEl = new File([], conduitFileEl.name);
      setAttachedVideo({ en: fileEn, el: fileEl });
    }

    if (data.thumbnail) {
      const conduitFileEn = data.thumbnail.en as ConduitFile;
      const conduitFileEl = data.thumbnail.el as ConduitFile;
      const fileEn = new File([], conduitFileEn.name);
      const fileEl = new File([], conduitFileEl.name);
      setThumbnailFile({ en: fileEn, el: fileEl });
    }
  }, [data, version]);

  const handleChange = (fieldName: string, value: any) => {
    setVideo(oldValue => ({
      ...oldValue,
      [fieldName]: value,
    }));
  };

  const handleNewFiles = (files: File[]) => {
    const file = files[0];
    if (!file) return;
    setAttachedVideo({ ...attachedVideo, [version]: file });

    const videoElement = document.createElement('video');
    videoElement.src = URL.createObjectURL(file);
    videoElement.addEventListener('loadedmetadata', () => {
      if (videoElement.duration) {
        const duration = Math.round(videoElement.duration);
        handleChange('duration', duration);
      } else {
        presentError('Could not found duration of video!');
        handleChange('duration', 0);
      }

      // Take a snapshot of the video at a specific time (e.g., at 5 seconds)
      videoElement.currentTime = 5;
      videoElement.addEventListener('seeked', () => {
        const canvas = document.createElement('canvas');
        canvas.width = 222; // This is the width that we need for the Exercise card
        canvas.height = 115; // This is the height that we need for the Exercise card
        const context = canvas.getContext('2d');
        if (context) {
          context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
          // Convert the canvas image to a Blob
          if (!canvas) {
            presentError('Could not found canvas of video!');
            return;
          }
          canvas.toBlob(blob => {
            if (blob) {
              // Create a File from the Blob
              const file = new File([blob], 'thumbnail.jpg', {
                type: 'image/jpeg',
              });
              setThumbnailFile({ ...thumbnailFile, [version]: file });
            }
          }, 'image/jpeg');
        } else {
          presentError('Could not found context of video!');
          setThumbnailFile({ ...thumbnailFile, [version]: undefined });
        }

        // Remove the video element from the DOM
        videoElement.removeAttribute('src');
        videoElement.load();
      });
    });
  };

  const handleUpload = async () => {
    if (video.title.en === '' || video.title.el === '') {
      presentError('Please enter a title for both languages');
      return;
    }

    const { en: attachedEn, el: attachedEl } = attachedVideo;
    const { en: thumbnailEn, el: thumbnailEl } = thumbnailFile;
    const existingFileEn = data?.attachedVideo.en as ConduitFile;
    const existingFileEl = data?.attachedVideo.el as ConduitFile;
    const existingThumbnailEn = data?.thumbnail.en as ConduitFile;
    const existingThumbnailEl = data?.thumbnail.el as ConduitFile;

    if (!attachedEn || !attachedEl) {
      presentError('Please attach a file for both languages');
      return;
    }
    if (!thumbnailEn || !thumbnailEl) {
      presentError('An error occurred while creating the thumbnail. Please try again.');
      return;
    }

    try {
      const fileResEn = await handleCreateFiles(attachedEn, existingFileEn);
      const fileResEl = await handleCreateFiles(attachedEl, existingFileEl);
      const thumbnailResEn = await handleCreateFiles(thumbnailEn, existingThumbnailEn);
      const thumbnailResEl = await handleCreateFiles(thumbnailEl, existingThumbnailEl);

      let videoData = {
        notes: video.notes,
        title: video.title,
        avatar: video.avatar,
        kcalAvg: video.kcalAvg,
        bodyPart: video.bodyPart,
        duration: video.duration,
        id: video._id ? video._id : '',
        description: video.description,
        workoutCategory: video.workoutCategory,
        thumbnail: {
          en: thumbnailResEn
            ? thumbnailResEn.file._id
            : (video.thumbnail.en as ConduitFile)._id,
          el: thumbnailResEl
            ? thumbnailResEl.file._id
            : (video.thumbnail.el as ConduitFile)._id,
        },
        attachedVideo: {
          en: fileResEn
            ? fileResEn.file._id
            : (video.attachedVideo.en as ConduitFile)._id,
          el: fileResEl
            ? fileResEl.file._id
            : (video.attachedVideo.el as ConduitFile)._id,
        },
      };

      let exercisePromise;
      if (!data) {
        const { id, ...rest } = videoData;
        exercisePromise = postVideo(rest).unwrap();
      } else {
        exercisePromise = editVideo(videoData).unwrap();
      }

      await exercisePromise;
      uploadBlobData(fileResEn, attachedEn);
      uploadBlobData(fileResEl, attachedEl);
      uploadBlobData(thumbnailResEn, thumbnailEn);
      uploadBlobData(thumbnailResEl, thumbnailEl);
      presentSuccess('Video created successfully');
      setOpen(false);
      resetData();
    } catch (error) {
      console.log(error);
      presentError('Error creating video');
    }
  };

  const handleCreateFiles = async (
    selectedFile: File,
    existingFile?: ConduitFile,
  ): Promise<UploadResponse | void> => {
    let uploadPromise: Promise<UploadResponse> | undefined;
    if (!existingFile) {
      // Create new conduit file for attached file
      uploadPromise = upload({
        size: selectedFile.size,
        name: `${uuidv4()}_${selectedFile.name}`,
      }).unwrap();
    } else {
      if (selectedFile.size === 0 && selectedFile.name === existingFile.name) {
        return;
      } else {
        // Upload new conduit file for attached file
        uploadPromise = reUpload({
          _id: existingFile._id,
          size: selectedFile.size,
          name: `${uuidv4()}_${selectedFile.name}`,
        }).unwrap();
      }
    }
    return uploadPromise;
  };

  const uploadBlobData = async (
    uploadItem: UploadResponse | void,
    attachedFile: File,
  ) => {
    if (!uploadItem) return;
    return await fetch(uploadItem.url, {
      method: 'PUT',
      body: attachedFile,
      headers: {
        'x-ms-blob-type': 'BlockBlob',
        'Content-Type': attachedFile.type,
      },
    });
  };

  const resetData = () => {
    setVideo({
      _id: '',
      title: {
        en: '',
        el: '',
      },
      notes: {
        en: '',
        el: '',
      },
      kcalAvg: 0,
      duration: 0,
      thumbnail: {
        en: '',
        el: '',
      },
      description: {
        en: '',
        el: '',
      },
      avatar: 'female',
      attachedVideo: {
        en: '',
        el: '',
      },
      bodyPart: 'whole_body',
      workoutCategory: 'warm_up',
    });
    setAttachedVideo({ el: undefined, en: undefined });
    setThumbnailFile({ el: undefined, en: undefined });
  };

  const extraInputs = (
    <Box ml={2} gap={2} flex={1} display={'flex'} justifyContent={'space-between'}>
      <Box gap={1} display={'flex'} flexDirection={'column'}>
        <Typography
          variant={'subtitle2'}
          sx={{
            color: theme.palette.kmColorsCoolGrey.main,
            fontWeight: theme.typography.fontWeightRegular,
          }}
        >
          Avatar Type
        </Typography>
        <ToggleButtonGroupCu
          value={video.avatar}
          headings={['male', 'female']}
          style={{ textTransform: 'capitalize' }}
          setValue={(value: string) => handleChange('avatar', value)}
        />
      </Box>

      <Box display={'flex'} justifyContent={'flex-end'} width={'288px'}>
        <DropdownMenu
          readOnly
          fullWidth
          label={'Type'}
          value={'Workout'}
          placeholder={'Workout'}
          sx={{ maxHeight: '44px' }}
          setValue={() => undefined}
          menuItems={['Workout', 'Other']}
        />
      </Box>
    </Box>
  );

  return (
    <ModalBaseContent
      open={open}
      data={!!data}
      value={version}
      setOpen={setOpen}
      setValue={setVersion}
      optionalInput={extraInputs}
      category={'Exercise Videos'}
      handleSaveModal={handleUpload}
    >
      <Box gap={2} display={'flex'}>
        <Box flex={1}>
          <DropdownMenu
            fullWidth
            label={'Body-part'}
            value={video.bodyPart}
            sx={{ maxHeight: '44px' }}
            menuItems={BodyPartDropdown}
            setValue={(value: string) => handleChange('bodyPart', value)}
          />
        </Box>
        <CustomInput
          fullWidth
          label={'Title'}
          placeholder={'Enter title'}
          value={video.title[version]}
          handleChange={(value: string) => {
            handleChange('title', {
              ...video.title,
              [version]: value,
            });
          }}
        />
      </Box>

      <Box gap={2} display={'flex'}>
        <Box flex={1}>
          <DropdownMenu
            fullWidth
            label={'Workout category'}
            sx={{ maxHeight: '44px' }}
            value={video.workoutCategory}
            menuItems={AllProgramsDropdown}
            setValue={(value: string) => handleChange('workoutCategory', value)}
          />
        </Box>
        <CustomInput
          fullWidth
          type={'number'}
          value={video.kcalAvg}
          label={'Average kcal'}
          placeholder={'Enter amount'}
          handleChange={(value: string) => handleChange('kcalAvg', value)}
        />
      </Box>

      {/*TODO: replace with Rich Text Editor*/}
      <CustomInput
        fullWidth
        rows={6}
        minRows={6}
        label={'Description'}
        value={video.description?.[version]}
        placeholder={'Type something here'}
        handleChange={(value: string) => {
          handleChange('description', {
            ...video.description,
            [version]: value,
          });
        }}
      />

      <CustomInput
        fullWidth
        rows={6}
        minRows={6}
        label={'Notes'}
        value={video.notes?.[version]}
        placeholder={'Type something here'}
        handleChange={(value: string) => {
          handleChange('notes', {
            ...video.notes,
            [version]: value,
          });
        }}
      />

      <Box height={'187px'}>
        <ContentDropzone heading={'Attach MP4'} handleContentDrop={handleNewFiles} />
      </Box>

      {attachedVideo && (
        <UploadedFileMarker
          fileName={attachedVideo[version]?.name || 'N/A'}
          handleRemoveFile={() =>
            setAttachedVideo({ ...attachedVideo, [version]: undefined })
          }
          fileSize={fileSize(
            attachedVideo[version],
            data?.attachedVideo[version] as ConduitFile,
          )}
        />
      )}
    </ModalBaseContent>
  );
};

export default ModalAddEditVideoExercise;
