import React, { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import {
  useEditArticleMutation,
  usePostArticleMutation,
} from '../queries/articleQueries';
import { v4 as uuidv4 } from 'uuid';
import useAlert from '../../../hooks/useAlert';
import { fileSize } from '../../../utils/bytes';
import ModalBaseContent from './ModalBaseContent';
import UploadedFileMarker from './UploadedFileMarker';
import RichTextEditor from '../../../components/RichTextEditor';
import ContentDropzone from '../../../components/ContentDropzone';
import AutocompleteDropdown from '../../../components/AutocompleteDropdown';
import { Article, ConduitFile, CustomInput, UploadResponse } from '@hdcorner/ui-library';
import {
  useReUploadFileMutation,
  useUploadFileMutation,
} from '../../../queries/fileQueries';

type Props = {
  open: boolean;
  data?: Article;
  setOpen: (open: boolean) => void;
};

const ModalAddEditArticle: FC<Props> = ({ data, open, setOpen }) => {
  const { presentSuccess, presentError } = useAlert();

  // STORE SELECTED FILES FOR UPLOAD
  const [attachedImage, setAttachedImage] = useState<File | null>(null);

  const [version, setVersion] = useState('en');
  const [article, setArticle] = useState<Article>({
    _id: '',
    title: '',
    image: '',
    author: '',
    category: '',
    subtitle: '',
    lang: version,
    description: '',
  });

  const [upload] = useUploadFileMutation();
  const [reUpload] = useReUploadFileMutation();
  const [postArticle] = usePostArticleMutation();
  const [editArticle] = useEditArticleMutation();

  useEffect(() => {
    if (!data) return;
    setVersion(data.lang);
    setArticle({
      _id: data._id,
      lang: data.lang,
      title: data.title,
      image: data.image,
      author: data.author,
      subtitle: data.subtitle,
      category: data.category,
      description: data.description,
    });

    if (data.image) {
      const conduitFile = data.image as ConduitFile;
      const file = new File([], conduitFile.name);
      setAttachedImage(file);
    }
  }, [data]);

  const handleChange = (fieldName: string, value: any) => {
    setArticle(oldValue => ({
      ...oldValue,
      [fieldName]: value,
    }));
  };

  const handleNewFiles = (files: File[]) => {
    const file = files[0];
    if (!file) return;

    setAttachedImage(file);
  };

  const handleUpload = async () => {
    let uploadPromise;
    let resultImage: UploadResponse | void;
    const existingConduitFile = data?.image as ConduitFile;
    if (attachedImage) {
      if (!existingConduitFile) {
        // Create new conduit file for attached image
        uploadPromise = upload({
          size: attachedImage.size,
          name: `${uuidv4()}_${attachedImage.name}`,
        }).unwrap();
      } else {
        // Re-upload existing conduit file for attached image
        uploadPromise = reUpload({
          size: attachedImage.size,
          _id: existingConduitFile._id,
          name: `${uuidv4()}_${attachedImage.name}`,
        }).unwrap();
      }

      resultImage = await uploadPromise.catch(error => {
        console.log(error);
        presentError('Error uploading creating image file');
        return;
      });

      if (!resultImage) {
        presentError('No file attached');
        return;
      }
    }

    const articleData = {
      lang: version,
      title: article.title,
      author: article.author,
      subtitle: article.subtitle,
      category: article.category,
      description: article.description,
      id: article._id ? article._id : '',
      image: resultImage ? (resultImage as UploadResponse).file._id : undefined,
    };

    let promiseData;
    if (!data) {
      const { id, ...rest } = articleData;
      promiseData = postArticle(rest).unwrap();
    } else {
      promiseData = editArticle(articleData).unwrap();
    }

    promiseData
      .then(() => {
        presentSuccess('Article created successfully');
        setAttachedImage(null);
        setOpen(false);
        resetData();
        if (attachedImage) {
          fetch((resultImage as UploadResponse).url, {
            method: 'PUT',
            body: attachedImage,
            headers: {
              'Content-Type': attachedImage.type,
              'x-ms-blob-type': 'BlockBlob',
            },
          });
        }
      })
      .catch(error => {
        console.log(error);
        presentError('Error uploading data');
        setOpen(false);
        resetData();
      });
  };

  const resetData = () => {
    setArticle({
      _id: '',
      title: '',
      image: '',
      author: '',
      subtitle: '',
      lang: version,
      description: '',
      category: 'benefits',
    });
  };

  return (
    <ModalBaseContent
      open={open}
      data={!!data}
      value={version}
      setOpen={setOpen}
      setValue={setVersion}
      category={'articles'}
      handleSaveModal={handleUpload}
    >
      <Box gap={2} display={'flex'}>
        <CustomInput
          fullWidth
          label={'Title'}
          value={article.title}
          placeholder={'Enter title'}
          handleChange={(value: string) => handleChange('title', value)}
        />
        <CustomInput
          fullWidth
          label={'Subtitle'}
          value={article.subtitle}
          placeholder={'Enter subtitle'}
          handleChange={(value: string) => handleChange('subtitle', value)}
        />
      </Box>

      <Box gap={2} display={'flex'}>
        <Box flex={1}>
          <CustomInput
            fullWidth
            label={'Author'}
            value={article.author}
            placeholder={"Enter author's name"}
            handleChange={(value: string) => handleChange('author', value)}
          />
        </Box>
        <Box flex={1}>
          <AutocompleteDropdown
            label={'Category'}
            value={article.category}
            onChangeValue={value => handleChange('category', value)}
          />
        </Box>
      </Box>

      <RichTextEditor
        content={article.description}
        onUpdate={text => {
          handleChange('description', text);
        }}
      />

      <Box height={'187px'}>
        <ContentDropzone
          heading={'Attach profile picture'}
          handleContentDrop={handleNewFiles}
        />
      </Box>

      {attachedImage && (
        <UploadedFileMarker
          fileName={attachedImage.name}
          handleRemoveFile={() => setAttachedImage(null)}
          fileSize={fileSize(attachedImage, data?.image as ConduitFile)}
        />
      )}
    </ModalBaseContent>
  );
};

export default ModalAddEditArticle;
