import api from '../../../redux/api';
import { HcpAuthUser } from '../types/HcpAuthUser';
import { AuthUser, HealthProfessionalsData } from '@hdcorner/ui-library';

const inviteHcpQueries = api.injectEndpoints({
  endpoints: build => ({
    registerHcpUser: build.mutation<{ user: AuthUser }, { email: string }>({
      query: ({ email }) => ({
        method: 'POST',
        url: '/authentication/local/new',
        body: { email, password: 'newHCP123!@#' },
      }),
    }),

    setHcpAuthUser: build.mutation<void, HcpAuthUser & { hcpId: string }>({
      query: ({ hcpId, ...data }) => ({
        method: 'PATCH',
        body: { ...data },
        url: `/database/function/adminSetAuthUser/${hcpId}`,
      }),
    }),

    createHcpUserData: build.mutation<
      void,
      Omit<HealthProfessionalsData, '_id' | 'verified' | 'authUser'> & { hcpId: string }
    >({
      query: ({ hcpId, ...data }) => ({
        method: 'POST',
        body: { ...data },
        url: `/database/function/adminCreateHcpData/${hcpId}`,
      }),
    }),

    inviteHcpUser: build.mutation<void, { hcpId: string }>({
      query: ({ hcpId }) => ({
        method: 'POST',
        url: `/hdCornerService/admin/health-professional/${hcpId}/invite`,
      }),
    }),

    createLocationData: build.mutation<void, { _id: string }>({
      query: ({ _id }) => ({
        method: 'POST',
        url: `/hdCornerService/admin/health-professional/${_id}/location`,
      }),
    }),
  }),
});

export const {
  useInviteHcpUserMutation,
  useSetHcpAuthUserMutation,
  useRegisterHcpUserMutation,
  useCreateHcpUserDataMutation,
  useCreateLocationDataMutation,
} = inviteHcpQueries;

export default inviteHcpQueries;
