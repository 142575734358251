import React, { FC, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import {
  CustomButton,
  ModalMobile,
  RadioGroup,
  RadioSingle,
  ToggleButtons,
} from '@hdcorner/ui-library';
import { monthYearCustomThis, subscriptionTypes } from '../features/toggleHeadings';

type Props = {
  open: boolean;
  selectedSubscriptionType: string;
  saveData: (data: any) => void;
  setOpen: (bool: boolean) => void;
};
const SalesCalendarModal: FC<Props> = ({ open, setOpen, saveData }) => {
  const theme = useTheme();

  const [selectedMonths, setSelectedMonths] = useState<number[]>([]);
  const [toggleValue, setToggleValue] = useState<string>('this month');
  const [subscriptionType, setSubscriptionType] = useState<string>('monthly');

  const handleChange = (newValue: string) => {
    setToggleValue(newValue);
  };

  const handleSave = () => {
    const startingMonth = selectedMonths[0];
    const endingMonth = selectedMonths[selectedMonths.length - 1];
    let start, end;

    if (toggleValue === 'month') {
      start = moment().startOf('month').toISOString();
      end = moment().endOf('month').toISOString();
      saveData({
        endDate: end,
        startDate: start,
        subscriptionType,
      });
      setOpen(false);
      return;
    }
    if (toggleValue === 'year') {
      start = moment().startOf('year').toISOString();
      end = moment().endOf('year').toISOString();
      saveData({
        endDate: end,
        startDate: start,
        subscriptionType,
      });
      setOpen(false);
      return;
    }
    if (selectedMonths.length === 1) {
      start = moment().month(startingMonth).startOf('month').toISOString();
      end = moment().month(startingMonth).endOf('month').toISOString();
    } else {
      start = moment().month(startingMonth).startOf('month').toISOString();
      end = moment().month(endingMonth).endOf('month').toISOString();
    }

    saveData({
      endDate: end,
      startDate: start,
      subscriptionType,
    });
    setOpen(false);
  };

  return (
    <ModalMobile open={open} setOpen={setOpen}>
      <Box
        p={2}
        gap={2}
        display={'flex'}
        flexDirection={'column'}
        bgcolor={
          theme.palette.mode === 'dark'
            ? theme.palette.secondary.main
            : theme.palette.secondary.light
        }
      >
        <ToggleButtons
          size={'medium'}
          value={toggleValue}
          handleChange={handleChange}
          headings={monthYearCustomThis}
        />
        <Box>
          {
            // render cards with the 12 months of the year
            toggleValue === 'custom' && (
              <Box gap={1} display={'flex'} flexWrap={'wrap'} justifyContent={'center'}>
                {moment.months().map((month, index) => (
                  <Box
                    key={index}
                    width={'25%'}
                    height={'48px'}
                    display={'flex'}
                    borderRadius={'8px'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    bgcolor={theme.palette.background.default}
                    sx={{
                      cursor: 'pointer',
                      boxSizing: 'border-box',
                      '&:hover': {
                        border: '1px solid #FFFFFF',
                      },
                      border: selectedMonths.includes(index)
                        ? '1px solid #FFFFFF'
                        : 'none',
                    }}
                    onClick={() => {
                      // if no months are selected, select one month
                      //if one month is selected, add all months between the selected month and the clicked month
                      //if multiple months are selected, reset the selection and select the clicked month
                      if (selectedMonths.length === 0 || selectedMonths.length > 1) {
                        setSelectedMonths([index]);
                        return;
                      }
                      if (selectedMonths.length === 1) {
                        const firstSelectedMonth = selectedMonths[0];
                        const monthsToAdd = index - firstSelectedMonth;
                        const months = [];
                        for (let i = 0; i <= monthsToAdd; i++) {
                          months.push(firstSelectedMonth + i);
                        }
                        setSelectedMonths(months);
                        return;
                      }
                    }}
                  >
                    <Typography
                      variant={'body1'}
                      sx={{
                        color: theme.palette.kmColorsCoolGrey.main,
                        fontWeight: theme.typography.fontWeightMedium,
                      }}
                    >
                      {month}
                    </Typography>
                  </Box>
                ))}
              </Box>
            )
          }
        </Box>
        <Box>
          {/*//Render radio buttons with3 options of different subscription plans*/}
          <RadioGroup
            value={subscriptionType}
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
            onChange={event => {
              setSubscriptionType(event.target.value);
            }}
          >
            {subscriptionTypes.map(type => (
              <RadioSingle
                key={type.value}
                value={type.value}
                label={type.label}
              ></RadioSingle>
            ))}
          </RadioGroup>
        </Box>
        <Box display={'flex'} justifyContent={'end'} gap={2} alignItems={'center'}>
          <CustomButton onClick={() => setOpen(false)}>Cancel</CustomButton>
          <CustomButton
            variant={'contained'}
            onClick={() => handleSave()}
            disabled={toggleValue === 'custom' && selectedMonths.length === 0}
          >
            Save
          </CustomButton>
        </Box>
      </Box>
    </ModalMobile>
  );
};

export default SalesCalendarModal;
