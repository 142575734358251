import React, { FC } from 'react';
import { Box, BoxProps, useTheme } from '@mui/material';

type Props = {
  color: string;
};

const TrafficLightCircle: FC<Props & BoxProps> = ({ color, ...rest }) => {
  const theme = useTheme();

  const handleColor = (value: string) => {
    switch (value) {
      case 'frequent':
        return theme.palette.success.main;
      case 'moderate':
        return theme.palette.warning.main;
      case 'avoid':
        return theme.palette.error.main;
      case 'Frequent consumption':
        return theme.palette.success.main;
      case 'Moderate consumption':
        return theme.palette.warning.main;
      case 'Avoid consumption':
        return theme.palette.error.main;
      default:
        return theme.palette.success.main;
    }
  };

  return (
    <Box
      {...rest}
      width={'16px'}
      height={'16px'}
      borderRadius={'50%'}
      bgcolor={handleColor(color)}
    />
  );
};

export default TrafficLightCircle;
