import React, { useEffect, useMemo, useState } from 'react';
import { Box, Table, TableBody } from '@mui/material';
import UsersTableRows from '../users/components/UsersTableRows';
import UsersTableHeading from '../users/components/UsersTableHeading';
import useAlert from '../../hooks/useAlert';
import ModalAddEditQuote from './components/ModalAddEditQuote';
import { useDeleteQuoteMutation, useGetQuotesQuery } from './queries/quoteQueries';
import moment from 'moment/moment';
import { convertEnumValue } from './utils/dataConversion';
import { QuoteCategories } from './constants';
import { tableSort } from '../../utils/tableSorting';
import ModalConfirmDelete from '../../components/ModalConfirmDelete';
import { AdminTablePagination, FeelsEmpty, Quote, HeadCell } from '@hdcorner/ui-library';

const createData = (row: any) => {
  return [row.name, row.quote, row.author, row.associatedGoal, row.createdAt];
};

const headCells: HeadCell[] = [
  { _id: 'name', label: 'Quote name', align: 'left', sort: 'name' },
  { _id: 'quote', label: 'Quote', align: 'center', sort: 'quote' },
  { _id: 'author', label: 'Author', align: 'center', sort: 'author' },
  {
    _id: 'associatedGoal',
    label: 'Associated goal',
    align: 'center',
    sort: 'associatedGoal',
  },
  { _id: 'createdAt', label: 'Date added', align: 'center', sort: 'createdAt' },
  { _id: 'actions', label: 'Actions', align: 'center' },
];
type Order = 'asc' | 'desc';

const skipAmount = 5;

const ContentQuotesPanel = () => {
  const { presentSuccess, presentError } = useAlert();

  const [rowId, setRowId] = useState<string>('');
  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState<Order>('asc');
  const [open, setOpen] = useState<boolean>(false);
  const [skip, setSkip] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [modelData, setModelData] = useState<Quote | undefined>(undefined);

  const [deleteQuote] = useDeleteQuoteMutation();
  const {
    error,
    data: quoteRes,
    isFetching: dataFetch,
  } = useGetQuotesQuery({
    limit: 10,
    skip: skip,
    sort: [tableSort(orderBy, order, headCells)],
  });

  useEffect(() => {
    if (error) presentError('An unexpected error occurred fetching quotes data');
  }, [error]);

  const quoteRows = useMemo(() => {
    if (!quoteRes || quoteRes.documents.length === 0) return [];

    return quoteRes.documents.map((data: Quote) => ({
      _id: data._id,
      name: data.name,
      lang: data.lang,
      quote: data.quote,
      author: data.author,
      createdAt: moment(data.createdAt).format('DD/MM/YYYY'),
      associatedGoal: convertEnumValue(data.associatedGoal, QuoteCategories),
    }));
  }, [quoteRes]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleEditRow = (data: Quote) => {
    setOpen(true);
    setModelData(data);
  };

  const handleDeleteRow = (id: string) => {
    deleteQuote({
      id: id,
    })
      .unwrap()
      .then(() => {
        presentSuccess('Quote deleted');
        setOpenDelete(false);
      })
      .catch(() => {
        presentError('Something went wrong.');
      });
  };

  const handleSkipClick = (amount: number) => {
    setCurrentPage(currentPage => currentPage + amount);
  };

  useEffect(() => {
    const apiSkip = (currentPage - 1) * 10;
    setSkip(apiSkip);
  }, [currentPage]);

  const numberOfPages = useMemo(() => {
    if (!quoteRes) return 0;
    return Math.ceil(quoteRes.count / 10);
  }, [quoteRes]);

  return (
    <Box gap={3} display={'flex'} flexDirection={'column'}>
      {quoteRows.length === 0 && <FeelsEmpty />}

      {quoteRows.length > 0 && (
        <>
          <Table>
            <UsersTableHeading
              content
              order={order}
              orderBy={orderBy}
              headCells={headCells}
              totalRowsCount={quoteRows.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {quoteRows.map(row => (
                <UsersTableRows
                  content
                  alignData
                  rowCells={createData(row)}
                  key={row._id ? row._id : ''}
                  handleEditRow={() => handleEditRow(row)}
                  handleDeleteRow={() => {
                    setOpenDelete(true);
                    setRowId(row._id ? row._id : '');
                  }}
                />
              ))}
            </TableBody>
          </Table>
          <AdminTablePagination
            loading={dataFetch}
            skipAmount={skipAmount}
            currentPage={currentPage}
            numberOfPages={numberOfPages}
            onSkipClick={handleSkipClick}
          />
        </>
      )}

      <ModalConfirmDelete
        open={openDelete}
        content={'quote'}
        setOpen={setOpenDelete}
        handleDelete={() => handleDeleteRow(rowId)}
      />
      <ModalAddEditQuote open={open} setOpen={setOpen} data={modelData} />
    </Box>
  );
};

export default ContentQuotesPanel;
