import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import ProgramCard from './ProgramCard';
import { Program } from '../types/Program';
import WorkoutToolbar from './WorkoutToolbar';
import ModalEditProgram from './ModalEditProgram';
import { useAppSelector } from '../../../redux/hooks';
import { useGetProgramQuery } from '../queries/programQueries';
import { AllWorkoutCategories, BodyPartList } from '../constants';
import { AdminTablePagination, FeelsEmpty } from '@hdcorner/ui-library';
import useAlert from '../../../hooks/useAlert';

const skipAmount = 5;

const ContentProgramPanel = () => {
  const { presentError } = useAlert();
  const { sort } = useAppSelector(state => state.video);

  const [skip, setSkip] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState<boolean>(false);
  const [sorting, setSorting] = useState<string>('-createdAt');
  const [programData, setProgramData] = useState<Program | undefined>(undefined);

  const [sex, setSex] = useState<string>('male');
  const [bodyParts, setBodyParts] = useState(BodyPartList[0].value);
  const [program, setProgram] = useState(AllWorkoutCategories[0].value);

  useEffect(() => {
    if (!sort) return;
    setSorting(sort);
  }, [sort]);

  const {
    error,
    data: programRes,
    isFetching: dataFetch,
  } = useGetProgramQuery({
    limit: 16,
    skip: skip,
    avatar: sex,
    sort: [sorting],
    workoutType: program === 'all' ? undefined : program,
    bodyPart: bodyParts === 'all' ? undefined : bodyParts,
  });

  useEffect(() => {
    if (error) presentError('An unexpected error occurred fetching program data');
  }, [error]);

  const programs = useMemo(() => {
    if (!programRes || programRes.documents.length === 0) return [];

    return programRes.documents.map((data: Program) => ({
      name: data.name,
      avatar: data.avatar,
      bodyPart: data.bodyPart,
      exercises: data.exercises,
      _id: data._id ? data._id : '',
      workoutType: data.workoutType,
    }));
  }, [programRes]);

  const handleEditProgram = (data: Program) => {
    setProgramData(data);
    setOpen(true);
  };

  const handleSkipClick = (amount: number) => {
    setCurrentPage(currentPage => currentPage + amount);
  };

  useEffect(() => {
    const apiSkip = (currentPage - 1) * 16;
    setSkip(apiSkip);
  }, [currentPage]);

  const numberOfPages = useMemo(() => {
    if (!programRes) return 0;
    return Math.ceil(programRes.count / 16);
  }, [programRes]);

  return (
    <Box
      gap={3}
      flex={1}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
    >
      <Box>
        <WorkoutToolbar
          sex={sex}
          versionProgram
          setSex={setSex}
          program={program}
          bodyParts={bodyParts}
          setProgram={setProgram}
          setBodyParts={setBodyParts}
        />

        {programs.length === 0 && <FeelsEmpty />}

        <Box mt={3} gap={3} display={'flex'} flexWrap={'wrap'}>
          {programs.map(data => (
            <ProgramCard
              data={data}
              key={data._id}
              handleEditProgram={() => handleEditProgram(data)}
            />
          ))}
        </Box>
      </Box>

      {programs.length > 0 && (
        <AdminTablePagination
          loading={dataFetch}
          skipAmount={skipAmount}
          currentPage={currentPage}
          numberOfPages={numberOfPages}
          onSkipClick={handleSkipClick}
        />
      )}

      {programData && (
        <ModalEditProgram open={open} setOpen={setOpen} data={programData} />
      )}
    </Box>
  );
};

export default ContentProgramPanel;
