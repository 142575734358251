import React, { FC, useEffect, useMemo, useState } from 'react';
import moment from 'moment/moment';
import MedNavCard from './MedNavCard';
import { Box, useTheme } from '@mui/material';
import { RouteComponentProps } from 'react-router';
import SectionHeader from '../../../components/SectionHeader';
import { useGetMedicationsQuery } from '../queries/medicationQueries';
import { extractMedicationState, HorizontalCalendar } from '@hdcorner/ui-library';
import useAlert from '../../../hooks/useAlert';

interface Props extends RouteComponentProps<{ userId: string }> {}

const DataPanelMedication: FC<Props> = ({ match }) => {
  const theme = useTheme();
  const { presentError } = useAlert();

  const [selectedDate, setSelectedDate] = useState<Date>(moment().toDate());

  const { error, data: medicationRes } = useGetMedicationsQuery({
    skip: 0,
    limit: 1000,
    userId: match.params.userId,
    endDate: moment(selectedDate).utc(true).endOf('day').toISOString(),
    startDate: moment(selectedDate).utc(true).startOf('day').toISOString(),
  });

  useEffect(() => {
    if (error) presentError('An unexpected error occurred fetching medication data');
  }, [error]);

  const medications = useMemo(() => {
    if (!medicationRes) return [];
    return medicationRes.documents;
  }, [medicationRes]);
  const handleChange = (newDate: Date) => {
    setSelectedDate(newDate);
  };

  return (
    <Box flex={1} display={'flex'}>
      {/*Medications Calendar*/}
      <Box flex={1}>
        <SectionHeader title={'Medications'} />
        <Box
          gap={1}
          display={'flex'}
          overflow={'hidden'}
          borderRadius={'8px'}
          boxSizing={'border-box'}
          flexDirection={'column'}
          padding={theme.spacing(2)}
          bgcolor={theme.palette.secondary.main}
        >
          <HorizontalCalendar
            selectedDate={selectedDate}
            handleChange={date => handleChange(date as Date)}
          />
          {medications.map((medication, index) => (
            <MedNavCard
              key={index}
              time={medication.time}
              medicineName={medication.medicine.medicineName}
              medicationState={extractMedicationState(medication)}
              amount={`${medication.amount} (${medication.medicine.medicineType})`}
            />
          ))}
        </Box>
      </Box>
      <Box flex={1}></Box>
    </Box>
  );
};

export default DataPanelMedication;
