import api from '../../../redux/api';
import { Meal } from '@hdcorner/ui-library';

const foodsQueries = api
  .enhanceEndpoints({ addTagTypes: ['CustomMeals', 'FoodData', 'MealsData'] })
  .injectEndpoints({
    endpoints: build => ({
      getMealsOfDay: build.query<Meal[], { start: string; end: string; userId: string }>({
        query: ({ start, end, userId }) => ({
          method: 'GET',
          url: '/database/function/getMealsOfDay',
          params: { populate: 'foods.food', start, end, scope: `Nutrition:${userId}` },
        }),
        providesTags: ['MealsData'],
      }),
    }),
  });

export const { useGetMealsOfDayQuery } = foodsQueries;

export default foodsQueries;
