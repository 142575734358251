import React, { FC } from 'react';
import { Box } from '@mui/material';
import useAlert from '../../../hooks/useAlert';
import { useChangeEmailMutation } from '../queries/profileQueries';
import { ModalMobile, SettingsEmailPanelMobile } from '@hdcorner/ui-library';
import { useSignInMutation } from '../../authentication/queries/signInQueries';

type ModalChangeEmailProps = {
  open: boolean;
  email: string;
  setOpen: (open: boolean) => void;
};
const ModalChangeEmail: FC<ModalChangeEmailProps> = ({ email, open, setOpen }) => {
  const { presentError } = useAlert();

  const [login] = useSignInMutation();
  const [updateEmail] = useChangeEmailMutation();

  const changeEmail = async (newEmail: string, password: string) => {
    if (!email) {
      presentError('An unexpected error occurred. Please try again later.');
      return;
    }
    try {
      await login({ email: email, password: password }).unwrap();
      await updateEmail({ newEmail: newEmail }).unwrap();
      setOpen(false);
    } catch (e) {
      console.log(e);
      presentError('An unexpected error occurred. Please try again later.');
      setOpen(false);
    }
  };

  return (
    <ModalMobile open={open} height={'auto'} setOpen={setOpen} width={'512px'}>
      <Box padding={2} boxSizing={'border-box'}>
        <SettingsEmailPanelMobile handleChangeEmail={changeEmail} />
      </Box>
    </ModalMobile>
  );
};

export default ModalChangeEmail;
