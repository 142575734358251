import { Box, useTheme } from '@mui/material';
import SectionDietPlan from './SectionDietPlan';
import CalorieDataInner from './CalorieDataInner';
import SectionNutritionCal from './SectionNutritionCal';
import CaloriesSectionGraph from './CaloriesSectionGraph';
import { FC } from 'react';

type Props = { userId: string };
const FitnessPanelNutrition: FC<Props> = ({ userId }) => {
  const theme = useTheme();
  return (
    <Box display={'flex'} flex={1} gap={theme.spacing(3)}>
      <Box flex={1}>
        <Box display={'flex'} gap={theme.spacing(3)} flexDirection={'column'}>
          <CaloriesSectionGraph heading userId={userId} />
          <CalorieDataInner userId={userId} />
        </Box>
      </Box>
      <Box flex={1}>
        <Box display={'flex'} gap={theme.spacing(3)} flexDirection={'column'}>
          <SectionNutritionCal userId={userId} />
          <SectionDietPlan userId={userId} />
        </Box>
      </Box>
    </Box>
  );
};

export default FitnessPanelNutrition;
