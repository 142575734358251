import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: {
  fitness: { page: string };
  diabetes: { filter: string };
  hypertension: { page: string; filter: string };
} = {
  fitness: { page: 'nutrition' },
  diabetes: { filter: 'default' },
  hypertension: { filter: 'all', page: 'indepth' },
};

const userDataPageSlice = createSlice({
  name: 'userDataPage',
  initialState,
  reducers: {
    changeFitnessPage: (state, action: PayloadAction<string>) => {
      state.fitness.page = action.payload;
    },
    changeDiabetesResultsFilter: (state, action: PayloadAction<string>) => {
      state.diabetes.filter = action.payload;
    },
    changeHypertensionPage: (state, action: PayloadAction<string>) => {
      state.hypertension.page = action.payload;
    },
    changeHypertensionResultsFilter: (state, action: PayloadAction<string>) => {
      state.hypertension.filter = action.payload;
    },
  },
});

export const {
  changeFitnessPage,
  changeHypertensionPage,
  changeHypertensionResultsFilter,
  changeDiabetesResultsFilter,
} = userDataPageSlice.actions;
export default userDataPageSlice.reducer;
