import { MenuItem, styled } from '@mui/material';

export const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1),
  color: theme.palette.kmColorsCoolGrey.main,
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.subtitle2.fontSize,
  lineHeight: theme.typography.subtitle2.lineHeight,
  '&:first-of-type': {
    marginTop: theme.spacing(2),
  },
  '&:last-child': {
    marginBottom: theme.spacing(2),
  },
}));
