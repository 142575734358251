import React, { FC, useEffect, useMemo, useState } from 'react';
import moment from 'moment/moment';
import { Box, useTheme } from '@mui/material';
import { useAppSelector } from '../../../redux/hooks';
import SectionHeader from '../../../components/SectionHeader';
import {
  Collapsible,
  DiabetesBloodGlucoseChart,
  FeelsEmpty,
  InDepthDataCard,
  returnFiltersLabel,
  useOverallData,
  usePrepareGraph,
  useTimeframe,
  useCurrentMeasurement,
} from '@hdcorner/ui-library';
import {
  useGetBloodGlucoseGraphDataQuery,
  useGetBloodGlucoseLogsQuery,
  useGetBloodGlucoseOverallQuery,
  useGetBloodGlucoseCurrentMeasurementQuery,
} from '../queries/diabetesQueries';
import useAlert from '../../../hooks/useAlert';
import { weekMonthYearCustom } from '../../toggleHeadings';

type Props = {
  userId: string;
};

const DiabetesBloodGlucosePanel: FC<Props> = ({ userId }) => {
  const theme = useTheme();
  const { presentError } = useAlert();

  const { diabetes } = useAppSelector(state => state.userDataPage);
  const { dateFilter, customDateFilter } = useAppSelector(state => state.layout);
  const [bloodGlucoseParams] = useState({
    skip: 0,
    limit: 1000,
  });

  const timeframe = useTimeframe(
    dateFilter,
    customDateFilter?.start,
    customDateFilter?.end,
  );

  const diabetesFilter = useMemo(() => {
    return diabetes.filter;
  }, [diabetes]);

  const { data: currentMeasurements, error: errorGlucoseMeasurement } =
    useGetBloodGlucoseCurrentMeasurementQuery({
      userId,
    });
  const { data: graphDataRes, error: errorGlucoseGraph } =
    useGetBloodGlucoseGraphDataQuery({
      ...timeframe,
      userId,
    });
  const { data: overallData, error: errorGlucoseOverall } =
    useGetBloodGlucoseOverallQuery({
      category: diabetesFilter === 'all' ? undefined : diabetesFilter,
      ...timeframe,
      userId,
    });

  const { data: bloodGlucoseLogs, error: errorGlucoseLogs } = useGetBloodGlucoseLogsQuery(
    {
      params: {
        ...bloodGlucoseParams,
        ...timeframe,
        userId,
      },
    },
  );

  useEffect(() => {
    if (errorGlucoseMeasurement)
      presentError('An unexpected error occurred fetching glucose measurements data');
    if (errorGlucoseGraph)
      presentError('An unexpected error occurred fetching glucose graph data');
    if (errorGlucoseOverall)
      presentError('An unexpected error occurred fetching glucose overall data');
    if (errorGlucoseLogs)
      presentError('An unexpected error occurred fetching glucose log data');
  }, [errorGlucoseMeasurement, errorGlucoseGraph, errorGlucoseOverall, errorGlucoseLogs]);

  const graphData = usePrepareGraph(graphDataRes);
  const prepareOverallData = useOverallData(overallData);
  const prepareCurrentMeasurement = useCurrentMeasurement(currentMeasurements);

  const prepareOverallDataValues = useMemo(() => {
    if (!overallData)
      return [
        {
          name: 'All',
          min: 'N/A',
          max: 'N/A',
          avg: 'N/A',
        },
      ];
    const data = overallData;
    return [
      {
        name: 'All',
        min: data.min ? data.min.toFixed() : 'N/A',
        max: data.max ? data.max.toFixed() : 'N/A',
        avg: data.avg ? data.avg.toFixed() : 'N/A',
      },
    ];
  }, [overallData]);

  const prepareInDepthData = useMemo(() => {
    if (!bloodGlucoseLogs) return [];

    return bloodGlucoseLogs.documents.map(item => {
      return {
        title: item.measurement.toString(),
        titleSub: 'mg/dL',
        values: {
          'Blood Glucose': { value: `${item.measurement} mg/dL` },
          Filters: { value: returnFiltersLabel(item.category) },
          'Time of Calculation': { value: moment(item.logDate).format('h:mm A') },
          'Date of Calculation': {
            value: moment(item.logDate).format('MMM Do, YYYY'),
          },
        },
      };
    });
  }, [bloodGlucoseLogs]);

  return (
    <Box display={'flex'} flexDirection={'column'} flex={1} gap={3}>
      <Box display={'flex'} flexDirection={'column'}>
        <SectionHeader title={'blood glucose'} />
        <DiabetesBloodGlucoseChart
          chartData={graphData}
          overallData={prepareOverallData}
          latestMeasurement={prepareCurrentMeasurement}
          headings={weekMonthYearCustom.filter(it => it.value !== 'day')}
        />
      </Box>
      <Box display={'flex'} flex={1} flexDirection={'column'} gap={1}>
        <SectionHeader title={'in-depth data'} />
        <InDepthDataCard
          measurement={'mg/dL'}
          rows={prepareOverallDataValues}
          headings={['Min.', 'Max.', 'Avg.']}
        />
        {prepareInDepthData.map((data, index) => (
          <Box key={`${data.title}_${index}`} sx={{ marginBottom: theme.spacing(1) }}>
            <Collapsible title={data.title} data={data.values} titleSub={data.titleSub} />
          </Box>
        ))}
        {prepareInDepthData.length === 0 && <FeelsEmpty />}
      </Box>
    </Box>
  );
};

export default DiabetesBloodGlucosePanel;
