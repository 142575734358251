import api from '../../../redux/api';
import { ApiPagination, PaginationResult, TipsTricks } from '@hdcorner/ui-library';

const trafficLightQueries = api
  .enhanceEndpoints({ addTagTypes: ['TipsTricks'] })
  .injectEndpoints({
    endpoints: build => ({
      getTrafficLights: build.query<PaginationResult<TipsTricks>, ApiPagination>({
        query: ({ sort, ...rest }) => ({
          url: '/hdCornerService/admin/content/tip-and-trick',
          method: 'GET',
          params: { sort, ...rest },
        }),
        providesTags: ['TipsTricks'],
      }),

      postTrafficLight: build.mutation<void, TipsTricks>({
        query: trafficLight => ({
          url: '/hdCornerService/admin/content/tip-and-trick',
          method: 'POST',
          body: trafficLight,
        }),
        invalidatesTags: ['TipsTricks'],
      }),

      editTrafficLight: build.mutation<void, TipsTricks & { id: string }>({
        query: ({ id, ...rest }) => ({
          url: `/hdCornerService/admin/content/tip-and-trick/${id}`,
          method: 'PATCH',
          body: rest,
        }),
        invalidatesTags: ['TipsTricks'],
      }),

      deleteTrafficLight: build.mutation<void, { id: string }>({
        query: ({ id }) => ({
          url: `/hdCornerService/admin/content/tip-and-trick/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['TipsTricks'],
      }),
    }),
  });

export const {
  useGetTrafficLightsQuery,
  usePostTrafficLightMutation,
  useEditTrafficLightMutation,
  useDeleteTrafficLightMutation,
} = trafficLightQueries;

export default trafficLightQueries;
