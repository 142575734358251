import React, { FC } from 'react';
import { styled, Typography, TypographyProps } from '@mui/material';

const UserAvg = styled(Typography)<TypographyProps>(({ theme }) => ({
  textTransform: 'uppercase',
  marginBottom: theme.spacing(1),
  color: theme.palette.kmColorsCoolGrey.main,
  fontWeight: theme.typography.fontWeightMedium,
}));

type Props = {
  title: string;
};

const SectionHeader: FC<Props & TypographyProps> = ({ title, ...rest }) => {
  return (
    <UserAvg variant={'subtitle1'} {...rest}>
      {title}
    </UserAvg>
  );
};

export default SectionHeader;
