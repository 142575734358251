import React, { FC } from 'react';
import { LineChart, theme } from '@hdcorner/ui-library';
import { Box, BoxProps, styled, Typography, TypographyProps } from '@mui/material';

const Container = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 1,
  display: 'flex',
  borderRadius: '8px',
  flexDirection: 'column',
  padding: theme.spacing(2),
  background: theme.palette.secondary.main,
}));

const InnerContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

const Text = styled(Typography)<TypographyProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.primary.main,
  marginRight: theme.spacing(1),
}));

const Circle = styled(Box)<BoxProps & { color: string }>(({ theme, color }) => ({
  width: 8,
  height: 8,
  background: color,
  borderRadius: '30px',
  marginRight: theme.spacing(1),
}));

type Props = {
  label1: string;
  label2?: string;
  label3?: string;
  data: { name: string; data: { x: string; y: number }[] }[];
};

const AdminLineChart: FC<Props> = ({ data, label1, label2, label3 }) => {
  return (
    <Container>
      <InnerContainer>
        <Text variant={'subtitle2'}>
          <Circle color={theme.palette.medication.main} />
          {label1}
        </Text>
        {label2 && (
          <Text variant={'subtitle2'}>
            <Circle color={theme.palette.hypertension.main} />
            {label2}
          </Text>
        )}
        {label3 && (
          <Text variant={'subtitle2'}>
            <Circle color={'#00FFFF'} />
            {label3}
          </Text>
        )}
      </InnerContainer>
      <Box>
        <LineChart
          chartData={data}
          colors={[theme.palette.medication.main, theme.palette.hypertension.main]}
          height={185}
        />
      </Box>
    </Container>
  );
};

export default AdminLineChart;
