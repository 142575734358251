import React, { FC, useEffect, useMemo } from 'react';
import moment from 'moment';
import { Box } from '@mui/material';
import { useAppSelector } from '../../../redux/hooks';
import SectionHeader from '../../../components/SectionHeader';
import { useGetAllActivityLogsQuery } from '../queries/activitiesQueries';
import {
  capitalizeFirstLetter,
  Collapsible,
  FeelsEmpty,
  useTimeframe,
} from '@hdcorner/ui-library';
import useAlert from '../../../hooks/useAlert';

type Props = { userId: string };

const SectionActivitiesData: FC<Props> = ({ userId }) => {
  const { presentError } = useAlert();

  const { dateFilter, customDateFilter } = useAppSelector(state => state.layout);

  const timeframe = useTimeframe(
    dateFilter,
    customDateFilter?.start,
    customDateFilter?.end,
  );

  const { error, data: activityLogsRes } = useGetAllActivityLogsQuery({
    userId,
    skip: 0,
    limit: 1000,
    sort: ['-logDate'],
    ...timeframe,
  });

  useEffect(() => {
    if (error) presentError('An unexpected error occurred fetching activities data');
  }, [error]);

  const inDepthData = useMemo(() => {
    if (!activityLogsRes || activityLogsRes.documents.length === 0) return [];

    return activityLogsRes.documents.map((it: any) => {
      return {
        _id: it._id,
        subtitle: 'Activity',
        title:
          it.custom || !it.activity
            ? capitalizeFirstLetter(it.name)
            : capitalizeFirstLetter(it.activity.name),
        data: {
          'kcal burned': {
            value: Math.round(it.calories).toString(),
          },
          'Duration (mins)': { value: it.duration.toString() },
          'Time of calculation': { value: moment(it.logDate).format('h:mm a') },
          'Date of calculation': { value: moment(it.logDate).format('MMMM Do, YYYY') },
        },
      };
    });
  }, [activityLogsRes]);

  return (
    <Box>
      <SectionHeader title={'in-depth data'} />

      <Box mt={1} gap={1} display={'flex'} flexDirection={'column'}>
        {inDepthData.map(data => (
          <Box key={data._id} width={'100%'}>
            <Collapsible data={data.data} title={data.title} titleSub={data.subtitle} />
          </Box>
        ))}

        {inDepthData.length === 0 && (
          <Box mb={1} width={'100%'}>
            <FeelsEmpty />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SectionActivitiesData;
