import { FC } from 'react';
import LipidsPanelAll from './LipidsPanelAll';
import LipidsPanelHdl from './LipidsPanelHdl';
import LipidsPanelLdl from './LipidsPanelLdl';
import LipidsPanelLpa from './LipidsPanelLpa';
import LipidsPanelTrg from './LipidsPanelTrg';
import LipidsPanelChol from './LipidsPanelChol';
import { RouteComponentProps } from 'react-router';
import { useAppSelector } from '../../../redux/hooks';

interface Props extends RouteComponentProps<{ userId: string }> {}
const DataPanelLipid: FC<Props> = ({ match }) => {
  const { page } = useAppSelector(state => state.lipid);

  return (
    <>
      {page === 'all' && <LipidsPanelAll userId={match.params.userId} />}
      {page === 'chol' && <LipidsPanelChol userId={match.params.userId} />}
      {page === 'hdl' && <LipidsPanelHdl userId={match.params.userId} />}
      {page === 'ldl' && <LipidsPanelLdl userId={match.params.userId} />}
      {page === 'trg' && <LipidsPanelTrg userId={match.params.userId} />}
      {page === 'lpa' && <LipidsPanelLpa userId={match.params.userId} />}
    </>
  );
};

export default DataPanelLipid;
