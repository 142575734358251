import React, { useEffect, useMemo, useState } from 'react';
import {
  AdminTablePagination,
  capitalizeFirstLetter,
  ComponentsLayout,
  HealthProfDashboardCard,
  UsersSQ,
} from '@hdcorner/ui-library';
import moment from 'moment/moment';
import PageLayout from '../../PageLayout';
import { useIonRouter } from '@ionic/react';
import { UserReduced } from './types/UserReduced';
import UsersTableRows from './components/UsersTableRows';
import {
  useGetUsersQuery,
  useGetUserSubscriptionAnalyticsQuery,
} from './queries/usersQueries';
import avatar from '../../assets/illustrations/UserAvater.png';
import UsersTableHeading from './components/UsersTableHeading';
import { Box, Table, TableBody, useTheme } from '@mui/material';
import handleTrendIcon from '../../utils/handleTrendIcon';
import useAlert from '../../hooks/useAlert';

const createData = (row: any) => {
  return [
    row.name,
    row.subscription,
    row.userId,
    row.subscriptionType,
    row.personalityType,
  ];
};

const headCells: {
  _id: string;
  label: string;
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
}[] = [
  { _id: 'name', label: 'Name', align: 'left' },
  { _id: 'subscription', label: 'Subscription', align: 'center' },
  { _id: 'userId', label: 'User ID', align: 'center' },
  { _id: 'subscriptionType', label: 'Subscription type', align: 'center' },
  { _id: 'personalityType', label: 'Personality type', align: 'center' },
];
type Order = 'asc' | 'desc';

const skipAmount = 5;

const Users: React.FC = () => {
  const theme = useTheme();
  const router = useIonRouter();
  const { presentError } = useAlert();

  const [skip, setSkip] = useState<number>(0);
  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState<Order>('asc');
  const [currentPage, setCurrentPage] = useState(1);

  const {
    data: usersRes,
    error: errorUsers,
    isFetching: dataFetch,
  } = useGetUsersQuery({
    skip: skip,
    limit: 12,
    sort: ['-premiumUser'],
  });

  const { data: tallyRes, error: errorSubsAnalytics } =
    useGetUserSubscriptionAnalyticsQuery({
      skip: 0,
      limit: 10,
    });

  useEffect(() => {
    if (errorUsers) presentError('An unexpected error occurred fetching users data');
    if (errorSubsAnalytics)
      presentError('An unexpected error occurred fetching subscription analytics data');
  }, [errorUsers, errorSubsAnalytics]);

  const userRows = useMemo(() => {
    if (!usersRes || usersRes.documents.length === 0) return [];
    return usersRes.documents.map((user: UserReduced) => ({
      avatar: avatar,
      userId: user.userId,
      name: 'HD Corner User',
      subscription: user.premiumUser ? 'Yes' : 'No',
      personalityType: user.questionnaireOutcome
        ? capitalizeFirstLetter(user.questionnaireOutcome)
        : '-',
      subscriptionType: user.subscriptionType || '-',
    }));
  }, [usersRes]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (id: string) => {
    router.push(`/dashboard/user/data/${id}`);
  };

  const handleSkipClick = (amount: number) => {
    setCurrentPage(currentPage => currentPage + amount);
  };

  useEffect(() => {
    const apiSkip = (currentPage - 1) * 12;
    setSkip(apiSkip);
  }, [currentPage]);

  const numberOfPages = useMemo(() => {
    if (!usersRes) return 0;
    return Math.ceil(usersRes.count / 12);
  }, [usersRes]);

  const prepareData = (tally: string, color: string) => {
    const results: any = tallyRes ? tallyRes.documents[0] : undefined;
    return {
      id: results?._id + tally || '',
      icon: <UsersSQ color={color} />,
      value: results?.[tally]?.measurement || 0,
      direction: results?.[tally]?.trend || 'stable',
      date: moment(results?.updatedAt).format('MMM YYYY') || 'N/A',
    };
  };

  /** Returns information for the Data Card totals */
  const data = useMemo(() => {
    return {
      users: prepareData('total', theme.palette.kmColorsPurple.main),
      free: prepareData('free', '#318DE2'),
      monthly: prepareData('monthly', '#E87A2B'),
      annually: prepareData('annually', '#5FD071'),
      biAnnually: prepareData('biAnnually', '#616161'),
    };
  }, [prepareData]);

  return (
    <PageLayout>
      <ComponentsLayout display={'flex'} flexDirection={'column'} gap={theme.spacing(3)}>
        <Box display={'flex'} gap={theme.spacing(3)}>
          {Object.entries(data).map(([key, value]) => (
            <HealthProfDashboardCard
              expanded
              heading={key}
              key={value.id}
              num={value.value}
              icon={value.icon}
              date={value.date as string}
              iconTwo={handleTrendIcon(value.direction)}
            />
          ))}
        </Box>

        <Table>
          <UsersTableHeading
            order={order}
            orderBy={orderBy}
            headCells={headCells}
            totalRowsCount={userRows.length}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {userRows.map((row, index) => (
              <UsersTableRows
                avatar={row.avatar}
                key={row.userId + index}
                rowCells={createData(row)}
                onClick={() => handleClick(row.userId ? row.userId : '')}
              />
            ))}
          </TableBody>
        </Table>
        <AdminTablePagination
          loading={dataFetch}
          skipAmount={skipAmount}
          currentPage={currentPage}
          numberOfPages={numberOfPages}
          onSkipClick={handleSkipClick}
        />
      </ComponentsLayout>
    </PageLayout>
  );
};

export default Users;
