import React, { useEffect, useMemo, useState } from 'react';
import { useIonRouter } from '@ionic/react';
import { styled } from '@mui/material/styles';
import { useAppDispatch } from '../../../redux/hooks';
import { Box, BoxProps, useTheme } from '@mui/material';
import DropdownMenu from '../../../components/DropdownMenu';
import ToolbarContainer from '../../../components/ToolbarContainer';
import { changeCategory, changeSort } from '../slices/articleSlice';
import {
  MeasurementsIcon,
  Searchbar,
  ToggleButtons,
  useDebounce,
} from '@hdcorner/ui-library';
import { CategoriesList, DropdownList, SortingList, VideoSortList } from '../constants';
import { changeVideoSection, changeVideoSort } from '../slices/videoSlice';
import { useGetArticlesCategoriesQuery } from '../queries/articleQueries';
import useAlert from '../../../hooks/useAlert';
import { clearSearchText, updateSearchText } from '../slices/searchSlice';

const Divider = styled(Box)<BoxProps>(({ theme }) => ({
  width: '2px',
  opacity: 0.1,
  height: '16px',
  backgroundColor: theme.palette.kmColorsCoolGrey.main,
}));

const ContentToolbarMain = () => {
  const theme = useTheme();
  const { presentError } = useAlert();
  const dispatch = useAppDispatch();
  const router = useIonRouter();

  const currentPage = router?.routeInfo?.pathname?.split('/').slice(-1);

  const [search, setSearch] = useState<string>('');
  const [category, setCategory] = useState('all');
  const [sorting, setSorting] = useState(SortingList[0].value);
  const [videoType, setVideoType] = useState<string>('other');
  const [videoSort, setVideoSort] = useState(VideoSortList[0].value);
  const [contentValue, setContentValue] = useState(DropdownList[0].value);

  const searchText = useDebounce(search, 500);

  /**
   * Update redux slice with search value
   */
  useEffect(() => {
    dispatch(updateSearchText(searchText));
  }, [searchText]);

  useEffect(() => {
    setContentValue(currentPage[0]);
  }, [currentPage]);

  const { data: articlesCategories, error: errorArticlesCategories } =
    useGetArticlesCategoriesQuery(
      { search: undefined },
      { skip: contentValue !== 'articles' },
    );

  useEffect(() => {
    if (errorArticlesCategories) {
      console.log(errorArticlesCategories);
      presentError('Error fetching articles categories');
    }
  }, [errorArticlesCategories]);

  const categories = useMemo(() => {
    let categories = [{ name: 'All categories', value: 'all' }];
    const apiCategories = articlesCategories?.categories?.map((category: string) => ({
      name: category.charAt(0).toUpperCase() + category.slice(1),
      value: category,
    }));
    return categories.concat(apiCategories || []);
  }, [articlesCategories]);

  const handleContentChange = (event: any) => {
    const newState = DropdownList.find(option => option.value === event.target.value);
    if (newState) {
      setSearch('');
      dispatch(clearSearchText());
      setContentValue(newState?.value);
      router.push(`/dashboard/content/${newState.value}`);
    }
  };

  const handleSortArticles = (event: any) => {
    const newState = SortingList.find(option => option.value === event.target.value);
    if (newState) {
      setSorting(newState?.value);
      dispatch(changeSort(newState?.value));
    }
  };

  const handleFilterCategory = (event: any) => {
    const newState = CategoriesList.find(option => option.value === event.target.value);
    if (newState) {
      setCategory(newState?.value);
      dispatch(changeCategory(newState?.value));
    }
  };

  const handleSortVideos = (event: any) => {
    const newState = VideoSortList.find(option => option.value === event.target.value);
    if (newState) {
      setVideoSort(newState?.value);
      dispatch(changeVideoSort(newState?.value));
    }
  };

  const handleVideoChange = (arg: string) => {
    setVideoType(arg);
    dispatch(changeVideoSection(arg));
  };

  const measurementsIcon = (
    <MeasurementsIcon
      width={20}
      height={22}
      color={theme.palette.kmColorsCoolGrey.main}
    />
  );

  return (
    <ToolbarContainer style={{ justifyContent: 'space-between' }}>
      <Box gap={3} display={'flex'} alignItems={'center'}>
        <DropdownMenu
          value={contentValue}
          options={DropdownList}
          setValue={setContentValue}
          handleDropdownChange={handleContentChange}
        />

        {contentValue === 'articles' && (
          <>
            <Divider />
            <Box gap={1} display={'flex'} alignItems={'center'}>
              {measurementsIcon}
              <DropdownMenu
                value={sorting}
                options={SortingList}
                setValue={setSorting}
                handleDropdownChange={handleSortArticles}
              />
            </Box>
            <Divider />
            <Box gap={1} display={'flex'} alignItems={'center'}>
              {measurementsIcon}
              <DropdownMenu
                value={category}
                options={categories}
                setValue={setCategory}
                handleDropdownChange={handleFilterCategory}
              />
            </Box>
          </>
        )}

        {contentValue === 'video' && (
          <>
            {videoType !== 'other' && (
              <>
                <Divider />
                <Box gap={1} display={'flex'} alignItems={'center'}>
                  {measurementsIcon}
                  <DropdownMenu
                    value={videoSort}
                    setValue={setVideoSort}
                    options={VideoSortList}
                    handleDropdownChange={handleSortVideos}
                  />
                </Box>
              </>
            )}

            <Divider />
            <ToggleButtons
              size={'large'}
              value={videoType}
              sx={{
                width: 'auto',
                textTransform: 'uppercase',
                '&.Mui-selected, &.Mui-selected:hover': {
                  backgroundColor: 'rgba(0,0,0,0.22)',
                  color: theme.palette.kmColorsCoolGrey.main,
                },
              }}
              headings={[
                { label: 'other', value: 'other' },
                { label: 'workouts', value: 'workouts' },
              ]}
              handleChange={handleVideoChange}
            />

            {videoType !== 'other' && (
              <>
                <Divider />
                <ToggleButtons
                  size={'large'}
                  value={videoType}
                  sx={{
                    width: 'auto',
                    textTransform: 'uppercase',
                    '&.Mui-selected, &.Mui-selected:hover': {
                      backgroundColor: 'rgba(0,0,0,0.22)',
                      color: theme.palette.kmColorsCoolGrey.main,
                    },
                  }}
                  handleChange={handleVideoChange}
                  headings={[{ label: 'programs', value: 'programs' }]}
                />
              </>
            )}
          </>
        )}
      </Box>

      {(contentValue === 'activity' ||
        contentValue === 'foods' ||
        contentValue === 'medication') && (
        <Searchbar
          value={search}
          sx={{ padding: 0 }}
          setValue={setSearch}
          placeholder={'Search'}
        />
      )}
    </ToolbarContainer>
  );
};

export default ContentToolbarMain;
