import React, { FC } from 'react';
import { DropdownDownArrow } from '@hdcorner/ui-library';
import { FormControl, Select, styled } from '@mui/material';
import { DropdownType } from '../features/content/types/DropdownType';
import { CustomMenuItem } from './CustomMenuItem';

const CustomFormControl = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '.MuiInputBase-root > div': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  '.MuiInputBase-root > svg': {
    transform: 'translateY(-6px)',
  },
  '.MuiSelect-select.MuiSelect-standard.MuiInputBase-input': {
    color: theme.palette.kmColorsCoolGrey.main,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.subtitle2.fontSize,
    lineHeight: theme.typography.subtitle1.lineHeight,
  },
}));

type Props = {
  value: string;
  options: DropdownType[];
  setValue: (value: string) => void;
  handleDropdownChange: (event: any) => void;
};

const DropdownMenu: FC<Props> = ({ options, value, setValue, handleDropdownChange }) => {
  return (
    <CustomFormControl>
      <Select
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        sx={{
          display: 'flex',
          paddingRight: '8px',
          position: 'relative',
          alignItems: 'center',
        }}
        value={value}
        disableUnderline
        variant={'standard'}
        onChange={handleDropdownChange}
        inputProps={{
          IconComponent: DropdownDownArrow,
        }}
      >
        {options.map((category, index) => (
          <CustomMenuItem key={index} value={category.value}>
            {category.icon && category.icon}
            {category.name}
          </CustomMenuItem>
        ))}
      </Select>
    </CustomFormControl>
  );
};

export default DropdownMenu;
