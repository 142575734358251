import React, { FC, ReactNode } from 'react';
import {
  CustomButton,
  ModalHeading,
  ModalMobile,
  ToggleButtonGroupCu,
  XCloseIcon,
} from '@hdcorner/ui-library';
import { Box, Typography, useTheme } from '@mui/material';

type Props = {
  value: string;
  open: boolean;
  data?: boolean;
  category: string;
  disabled?: boolean;
  children: ReactNode;
  optionalInput?: ReactNode;
  handleSaveModal: () => void;
  setOpen: (open: boolean) => void;
  setValue: (value: 'en' | 'el') => void;
};
const ModalBaseContent: FC<Props> = ({
  open,
  data,
  value,
  setOpen,
  category,
  disabled,
  setValue,
  children,
  optionalInput,
  handleSaveModal,
}) => {
  const theme = useTheme();

  const handleChange = (arg: string) => {
    const value = arg as 'en' | 'el';
    setValue(value);
  };

  return (
    <ModalMobile open={open} setOpen={setOpen}>
      <Box width={'640px'}>
        <Box
          gap={2}
          display={'flex'}
          flexDirection={'column'}
          padding={theme.spacing(2, 3, 2, 3)}
        >
          <ModalHeading
            align={'left'}
            title={category}
            showCalendar={'flex'}
            sx={{ marginBottom: 0 }}
            alternateIcon={<XCloseIcon />}
            handleCalendarClick={() => setOpen(false)}
          />

          <Box mb={2} display={'flex'} justifyContent={'space-between'}>
            <Box gap={1} display={'flex'} flexDirection={'column'}>
              <Typography
                variant={'subtitle2'}
                sx={{
                  color: theme.palette.kmColorsCoolGrey.main,
                  fontWeight: theme.typography.fontWeightRegular,
                }}
              >
                Version
              </Typography>
              <ToggleButtonGroupCu
                value={value}
                setValue={handleChange}
                headings={['en', 'el']}
              />
            </Box>
            {optionalInput}
          </Box>
          {children}
        </Box>
        <Box textAlign={'center'} padding={theme.spacing(3)} bgcolor={'#272727'}>
          <CustomButton
            disabled={disabled}
            variant={'contained'}
            sx={{
              width: '212px',
              ':disabled': {
                opacity: 0.3,
                backgroundColor: theme.palette.kmColorsTurquoise.main,
              },
            }}
            onClick={handleSaveModal}
          >
            {data ? 'Save changes' : 'Create'}
          </CustomButton>
        </Box>
      </Box>
    </ModalMobile>
  );
};

export default ModalBaseContent;
