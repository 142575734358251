import _ from 'lodash';
import PageLayout from '../../PageLayout';
import useAlert from '../../hooks/useAlert';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import {
  useSetUserProfilePictureMutation,
  useUpdateAuthUserMutation,
} from './queries/profileQueries';
import { v4 as uuidv4 } from 'uuid';
import {
  useReUploadFileMutation,
  useUploadFileMutation,
} from '../../queries/fileQueries';
import { useGetAuthUserQuery } from '../authentication/queries/signInQueries';
import {
  AuthUser,
  ConduitFile,
  CustomButton,
  CustomInput,
  WebProfilePic,
} from '@hdcorner/ui-library';
import ModalChangeEmail from './components/ModalChangeEmail';

const BoxContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  padding: theme.spacing(3),
}));

const TypographyHeading = styled(Typography)<TypographyProps>(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.palette.secondary.light,
  marginBottom: theme.spacing(1),
  fontWeight: theme.typography.fontWeightBold,
}));

const Profile = () => {
  const theme = useTheme();
  const { presentError, presentSuccess } = useAlert();

  const [openEmail, setOpenEmail] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<Omit<AuthUser, '_id' | 'birthDate'>>({
    email: '',
    lastName: '',
    jobTitle: '',
    firstName: '',
    profilePicture: undefined,
  });

  const { error, data: userData } = useGetAuthUserQuery();

  useEffect(() => {
    if (error) presentError('An unexpected error occurred fetching your data');
  }, [error]);

  const [upload] = useUploadFileMutation();
  const [reUpload] = useReUploadFileMutation();

  const [setProfilePicture] = useSetUserProfilePictureMutation();
  const [updateAuthUser, { error: updateAuthUserError }] = useUpdateAuthUserMutation();

  useEffect(() => {
    if (!userData) return;
    const user = userData[0];
    if (!user) return;

    let newAuthUser: Omit<AuthUser, '_id' | 'birthDate'>;
    if (userData) {
      newAuthUser = {
        email: user.email,
        jobTitle: user.jobTitle,
        lastName: user.lastName,
        firstName: user.firstName,
        profilePicture: user.profilePicture,
      };
    }

    setUserDetails((oldValue: any) => ({ ...oldValue, ...newAuthUser }));
  }, [userData]);

  useEffect(() => {
    if (updateAuthUserError) {
      presentError('Something went wrong');
    }
  }, [updateAuthUserError]);

  const stateHasChanged = useMemo(() => {
    if (userData) {
      const user = userData[0];
      if (!user) return;
      const allUserDataInitial = {
        email: user.email,
        jobTitle: user.jobTitle,
        lastName: user.lastName,
        firstName: user.firstName,
        profilePicture: user.profilePicture,
      };

      return !_.isEqual(allUserDataInitial, userDetails);
    }

    return false;
  }, [userDetails, userData]);

  const handleSave = () => {
    updateAuthUser({
      jobTitle: userDetails.jobTitle,
      lastName: userDetails.lastName,
      firstName: userDetails.firstName,
    })
      .then(() => presentSuccess('Changes saved successfully'))
      .catch(() => presentError('Something went wrong'));
  };

  const handleChange = (fieldName: string, value: any) => {
    setUserDetails(oldValue => ({
      ...oldValue,
      [fieldName]: value,
    }));
  };

  const handleDrop = (files: File[]) => {
    const file = files[0];
    if (!file) return;

    const fileName = `${uuidv4()}-${file.name}`;
    const existingProfileImage = userData?.[0]?.profilePicture as ConduitFile;
    if (existingProfileImage) {
      reUpload({
        name: fileName,
        size: file.size,
        _id: existingProfileImage._id,
      })
        .unwrap()
        .then(res => {
          const conduitFile = res.file;
          const url = res.url;

          setProfilePicture({
            profilePicture: conduitFile._id,
          })
            .unwrap()
            .then(() => {
              fetch(url, {
                method: 'PUT',
                body: file,
                headers: {
                  'x-ms-blob-type': 'BlockBlob',
                  'Content-Type': file.type,
                },
              });
            });
        });
      return;
    }

    upload({
      name: fileName,
      size: file.size,
    })
      .unwrap()
      .then(res => {
        const conduitFile = res.file;
        const url = res.url;

        setProfilePicture({
          profilePicture: conduitFile._id,
        })
          .unwrap()
          .then(() => {
            fetch(url, {
              method: 'PUT',
              body: file,
              headers: {
                'x-ms-blob-type': 'BlockBlob',
                'Content-Type': file.type,
              },
            });
          });
      });
  };

  return (
    <PageLayout>
      <BoxContainer>
        <Box display={'flex'} flex={1} minWidth={'421px'}>
          <WebProfilePic
            handleDrop={handleDrop}
            textDown="Max size 24MB."
            img={userDetails.profilePicture?.url ?? ''}
            textUp="Click on your image to upload a new one"
          />
        </Box>
        <Box gap={3} display={'flex'} flexDirection={'column'}>
          <Box display={'flex'} flexDirection={'column'}>
            <TypographyHeading variant={'subtitle2'}>General info</TypographyHeading>

            <Box
              borderRadius={'8px'}
              boxSizing={'border-box'}
              padding={theme.spacing(3)}
              bgcolor={theme.palette.secondary.main}
            >
              <Box display={'flex'} flex={1} gap={theme.spacing(3)} flexWrap={'wrap'}>
                <Box flex={'1 0 223px'}>
                  <CustomInput
                    fullWidth
                    label={'First Name'}
                    value={userDetails.firstName}
                    handleChange={(value: string) => handleChange('firstName', value)}
                  />
                </Box>

                <Box flex={'1 0 223px'}>
                  <CustomInput
                    fullWidth
                    label={'Last Name'}
                    value={userDetails.lastName}
                    handleChange={(value: string) => handleChange('lastName', value)}
                  />
                </Box>
                <Box flex={'1 0 223px'}>
                  <CustomInput
                    fullWidth
                    label={'Job Title'}
                    value={userDetails.jobTitle}
                    handleChange={(value: string) => handleChange('jobTitle', value)}
                  />
                </Box>
                <Box flex={'1 0 223px'}>
                  <CustomInput
                    readOnly
                    fullWidth
                    label={'Level'}
                    value={'Admin'}
                    handleChange={(value: string) => handleChange('level', value)}
                  />
                </Box>
                <Box flex={'1 0 223px'} display={'flex'} alignItems={'flex-end'}>
                  <CustomButton
                    fullWidth
                    color={'secondary'}
                    onClick={handleSave}
                    variant={'contained'}
                    disabled={!stateHasChanged}
                    sx={{
                      minHeight: '47px',
                      padding: theme.spacing(3),
                      ':disabled': {
                        opacity: 0.3,
                      },
                    }}
                  >
                    Save
                  </CustomButton>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box display={'flex'} flexDirection={'column'}>
            <TypographyHeading variant={'subtitle2'}>Account Settings</TypographyHeading>

            <Box
              borderRadius={'8px'}
              boxSizing={'border-box'}
              padding={theme.spacing(3)}
              bgcolor={theme.palette.secondary.main}
            >
              <Box display={'flex'} flex={1} gap={theme.spacing(3)} flexWrap={'wrap'}>
                <Box flex={'1 0 223px'}>
                  <CustomInput
                    readOnly
                    fullWidth
                    label={'Email'}
                    value={userDetails.email}
                    handleChange={(value: string) => handleChange('email', value)}
                  />
                </Box>

                <Box flex={'1 0 223px'} display={'flex'} alignItems={'flex-end'}>
                  <CustomButton
                    fullWidth
                    color={'secondary'}
                    variant={'contained'}
                    onClick={() => setOpenEmail(true)}
                    sx={{
                      minHeight: '47px',
                      padding: theme.spacing(3),
                      ':disabled': {
                        opacity: 0.3,
                      },
                    }}
                  >
                    Change email
                  </CustomButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </BoxContainer>
      <ModalChangeEmail
        open={openEmail}
        setOpen={setOpenEmail}
        email={userDetails.email || ''}
      />
    </PageLayout>
  );
};

export default Profile;
