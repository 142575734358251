import React, { FC } from 'react';
import {
  Avatar,
  TableCell,
  TableCellProps,
  TableRow,
  useTheme,
  styled,
  Typography,
} from '@mui/material';
import avatar from '../../../assets/illustrations/UserAvater.png';

const TableCellData = styled(TableCell)<TableCellProps>(({ theme }) => ({
  flex: '1',
  height: '40px',
  overflow: 'hidden',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  textOverflow: 'ellipsis',
  padding: theme.spacing(1),
  color: theme.palette.primary.main,
  fontSize: theme.typography.body1.fontSize,
  fontWeight: theme.typography.body1.fontWeight,
  backgroundColor: theme.palette.secondary.main,
  borderBottomColor: theme.palette.background.default,
  '.Mui-active': {
    color: theme.palette.primary.light,
  },
  '&:first-of-type': {
    width: '52px',
    textAlign: 'center',
  },
  '&:nth-of-type(2)': {
    textAlign: 'left',
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export type UsersTableRowsProps = {
  data: {
    cc: string;
    sales: string;
    gender: string;
    ccDate: string;
    salesDate: string;
    subscriptionType: string;
    subscriptionDate: string;
  };
};

const UsersTableRows: FC<UsersTableRowsProps> = ({ data }) => {
  const theme = useTheme();
  return (
    <TableRow
      hover
      tabIndex={-1}
      sx={{
        '&:last-child th, &:last-child td': {
          borderBottom: 0,
          '&:first-of-type': {
            borderBottomLeftRadius: '8px',
          },
          '&:last-of-type': {
            borderBottomRightRadius: '8px',
          },
        },
      }}
    >
      <TableCellData align={'center'}>
        <Avatar
          src={avatar}
          sx={{
            width: 24,
            height: 24,
            margin: '0 auto',
          }}
        />
      </TableCellData>
      <TableCellData align={'left'}>HD Corner User</TableCellData>
      <TableCellData align={'center'}>{data.gender}</TableCellData>
      <TableCellData>
        <Typography sx={{ fontWeight: theme.typography.fontWeightRegular }}>
          {data.sales}
        </Typography>
        {data.sales === 'Yes' && (
          <Typography sx={{ fontWeight: theme.typography.fontWeightRegular }}>
            {data.salesDate}
          </Typography>
        )}
      </TableCellData>
      <TableCellData>
        <Typography sx={{ fontWeight: theme.typography.fontWeightRegular }}>
          {data.cc}
        </Typography>
        {data.cc === 'Yes' && (
          <Typography sx={{ fontWeight: theme.typography.fontWeightRegular }}>
            {data.ccDate}
          </Typography>
        )}
      </TableCellData>
      <TableCellData>
        <Typography sx={{ fontWeight: theme.typography.fontWeightRegular }}>
          {data.subscriptionType}
        </Typography>
        {data.subscriptionType !== '-' && (
          <Typography sx={{ fontWeight: theme.typography.fontWeightRegular }}>
            {data.subscriptionDate}
          </Typography>
        )}
      </TableCellData>
    </TableRow>
  );
};

export default UsersTableRows;
