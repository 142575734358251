import { IonRouterOutlet } from '@ionic/react';
import { Redirect, Route } from 'react-router-dom';
import React, { useMemo } from 'react';
import { isNil } from 'lodash';
import Profile from '../features/profile/Profile';
import Chat from '../features/chat/Chat';
import HealthProfessionals from '../features/healthProfessionals/HealthProfessionals';
import Users from '../features/users/Users';
import Dashboard from '../features/dashboard/Dashboard';
import { useAppSelector } from '../redux/hooks';
import HcpInfoData from '../features/healthProfessionals/HcpInfoData';
import UserInfo from '../features/users/UserInfo';
import ContentRoutes from './ContentRoutes';
import UserDataRoutes from './UserDataRoutes';

const ProtectedRoutes = () => {
  const {
    data: { user },
  } = useAppSelector(state => state.auth);

  const shouldRedirect = useMemo(() => {
    return !!isNil(user);
  }, [user]);

  const decideRedirect = useMemo(() => {
    if (!user) {
      return <Redirect to={'/sign-in'} />;
    }
  }, [user]);

  const renderRoutes = () => {
    return (
      <IonRouterOutlet animated={false}>
        <Route exact path="/dashboard/home">
          {!shouldRedirect ? <Dashboard /> : decideRedirect}
        </Route>
        <Route exact path="/dashboard/profile-settings">
          <Profile />
        </Route>
        <Route exact path="/dashboard/home">
          <Dashboard />
        </Route>
        <Route exact path="/dashboard/chat/:chatType/" component={Chat} />
        <Route exact path="/dashboard/chat/:chatType/:supportChatId" component={Chat} />
        <Route exact path="/dashboard/healthProfessionals">
          <HealthProfessionals />
        </Route>
        <Route
          exact
          path="/dashboard/healthProfessional/:hcpId"
          render={props => <HcpInfoData {...props} />}
        />
        <Route exact path="/dashboard/users">
          <Users />
        </Route>
        <Route
          path="/dashboard/user/data/:userId"
          render={props => <UserDataRoutes {...props} />}
        />
        <Route
          exact
          path="/dashboard/user/info/:userId"
          render={props => <UserInfo {...props} />}
        />
        <Route path="/dashboard/content">
          <ContentRoutes />
        </Route>
      </IonRouterOutlet>
    );
  };
  return renderRoutes();
};

export default ProtectedRoutes;
