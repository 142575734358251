import React, { FC, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Box, BoxProps, styled, useTheme } from '@mui/material';
import SectionHeader from '../../../components/SectionHeader';
import { useGetMealsOfDayQuery } from '../queries/foodsQueries';
import { useGetDietPlanQuery } from '../queries/dietPlanQueries';
import {
  HorizontalCalendar,
  MealCollapseCard,
  usePrepareMealList,
} from '@hdcorner/ui-library';
import useAlert from '../../../hooks/useAlert';

const BoxMain = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  marginBottom: 0,
  overflow: 'auto',
  maxHeight: '535px',
  borderRadius: '8px',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  flexDirection: 'column',
  '::-webkit-scrollbar': {
    display: 'none',
  },
  backgroundColor: theme.palette.secondary.main,
}));

const BoxSecondary = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  gap: theme.spacing(1),
  borderTop: `1px solid ${theme.palette.secondary.dark}`,
  padding: theme.spacing(1, 1, 2, 1),
}));

type Props = { userId: string };

const SectionNutritionCal: FC<Props> = ({ userId }) => {
  const theme = useTheme();
  const { presentError } = useAlert();

  const [monthlyCalendarOpen, setMonthlyCalendarOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>(moment().utc(true).toDate());
  const [params, setParams] = useState({
    end: moment().utc(true).endOf('day').toDate().toISOString(),
    start: moment().utc(true).startOf('day').toDate().toISOString(),
  });

  const { data: dietPlanRes, error: errorDietPlan } = useGetDietPlanQuery({ userId });
  const { data: mealLogRes, error: errorMealLog } = useGetMealsOfDayQuery({
    userId,
    end: params.end,
    start: params.start,
  });

  useEffect(() => {
    if (errorDietPlan)
      presentError('An unexpected error occurred fetching diet plan data');
    if (errorMealLog) presentError('An unexpected error occurred fetching meal log data');
  }, [errorDietPlan, errorMealLog]);

  const getDietDetails = useMemo(() => {
    if (dietPlanRes && dietPlanRes.length > 0) {
      const dietPlan = dietPlanRes[0];
      return {
        dietType: dietPlan.dietType,
        calories: dietPlan.calculatedCalories,
      };
    }
    return {
      dietType: '',
      calories: 0,
    };
  }, [dietPlanRes]);

  const lunchList = usePrepareMealList(
    'lunch',
    mealLogRes,
    getDietDetails.dietType,
    getDietDetails.calories,
  );
  const dinnerList = usePrepareMealList(
    'dinner',
    mealLogRes,
    getDietDetails.dietType,
    getDietDetails.calories,
  );
  const snacksList = usePrepareMealList(
    'snacks',
    mealLogRes,
    getDietDetails.dietType,
    getDietDetails.calories,
  );
  const breakfastList = usePrepareMealList(
    'breakfast',
    mealLogRes,
    getDietDetails.dietType,
    getDietDetails.calories,
  );

  const meals = useMemo(() => {
    return [breakfastList, lunchList, dinnerList, snacksList];
  }, [breakfastList, dinnerList, lunchList, snacksList]);

  const handleChangeDate = (date: Date) => {
    setSelectedDate(date);

    const newDate = moment(date).utc(true);

    const endDate = newDate.endOf('day').toISOString();
    const startDate = newDate.startOf('day').toISOString();

    const data = { endDate, startDate };

    handleCalClick(data);
  };

  const handleCalClick = (data: any) => {
    const endDate = data.endDate;
    const startDate = data.startDate;

    setParams((prevState: any) => ({
      ...prevState,
      end: endDate,
      start: startDate,
    }));
  };

  return (
    <Box gap={1} display={'flex'} flexDirection={'column'}>
      <SectionHeader title={'Nutrition Calendar'} />
      <BoxMain>
        <Box padding={1} bgcolor={theme.palette.secondary.main}>
          <HorizontalCalendar
            sx={{ flex: 1 }}
            selectedDate={selectedDate}
            openMonthly={monthlyCalendarOpen}
            setOpenMonthly={setMonthlyCalendarOpen}
            handleChange={(date: any) => handleChangeDate(date)}
          />
        </Box>
        <BoxSecondary>
          {meals.map((meal, index) => (
            <MealCollapseCard
              admin
              title={meal.title}
              metrics={meal.metrics}
              mealItems={meal.mealItems}
              totalCal={meal.totalCalories}
              key={`${meal.title}-${index}`}
              handleRemoveItem={() => undefined}
              description={meal.description || ''}
            />
          ))}
        </BoxSecondary>
      </BoxMain>
    </Box>
  );
};

export default SectionNutritionCal;
