import { FC } from 'react';
import {
  Box,
  BoxProps,
  IconButton,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DeleteCircleIcon } from '@hdcorner/ui-library';

const BoxMain = styled(Box)<BoxProps>(() => ({
  height: '54px',
  display: 'flex',
  minWidth: '192px',
  borderRadius: '8px',
  backgroundColor: '#282828',
  justifyContent: 'space-between',
}));

const BoxSecondary = styled(Box)<BoxProps>(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: theme.spacing(1),
}));

const BoxEnd = styled(Box)<BoxProps>(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  borderTopRightRadius: '8px',
  borderBottomRightRadius: '8px',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.secondary.main,
}));

const FileName = styled(Typography)<TypographyProps>(({ theme }) => ({
  // width: '150px',
  // overflow: 'hidden',
  // whiteSpace: 'nowrap',
  // textOverflow: 'ellipsis',
  fontSize: theme.typography.body1.fontSize,
  color: theme.palette.kmColorsCoolGrey.main,
  fontWeight: theme.typography.fontWeightRegular,
}));

type Props = {
  fileName: string;
  fileSize: string;
  handleRemoveFile: () => void;
};

const UploadedFileMarker: FC<Props> = ({ fileName, fileSize, handleRemoveFile }) => {
  const theme = useTheme();

  return (
    <BoxMain>
      <BoxSecondary>
        <FileName>{fileName}</FileName>
        <Typography
          variant={'body2'}
          sx={{ color: theme.palette.kmColorsTurquoise.main }}
        >
          {fileSize}
        </Typography>
      </BoxSecondary>
      <BoxEnd>
        <IconButton onClick={handleRemoveFile} sx={{ padding: 0 }}>
          <DeleteCircleIcon color={theme.palette.kmColorsCoolGrey.main} />
        </IconButton>
      </BoxEnd>
    </BoxMain>
  );
};

export default UploadedFileMarker;
