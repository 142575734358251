import { Box, useTheme } from '@mui/material';
import PageLayout from '../../../PageLayout';
import SignInPanel from './components/SignInPanel';
import HeroImage from '../../../assets/illustrations/HeroImagePNG.png';

const SignIn = () => {
  const theme = useTheme();

  return (
    <PageLayout>
      <Box display={'flex'} height={'100%'}>
        <Box
          boxSizing={'border-box'}
          bgcolor={theme.palette.secondary.main}
          padding={theme.spacing(7.25, 13, 3.5, 13)}
        >
          <SignInPanel />
        </Box>
        <Box
          flexGrow={'1'}
          sx={{
            backgroundSize: 'cover',
            backgroundPositionX: '-7px',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${HeroImage})`,
          }}
        ></Box>
      </Box>
    </PageLayout>
  );
};

export default SignIn;
