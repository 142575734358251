import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment/moment';
import { Box } from '@mui/material';
import {
  useDeleteVideoExerciseMutation,
  useGetVideoExerciseQuery,
} from '../queries/videoExerciseQueries';
import WorkoutToolbar from './WorkoutToolbar';
import useAlert from '../../../hooks/useAlert';
import VideoExerciseCard from './VideoExerciseCard';
import { useAppSelector } from '../../../redux/hooks';
import ChooseVideoToolbar from './ChooseVideoToolbar';
import { VideoExercise } from '../types/VideoExercise';
import { convertEnumValue } from '../utils/dataConversion';
import ModalAddEditVideoExercise from './ModalAddEditVideoExercise';
import {
  AllProgramsDropdown,
  AllProgramsList,
  BodyPartDropdown,
  BodyPartList,
} from '../constants';
import { AdminTablePagination, FeelsEmpty } from '@hdcorner/ui-library';
import { getThumbnail } from '../../../utils/bytes';
import ModalAddToProgram from './ModalAddToProgram';
import ModalCreateProgram from './ModalCreateProgram';
import ModalConfirmDelete from '../../../components/ModalConfirmDelete';

const skipAmount = 5;

const ContentVideoWorkoutPanel = () => {
  const { presentSuccess, presentError } = useAlert();

  const { sort } = useAppSelector(state => state.video);

  const [skip, setSkip] = useState<number>(0);
  const [rowId, setRowId] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [videoData, setVideoData] = useState<VideoExercise | undefined>(undefined);

  const [open, setOpen] = useState<boolean>(false);
  const [openAddToProgram, setOpenAddToProgram] = useState<boolean>(false);
  const [openCreateProgram, setOpenCreateProgram] = useState<boolean>(false);

  const [sex, setSex] = useState<string>('male');
  const [program, setProgram] = useState(AllProgramsList[0].value);
  const [bodyParts, setBodyParts] = useState(BodyPartList[0].value);
  const [sorting, setSorting] = useState<string>('-createdAt');
  const [selectedVideos, setSelectedVideos] = useState<string[]>([]);

  useEffect(() => {
    if (!sort) return;
    setSorting(sort);
  }, [sort]);

  useEffect(() => {
    if (!isEditable) setSelectedVideos([]);
  }, [isEditable]);

  const {
    error,
    data: videoRes,
    isFetching: dataFetch,
  } = useGetVideoExerciseQuery({
    limit: 12,
    skip: skip,
    avatar: sex,
    sort: [sorting],
    bodyPart: bodyParts === 'all' ? undefined : bodyParts,
    workoutCategory: program === 'all' ? undefined : program,
  });

  useEffect(() => {
    if (error) presentError('An unexpected error occurred fetching video data');
  }, [error]);

  const [deleteVideo] = useDeleteVideoExerciseMutation();

  const videos = useMemo(() => {
    if (!videoRes || videoRes.documents.length === 0) return [];

    return videoRes.documents.map(data => ({
      title:
        typeof data.title === 'string'
          ? data.title
          : data.title.en + ' / ' + data.title.el,
      notes: data.notes,
      avatar: data.avatar,
      kcalAvg: data.kcalAvg,
      bodyPart: data.bodyPart,
      duration: data.duration,
      _id: data._id,
      description: data.description,
      workoutCategory: data.workoutCategory,
      thumbnail: data.thumbnail ? data.thumbnail : '',
      suggested: data.suggested ? data.suggested : false,
      attachedVideo: data.attachedVideo ? data.attachedVideo : '',
      createdAt: moment(data.createdAt).format('DD/MM/YYYY'),
    }));
  }, [videoRes]);

  const handleEdit = (_id?: string) => {
    const data = videoRes?.documents.find(video => video._id === _id);
    if (!data) return;
    setVideoData(data);
    setOpen(true);
  };

  const handleDelete = (id: string) => {
    deleteVideo({
      id: id,
    })
      .unwrap()
      .then(() => {
        presentSuccess('Video deleted');
        setOpenDelete(false);
      })
      .catch(() => {
        presentError('Something went wrong.');
      });
  };

  const handleSkipClick = (amount: number) => {
    setCurrentPage(currentPage => currentPage + amount);
  };

  useEffect(() => {
    const apiSkip = (currentPage - 1) * 12;
    setSkip(apiSkip);
  }, [currentPage]);

  const numberOfPages = useMemo(() => {
    if (!videoRes) return 0;
    return Math.ceil(videoRes.count / 12);
  }, [videoRes]);

  const handleNewProgram = () => {
    setOpenCreateProgram(true);
  };
  const handleAddToProgram = () => {
    setOpenAddToProgram(true);
  };

  const handleCardClick = (_id?: string) => {
    if (!_id) return;
    const stringIndex = selectedVideos.indexOf(_id);

    if (stringIndex !== -1) {
      const updatedArray = [
        ...selectedVideos.slice(0, stringIndex),
        ...selectedVideos.slice(stringIndex + 1),
      ];
      return setSelectedVideos(updatedArray);
    } else {
      const updatedArray = [...selectedVideos, _id];
      return setSelectedVideos(updatedArray);
    }
  };

  return (
    <Box
      gap={3}
      flex={1}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
    >
      <Box>
        {!isEditable && (
          <WorkoutToolbar
            sex={sex}
            setSex={setSex}
            program={program}
            bodyParts={bodyParts}
            setProgram={setProgram}
            setBodyParts={setBodyParts}
            videoQuantity={videos.length}
            handleChooseVideos={() => setIsEditable(true)}
            disabled={program === 'all' || !program || bodyParts === 'all' || !bodyParts}
          />
        )}

        {isEditable && (
          <ChooseVideoToolbar
            videoNum={0}
            handleNewProgram={handleNewProgram}
            handleAddToProgram={handleAddToProgram}
            handleCancel={() => setIsEditable(false)}
          />
        )}

        {videos.length === 0 && <FeelsEmpty />}

        <Box mt={3} gap={3} display={'flex'} flexWrap={'wrap'}>
          {videos.map(video => (
            <VideoExerciseCard
              key={video._id}
              title={video.title}
              isEditable={isEditable}
              avatarSrc={video.avatar}
              duration={video.duration}
              suggested={video.suggested}
              previewImg={getThumbnail(
                typeof video.thumbnail === 'object'
                  ? video.thumbnail.en
                  : video.thumbnail,
              )}
              handleDelete={() => {
                setOpenDelete(true);
                setRowId(video._id ? video._id : '');
              }}
              handleEdit={() => handleEdit(video._id)}
              handleCardClick={() => handleCardClick(video._id)}
              workoutType={convertEnumValue(video.bodyPart, BodyPartDropdown)}
              programType={convertEnumValue(video.workoutCategory, AllProgramsDropdown)}
            />
          ))}
        </Box>
      </Box>

      {videos.length > 0 && (
        <AdminTablePagination
          loading={dataFetch}
          skipAmount={skipAmount}
          currentPage={currentPage}
          numberOfPages={numberOfPages}
          onSkipClick={handleSkipClick}
        />
      )}

      <ModalCreateProgram
        data={{
          avatar: sex,
          bodyPart: bodyParts,
          workoutType: program,
          exercises: selectedVideos,
        }}
        open={openCreateProgram}
        setOpen={setOpenCreateProgram}
      />
      <ModalAddToProgram
        data={{
          avatar: sex,
          bodyPart: bodyParts,
          workoutType: program,
          exercises: selectedVideos,
        }}
        open={openAddToProgram}
        setOpen={setOpenAddToProgram}
        handleUpdateStatus={() => setIsEditable(false)}
      />
      <ModalConfirmDelete
        open={openDelete}
        content={'video'}
        setOpen={setOpenDelete}
        handleDelete={() => handleDelete(rowId)}
      />
      <ModalAddEditVideoExercise open={open} setOpen={setOpen} data={videoData} />
    </Box>
  );
};

export default ContentVideoWorkoutPanel;
