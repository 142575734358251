import api from '../../../redux/api';
import { DietPlan } from '@hdcorner/ui-library';

const dietPlanQueries = api
  .enhanceEndpoints({ addTagTypes: ['DietPlan'] })
  .injectEndpoints({
    endpoints: build => ({
      getDietPlan: build.query<DietPlan[], { userId: string }>({
        query: ({ userId }) => ({
          method: 'GET',
          url: '/database/function/getDietPlan',
          params: {
            scope: `Nutrition:${userId}`,
            populate: ['dietPlan', 'dietPlan.attachedFile'],
          },
        }),
        providesTags: ['DietPlan'],
      }),
    }),
  });

export const { useGetDietPlanQuery } = dietPlanQueries;

export default dietPlanQueries;
