import React, { useEffect, useMemo, useState } from 'react';
import { Box, Table, TableBody } from '@mui/material';
import UsersTableRows from '../users/components/UsersTableRows';
import UsersTableHeading from '../users/components/UsersTableHeading';
import {
  useDeleteMedicineMutation,
  useGetMedicinesQuery,
} from './queries/medicineQueries';
import useAlert from '../../hooks/useAlert';
import { tableSort } from '../../utils/tableSorting';
import {
  AdminTablePagination,
  FeelsEmpty,
  HeadCell,
  Medicine,
} from '@hdcorner/ui-library';
import ModalAddEditMeds from './components/ModalAddEditMeds';
import ModalConfirmDelete from '../../components/ModalConfirmDelete';
import { useAppSelector } from '../../redux/hooks';

const createData = (row: any) => {
  return [
    row.medicineName,
    row.substance,
    row.package,
    row.prescribed ? 'Yes' : 'No',
    row.content,
    row.medicineType,
  ];
};

const headCells: HeadCell[] = [
  { _id: 'medicineName', label: 'Name', align: 'left', sort: 'medicineName' },
  { _id: 'substance', label: 'Active substance', align: 'center', sort: 'substance' },
  { _id: 'package', label: 'Package', align: 'center', sort: 'package' },
  { _id: 'prescribed', label: 'Prescribed', align: 'center', sort: 'prescribed' },
  { _id: 'content', label: 'Content', align: 'center', sort: 'content' },
  { _id: 'medicineType', label: 'Type', align: 'center', sort: 'medicineType' },
  { _id: 'actions', label: 'Actions', align: 'center' },
];
type Order = 'asc' | 'desc';

const skipAmount = 5;

const ContentMedsPanel = () => {
  const { presentSuccess, presentError } = useAlert();

  const { searchText } = useAppSelector(state => state.search);

  const [rowId, setRowId] = useState<string>('');
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  const [skip, setSkip] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [orderBy, setOrderBy] = useState('medicineName');
  const [order, setOrder] = useState<Order>('asc');
  const [modelData, setModelData] = useState<Medicine | undefined>(undefined);
  const [open, setOpen] = useState<boolean>(false);

  const [deleteMed] = useDeleteMedicineMutation();

  const {
    error,
    data: medRes,
    isFetching: dataFetch,
  } = useGetMedicinesQuery({
    limit: 10,
    skip: skip,
    search: searchText,
    sort: [tableSort(orderBy, order, headCells)],
  });

  useEffect(() => {
    if (error) presentError('An unexpected error occurred fetching medication data');
  }, [error]);

  const medRows = useMemo(() => {
    if (!medRes || medRes.documents.length === 0) return [];

    return medRes.documents.map((data: Medicine) => ({
      _id: data._id,
      content: data.content,
      package: data.package,
      version: data.version,
      substance: data.substance,
      prescribed: data.prescribed,
      description: data.description,
      medicineName: data.medicineName,
      medicineType: data.medicineType,
      imagePreview: data.imagePreview ? data.imagePreview : '',
    }));
  }, [medRes]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleEditRow = (data: Medicine) => {
    setOpen(true);
    setModelData(data);
  };

  const handleDeleteRow = (id: string) => {
    deleteMed({
      id: id,
    })
      .unwrap()
      .then(() => {
        presentSuccess('Medicine deleted');
        setOpenDelete(false);
      })
      .catch(() => {
        presentError('Something went wrong.');
      });
  };

  const handleSkipClick = (amount: number) => {
    setCurrentPage(currentPage => currentPage + amount);
  };

  useEffect(() => {
    const apiSkip = (currentPage - 1) * 10;
    setSkip(apiSkip);
  }, [currentPage]);

  const numberOfPages = useMemo(() => {
    if (!medRes) return 0;
    return Math.ceil(medRes.count / 10);
  }, [medRes]);

  return (
    <Box gap={3} display={'flex'} flexDirection={'column'}>
      {medRows.length === 0 && <FeelsEmpty />}

      {medRows.length > 0 && (
        <>
          <Table>
            <UsersTableHeading
              content
              order={order}
              orderBy={orderBy}
              headCells={headCells}
              totalRowsCount={medRows.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {medRows.map(row => (
                <UsersTableRows
                  content
                  alignData
                  rowCells={createData(row)}
                  key={row._id ? row._id : ''}
                  handleEditRow={() => handleEditRow(row)}
                  handleDeleteRow={() => {
                    setOpenDelete(true);
                    setRowId(row._id ? row._id : '');
                  }}
                />
              ))}
            </TableBody>
          </Table>
          <AdminTablePagination
            loading={dataFetch}
            skipAmount={skipAmount}
            currentPage={currentPage}
            numberOfPages={numberOfPages}
            onSkipClick={handleSkipClick}
          />
        </>
      )}

      <ModalConfirmDelete
        open={openDelete}
        content={'medication'}
        setOpen={setOpenDelete}
        handleDelete={() => handleDeleteRow(rowId)}
      />

      <ModalAddEditMeds open={open} setOpen={setOpen} data={modelData} />
    </Box>
  );
};

export default ContentMedsPanel;
