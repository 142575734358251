import React, { FC } from 'react';
import { ChartIconsType, ChartTypeButtons, ToggleButtons } from '@hdcorner/ui-library';
import { Box, BoxProps, styled } from '@mui/material';
import ToolbarContainer from '../../../components/ToolbarContainer';
import { weekMonthYearCustom } from '../../toggleHeadings';

const Line = styled(Box)<BoxProps>(({ theme }) => ({
  width: 2,
  height: 16,
  borderRadius: '1px',
  margin: theme.spacing(0, 3),
  background: theme.palette.kmColorsCoolGrey.main,
}));

type Props = {
  value: string;
  type: string;
  icons: ChartIconsType[];
  setType: (str: string) => void;
  setValue: (date: string) => void;
};

const GraphToolbar: FC<Props> = ({ value, setValue, icons, type, setType }) => {
  const handleChange = (newValue: string) => {
    setValue(newValue);
  };

  return (
    <ToolbarContainer>
      <ToggleButtons
        buttonBorder
        value={value}
        size={'large'}
        handleChange={handleChange}
        headings={weekMonthYearCustom.filter(
          it => it.value !== 'all' && it.value !== 'day',
        )}
      />

      <Line />

      <ChartTypeButtons
        icons={icons}
        size={'large'}
        iconValue={type}
        setIconValue={setType}
      />
    </ToolbarContainer>
  );
};

export default GraphToolbar;
