import React, { FC } from 'react';
import { DeleteBucket, EditPencil } from '@hdcorner/ui-library';
import {
  Avatar,
  Box,
  IconButton,
  TableCell,
  TableCellProps,
  TableRow,
  useTheme,
  styled,
} from '@mui/material';
import TrafficLightCircle from '../../content/components/TrafficLightCircle';

const TableCellData = styled(TableCell, {
  shouldForwardProp: prop =>
    prop !== 'alignData' && prop !== 'contentStyle' && prop !== 'boldText',
})<TableCellProps & { alignData?: boolean; contentStyle?: boolean; boldText?: boolean }>(
  ({ alignData, theme, contentStyle, boldText }) => ({
    flexGrow: '1',
    height: '40px',
    overflow: 'hidden',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    textOverflow: 'ellipsis',
    padding: theme.spacing(1),
    color: theme.palette.primary.main,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.body1.fontWeight,
    backgroundColor: theme.palette.secondary.main,
    borderBottomColor: theme.palette.background.default,
    '.Mui-active': {
      color: theme.palette.primary.light,
    },
    '&:first-of-type': {
      width: contentStyle ? 'auto' : '52px',
      textAlign: contentStyle ? 'left' : 'center',
    },
    '&:nth-of-type(2)': {
      textAlign: alignData ? 'center' : 'left',
      fontWeight: boldText
        ? theme.typography.body1.fontWeight
        : theme.typography.fontWeightRegular,
    },
  }),
);

export type UsersTableRowsProps = {
  avatar?: string;
  rowCells: string[];
  alignData?: boolean;
  content?: boolean | undefined;
  onClick?: () => void;
  handleEditRow?: () => void;
  handleDeleteRow?: () => void;
};

const UsersTableRows: FC<UsersTableRowsProps> = ({
  avatar,
  onClick,
  content,
  rowCells,
  alignData,
  handleEditRow,
  handleDeleteRow,
}) => {
  const theme = useTheme();

  return (
    <TableRow
      hover
      tabIndex={-1}
      onClick={onClick}
      sx={{
        '&:hover': { opacity: 0.5 },
        '&:last-child th, &:last-child td': {
          borderBottom: 0,
          '&:first-of-type': {
            borderBottomLeftRadius: '8px',
          },
          '&:last-of-type': {
            borderBottomRightRadius: '8px',
          },
        },
      }}
    >
      {!content && (
        <TableCellData align={'center'} contentStyle={content} alignData={alignData}>
          <Avatar
            src={avatar}
            sx={{
              width: 24,
              height: 24,
              margin: '0 auto',
            }}
          />
        </TableCellData>
      )}
      {rowCells.map((cell, index) => (
        <TableCellData
          key={index}
          align={'center'}
          boldText={content}
          alignData={alignData}
          contentStyle={content}
        >
          <Box
            gap={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={index === 0 ? 'flex-start' : 'center'}
          >
            <TrafficLightCircle
              color={cell}
              display={
                cell === 'Frequent consumption' ||
                cell === 'Moderate consumption' ||
                cell === 'Avoid consumption'
                  ? 'flex'
                  : 'none'
              }
            />
            {cell}
          </Box>
        </TableCellData>
      ))}

      {content && handleEditRow && handleDeleteRow && (
        <TableCellData>
          <IconButton
            onClick={event => {
              handleEditRow();
              event.stopPropagation();
            }}
          >
            <EditPencil color={theme.palette.kmColorsCoolGrey.main} />
          </IconButton>
          <IconButton
            onClick={event => {
              handleDeleteRow();
              event.stopPropagation();
            }}
          >
            <DeleteBucket color={theme.palette.kmColorsCoolGrey.main} />
          </IconButton>
        </TableCellData>
      )}
    </TableRow>
  );
};

export default UsersTableRows;
