import React, { FC, useMemo, useState } from 'react';
import { Avatar, AvatarProps, Box, BoxProps, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ExerciseIcon } from '@hdcorner/ui-library';
import DropdownTriggerMenu from './DropdownTriggerMenu';
import Male from '../../../assets/illustrations/MaleAvatar.png';
import Female from '../../../assets/illustrations/FemaleAvatar.png';
import VideoPlayIcon from '../../../assets/icons/VideoPlayIcon';
import { secondsToMinutes } from '../../../utils/dateTimeUtils';

const BoxMain = styled(Box, {
  shouldForwardProp: prop => prop !== 'checked',
})<BoxProps & { checked: boolean }>(({ theme, checked }) => ({
  width: '222px',
  height: '161px',
  borderRadius: '8px',
  boxSizing: 'border-box',
  border: checked ? `2px solid ${theme.palette.kmColorsTurquoise.main}` : 'none',
}));

const AvatarMain = styled(Avatar)<AvatarProps>(() => ({
  width: '24px',
  height: '24px',
  border: `none`,
  borderRadius: 100,
}));

const BoxText = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
  padding: theme.spacing(1),
  justifyContent: 'space-between',
  backgroundImage: 'linear-gradient(to top, transparent, black )',
}));

const BoxRibbon = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 1,
  display: 'flex',
  maxHeight: '46px',
  alignItems: 'center',
  boxSizing: 'border-box',
  gap: theme.spacing(1),
  borderBottomLeftRadius: '8px',
  borderBottomRightRadius: '8px',
  padding: theme.spacing(1),
  justifyContent: 'space-between',
  backgroundColor: theme.palette.secondary.main,
}));

const Radio = styled(Box, {
  shouldForwardProp: prop => prop !== 'checked',
})<BoxProps & { checked: boolean }>(({ theme, checked }) => ({
  width: '20px',
  height: '20px',
  borderRadius: '50%',
  boxSizing: 'border-box',
  border: checked
    ? `6px solid ${theme.palette.kmColorsTurquoise.main}`
    : `2px solid ${theme.palette.kmColorsCoolGrey.main}`,
}));

type Props = {
  title: string;
  duration?: number;
  suggested: boolean;
  avatarSrc?: string;
  previewImg?: string;
  isEditable?: boolean;
  workoutType?: string;
  programType: string;
  otherVideo?: boolean;
  handleEdit: () => void;
  handleDelete: () => void;
  handleCardClick?: () => void;
  handleSetSuggested?: () => void;
};

const VideoExerciseCard: FC<Props> = ({
  title,
  duration,
  avatarSrc,
  suggested,
  handleEdit,
  previewImg,
  isEditable = false,
  otherVideo = false,
  workoutType = '',
  programType,
  handleDelete,
  handleCardClick,
  handleSetSuggested,
}) => {
  const theme = useTheme();

  const [checked, setChecked] = useState<boolean>(false);

  const handleCheck = () => {
    if (isEditable && handleCardClick) {
      setChecked(!checked);
      handleCardClick();
    }
  };

  useMemo(() => {
    if (!isEditable) setChecked(false);
  }, [isEditable]);

  return (
    <BoxMain
      checked={checked}
      onClick={handleCheck}
      sx={{
        objectFit: 'cover',
        position: 'relative',
        backgroundImage: `url(${previewImg})`,
      }}
    >
      {suggested && (
        <Box
          top={'85px'}
          right={'5px'}
          padding={0.5}
          borderRadius={'4px'}
          position={'absolute'}
          bgcolor={`rgba(0, 0, 0, 0.6)`}
        >
          <Typography
            variant={'caption'}
            sx={{
              textTransform: 'uppercase',
              color: theme.palette.kmColorsTurquoise.main,
              fontWeight: theme.typography.fontWeightBold,
            }}
          >
            Suggested
          </Typography>
        </Box>
      )}
      <Box
        height={'100%'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
      >
        <BoxText>
          <Box gap={1} display={'flex'} alignItems={'center'}>
            {!otherVideo && (
              <ExerciseIcon
                width={'15px'}
                height={'14px'}
                color={theme.palette.primary.main}
              />
            )}
            {otherVideo && (
              <VideoPlayIcon
                width={'16px'}
                height={'16px'}
                color={theme.palette.primary.main}
              />
            )}
            <Typography
              color={'primary'}
              variant={'subtitle2'}
              sx={{ textTransform: 'capitalize' }}
            >
              {otherVideo
                ? 'Other Video'
                : workoutType.toLowerCase() === 'whole body'
                ? workoutType
                : `${workoutType} workout`}
            </Typography>
          </Box>
          <DropdownTriggerMenu
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handleSetSuggested={handleSetSuggested ? handleSetSuggested : undefined}
          />
        </BoxText>

        <BoxRibbon>
          <Box gap={1} display={'flex'} alignItems={'center'}>
            {' '}
            {!otherVideo && !isEditable && (
              <AvatarMain src={avatarSrc === 'female' ? Female : Male} />
            )}
            {!otherVideo && isEditable && <Radio checked={checked} />}
            <Box>
              <Typography
                variant={'body1'}
                sx={{
                  color: theme.palette.kmColorsCoolGrey.main,
                  fontWeight: theme.typography.fontWeightRegular,
                }}
              >
                {title}
              </Typography>
              <Typography
                variant={'body2'}
                sx={{
                  color: theme.palette.kmColorsCoolGrey.main,
                }}
              >
                {programType}
              </Typography>
            </Box>
          </Box>

          {duration && (
            <Typography
              variant={'caption'}
              sx={{
                color: theme.palette.kmColorsCoolGrey.main,
                fontWeight: theme.typography.fontWeightBold,
              }}
            >
              {secondsToMinutes(duration)}
            </Typography>
          )}
        </BoxRibbon>
      </Box>
    </BoxMain>
  );
};

export default VideoExerciseCard;
