import { FC } from 'react';
import { Box } from '@mui/material';
import { RouteComponentProps } from 'react-router';
import DiabetesHbac1Panel from './DiabetesHbac1Panel';
import DiabetesBloodGlucosePanel from './DiabetesBloodGlucosePanel';

interface Props extends RouteComponentProps<{ userId: string }> {}
const DataPanelDiabetes: FC<Props> = ({ match }) => {
  return (
    <Box gap={3} flex={1} display={'flex'} flexWrap={'wrap'}>
      <DiabetesBloodGlucosePanel userId={match.params.userId} />
      <DiabetesHbac1Panel userId={match.params.userId} />
    </Box>
  );
};

export default DataPanelDiabetes;
