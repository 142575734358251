import ToggleButtonGroup, {
  ToggleButtonGroupProps,
} from '@mui/material/ToggleButtonGroup';
import React, { FC, ReactNode } from 'react';
import { Box, styled, useTheme } from '@mui/material';
import ToggleButton, { ToggleButtonProps } from '@mui/material/ToggleButton';

const CustomToggleButton = styled(ToggleButton, {
  shouldForwardProp: prop => prop !== 'isSelected',
})<
  ToggleButtonProps & {
    isSelected: boolean;
  }
>(({ theme, isSelected }) => ({
  display: 'flex',
  boxSizing: 'border-box',
  alignItems: 'flex-start',
  color: theme.palette.kmColorsCoolGrey.main,
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.subtitle2.fontSize,
  padding: theme.spacing(0.5, 1),
  '&.Mui-selected, &.Mui-selected:hover': {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.kmColorsCoolGrey.main,
  },
  '&.MuiToggleButton-root': {
    border: 'none',
  },
}));

const CustomToggleButtonGroup = styled(ToggleButtonGroup)<ToggleButtonGroupProps>(
  ({ theme }) => ({
    '.MuiToggleButtonGroup-grouped': {
      height: '40px',
      border: 'none',
      boxSizing: 'border-box',
      padding: theme.spacing(1.25),
      margin: theme.spacing(0, 0.25, 0, 0),
      '&:last-of-type': {
        margin: theme.spacing(0),
      },
    },
    '.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
      borderRadius: '4px',
    },
    '.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
      borderRadius: '4px',
    },
  }),
);

type ToggleButtonSMProps = {
  value: string;
  handleChange: (newValue: string) => void;
  headings: { text: string; icon: ReactNode }[];
};

const ToggleButtonsIcon: FC<ToggleButtonSMProps> = ({
  value,
  headings,
  handleChange,
}) => {
  const theme = useTheme();

  const handleOnChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null,
  ) => {
    if (newValue === null) {
      handleChange(value);
    } else {
      handleChange(newValue);
    }
  };

  return (
    <CustomToggleButtonGroup
      exclusive
      onChange={handleOnChange}
      value={value?.toLowerCase()}
    >
      {headings.map((heading, index) => (
        <CustomToggleButton
          key={index}
          value={heading?.text.toLowerCase()}
          isSelected={value?.toLowerCase() === heading?.text.toLowerCase()}
          sx={{
            width: '140px',
            padding: theme.spacing(1.25),
          }}
        >
          <Box paddingTop={theme.spacing(0.25)} marginRight={theme.spacing(0.75)}>
            {heading.icon}
          </Box>
          {heading.text}
        </CustomToggleButton>
      ))}
    </CustomToggleButtonGroup>
  );
};

export default ToggleButtonsIcon;
