import React, { FC, useEffect, useState } from 'react';
import { CustomInput, DeleteBucket, ModalMobile, SaveIcon } from '@hdcorner/ui-library';
import {
  Box,
  useTheme,
  styled,
  BoxProps,
  IconButton,
  IconButtonProps,
} from '@mui/material';
import {
  useDeleteProgramMutation,
  useEditProgramMutation,
} from '../queries/programQueries';
import { Program } from '../types/Program';
import useAlert from '../../../hooks/useAlert';
import ProgramVideoCard from './ProgramVideoCard';
import { VideoExercise } from '../types/VideoExercise';
import ModalConfirmDelete from '../../../components/ModalConfirmDelete';
import ModalAddEditVideoExercise from './ModalAddEditVideoExercise';

const BoxMain = styled(Box)<BoxProps>(({ theme }) => ({
  width: '450px',
  display: 'flex',
  maxHeight: '80%',
  borderRadius: '8px',
  flexDirection: 'column',
  boxSizing: 'border-box',
  gap: theme.spacing(3),
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
}));

const BoxExercises = styled(Box)<BoxProps>(({ theme }) => ({
  width: '100%',
  display: 'flex',
  overflow: 'scroll',
  scrollbarWidth: 'none',
  flexDirection: 'column',
  boxSizing: 'border-box',
  gap: theme.spacing(1),
  msOverflowStyle: 'none',
  '::-webkit-scrollbar': {
    display: 'none',
  },
}));

const Buttons = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  width: '44px',
  height: '44px',
  display: 'flex',
  borderRadius: '8px',
  alignItems: 'center',
  boxSizing: 'border-box',
  justifyContent: 'center',
  padding: theme.spacing(1),
  backgroundColor: `rgba(0, 0, 0, 0.12)`,
  ':hover': {
    backgroundColor: 'rgba(56, 216, 226, 0.5)',
  },
}));

type Props = {
  open: boolean;
  data: Program;
  setOpen: (open: boolean) => void;
};

const ModalEditProgram: FC<Props> = ({ open, data, setOpen }) => {
  const theme = useTheme();
  const { presentSuccess, presentError } = useAlert();

  useEffect(() => {
    if (!data) return;
    setProgram({ name: data.name });
    setExercises(data?.exercises);
  }, [data]);

  const [program, setProgram] = useState({
    name: '',
  });
  const [modalData, setModalData] = useState<VideoExercise | undefined>();
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [exercises, setExercises] = useState<VideoExercise[] | []>([]);

  const [editProgram] = useEditProgramMutation();
  const [deleteProgram] = useDeleteProgramMutation();

  const handleChange = (fieldName: string, value: any) => {
    setProgram(oldValue => ({
      ...oldValue,
      [fieldName]: value,
    }));
  };

  const handleEditProgram = () => {
    if (!exercises) return;
    const exerciseList: string[] = exercises.map(exercise => exercise._id || '');

    editProgram({ id: data._id || '', name: program.name, exercises: exerciseList })
      .unwrap()
      .then(() => {
        presentSuccess('Program updated');
        setExercises([]);
        setOpen(false);
      })
      .catch(() => {
        presentError('Something went wrong.');
      });
  };

  const handleDeleteProgram = () => {
    if (!data._id) return;
    deleteProgram({ id: data._id })
      .unwrap()
      .then(() => {
        presentSuccess('Program deleted');
        setOpen(false);
        setOpenDelete(false);
      })
      .catch(() => {
        presentError('Something went wrong.');
      });
  };

  const handleRemoveVideo = (id: string) => {
    const updatedExercises = exercises.filter(exercise => exercise._id !== id);
    setExercises(updatedExercises);
  };

  return (
    <ModalMobile open={open} setOpen={setOpen}>
      <BoxMain>
        <Box
          gap={3}
          flex={1}
          display={'flex'}
          alignItems={'flex-end'}
          justifyContent={'space-between'}
        >
          <CustomInput
            fullWidth
            value={program.name}
            label={'Program name'}
            placeholder={'Enter program name'}
            handleChange={(value: string) => handleChange('name', value)}
          />

          <Box gap={1} display={'flex'}>
            <Buttons onClick={handleEditProgram}>
              <SaveIcon
                width={16}
                height={16}
                color={theme.palette.kmColorsCoolGrey.main}
              />
            </Buttons>
            <Buttons onClick={() => setOpenDelete(true)}>
              <DeleteBucket
                width={16}
                height={16}
                color={theme.palette.kmColorsCoolGrey.main}
              />
            </Buttons>
          </Box>
        </Box>

        <BoxExercises>
          {data.exercises &&
            exercises.map(data => (
              <ProgramVideoCard
                data={data}
                key={data._id}
                handleEdit={() => {
                  setModalData(data);
                  setOpenEdit(true);
                }}
                handleDelete={() => handleRemoveVideo(data._id ? data._id : '')}
              />
            ))}
        </BoxExercises>
      </BoxMain>

      <ModalConfirmDelete
        open={openDelete}
        content={'program'}
        setOpen={setOpenDelete}
        handleDelete={handleDeleteProgram}
      />
      <ModalAddEditVideoExercise open={openEdit} setOpen={setOpenEdit} data={modalData} />
    </ModalMobile>
  );
};

export default ModalEditProgram;
