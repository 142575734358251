import React, { FC, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Box, useTheme } from '@mui/material';
import { useAppSelector } from '../../../redux/hooks';
import SectionHeader from '../../../components/SectionHeader';
import {
  Collapsible,
  FeelsEmpty,
  HBAC1Chart,
  InDepthDataCard,
  useCurrentMeasurement,
  useOverallData,
  usePrepareGraph,
  useTimeframe,
} from '@hdcorner/ui-library';
import {
  useGetHbac1CurrentMeasurementQuery,
  useGetHbac1LogsQuery,
  useGetHbac1OverallDataQuery,
  useGetHcac1GraphDataQuery,
} from '../queries/diabetesQueries';
import useAlert from '../../../hooks/useAlert';
import { weekMonthYearCustom } from '../../toggleHeadings';

type Props = {
  userId: string;
};
const Hbac1WebSection: FC<Props> = ({ userId }) => {
  const theme = useTheme();
  const { presentError } = useAlert();

  const { dateFilter, customDateFilter } = useAppSelector(state => state.layout);

  const [bloodHbca1Params] = useState({
    skip: 0,
    limit: 1000,
  });

  const timeframe = useTimeframe(
    dateFilter,
    customDateFilter?.start,
    customDateFilter?.end,
  );

  const { data: overallData, error: errorOverall } = useGetHbac1OverallDataQuery({
    ...timeframe,
    userId,
  });
  const { data: hbacGraphData, error: errorHbacGraph } = useGetHcac1GraphDataQuery({
    ...timeframe,
    userId,
  });
  const { data: hbac1CurrentMeasurement, error: errorhbacMeasurement } =
    useGetHbac1CurrentMeasurementQuery({
      userId,
    });
  const { data: hbac1Logs, error: errorHbacLogs } = useGetHbac1LogsQuery({
    params: { ...bloodHbca1Params, ...timeframe, userId },
  });

  useEffect(() => {
    if (errorOverall)
      presentError('An unexpected error occurred fetching overall HbA1C data');
    if (errorHbacGraph)
      presentError('An unexpected error occurred fetching HbA1C graph data');
    if (errorhbacMeasurement)
      presentError('An unexpected error occurred fetching HbA1C measurement data');
    if (errorHbacLogs)
      presentError('An unexpected error occurred fetching HbA1C log data');
  }, [errorOverall, errorHbacGraph, errorhbacMeasurement]);

  const prepareOverallData = useOverallData(overallData);
  const prepareGraphData = usePrepareGraph(hbacGraphData);
  const prepareCurrentMeasurement = useCurrentMeasurement(hbac1CurrentMeasurement);

  const prepareData = useMemo(() => {
    if (!hbac1Logs || hbac1Logs.documents.length === 0) return [];

    return hbac1Logs.documents.map(item => {
      return {
        title: item.measurement.toString(),
        titleSub: 'mg/dl',
        data: {
          HbA1C: { value: `${item.measurement.toString()}%` },
          'Time of Calculation': { value: moment(item.logDate).format('h:mm A') },
          'Date of Calculation': {
            value: moment(item.logDate).format('MMM Do, YYYY'),
          },
        },
      };
    });
  }, [hbac1Logs]);

  const prepareOverallDataValues = useMemo(() => {
    if (!overallData)
      return [
        {
          name: 'All',
          min: 'N/A',
          max: 'N/A',
          avg: 'N/A',
        },
      ];
    const data = overallData;
    return [
      {
        name: 'All',
        min: data.min ? data.min.toFixed() : 'N/A',
        max: data.max ? data.max.toFixed() : 'N/A',
        avg: data.avg ? data.avg.toFixed() : 'N/A',
      },
    ];
  }, [overallData]);

  return (
    <Box display={'flex'} flexDirection={'column'} flex={1} gap={theme.spacing(3)}>
      <Box display={'flex'} flexDirection={'column'}>
        <SectionHeader title={'HbA1c'} sx={{ textTransform: 'none' }} />
        <HBAC1Chart
          chartData={prepareGraphData}
          headings={weekMonthYearCustom}
          overallData={prepareOverallData}
          latestMeasurement={prepareCurrentMeasurement}
        />
      </Box>
      <Box display={'flex'} flex={1} flexDirection={'column'} gap={1}>
        <SectionHeader title={'in-depth data'} />
        <InDepthDataCard
          measurement={'%'}
          rows={prepareOverallDataValues}
          headings={['Min.', 'Max.', 'Avg.']}
        />
        {prepareData.map((data, index) => (
          <Box key={`${data.title}_${index}`} sx={{ marginBottom: theme.spacing(1) }}>
            <Collapsible
              key={index}
              data={data.data}
              title={data.title}
              titleSub={data.titleSub}
            />
          </Box>
        ))}
        {prepareData.length === 0 && <FeelsEmpty />}
      </Box>
    </Box>
  );
};

export default Hbac1WebSection;
