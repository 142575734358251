import React, { FC } from 'react';
import { useTheme } from '@mui/material';
import {
  DeleteBucket,
  DropdownTrigger,
  EditPencil,
  StarFavouriteIcon,
} from '@hdcorner/ui-library';

type Props = {
  handleEdit: () => void;
  handleDelete: () => void;
  handleSetSuggested?: () => void;
};

const DropdownTriggerMenu: FC<Props> = ({
  handleEdit,
  handleDelete,
  handleSetSuggested,
}) => {
  const theme = useTheme();

  const options = [
    {
      text: 'Edit',
      onClick: handleEdit,
      icon: <EditPencil color={theme.palette.kmColorsCoolGrey.main} />,
    },
    {
      text: 'Delete',
      onClick: handleDelete,
      icon: <DeleteBucket color={theme.palette.kmColorsCoolGrey.main} />,
    },
  ];

  if (handleSetSuggested) {
    options.unshift({
      text: 'Set as suggested',
      onClick: handleSetSuggested,
      icon: <StarFavouriteIcon color={theme.palette.kmColorsCoolGrey.main} />,
    });
  }

  return <DropdownTrigger admin options={options.filter(Boolean)} />;
};

export default DropdownTriggerMenu;
