import api from '../../../redux/api';
import { AuthUser, ChangeEmail } from '@hdcorner/ui-library';

const profileQueries = api
  .enhanceEndpoints({ addTagTypes: ['UserData'] })
  .injectEndpoints({
    endpoints: build => ({
      updateAuthUser: build.mutation<AuthUser, Partial<AuthUser>>({
        query: ({ firstName, lastName, jobTitle }) => ({
          method: 'PUT',
          param: { populate: 'profilePicture' },
          url: `/database/function/setAuthUser`,
          body: { firstName, lastName, jobTitle },
        }),
        invalidatesTags: ['AuthUser'],
      }),

      setUserProfilePicture: build.mutation<void, { profilePicture: string }>({
        query: ({ profilePicture }) => ({
          method: 'PUT',
          body: { profilePicture },
          url: 'database/function/setAuthUser',
        }),
        invalidatesTags: ['UserData', 'AuthUser'],
      }),

      changeEmail: build.mutation<ChangeEmail, { newEmail: string }>({
        query: ({ newEmail }) => ({
          method: 'POST',
          body: { newEmail },
          url: '/authentication/local/change-email',
        }),
      }),
    }),
  });

export const {
  useChangeEmailMutation,
  useUpdateAuthUserMutation,
  useSetUserProfilePictureMutation,
} = profileQueries;

export default profileQueries;
