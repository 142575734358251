import {
  Box,
  BoxProps,
  styled,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableRow,
  useTheme,
} from '@mui/material';
import moment from 'moment/moment';
import React, { FC, useEffect, useMemo, useState } from 'react';
import SectionHeader from '../../../components/SectionHeader';
import { useGetActivityLogsQuery } from '../queries/activitiesQueries';
import { capitalizeFirstLetter, HorizontalCalendar } from '@hdcorner/ui-library';
import useAlert from '../../../hooks/useAlert';

const BoxMain = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  boxSizing: 'border-box',
  flexDirection: 'column',
  backgroundColor: theme.palette.secondary.main,
  borderBottom: `${theme.spacing(1)} solid ${theme.palette.secondary.main}`,
}));

const BoxSecondary = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 1,
  display: 'flex',
  overflow: 'scroll',
  scrollbarWidth: 'none',
  boxSizing: 'border-box',
  flexDirection: 'column',
  '::-webkit-scrollbar': {
    display: 'none',
  },
  maxHeight: '151px',
  msOverflowStyle: 'none',
  padding: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.secondary.dark}`,
}));

const TableData = styled(TableCell)<TableCellProps>(({ theme }) => ({
  flex: 1,
  border: 'none',
  textAlign: 'center',
  fontsize: theme.typography.body1.fontSize,
  color: theme.palette.kmColorsCoolGrey.main,
  fontWeight: theme.typography.body1.fontWeight,
  padding: theme.spacing(0, 0, 2, 0),
  '&:first-of-type': {
    textAlign: 'left',
    textTransform: 'capitalize',
    fontsize: theme.typography.subtitle2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
  },
  '&:last-of-type': {
    textAlign: 'right',
  },
}));

type Props = { userId: string };

const SectionActivitiesCal: FC<Props> = ({ userId }) => {
  const theme = useTheme();
  const { presentError } = useAlert();

  const [monthlyCalendarOpen, setMonthlyCalendarOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>(moment().utc(true).toDate());
  const [params, setParams] = useState({
    end: moment().utc(true).endOf('day').toDate().toISOString(),
    start: moment().utc(true).startOf('day').toDate().toISOString(),
  });

  const { error, data: activityLogRes } = useGetActivityLogsQuery({
    userId,
    skip: 0,
    limit: 1000,
    end: params.end,
    sort: ['-logDate'],
    start: params.start,
  });

  useEffect(() => {
    if (error) presentError('An unexpected error occurred fetching activities data');
  }, [error]);

  const activityList = useMemo(() => {
    if (!activityLogRes || activityLogRes.documents.length === 0) return [];

    return activityLogRes.documents.map((it: any) => {
      return {
        _id: it._id,
        minutes: it.duration,
        activity:
          it.custom || !it.activity
            ? capitalizeFirstLetter(it.name)
            : capitalizeFirstLetter(it.activity.name),
        cal: Math.round(it.calories),
      };
    });
  }, [activityLogRes]);

  const handleChangeDate = (date: Date) => {
    setSelectedDate(date);
    const newDate = moment(date).utc(true);
    const endDate = newDate.endOf('day').toISOString();
    const startDate = newDate.startOf('day').toISOString();

    const data = { endDate, startDate };
    handleCalClick(data);
  };

  const handleCalClick = (data: any) => {
    const endDate = data.endDate;
    const startDate = data.startDate;

    setParams((prevState: any) => ({
      ...prevState,
      end: endDate,
      start: startDate,
    }));
  };

  return (
    <Box gap={1} display={'flex'} flexDirection={'column'}>
      <SectionHeader title={'Activities'} />
      <BoxMain>
        <Box padding={2} bgcolor={theme.palette.secondary.main} borderRadius={'8px'}>
          <HorizontalCalendar
            sx={{ flex: 1 }}
            selectedDate={selectedDate}
            openMonthly={monthlyCalendarOpen}
            setOpenMonthly={setMonthlyCalendarOpen}
            handleChange={(date: any) => handleChangeDate(date)}
          />
        </Box>
        <BoxSecondary>
          <Table>
            <TableBody>
              {activityList.map(it => (
                <TableRow
                  key={it._id}
                  sx={{
                    display: 'flex',
                    '&:last-child th, &:last-child td': {
                      padding: 0,
                    },
                  }}
                >
                  <TableData>{it.activity}</TableData>
                  <TableData>{it.minutes} mins</TableData>
                  <TableData>{it.cal} kcal</TableData>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </BoxSecondary>
      </BoxMain>
    </Box>
  );
};

export default SectionActivitiesCal;
