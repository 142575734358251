import api from '../../../redux/api';
import { socketInstance } from '../socket';
import {
  ApiPagination,
  ChatMessageType,
  SupportChatRoomType,
} from '@hdcorner/ui-library';
import moment from 'moment';

const chatQueries = api
  .enhanceEndpoints({ addTagTypes: ['SupportChatRoom', 'ChatMessage'] })
  .injectEndpoints({
    endpoints: build => ({
      /* SUPPORT CHAT ROOMS */
      getSupportChatRooms: build.query<
        {
          count: number;
          rooms: SupportChatRoomType[];
        },
        ApiPagination & { roomType: 'user' | 'hcp' | 'pending' }
      >({
        query: params => ({
          method: 'GET',
          params: { ...params },
          url: 'hdCornerService/admin/chat/rooms/support',
        }),
        async onCacheEntryAdded(
          arg,
          { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
        ) {
          const messageListener = (data: any) => {
            const currentDate = moment().toISOString();
            updateCachedData(draft => {
              const room = draft.rooms?.find(room => room.chatRoom?._id === data.room);
              if (!room || !room.chatRoom) return;
              room.chatRoom.lastMessage = {
                room: data.room,
                message: data.message,
                createdAt: currentDate,
                updatedAt: currentDate,
                senderUser: data.sender,
                _id: `temp-${currentDate}`,
              };
              room.chatRoom.unreadMessageCount = room.chatRoom.unreadMessageCount
                ? room.chatRoom.unreadMessageCount + 1
                : 1;
              draft.rooms = draft.rooms.filter(r => {
                if (r.chatRoom) {
                  return r.chatRoom._id !== data.room;
                }
                return true;
              });
              draft.rooms.unshift(room);
            });
          };

          try {
            await cacheDataLoaded;
            socketInstance.on('message', messageListener);
          } catch (error) {
            console.error(error);
          }
          await cacheEntryRemoved;
          socketInstance.off('message', messageListener);
        },
        serializeQueryArgs: ({ queryArgs }) => {
          const { roomType } = queryArgs;
          return { params: { roomType } };
        },
        merge: (currentCache, newItems, otherArgs) => {
          if (otherArgs.arg.skip === 0) {
            return newItems;
          } else {
            currentCache?.rooms.push(...newItems?.rooms);
          }
        },
        forceRefetch: ({ previousArg, currentArg }) =>
          previousArg?.skip !== currentArg?.skip ||
          previousArg?.limit !== currentArg?.limit ||
          previousArg?.roomType !== currentArg?.roomType,
        providesTags: result =>
          result?.rooms
            ? [
                ...result?.rooms.map(({ _id }) => ({
                  type: 'SupportChatRoom' as const,
                  id: _id,
                })),
                { type: 'SupportChatRoom', id: 'LIST' },
              ]
            : [{ type: 'SupportChatRoom', id: 'LIST' }],
      }),
      getSupportChatRoom: build.query<SupportChatRoomType, { roomId: string }>({
        query: ({ roomId }) => ({
          method: 'GET',
          url: `/database/SupportChatRoom/${roomId}`,
          params: { populate: ['chatRoom', 'chatRoom.participants', 'user'] },
        }),
        providesTags: result =>
          result ? [{ type: 'SupportChatRoom', id: result._id }] : [],
      }),
      resolveSupportChatRoom: build.mutation<void, { supportRoomId: string }>({
        query: ({ supportRoomId }) => ({
          url: `hdCornerService/admin/chat/rooms/support/${supportRoomId}/resolve`,
          method: 'PATCH',
        }),
        invalidatesTags: (result, error, arg) => [
          {
            type: 'SupportChatRoom',
            id: arg.supportRoomId,
          },
          { type: 'SupportChatRoom', id: 'LIST' },
        ],
      }),
      acceptSupportChatRoom: build.mutation<void, { supportRoomId: string }>({
        query: ({ supportRoomId }) => ({
          method: 'PATCH',
          url: `hdCornerService/admin/chat/rooms/support/${supportRoomId}/accept`,
        }),
        invalidatesTags: (result, error, arg) => [
          {
            id: arg.supportRoomId,
            type: 'SupportChatRoom',
          },
          { type: 'SupportChatRoom', id: 'LIST' },
        ],
      }),

      /* CHAT MESSAGES */
      getChatMessages: build.query<
        {
          count: number;
          messages: ChatMessageType[];
        },
        ApiPagination & { roomId: string }
      >({
        query: ({ skip, limit, roomId }) => ({
          method: 'GET',
          url: `chat/messages`,
          params: { roomId, skip, limit },
        }),
        async onCacheEntryAdded(
          arg,
          { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
        ) {
          const messageListener = (data: any) => {
            if (data.room !== arg.roomId) return;
            const currentDate = moment().toISOString();
            updateCachedData(draft => {
              const message = {
                room: data.room,
                message: data.message,
                createdAt: currentDate,
                updatedAt: currentDate,
                senderUser: data.sender,
                _id: `temp-${currentDate}`,
              };
              draft.messages?.unshift(message);
              draft.count += 1;
            });
          };

          try {
            await cacheDataLoaded;
            socketInstance.on('message', messageListener);
          } catch (error) {
            console.error(error);
          }
          await cacheEntryRemoved;
          socketInstance.off('message', messageListener);
        },
        serializeQueryArgs: ({ queryArgs }) => {
          const { roomId } = queryArgs;
          return { params: { roomId } };
        },
        merge: (currentCache, newItems, otherArgs) => {
          if (otherArgs.arg.skip === 0) {
            return newItems;
          } else {
            currentCache?.messages.push(...newItems?.messages);
          }
        },
        forceRefetch: ({ previousArg, currentArg }) =>
          previousArg?.skip !== currentArg?.skip ||
          previousArg?.limit !== currentArg?.limit ||
          previousArg?.roomId !== currentArg?.roomId,
        providesTags: result =>
          result?.messages
            ? [
                ...result?.messages.map(({ _id }) => ({
                  type: 'ChatMessage' as const,
                  id: _id,
                })),
                { type: 'ChatMessage', id: 'LIST' },
              ]
            : [{ type: 'ChatMessage', id: 'LIST' }],
      }),
      deleteChatMessage: build.mutation<void, { messageId: string }>({
        query: ({ messageId }) => ({
          method: 'DELETE',
          url: `chat/messages/${messageId}`,
        }),
        invalidatesTags: (_result, _error, meta) => [
          { type: 'ChatMessage', id: meta.messageId },
          { type: 'ChatMessage', id: 'LIST' },
        ],
      }),
    }),
  });

export const {
  useGetChatMessagesQuery,
  useGetSupportChatRoomQuery,
  useGetSupportChatRoomsQuery,
  useAcceptSupportChatRoomMutation,
  useResolveSupportChatRoomMutation,
} = chatQueries;
export default chatQueries;
