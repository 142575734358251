import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment/moment';
import useAlert from '../../hooks/useAlert';
import { tableSort } from '../../utils/tableSorting';
import { Box, Table, TableBody } from '@mui/material';
import {
  Activity,
  AdminTablePagination,
  FeelsEmpty,
  HeadCell,
} from '@hdcorner/ui-library';
import UsersTableRows from '../users/components/UsersTableRows';
import ModalAddEditActivity from './components/ModalAddEditActivity';
import ModalConfirmDelete from '../../components/ModalConfirmDelete';
import UsersTableHeading from '../users/components/UsersTableHeading';
import {
  useDeleteActivityMutation,
  useGetActivitiesQuery,
} from './queries/activityQueries';
import { useAppSelector } from '../../redux/hooks';

const createData = (row: any) => {
  return [row.name, row.met, row.createdAt];
};

const headCells: HeadCell[] = [
  { _id: 'name', label: 'Activity Name', align: 'left', sort: 'name' },
  { _id: 'met', label: 'MET', align: 'center', sort: 'met' },
  { _id: 'createdAt', label: 'Date added', align: 'center', sort: 'createdAt' },
  { _id: 'actions', label: 'Actions', align: 'center' },
];
type Order = 'asc' | 'desc';

const ContentActivityPanel = () => {
  const { presentSuccess, presentError } = useAlert();

  const { searchText } = useAppSelector(state => state.search);

  const [skip, setSkip] = useState<number>(0);
  const [rowId, setRowId] = useState<string>('');
  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState<Order>('asc');
  const [open, setOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [modelData, setModelData] = useState<Activity | undefined>(undefined);

  const [deleteActivity] = useDeleteActivityMutation();

  const {
    error,
    data: activityRes,
    isFetching: dataFetch,
  } = useGetActivitiesQuery({
    limit: 10,
    skip: skip,
    search: searchText,
    sort: [tableSort(orderBy, order, headCells)],
  });

  useEffect(() => {
    if (error) presentError('An unexpected error occurred fetching activities data');
  }, [error]);

  const activityRows = useMemo(() => {
    if (!activityRes || activityRes.documents.length === 0) return [];

    return activityRes.documents.map((data: Activity) => ({
      _id: data._id,
      met: data.met,
      description: data.description,
      createdAt: moment(data.createdAt).format('DD/MM/YYYY'),
      name:
        typeof data.name === 'string' ? data.name : data.name.en + ' / ' + data.name.el,
    }));
  }, [activityRes]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleEditRow = (_id?: string) => {
    const data = activityRes?.documents.find(activity => activity._id === _id);
    if (!data) return;
    setOpen(true);
    setModelData(data);
  };

  const handleDeleteRow = (id: string) => {
    deleteActivity({
      id: id,
    })
      .unwrap()
      .then(() => {
        presentSuccess('Activity deleted');
        setOpenDelete(false);
      })
      .catch(() => {
        presentError('Something went wrong.');
      });
  };

  const handleSkipClick = (amount: number) => {
    setCurrentPage(currentPage => currentPage + amount);
  };

  useEffect(() => {
    const apiSkip = (currentPage - 1) * 10;
    setSkip(apiSkip);
  }, [currentPage]);

  const numberOfPages = useMemo(() => {
    if (!activityRes) return 0;
    return Math.ceil(activityRes.count / 10);
  }, [activityRes]);

  return (
    <Box gap={3} display={'flex'} flexDirection={'column'}>
      {activityRows.length === 0 && <FeelsEmpty />}

      {activityRows.length > 0 && (
        <>
          <Table>
            <UsersTableHeading
              content
              order={order}
              orderBy={orderBy}
              headCells={headCells}
              onRequestSort={handleRequestSort}
              totalRowsCount={activityRows.length}
            />
            <TableBody>
              {activityRows.map(row => (
                <UsersTableRows
                  content
                  alignData
                  rowCells={createData(row)}
                  key={row._id ? row._id : ''}
                  handleEditRow={() => handleEditRow(row._id)}
                  handleDeleteRow={() => {
                    setOpenDelete(true);
                    setRowId(row._id ? row._id : '');
                  }}
                />
              ))}
            </TableBody>
          </Table>

          <AdminTablePagination
            skipAmount={skip}
            loading={dataFetch}
            currentPage={currentPage}
            numberOfPages={numberOfPages}
            onSkipClick={handleSkipClick}
          />
        </>
      )}

      <ModalConfirmDelete
        open={openDelete}
        content={'activity'}
        setOpen={setOpenDelete}
        handleDelete={() => handleDeleteRow(rowId)}
      />

      <ModalAddEditActivity open={open} setOpen={setOpen} data={modelData} />
    </Box>
  );
};

export default ContentActivityPanel;
