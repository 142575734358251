import React, { FC } from 'react';
import { ToggleButtons } from '@hdcorner/ui-library';
import { Box, BoxProps, styled } from '@mui/material';
import ToolbarContainer from '../../../components/ToolbarContainer';
import { genderOptions, weekMonthYearCustom } from '../../toggleHeadings';

const Line = styled(Box)<BoxProps>(({ theme }) => ({
  width: 2,
  height: 16,
  borderRadius: '1px',
  margin: theme.spacing(0, 3),
  background: theme.palette.kmColorsCoolGrey.main,
}));

type DateBarType = {
  sex: string;
  value: string;
  setSex: (sex: string) => void;
  setValue: (date: string) => void;
};

const DateBar: FC<DateBarType> = ({ value, setValue, sex, setSex }) => {
  const handleChange = (newValue: string) => {
    setValue(newValue);
  };

  const handleSexChange = (newSex: string) => {
    setSex(newSex);
  };

  return (
    <ToolbarContainer>
      <ToggleButtons
        buttonBorder
        value={value}
        size={'large'}
        handleChange={handleChange}
        headings={weekMonthYearCustom.filter(
          it => it.value !== 'all' && it.value !== 'day',
        )}
      />
      <Line />
      <ToggleButtons
        value={sex}
        size={'large'}
        handleChange={handleSexChange}
        headings={genderOptions}
      />
    </ToolbarContainer>
  );
};

export default DateBar;
