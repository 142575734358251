import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import useAlert from './hooks/useAlert';
import { RootState } from './redux/store';
import { getScreenName } from './utils/getScreenName';
import { hideNavigation } from './utils/hideNavigation';
import { logout } from './features/authentication/authSlice';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { IonContent, IonPage, useIonRouter } from '@ionic/react';
import { removeAlert, shrinkBarToggle } from './redux/slices/appSlice';
import { useLazyGetAuthUserQuery } from './features/authentication/queries/signInQueries';
import {
  AuthUser,
  FloatingActionButton,
  HeaderBar,
  SnackbarAlert,
} from '@hdcorner/ui-library';
import {
  useGetNotificationsQuery,
  useReadNotificationMutation,
} from './features/notifications/queries/notificationQueries';

type PageLayoutType = {
  children: any;
  backgroundWhite?: boolean;
  fabIcon?: React.ReactNode;
  fabClick?: () => void;
};

const PageLayout: FC<PageLayoutType> = ({
  fabIcon,
  children,
  fabClick,
  backgroundWhite,
}) => {
  const router = useIonRouter();
  const dispatch = useAppDispatch();
  const { presentError } = useAlert();
  const pathname = router?.routeInfo?.pathname;

  const shrunkSideBar = useAppSelector((state: RootState) => state.layout.shrunkSideBar);

  const [userData, setUserData] = useState<AuthUser>();

  const {
    meta: { isAuthenticated },
  } = useAppSelector(state => state.auth);

  const {
    open: alertOpen,
    message: alertMessage,
    severity: alertSeverity,
  } = useAppSelector(state => state.layout.alert);

  const [lazyUserData] = useLazyGetAuthUserQuery();
  const [readNotification] = useReadNotificationMutation();

  const { data: notificationRes } = useGetNotificationsQuery(
    { skip: 0, limit: 100 },
    { skip: !isAuthenticated },
  );

  useEffect(() => {
    const retrieveData = async () => {
      const user = await lazyUserData().unwrap();
      setUserData(user[0]);
    };
    if (isAuthenticated) retrieveData();
  }, [isAuthenticated]);

  const notifications = useMemo(() => {
    if (!notificationRes || notificationRes.notifications.length === 0) return [];
    return notificationRes.notifications;
  }, [notificationRes]);

  const unReadNotis = useMemo(() => {
    if (!notificationRes) return 0;
    return notificationRes.unreadCount;
  }, [notificationRes]);

  const handleMarkAsRead = (id: string) => {
    readNotification({ id: id })
      .unwrap()
      .catch(() => {
        presentError('Something went wrong');
      });
  };

  const getUser = useMemo(() => {
    if (userData && isAuthenticated)
      return {
        avatarSrc:
          userData.profilePicture && typeof userData.profilePicture === 'object'
            ? userData.profilePicture.url
            : '',
        username: userData.firstName + ' ' + userData.lastName,
      };
    return {
      avatarSrc: '',
      username: 'N/A',
    };
  }, [userData]);

  const currentScreen = useMemo(
    () => getScreenName(router?.routeInfo?.pathname),
    [router?.routeInfo?.pathname],
  );

  const handleClickAccountSettings = () => {
    router.push('/dashboard/profile-settings', 'forward', 'replace');
  };

  const handleClickLogout = () => {
    dispatch(logout());
    router.push('/sign-in');
  };

  const handleClickMessages = () => {
    router.push(`/dashboard/chat/${'user'}/`);
  };

  const handleShrinkBar = useCallback(() => {
    dispatch(shrinkBarToggle());
  }, [dispatch]);

  const handleClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }

      dispatch(removeAlert());
    },
    [dispatch],
  );

  return (
    <IonPage>
      {!hideNavigation(pathname) && (
        <HeaderBar
          user={getUser}
          unreadMessages={0}
          notifications={notifications}
          shrunkSideBar={shrunkSideBar}
          currentScreen={currentScreen}
          handleShrinkBar={handleShrinkBar}
          unreadNotifications={unReadNotis}
          handleClickLogout={handleClickLogout}
          onNotificationClick={handleMarkAsRead}
          handleClickMessages={handleClickMessages}
          handleClickAccountSettings={handleClickAccountSettings}
        />
      )}
      <IonContent class={backgroundWhite ? 'pageWhite' : undefined}>
        <SnackbarAlert
          open={alertOpen}
          message={alertMessage}
          severity={alertSeverity}
          handleClose={handleClose}
        />
        {children}
      </IonContent>
      {fabClick ? <FloatingActionButton onClick={fabClick} icon={fabIcon} /> : null}
    </IonPage>
  );
};

export default PageLayout;
