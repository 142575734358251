import api from '../../../redux/api';
import { ApiPagination, PaginationResult, SleepLog } from '@hdcorner/ui-library';

const sleepQueries = api.enhanceEndpoints({ addTagTypes: ['SleepLog'] }).injectEndpoints({
  endpoints: build => ({
    getSleepLogs: build.query<
      PaginationResult<SleepLog>,
      { params: ApiPagination; start?: string; end?: string; userId: string }
    >({
      query: ({ params, start, end, userId }) => ({
        method: 'GET',
        url: '/database/function/getSleepLogs',
        params: { ...params, start, end, scope: `Sleep:${userId}` },
      }),
      providesTags: ['SleepLog'],
    }),

    getSleepGraphData: build.query<
      { graphData: SleepLog[]; total?: number; avg?: number },
      {
        end?: string;
        userId: string;
        start?: string;
        timezone: string;
        timeframe?: string;
      }
    >({
      query: ({ start, end, timeframe, timezone, userId }) => ({
        method: 'GET',
        url: '/hdCornerService/user/sleep/graph',
        params: { start, end, timeframe, timezone, userId },
      }),
    }),
  }),
});

export const { useGetSleepLogsQuery, useGetSleepGraphDataQuery } = sleepQueries;

export default sleepQueries;
