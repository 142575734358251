import React, { FC, useEffect, useState } from 'react';
import { useIonRouter } from '@ionic/react';
import { useAppDispatch } from '../../../redux/hooks';
import DropdownMenu from '../../../components/DropdownMenu';
import { Box, BoxProps, styled, useTheme } from '@mui/material';
import ToolbarContainer from '../../../components/ToolbarContainer';
import { MeasurementsIcon, ToggleButtons } from '@hdcorner/ui-library';
import {
  DiabetesFilterList,
  FitnessViewList,
  HypertensionFilterList,
  HypertensionViewList,
  LipidViewList,
  UserDataDropdownList,
} from '../constants';
import { DropdownType } from '../../content/types/DropdownType';
import {
  changeDiabetesResultsFilter,
  changeFitnessPage,
  changeHypertensionPage,
  changeHypertensionResultsFilter,
} from '../slices/userDataPageSlice';
import { changeLipidPage } from '../slices/lipidSlice';
import { weekMonthYearCustom } from '../../toggleHeadings';

const Divider = styled(Box)<BoxProps>(({ theme }) => ({
  width: '2px',
  opacity: 0.1,
  height: '16px',
  backgroundColor: theme.palette.kmColorsCoolGrey.main,
}));

type Props = {
  userId: string;
  activeFilter: string;
  changeFilter: (filter: string) => void;
};

const UserDataToolbar: FC<Props> = ({ userId, activeFilter, changeFilter }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const router = useIonRouter();
  const currentPage = router?.routeInfo?.pathname?.split('/').slice(-1);

  const [page, setPage] = useState<string>(UserDataDropdownList[0].value);
  const [fitnessPage, setFitnessPage] = useState<string>(FitnessViewList[0].value);
  const [lipidPage, setLipidPage] = useState<string>(LipidViewList[0].value);
  const [diabeticFilter, setDiabeticFilter] = useState<string>(
    DiabetesFilterList[0].value,
  );
  const [hypertensionPage, setHypertensionPage] = useState<string>(
    HypertensionViewList[0].value,
  );
  const [hypertensionFilter, setHypertensionFilter] = useState<string>(
    HypertensionFilterList[0].value,
  );

  useEffect(() => {
    if (currentPage[0] === userId) return;
    setPage(currentPage[0]);
  }, [currentPage]);

  const customToggle = weekMonthYearCustom.filter(it => it.value === 'custom');
  const customDayToggle = weekMonthYearCustom.filter(
    it => it.value === 'custom' || it.value === 'day',
  );
  const handleDropdown = (event: any, dropdown: DropdownType[], category: string) => {
    const newState = dropdown.find(option => option.value === event.target.value);
    if (newState) {
      switch (category) {
        case 'userdata':
          setPage(newState?.value);
          router.push(`/dashboard/user/data/${userId}/${newState.value}`);
          break;
        case 'diabetes':
          setDiabeticFilter(newState?.value);
          dispatch(changeDiabetesResultsFilter(newState?.value));
          break;
        case 'hypPage':
          setHypertensionPage(newState?.value);
          dispatch(changeHypertensionPage(newState?.value));
          break;
        case 'hypFilter':
          setHypertensionFilter(newState?.value);
          dispatch(changeHypertensionResultsFilter(newState?.value));
          break;
        case 'fitness':
          setFitnessPage(newState?.value);
          dispatch(changeFitnessPage(newState?.value));
          break;
        case 'lipid':
          setLipidPage(newState?.value);
          dispatch(changeLipidPage(newState?.value));
          break;
        default:
          break;
      }
    }
  };

  const MeasurementIcon = (
    <MeasurementsIcon
      width={20}
      height={22}
      color={theme.palette.kmColorsCoolGrey.main}
    />
  );

  return (
    <ToolbarContainer>
      <Box gap={3} display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
        <DropdownMenu
          value={page}
          setValue={setPage}
          options={UserDataDropdownList}
          handleDropdownChange={event =>
            handleDropdown(event, UserDataDropdownList, 'userdata')
          }
        />

        {page === 'lipid' && (
          <>
            <Divider />

            <Box gap={1} display={'flex'} alignItems={'center'}>
              {MeasurementIcon}
              <DropdownMenu
                value={lipidPage}
                setValue={setLipidPage}
                options={LipidViewList}
                handleDropdownChange={event =>
                  handleDropdown(event, LipidViewList, 'lipid')
                }
              />
            </Box>
          </>
        )}

        {page === 'diabetes' && (
          <>
            <Divider />

            <Box gap={1} display={'flex'} alignItems={'center'}>
              {MeasurementIcon}
              <DropdownMenu
                value={diabeticFilter}
                setValue={setDiabeticFilter}
                options={DiabetesFilterList}
                handleDropdownChange={event =>
                  handleDropdown(event, DiabetesFilterList, 'diabetes')
                }
              />
            </Box>
          </>
        )}

        {page === 'fitness' && (
          <>
            <Divider />

            <Box gap={1} display={'flex'} alignItems={'center'}>
              {MeasurementIcon}
              <DropdownMenu
                value={fitnessPage}
                setValue={setFitnessPage}
                options={FitnessViewList}
                handleDropdownChange={event =>
                  handleDropdown(event, FitnessViewList, 'fitness')
                }
              />
            </Box>
          </>
        )}

        {page === 'hypertension' && (
          <>
            <Divider />

            <Box gap={1} display={'flex'} alignItems={'center'}>
              {MeasurementIcon}
              <DropdownMenu
                value={hypertensionPage}
                setValue={setHypertensionPage}
                options={HypertensionViewList}
                handleDropdownChange={event =>
                  handleDropdown(event, HypertensionViewList, 'hypPage')
                }
              />
            </Box>
            {hypertensionPage === 'indepth' && (
              <>
                <Divider />

                <Box gap={1} display={'flex'} alignItems={'center'}>
                  {MeasurementIcon}
                  <DropdownMenu
                    value={hypertensionFilter}
                    setValue={setHypertensionFilter}
                    options={HypertensionFilterList}
                    handleDropdownChange={event =>
                      handleDropdown(event, HypertensionFilterList, 'hypFilter')
                    }
                  />
                </Box>
              </>
            )}
          </>
        )}

        <Divider />

        {page !== 'medication' && (
          <ToggleButtons
            buttonBorder
            size={'large'}
            value={activeFilter}
            handleChange={changeFilter}
            headings={
              hypertensionPage === 'esh'
                ? customToggle
                : fitnessPage === 'nutrition'
                ? customDayToggle
                : weekMonthYearCustom.filter(it => it.value !== 'all')
            }
          />
        )}
      </Box>
    </ToolbarContainer>
  );
};

export default UserDataToolbar;
