import React, { FC } from 'react';
import { visuallyHidden } from '@mui/utils';
import { ArrowDown, ArrowUp, CustomButton, HeadCell } from '@hdcorner/ui-library';
import {
  Box,
  BoxProps,
  Checkbox,
  CheckboxProps,
  styled,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
  TableSortLabel,
  useTheme,
} from '@mui/material';

const CustomCheckbox = styled(Checkbox)<CheckboxProps>(({ theme }) => ({
  width: '20px',
  borderRadius: '4px',
  margin: theme.spacing(0),
  padding: theme.spacing(0),
  color: theme.palette.kmColorsCoolGrey.main,
  '&.Mui-checked': {
    color: theme.palette.kmColorsTurquoise.main,
  },
  '&.MuiCheckbox-indeterminate': {
    color: theme.palette.kmColorsTurquoise.main,
  },
}));

const TableCellHeadings = styled(TableCell)<TableCellProps>(({ theme }) => ({
  flexGrow: '1',
  textAlign: 'center',
  boxSizing: 'border-box',
  color: theme.palette.kmColorsCoolGrey.main,
  backgroundColor: theme.palette.secondary.main,
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: theme.typography.subtitle1.fontWeight,
  borderBottomColor: theme.palette.background.default,
  padding: theme.spacing(1),
  '.Mui-active': {
    color: theme.palette.primary.light,
  },
  '&:first-of-type': {
    width: '52px',
    borderTopLeftRadius: '8px',
  },
  '&:nth-of-type(2)': {
    textAlign: 'left',
    paddingLeft: theme.spacing(0),
  },
}));

const TableCellSelectedStatus = styled(TableCell)<TableCellProps>(({ theme }) => ({
  flexGrow: '1',
  boxSizing: 'border-box',
  borderTopRightRadius: '8px',
  fontSize: theme.typography.body1.fontSize,
  color: theme.palette.kmColorsCoolGrey.main,
  fontWeight: theme.typography.fontWeightBold,
  backgroundColor: theme.palette.secondary.main,
  padding: theme.spacing(1.25, 0),
  borderBottomColor: theme.palette.background.default,
  '&:last-of-type': {
    paddingLeft: theme.spacing(0),
  },
}));

const IndeterminateCheckBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: '18px',
  height: '18px',
  borderRadius: '2px',
  boxSizing: 'border-box',
  border: `2px solid ${theme.palette.kmColorsCoolGrey.main}`,
}));

const MarginBox = styled(Box)<BoxProps>(({ theme }) => ({
  margin: theme.spacing(0, 0, 0, 0.75),
}));

const SortOrderDesc = () => (
  <MarginBox>
    <ArrowDown />
  </MarginBox>
);

const SortOrderAsc = () => {
  const theme = useTheme();
  return (
    <MarginBox>
      <ArrowUp fill={theme.palette.kmColorsCoolGrey.main} />
    </MarginBox>
  );
};

type Order = 'asc' | 'desc';

export type HealthProfTableHeadingProps = {
  order: Order;
  orderBy: string;
  headCells: HeadCell[];
  totalRowsCount: number;
  onCancelClick: () => void;
  totalRowsSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
};

const HealthProfessionalsTableHeading: FC<HealthProfTableHeadingProps> = ({
  order,
  orderBy,
  headCells,
  onCancelClick,
  onRequestSort,
  totalRowsCount,
  onSelectAllClick,
  totalRowsSelected,
}) => {
  const theme = useTheme();

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx={{ height: '55px' }}>
        <TableCellHeadings align={'center'}>
          <CustomCheckbox
            onChange={onSelectAllClick}
            indeterminateIcon={<IndeterminateCheckBox />}
            checked={totalRowsCount > 0 && totalRowsSelected === totalRowsCount}
            indeterminate={totalRowsSelected > 0 && totalRowsSelected < totalRowsCount}
          />
        </TableCellHeadings>
        {totalRowsSelected > 0 && (
          <TableCellSelectedStatus colSpan={6}>
            {totalRowsSelected} health care professionals selected
            <CustomButton
              variant={'text'}
              onClick={onCancelClick}
              sx={{
                color: theme.palette.kmColorsRed.main,
                fontSize: theme.typography.body1.fontSize,
                fontWeight: theme.typography.fontWeightBold,
              }}
            >
              Cancel
            </CustomButton>
          </TableCellSelectedStatus>
        )}
        {totalRowsSelected === 0 &&
          headCells.map(headCell => (
            <TableCellHeadings
              key={headCell._id}
              align={headCell.align}
              sortDirection={orderBy === headCell._id ? order : false}
            >
              {!headCell.sort ? (
                headCell.label
              ) : (
                <TableSortLabel
                  active={orderBy === headCell._id}
                  direction={orderBy === headCell._id ? order : 'asc'}
                  onClick={createSortHandler(headCell._id)}
                  IconComponent={
                    orderBy === headCell._id
                      ? order === 'asc'
                        ? SortOrderAsc
                        : SortOrderDesc
                      : SortOrderDesc
                  }
                >
                  {headCell.label}
                  {orderBy === headCell._id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              )}
            </TableCellHeadings>
          ))}
      </TableRow>
    </TableHead>
  );
};

export default HealthProfessionalsTableHeading;
