import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from './store';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query/react';
import { Mutex } from 'async-mutex';
import { setAccessToken, setRefreshToken } from '../features/authentication/authSlice';

const BASE_URL = process.env.REACT_APP_API_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  cache: 'no-cache',
  prepareHeaders: async (headers, { getState, endpoint }) => {
    const authState = (getState() as RootState).auth;
    const {
      data: { token, refreshToken },
    } = authState;

    const authToken = endpoint === '/authentication/renew' ? refreshToken : token;
    if (token) {
      headers.set('authorization', `Bearer ${authToken}`);
    }

    // Disables caching for all requests
    headers.set('Cache-Control', 'no-cache');
    headers.set('Pragma', 'no-cache');
    headers.set('expires', '0');
    return headers;
  },
});

const mutex = new Mutex();
const baseQueryWithReAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await baseQuery(
          {
            method: 'POST',
            url: '/authentication/renew',
          },
          { ...api, endpoint: '/authentication/renew' },
          extraOptions,
        );
        const refreshRes = refreshResult as any;
        console.log(refreshRes);
        if (refreshRes.data) {
          // update the tokens in redux store
          api.dispatch(setAccessToken(refreshRes.data.accessToken));
          api.dispatch(setRefreshToken(refreshRes.data.refreshToken));

          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          // TODO: logout user, clean up redux store
          // api.dispatch(logout());
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

const api = createApi({
  reducerPath: 'api',
  endpoints: () => ({}),
  tagTypes: ['AuthUser'],
  baseQuery: baseQueryWithReAuth,
});

export default api;
