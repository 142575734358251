import { useState } from 'react';
import { useIonRouter } from '@ionic/react';
import LoadingPage from '../../components/LoadingPage';
import { SignInPanelGlobal } from '@hdcorner/ui-library';
import { useLazyGetAuthUserQuery, useSignInMutation } from '../../queries/signInQueries';
import LogoSignInPNG from '../../../../assets/illustrations/LogoSignInPNG.png';
import useAlert from '../../../../hooks/useAlert';

const SignInPanel = () => {
  const router = useIonRouter();
  const { presentError } = useAlert();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);

  const [login] = useSignInMutation();
  const [getUser] = useLazyGetAuthUserQuery();

  const handleSignIn = async () => {
    try {
      setHasLoaded(true);
      await login({ email, password }).unwrap();
      const res = await getUser().unwrap();
      setHasLoaded(false);
      const userData = res[0];
      if (!userData) {
        throw new Error('User not found');
      }
      if (userData.role !== 'admin') {
        presentError('The user is not authorized to access admin app');
        return;
      }
    } catch (e) {
      console.log(e);
      setHasLoaded(false);
      presentError('An unexpected error occurred while signing in');
    }
  };

  const handleForgotPassword = () => {
    router.push('/forgot-password');
  };

  return (
    <>
      {hasLoaded && <LoadingPage />}
      {!hasLoaded && (
        <SignInPanelGlobal
          web={true}
          email={email}
          healthProf={true}
          password={password}
          setEmail={setEmail}
          setPassword={setPassword}
          handleSignIn={handleSignIn}
          handleForgotPassword={handleForgotPassword}
          logoComponent={
            <img
              src={LogoSignInPNG}
              alt={'company logo'}
              width={'110px'}
              height={'92px'}
            />
          }
        />
      )}
    </>
  );
};

export default SignInPanel;
