import { FC } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { CustomButton, DownloadIcon } from '@hdcorner/ui-library';

type Props = {
  handleDownload: () => void;
};

const DownloadButton: FC<Props> = ({ handleDownload }) => {
  const theme = useTheme();

  return (
    <CustomButton variant={'text'} onClick={handleDownload}>
      <Box gap={1} display={'flex'} alignItems={'center'}>
        <DownloadIcon
          width={'12px'}
          height={'14px'}
          color={theme.palette.kmColorsCoolGrey.main}
        />
        <Typography
          variant={'subtitle2'}
          color={theme.palette.kmColorsCoolGrey.main}
          sx={{ whiteSpace: 'nowrap', fontWeight: theme.typography.fontWeightBold }}
        >
          Download data
        </Typography>
      </Box>
    </CustomButton>
  );
};

export default DownloadButton;
