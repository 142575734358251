import { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useAppSelector } from '../../../redux/hooks';
import { Box, ListItem, useTheme } from '@mui/material';
import SectionHeader from '../../../components/SectionHeader';
import InfiniteScrollList from '../../../components/InifiniteScrollList';
import {
  Collapsible,
  CvdRiskChartWithRisk,
  useIdealData,
  usePrepareGraphData,
  usePrepareInDepthData,
  useTimeframe,
} from '@hdcorner/ui-library';
import {
  useGetCvdCurrentMeasurementQuery,
  useGetCvdGraphDataQuery,
  useGetCvdRiskLogsQuery,
} from '../queries/cvdRiskQueries';
import useAlert from '../../../hooks/useAlert';

interface Props extends RouteComponentProps<{ userId: string }> {}

const DataPanelCVD: FC<Props> = ({ match }) => {
  const theme = useTheme();
  const { presentError } = useAlert();

  const { dateFilter, customDateFilter } = useAppSelector(state => state.layout);

  const [cvdRiskParams, setCvdRiskParams] = useState({
    skip: 0,
    limit: 1000,
  });

  const timeframe = useTimeframe(
    dateFilter,
    customDateFilter?.start,
    customDateFilter?.end,
  );

  const userId = match.params.userId;

  const { data: graphData, error: errorGraph } = useGetCvdGraphDataQuery({
    ...timeframe,
    userId,
  });
  const { data: currentMeasurement, error: errorMeasurement } =
    useGetCvdCurrentMeasurementQuery({ userId });
  const {
    data: cvdRiskLogs,
    isFetching: cvdRiskFetching,
    error: errorLog,
  } = useGetCvdRiskLogsQuery({
    ...cvdRiskParams,
    ...timeframe,
    userId,
  });

  useEffect(() => {
    if (errorGraph) presentError('An unexpected error occurred fetching graph data');
    if (errorMeasurement)
      presentError('An unexpected error occurred fetching measurement data');
    if (errorLog) presentError('An unexpected error occurred fetching log data');
  }, [errorGraph, errorMeasurement, errorLog]);

  const idealData = useIdealData(currentMeasurement);
  const prepareGraphData = usePrepareGraphData(graphData);
  const prepareInDepthData = usePrepareInDepthData(cvdRiskLogs);

  return (
    <>
      <Box display={'flex'} flex={1} gap={theme.spacing(3)}>
        <Box display={'flex'} flex={1} flexDirection={'column'}>
          <SectionHeader title={'risk calculator'} />
          <CvdRiskChartWithRisk data={idealData} chartData={prepareGraphData} />
        </Box>
        <Box display={'flex'} flexDirection={'column'} flex={1} gap={theme.spacing(1)}>
          <SectionHeader title={'in-depth data'} />
          <InfiniteScrollList
            loading={cvdRiskFetching}
            skip={cvdRiskParams.skip}
            limit={cvdRiskParams.limit}
            totalCount={cvdRiskLogs?.count}
            onLoadMore={(skip, limit) => setCvdRiskParams({ skip, limit })}
          >
            {prepareInDepthData.map((item: any, index: number) => {
              const title = parseInt(item.values['Overall Risk'].value);
              return (
                <ListItem key={index} disablePadding disableGutters>
                  <Collapsible
                    icon
                    titleSub={'%'}
                    data={item.values}
                    title={title.toString()}
                    progress={item.progress}
                  />
                </ListItem>
              );
            })}
          </InfiniteScrollList>
        </Box>
      </Box>
    </>
  );
};

export default DataPanelCVD;
