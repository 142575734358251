import React, { FC, useEffect, useMemo } from 'react';
import moment from 'moment/moment';
import { Box } from '@mui/material';
import { useAppSelector } from '../../../redux/hooks';
import SectionHeader from '../../../components/SectionHeader';
import { useGetWeightLogsQuery } from '../queries/weightQueries';
import {
  calculateBMI,
  categoryBMI,
  Collapsible,
  FeelsEmpty,
  theme,
  useTimeframe,
} from '@hdcorner/ui-library';
import { useGetUserDetailsQuery } from '../queries/usersQueries';
import useAlert from '../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

type Props = { userId: string };
const SectionWeightData: FC<Props> = ({ userId }) => {
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const { dateFilter, customDateFilter } = useAppSelector(state => state.layout);

  const timeframe = useTimeframe(
    dateFilter,
    customDateFilter?.start,
    customDateFilter?.end,
  );

  const { data: weightLogs, error: errorWeight } = useGetWeightLogsQuery({
    userId,
    ...timeframe,
    params: { skip: 0, limit: 1000, sort: ['-logDate'] },
  });

  const { data: userData, error: errorUser } = useGetUserDetailsQuery({ id: userId });

  useEffect(() => {
    if (errorWeight || errorUser)
      presentError('An unexpected error occurred fetching weight data');
  }, [errorWeight, errorUser]);

  const userHeight = useMemo(() => {
    if (!userData) return 0;
    return userData.data.height;
  }, [userData]);

  const inDepthData = useMemo(() => {
    if (!weightLogs || weightLogs.documents.length === 0) return [];

    if (!userHeight) return [];

    return weightLogs.documents.map(it => {
      const BMI = calculateBMI(userHeight, !it.weight ? 0 : it.weight);
      const BMILabel = categoryBMI(Number(BMI));

      return {
        _id: it._id,
        measure: 'kg',
        count: !it.weight ? '0' : `${it.weight.toString()}kg`,
        data: {
          Weight: { value: !it.weight ? '0' : `${it.weight.toString()}kg` },
          BMI: {
            value: BMI ? BMI.toString() : '-',
          },
          'BMI Description': { value: t(BMILabel.label) },
          'Time of calculation': { value: moment(it.logDate).format('h:mm a') },
          'Date of calculation': { value: moment(it.logDate).format('MMMM Do, YYYY') },
        },
      };
    });
  }, [weightLogs, userHeight]);

  return (
    <Box>
      <SectionHeader title={'in-depth data'} />
      <Box mt={1} gap={1} display={'flex'} flexDirection={'column'}>
        {inDepthData.map(data => (
          <Box key={data._id} width={'100%'}>
            <Collapsible data={data.data} titleSub={data.measure} title={data.count} />
          </Box>
        ))}
        {inDepthData.length === 0 && (
          <Box sx={{ marginBottom: theme.spacing(1), width: '100%' }}>
            <FeelsEmpty />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SectionWeightData;
