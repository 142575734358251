import api from '../../../redux/api';
import { ApiPagination, PaginationResult, Video } from '@hdcorner/ui-library';

const videoQueries = api.enhanceEndpoints({ addTagTypes: ['Video'] }).injectEndpoints({
  endpoints: build => ({
    getVideos: build.query<PaginationResult<Video>, ApiPagination>({
      query: ({ skip, limit, sort }) => ({
        url: '/hdCornerService/admin/content/video',
        method: 'GET',
        params: {
          skip,
          limit,
          sort,
          populate: ['attachedVideo', 'thumbnail'],
        },
      }),
      providesTags: ['Video'],
    }),

    postVideo: build.mutation<void, Video>({
      query: video => ({
        url: '/hdCornerService/admin/content/video',
        method: 'POST',
        body: video,
      }),
      invalidatesTags: ['Video'],
    }),

    editVideo: build.mutation<void, Video & { id: string }>({
      query: ({ id, ...rest }) => ({
        url: `/hdCornerService/admin/content/video/${id}`,
        method: 'PATCH',
        body: rest,
      }),
      invalidatesTags: ['Video'],
    }),

    updateSuggestedVideo: build.mutation<void, { id: string; suggested: boolean }>({
      query: ({ id, suggested }) => ({
        url: `/hdCornerService/admin/content/video/${id}`,
        method: 'PATCH',
        body: { suggested },
      }),
      invalidatesTags: ['Video'],
    }),

    deleteVideo: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/hdCornerService/admin/content/video/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Video'],
    }),
  }),
});

export const {
  useGetVideosQuery,
  usePostVideoMutation,
  useEditVideoMutation,
  useDeleteVideoMutation,
  useUpdateSuggestedVideoMutation,
} = videoQueries;

export default videoQueries;
