// Convert the enum value to a label
const convertEnumValue = (value: string, array: { label: string; value: string }[]) => {
  if (!value || array.length === 0) return '';

  const category = array.find((it: any) => it.value === value);
  if (category) {
    return category.label;
  } else {
    return '';
  }
};

// Convert the label to enum
const convertLabeltoEnum = (value: string, array: { label: string; value: string }[]) => {
  if (!value || array.length === 0) return '';

  const category = array.find(it => it.label === value);
  if (category) {
    return category.value;
  } else {
    return '';
  }
};

const arrayToString = (array: (string | undefined)[]) => {
  if (!Array.isArray(array) || array.length === 0) {
    return '';
  }
  const validEntries = array.filter(entry => entry !== undefined);
  if (validEntries.length === 0) {
    return '';
  }

  if (validEntries.length === 1) {
    return array[0];
  }

  const lastIndex = validEntries.length - 1;
  const joinedString = validEntries.slice(0, lastIndex).join(', ');
  return `${joinedString}, ${validEntries[lastIndex]}`;
};

export { arrayToString, convertEnumValue, convertLabeltoEnum };
