import api from '../../../redux/api';
import { UserReduced } from '../types/UserReduced';
import { UserDetails } from '../types/UserDetails';
import { SubsAnalytics } from '../types/SubsAnalytics';
import { ApiPagination, PaginationResult } from '@hdcorner/ui-library';

const usersQueries = api
  .enhanceEndpoints({ addTagTypes: ['AuthUser', 'UserData'] })
  .injectEndpoints({
    endpoints: build => ({
      getUserSubscriptionAnalytics: build.query<
        PaginationResult<SubsAnalytics>,
        ApiPagination
      >({
        query: ({ skip, limit }) => ({
          url: '/database/UserSubscriptionAnalytics',
          method: 'GET',
          params: { skip, limit },
        }),
      }),

      getUsers: build.query<PaginationResult<UserReduced>, ApiPagination>({
        query: ({ skip, limit, sort }) => ({
          url: `/hdCornerService/admin/user`,
          method: 'GET',
          params: {
            skip,
            limit,
            sort,
          },
        }),
        providesTags: ['AuthUser'],
      }),

      getUserDetails: build.query<UserDetails, { id: string }>({
        query: ({ id }) => ({
          url: `/hdCornerService/admin/user/${id}/details`,
          method: 'GET',
        }),
        providesTags: ['AuthUser'],
      }),
    }),
  });

export const {
  useGetUsersQuery,
  useGetUserDetailsQuery,
  useGetUserSubscriptionAnalyticsQuery,
} = usersQueries;

export default usersQueries;
