import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import useAlert from '../../../hooks/useAlert';
import ModalBaseContent from './ModalBaseContent';
import {
  ConduitFile,
  CustomInput,
  Diet,
  DropdownMenu,
  UploadResponse,
} from '@hdcorner/ui-library';
import ContentDropzone from '../../../components/ContentDropzone';
import {
  useEditDietPlanMutation,
  usePostDietPlanMutation,
} from '../queries/dietPlanQueries';
import { DietPlanCategories } from '../constants';
import {
  useReUploadFileMutation,
  useUploadFileMutation,
} from '../../../queries/fileQueries';
import { v4 as uuidv4 } from 'uuid';
import UploadedFileMarker from './UploadedFileMarker';
import { fileSize } from '../../../utils/bytes';

const formatFileName = (name: string) => {
  const splitName = name.split('_');
  if (splitName.length > 1) {
    return splitName[1].replace(/%20/g, ' ');
  }
  return name;
};

type Props = {
  data?: Diet;
  open: boolean;
  setOpen: (open: boolean) => void;
};
const ModalAddEditDietPlan: FC<Props> = ({ data, open, setOpen }) => {
  const { presentError } = useAlert();

  // STORE SELECTED FILES FOR UPLOAD
  const [attachedFile, setAttachedFile] = useState<{
    en: File | undefined;
    el: File | undefined;
  }>({
    en: undefined,
    el: undefined,
  });
  const [version, setVersion] = useState<'en' | 'el'>('en');
  const [dietPlan, setDietPlan] = useState<Diet>({
    _id: '',
    name: {
      en: '',
      el: '',
    },
    calories: 0,
    attachedFile: {
      en: '',
      el: '',
    },
    dietType: '',
  });

  const [upload] = useUploadFileMutation();
  const [reUpload] = useReUploadFileMutation();
  const [postDietPlan] = usePostDietPlanMutation();
  const [editDietPlan] = useEditDietPlanMutation();

  useEffect(() => {
    if (!data) return;
    setDietPlan({
      _id: data._id,
      name: data.name,
      dietType: data.dietType,
      calories: data.calories,
      attachedFile: data.attachedFile,
    });

    if (data.attachedFile) {
      const conduitFileEn = data.attachedFile.en as ConduitFile;
      const conduitFileEl = data.attachedFile.el as ConduitFile;

      let fileEn = undefined;
      let fileEl = undefined;
      if (conduitFileEn) {
        fileEn = new File([], conduitFileEn.name);
      }
      if (conduitFileEl) {
        fileEl = new File([], conduitFileEl.name);
      }
      setAttachedFile({
        en: fileEn,
        el: fileEl,
      });
    }
  }, [data, version]);

  const fileName = useMemo(() => {
    const file = attachedFile[version];
    if (!file) return '';
    return file.name;
  }, [attachedFile, version]);

  const file = useMemo(() => {
    return attachedFile[version];
  }, [attachedFile, version]);

  const handleChange = (fieldName: string, value: any) => {
    setDietPlan(oldValue => ({
      ...oldValue,
      [fieldName]: value,
    }));
  };

  const handleNewFiles = (files: File[]) => {
    const file = files[0];
    if (!file) return;
    setAttachedFile({
      ...attachedFile,
      [version]: file,
    });
  };

  const handleSave = async () => {
    if (dietPlan.name.en === '' || dietPlan.name.el === '') {
      presentError('Please enter a name for both languages');
      return;
    }
    const existingFileEn = dietPlan.attachedFile.en as ConduitFile;
    const existingFileEl = dietPlan.attachedFile.el as ConduitFile;
    const { en: enAttachedFile, el: elAttachedFile } = attachedFile;
    if (!enAttachedFile || !elAttachedFile) {
      presentError('Please attach a file for both languages');
      return;
    }
    try {
      const resEn = await handleCreateFiles(enAttachedFile, existingFileEn);
      const resEl = await handleCreateFiles(elAttachedFile, existingFileEl);
      const planData = {
        name: dietPlan.name,
        calories: dietPlan.calories,
        dietType: dietPlan.dietType,
        id: dietPlan._id ? dietPlan._id : '',
        attachedFile: {
          en: resEn ? resEn.file._id : (dietPlan.attachedFile.en as ConduitFile)._id,
          el: resEl ? resEl.file._id : (dietPlan.attachedFile.el as ConduitFile)._id,
        },
      };
      let promiseData;
      if (!data) {
        const { id, ...rest } = planData;
        promiseData = postDietPlan(rest).unwrap();
      } else {
        promiseData = editDietPlan(planData).unwrap();
      }
      await promiseData;
      if (resEn && attachedFile.en) {
        uploadBlobData(resEn as UploadResponse, attachedFile.en);
      }
      if (resEl && attachedFile.el) {
        uploadBlobData(resEl as UploadResponse, attachedFile.el);
      }
      setOpen(false);
      resetData();
    } catch (e) {
      console.log(e);
      presentError('An error occurred while saving the diet plan');
    }
  };

  const handleCreateFiles = async (
    selectedFile: File,
    existingFile?: ConduitFile,
  ): Promise<UploadResponse | void> => {
    let uploadPromise: Promise<UploadResponse> | undefined;
    if (!existingFile) {
      // Create new conduit file for attached file
      uploadPromise = upload({
        size: selectedFile.size,
        name: `${uuidv4()}_${selectedFile.name}`,
      }).unwrap();
    } else {
      if (selectedFile.size === 0 && selectedFile.name === existingFile.name) {
        return;
      } else {
        // Upload new conduit file for attached file
        uploadPromise = reUpload({
          _id: existingFile._id,
          size: selectedFile.size,
          name: `${uuidv4()}_${selectedFile.name}`,
        }).unwrap();
      }
    }
    return uploadPromise;
  };

  const uploadBlobData = async (uploadItem: UploadResponse, attachedFile: File) => {
    return await fetch(uploadItem.url, {
      method: 'PUT',
      body: attachedFile,
      headers: {
        'Content-Type': attachedFile.type,
        'x-ms-blob-type': 'BlockBlob',
      },
    });
  };

  const resetData = () => {
    setDietPlan({
      _id: '',
      name: {
        en: '',
        el: '',
      },
      calories: 0,
      attachedFile: {
        en: '',
        el: '',
      },
      dietType: '',
    });
  };

  return (
    <ModalBaseContent
      open={open}
      data={!!data}
      value={version}
      setOpen={setOpen}
      setValue={setVersion}
      category={'Diet Plan'}
      handleSaveModal={handleSave}
    >
      <CustomInput
        fullWidth
        label={'Diet name'}
        placeholder={'Enter name'}
        value={dietPlan.name[version]}
        handleChange={(value: string) => {
          handleChange('name', { ...dietPlan.name, [version]: value });
        }}
      />
      <Box gap={2} display={'flex'}>
        <CustomInput
          fullWidth
          type={'number'}
          label={'Calories'}
          value={dietPlan.calories}
          placeholder={'Enter calories'}
          handleChange={(value: string) => handleChange('calories', value)}
        />
        <Box flex={1}>
          <DropdownMenu
            fullWidth
            placeholder={'-'}
            label={'Diet type'}
            value={dietPlan.dietType}
            menuItems={DietPlanCategories}
            setValue={(value: string) => handleChange('dietType', value)}
          />
        </Box>
      </Box>
      <Box height={'120px'}>
        <ContentDropzone heading={'Attach PDF'} handleContentDrop={handleNewFiles} />
      </Box>
      {attachedFile[version] && (
        <UploadedFileMarker
          fileName={formatFileName(fileName)}
          fileSize={fileSize(file, dietPlan.attachedFile[version] as ConduitFile)}
          handleRemoveFile={() => setAttachedFile({ ...attachedFile, [version]: null })}
        />
      )}
    </ModalBaseContent>
  );
};

export default ModalAddEditDietPlan;
