import api from '../../../redux/api';
import { ApiPagination, GetStepsLog, PaginationResult } from '@hdcorner/ui-library';

const stepsQueries = api
  .enhanceEndpoints({ addTagTypes: ['GetStepsLog'] })
  .injectEndpoints({
    endpoints: build => ({
      getStepsLogs: build.query<
        PaginationResult<GetStepsLog>,
        { params: ApiPagination; start?: string; end?: string; userId: string }
      >({
        query: ({ params, start, end, userId }) => ({
          method: 'GET',
          url: '/database/function/getStepLogs',
          params: { ...params, start, end, scope: `Exercise:${userId}` },
        }),
        providesTags: ['GetStepsLog'],
      }),

      getStepsGraphData: build.query<
        { graphData: any[]; total?: number; avg?: number },
        {
          end?: string;
          userId: string;
          start?: string;
          timezone: string;
          timeframe?: string;
        }
      >({
        query: ({ start, end, timeframe, timezone, userId }) => ({
          method: 'GET',
          url: '/hdCornerService/user/steps/graph',
          params: { start, end, timeframe, timezone, userId },
        }),
        providesTags: ['GetStepsLog'],
      }),
    }),
  });

export const { useGetStepsLogsQuery, useGetStepsGraphDataQuery } = stepsQueries;

export default stepsQueries;
