import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  CustomButton,
  DropdownMenu,
  ModalHeading,
  ModalMobile,
  XCloseIcon,
} from '@hdcorner/ui-library';
import { Box, useTheme, styled, BoxProps } from '@mui/material';
import { useEditProgramMutation, useGetProgramQuery } from '../queries/programQueries';
import useAlert from '../../../hooks/useAlert';
import { VideoExercise } from '../types/VideoExercise';
import { PostProgram } from '../types/Program';

const BoxMain = styled(Box)<BoxProps>(({ theme }) => ({
  width: '385px',
  display: 'flex',
  borderRadius: '8px',
  flexDirection: 'column',
  boxSizing: 'border-box',
  gap: theme.spacing(3),
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
}));

interface ProgramOption {
  name: string;
  _id: string | undefined;
  exercises: VideoExercise[] | [];
}

type Props = {
  open: boolean;
  data: Partial<PostProgram>;
  handleUpdateStatus: () => void;
  setOpen: (open: boolean) => void;
};

const ModalAddToProgram: FC<Props> = ({ open, data, setOpen, handleUpdateStatus }) => {
  const theme = useTheme();
  const { presentSuccess, presentError } = useAlert();

  const [programId, setProgramId] = useState({
    id: '',
  });

  const { error, data: programRes } = useGetProgramQuery({
    skip: 0,
    limit: 1000,
    sort: ['name'],
    bodyPart: data.bodyPart,
    avatar: data.avatar || 'male',
    workoutType: data.workoutType,
  });

  useEffect(() => {
    if (error) presentError('An unexpected error occurred fetching program data');
  }, [error]);

  const [editProgram] = useEditProgramMutation();

  const programs = useMemo(() => {
    if (!programRes || programRes.documents.length === 0) return [];

    return programRes.documents.map(data => ({
      name: data.name,
      _id: data._id,
      exercises: data.exercises,
    }));
  }, [programRes]);

  const menuList: { label: string; value: string }[] = useMemo(() => {
    if (!programRes || programRes.documents.length === 0)
      return [{ label: '', value: '' }];

    return programRes.documents.map(data => ({
      label: data.name || '',
      value: data._id || '',
    }));
  }, [programRes]);

  const handleChange = (fieldName: string, value: any) => {
    setProgramId(oldValue => ({
      ...oldValue,
      [fieldName]: value,
    }));
  };

  const handleAddToProgram = () => {
    const program = programs.find((option: ProgramOption) => option._id === programId.id);
    if (program && data.exercises) {
      const originalExercises = program.exercises.map(obj => obj._id); // filter to create array of strings (ids)
      const combinedArrays = [...data.exercises, ...originalExercises]; // smoosh together selected & original
      const finalArray = Array.from(new Set(combinedArrays)); // remove duplicates

      const stringifiedExercises = finalArray.map(id => String(id));

      editProgram({
        name: program.name,
        exercises: stringifiedExercises,
        id: program._id ? program._id : '',
      })
        .unwrap()
        .then(() => {
          presentSuccess('Program updated');
          setOpen(false);
          handleUpdateStatus();
        })
        .catch(() => {
          presentError('Something went wrong.');
        });
    }
  };

  return (
    <ModalMobile open={open} setOpen={setOpen}>
      <BoxMain>
        <ModalHeading
          align={'left'}
          showCalendar={'flex'}
          sx={{ marginBottom: 0 }}
          title={'Add to program'}
          alternateIcon={<XCloseIcon />}
          handleCalendarClick={() => setOpen(false)}
        />
        <Box flex={1}>
          <DropdownMenu
            fullWidth
            value={programId.id}
            menuItems={menuList}
            label={'Program name'}
            placeholder={'Choose your program'}
            setValue={(value: string) => handleChange('id', value)}
          />
        </Box>

        <Box display={'flex'} justifyContent={'center'}>
          <CustomButton
            variant={'contained'}
            onClick={handleAddToProgram}
            sx={{
              width: '212px',
              ':disabled': {
                opacity: 0.3,
                backgroundColor: theme.palette.kmColorsTurquoise.main,
              },
            }}
          >
            {'Choose'}
          </CustomButton>
        </Box>
      </BoxMain>
    </ModalMobile>
  );
};

export default ModalAddToProgram;
