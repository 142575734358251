import { FC, useMemo } from 'react';
import { useIonRouter } from '@ionic/react';
import ToolbarContainer from './ToolbarContainer';
import ToggleButtonsIcon from './ToggleButtonsIcon';
import DataCircleIcon from '../assets/icons/DataCricleIcon';
import avatar from '../../src/assets/illustrations/UserAvater.png';
import { Avatar, Box, IconButton, Typography, useTheme } from '@mui/material';
import AwaitingVerificationIcon from '../assets/icons/AwaitingVerificationIcon';
import {
  ArrowLeft,
  IDBadge,
  PlanBadge,
  UsersIconOutline,
  VerifiedIcon,
} from '@hdcorner/ui-library';
import VerificationButton from './VerificationButton';
import {
  useGetHCPDetailsQuery,
  usePostHCPVerificationMutation,
} from '../features/healthProfessionals/queries/hcpQueries';
import useAlert from '../hooks/useAlert';

type Props = {
  value: string;
  userId: string;
  hcpUser: boolean;
  premium?: boolean;
  setValue: (value: string) => void;
};
const UserHcpInfoToolbar: FC<Props> = ({ value, userId, hcpUser, premium, setValue }) => {
  const theme = useTheme();
  const router = useIonRouter();
  const { presentSuccess, presentError } = useAlert();

  const { data: hcpRes } = useGetHCPDetailsQuery(
    { id: userId, skip: 0, limit: 10 },
    { skip: !hcpUser },
  );

  const [verifyUser] = usePostHCPVerificationMutation();

  const hcpDetails = useMemo(() => {
    if (!hcpRes) return;
    const data = hcpRes.healthProfessionalData;
    return {
      id: data._id as string,
      verified: data.verified,
      avatar: data.authUser.profilePicture?.url,
      name:
        data.authUser.lastName && data.authUser.firstName
          ? `${data.authUser.firstName} ${data.authUser.lastName}`
          : '',
    };
  }, [hcpRes]);

  const handleChange = (newValue: string) => {
    setValue(newValue);
  };

  const handlePreviousPage = () => {
    router.push(hcpUser ? '/dashboard/healthProfessionals' : '/dashboard/users');
  };

  /** HCP user only : */
  const handleVerification = (status: boolean) => {
    verifyUser({
      ids: [userId],
      revoke: status,
    })
      .then(() => presentSuccess('Verification status updated'))
      .catch(() => presentError('Something went wrong'));
  };

  return (
    <ToolbarContainer sx={{ justifyContent: 'space-between' }}>
      <Box gap={2} display={'flex'} alignItems={'center'}>
        <IconButton onClick={handlePreviousPage} sx={{ padding: 0 }}>
          <ArrowLeft color={theme.palette.kmColorsCoolGrey.main} />
        </IconButton>
        <Box position={'relative'}>
          <Avatar
            alt="HD User"
            sx={{ width: 48, height: 48 }}
            src={hcpUser ? hcpDetails?.avatar : avatar}
          />

          {hcpUser && hcpDetails && (
            <Box position={'absolute'} bottom={-5} right={-1}>
              {hcpDetails.verified && <VerifiedIcon />}
              {!hcpDetails.verified && <AwaitingVerificationIcon />}
            </Box>
          )}
        </Box>

        <Typography
          variant={'subtitle2'}
          sx={{
            whiteSpace: 'nowrap',
            color: theme.palette.kmColorsBgText.main,
            fontWeight: theme.typography.fontWeightBold,
          }}
        >
          {hcpUser ? `${hcpDetails ? hcpDetails.name : ''}` : 'HD Corner User'}
        </Typography>

        <IDBadge id={userId} />

        {!hcpUser && <PlanBadge premium={premium ? premium : false} />}

        <ToggleButtonsIcon
          value={value}
          handleChange={handleChange}
          headings={[
            {
              text: 'Data',
              icon: (
                <DataCircleIcon
                  color={
                    value.toLowerCase() === 'data'
                      ? theme.palette.secondary.main
                      : theme.palette.kmColorsCoolGrey.main
                  }
                />
              ),
            },
            {
              text: 'User Info',
              icon: (
                <UsersIconOutline
                  color={
                    value.toLowerCase() === 'user info'
                      ? theme.palette.secondary.main
                      : theme.palette.kmColorsCoolGrey.main
                  }
                  width={'16px'}
                  height={'16px'}
                />
              ),
            },
          ]}
        />
      </Box>
      {hcpUser && hcpDetails && (
        <VerificationButton
          disabled={false}
          verify={!hcpDetails.verified}
          onClick={() => handleVerification(!!hcpDetails.verified)}
        />
      )}
    </ToolbarContainer>
  );
};

export default UserHcpInfoToolbar;
