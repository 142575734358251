import api from '../../../redux/api';
import { HPUsers } from '../types/HPUsers';

const totalHPUsersQueries = api.injectEndpoints({
  endpoints: build => ({
    getTotalHPUsers: build.query<HPUsers, void>({
      query: () => ({
        url: '/hdCornerService/admin/dashboard/total-hp-users',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetTotalHPUsersQuery } = totalHPUsersQueries;

export default totalHPUsersQueries;
