import api from '../../../redux/api';
import { ApiPagination, Medication, PaginationResult } from '@hdcorner/ui-library';

const medicationQueries = api
  .enhanceEndpoints({ addTagTypes: ['Medication'] })
  .injectEndpoints({
    endpoints: build => ({
      getMedications: build.query<
        PaginationResult<Medication>,
        ApiPagination & { startDate: string; endDate: string; userId: string }
      >({
        query: ({ skip, limit, startDate, endDate, userId }) => ({
          url: `database/function/getMedications`,
          method: 'GET',
          params: {
            skip,
            limit,
            startDate,
            endDate,
            sort: 'time',
            populate: 'medicine',
            scope: `PremiumHealth:${userId}`,
          },
        }),
        providesTags: ['Medication'],
      }),
    }),
  });

export const { useGetMedicationsQuery } = medicationQueries;

export default medicationQueries;
