import api from '../../../redux/api';
import { ApiPagination, Diet, PaginationResult } from '@hdcorner/ui-library';

const dietPlanQueries = api.enhanceEndpoints({ addTagTypes: ['Diet'] }).injectEndpoints({
  endpoints: build => ({
    getDietPlans: build.query<PaginationResult<Diet>, ApiPagination>({
      query: ({ skip, limit, sort }) => ({
        url: '/hdCornerService/admin/content/diet-plan',
        method: 'GET',
        params: {
          skip,
          limit,
          sort,
          populate: ['attachedFile.en', 'attachedFile.el'],
        },
      }),
      providesTags: ['Diet'],
    }),

    postDietPlan: build.mutation<void, Diet>({
      query: dietPlan => ({
        url: '/hdCornerService/admin/content/diet-plan',
        method: 'POST',
        body: dietPlan,
      }),
      invalidatesTags: ['Diet'],
    }),

    editDietPlan: build.mutation<void, Diet & { id: string }>({
      query: ({ id, ...rest }) => ({
        url: `/hdCornerService/admin/content/diet-plan/${id}`,
        method: 'PATCH',
        body: rest,
      }),
      invalidatesTags: ['Diet'],
    }),

    deleteDietPlan: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/hdCornerService/admin/content/diet-plan/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Diet'],
    }),
  }),
});

export const {
  useGetDietPlansQuery,
  usePostDietPlanMutation,
  useEditDietPlanMutation,
  useDeleteDietPlanMutation,
} = dietPlanQueries;

export default dietPlanQueries;
