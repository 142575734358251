import React, { FC, useEffect, useState } from 'react';
import {
  CustomButton,
  CustomInput,
  DropdownMenu,
  HealthProfessionalsData,
  ModalHeading,
  ModalMobile,
  XCloseIcon,
} from '@hdcorner/ui-library';
import { Box, BoxProps, styled, useTheme } from '@mui/material';
import { professionCategories } from '../constants';
import {
  useCreateHcpUserDataMutation,
  useCreateLocationDataMutation,
  useInviteHcpUserMutation,
  useRegisterHcpUserMutation,
  useSetHcpAuthUserMutation,
} from '../queries/inviteHcpQueries';
import { HcpAuthUser } from '../types/HcpAuthUser';
import { v4 as uuidv4 } from 'uuid';
import useAlert from '../../../hooks/useAlert';

const FieldBox = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 1,
  display: 'flex',
  gap: theme.spacing(2),
}));

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  data?: HcpAuthUser & Omit<HealthProfessionalsData, '_id' | 'verified' | 'authUser'>;
};

const ModalAddHCP: FC<Props> = ({ data, open, setOpen }) => {
  const theme = useTheme();
  const { presentError, presentSuccess } = useAlert();

  const [inviteUser] = useInviteHcpUserMutation();
  const [setAuthUser] = useSetHcpAuthUserMutation();
  const [registerUser] = useRegisterHcpUserMutation();
  const [createUserData] = useCreateHcpUserDataMutation();
  const [createLocationData] = useCreateLocationDataMutation();

  const [authUserData, setAuthUserData] = useState<HcpAuthUser>({
    email: '',
    gender: '',
    role: 'hcp',
    lastName: '',
    firstName: '',
  });

  const [hcpData, setHcpData] = useState<
    Omit<HealthProfessionalsData, '_id' | 'verified' | 'authUser'>
  >({
    vat: '',
    area: '',
    code: '',
    phone: '',
    address: '',
    addressNum: '',
    postalCode: '',
    speciality: '',
    clinicalCoach: false,
  });

  useEffect(() => {
    if (!data) {
      setHcpData(prevState => ({
        ...prevState,
        code: uuidv4(),
      }));
      return;
    }

    setAuthUserData({
      firstName: data.firstName,
      lastName: data.lastName,
      gender: data.gender,
      email: data.email,
      role: data.role,
    });

    setHcpData({
      vat: data.vat,
      area: data.area,
      code: data.code,
      phone: data.phone,
      address: data.address,
      addressNum: data.addressNum,
      postalCode: data.postalCode,
      speciality: data.speciality,
      clinicalCoach: data.clinicalCoach,
    });
  }, [data]);

  const handleAuthUserChange = (fieldName: string, value: any) => {
    setAuthUserData(oldValue => ({
      ...oldValue,
      [fieldName]: value,
    }));
  };

  const handleUserDataChange = (fieldName: string, value: any) => {
    if (fieldName === 'clinicalCoach') {
      setHcpData(oldValue => ({
        ...oldValue,
        [fieldName]: value === 'yes',
      }));
      return;
    }

    setHcpData(oldValue => ({
      ...oldValue,
      [fieldName]: value,
    }));
  };

  const handleSave = async () => {
    let authUser;
    try {
      authUser = await registerUser({
        email: authUserData.email,
      }).unwrap();
    } catch (err) {
      console.log(err);
    }

    if (!authUser || !authUser.user) {
      return;
    }

    try {
      await setAuthUser({
        ...authUserData,
        hcpId: authUser.user._id,
      }).unwrap();

      await createUserData({
        ...hcpData,
        hcpId: authUser.user._id,
      }).unwrap();

      if (data?.clinicalCoach) {
        await createLocationData({
          _id: authUser.user._id,
        }).unwrap();
      }

      await inviteUser({
        hcpId: authUser.user._id,
      })
        .unwrap()
        .then(() => presentSuccess('Healthcare professional added'));
    } catch (err) {
      console.log(err);
      presentError('An unexpected error occurred');
    }
  };

  return (
    <ModalMobile open={open} setOpen={setOpen}>
      <Box width={'640px'}>
        <Box
          gap={2}
          display={'flex'}
          flexDirection={'column'}
          padding={theme.spacing(2, 3, 0, 3)}
        >
          <ModalHeading
            align={'left'}
            showCalendar={'flex'}
            sx={{ marginBottom: 0 }}
            alternateIcon={<XCloseIcon />}
            title={'Health Care Professional creation'}
            handleCalendarClick={() => setOpen(false)}
          />

          <FieldBox>
            <CustomInput
              fullWidth
              label={'First name'}
              value={authUserData.firstName}
              placeholder={'Enter first name'}
              readOnly={!!(data || data !== undefined)}
              handleChange={(value: string) => handleAuthUserChange('firstName', value)}
            />
            <CustomInput
              fullWidth
              label={'Last name'}
              value={authUserData.lastName}
              placeholder={'Enter last name'}
              readOnly={!!(data || data !== undefined)}
              handleChange={(value: string) => handleAuthUserChange('lastName', value)}
            />
          </FieldBox>

          <FieldBox>
            <CustomInput
              fullWidth
              value={hcpData.vat}
              placeholder={'Enter VAT number'}
              label={'VAT registration number'}
              readOnly={!!(data || data !== undefined)}
              handleChange={(value: string) => handleUserDataChange('vat', value)}
            />
            <CustomInput
              fullWidth
              label={'Street name'}
              value={hcpData.address}
              placeholder={'Enter street name'}
              readOnly={!!(data || data !== undefined)}
              handleChange={(value: string) => handleUserDataChange('address', value)}
            />
          </FieldBox>

          <FieldBox>
            <CustomInput
              fullWidth
              label={'Street number'}
              value={hcpData.addressNum}
              placeholder={'Enter street number'}
              readOnly={!!(data || data !== undefined)}
              handleChange={(value: string) => handleUserDataChange('addressNum', value)}
            />
            <CustomInput
              fullWidth
              label={'Location'}
              value={hcpData.area}
              placeholder={'Enter location'}
              readOnly={!!(data || data !== undefined)}
              handleChange={(value: string) => handleUserDataChange('area', value)}
            />
          </FieldBox>

          <FieldBox>
            <CustomInput
              fullWidth
              label={'Postal Code'}
              value={hcpData.postalCode}
              placeholder={'Enter P.S.'}
              readOnly={!!(data || data !== undefined)}
              handleChange={(value: string) => handleUserDataChange('postalCode', value)}
            />
            <CustomInput
              fullWidth
              label={'Email'}
              value={authUserData.email}
              placeholder={'Enter email'}
              readOnly={!!(data || data !== undefined)}
              handleChange={(value: string) => handleAuthUserChange('email', value)}
            />
          </FieldBox>

          <FieldBox>
            <CustomInput
              fullWidth
              value={hcpData.phone}
              label={'Phone number'}
              placeholder={'Enter phone'}
              readOnly={!!(data || data !== undefined)}
              handleChange={(value: string) => handleUserDataChange('phone', value)}
            />
            <Box flex={1}>
              <DropdownMenu
                fullWidth
                label={'Sex'}
                value={authUserData.gender}
                menuItems={[
                  { label: 'Male', value: 'male' },
                  { label: 'Female', value: 'female' },
                ]}
                readOnly={!!(data || data !== undefined)}
                setValue={(value: string) => handleAuthUserChange('gender', value)}
              />
            </Box>
          </FieldBox>

          <FieldBox>
            <Box flex={1}>
              <DropdownMenu
                fullWidth
                label={'Specialty'}
                value={hcpData.speciality}
                menuItems={professionCategories}
                readOnly={!!(data || data !== undefined)}
                setValue={(value: string) => handleUserDataChange('speciality', value)}
              />
            </Box>
            <Box flex={1}>
              <DropdownMenu
                fullWidth
                label={'Clinical Coach'}
                menuItems={[
                  { label: 'No', value: 'no' },
                  { label: 'Yes', value: 'yes' },
                ]}
                value={hcpData.clinicalCoach ? 'yes' : 'no'}
                setValue={(value: string) => handleUserDataChange('clinicalCoach', value)}
              />
            </Box>
          </FieldBox>

          {/*<Box height={'151px'}>*/}
          {/*  <ContentDropzone*/}
          {/*    heading={'Attach profile picture'}*/}
          {/*    handleContentDrop={handleImageDrop}*/}
          {/*  />*/}
          {/*</Box>*/}
        </Box>

        <Box textAlign={'center'} padding={theme.spacing(3)} bgcolor={'#272727'}>
          <CustomButton
            onClick={handleSave}
            variant={'contained'}
            sx={{ width: '212px' }}
          >
            {data || data !== undefined ? 'Update' : 'Create'}
          </CustomButton>
        </Box>
      </Box>
    </ModalMobile>
  );
};

export default ModalAddHCP;
