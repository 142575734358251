import React, { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import useAlert from '../../../hooks/useAlert';
import ModalBaseContent from './ModalBaseContent';
import TrafficLightCircle from './TrafficLightCircle';
import { convertLabeltoEnum } from '../utils/dataConversion';
import { CustomInput, DropdownMenu, TipsTricks } from '@hdcorner/ui-library';
import {
  useEditTrafficLightMutation,
  usePostTrafficLightMutation,
} from '../queries/trafficLightQueries';
import {
  TrafficLightCategories,
  TrafficLightLevels,
  TrafficLightSections,
} from '../constants';

type Props = {
  open: boolean;
  data?: TipsTricks;
  setOpen: (open: boolean) => void;
};

const ModalAddEditTrafficLight: FC<Props> = ({ data, open, setOpen }) => {
  const { presentSuccess, presentError } = useAlert();

  const [version, setVersion] = useState('en');
  const [trafficLight, setTrafficLight] = useState<TipsTricks>({
    _id: '',
    lang: version,
    description: '',
    level: 'frequent',
    title: 'fish_meats',
    category: 'dyslipidemia',
  });

  const [postTrafficLight] = usePostTrafficLightMutation();
  const [editTrafficLight] = useEditTrafficLightMutation();

  useEffect(() => {
    if (!data) return;

    setVersion(data.lang ? data.lang : 'en');

    setTrafficLight({
      _id: data._id,
      description: data.description,
      level: convertLabeltoEnum(data.level, TrafficLightLevels),
      title: convertLabeltoEnum(data.title, TrafficLightSections),
      category: convertLabeltoEnum(data.category, TrafficLightCategories),
    });
  }, [data]);

  const handleChange = (fieldName: string, value: any) => {
    setTrafficLight(oldValue => ({
      ...oldValue,
      [fieldName]: value,
    }));
  };
  const handleSaveModal = () => {
    postTrafficLight({
      lang: version,
      title: trafficLight.title,
      level: trafficLight.level,
      category: trafficLight.category,
      description: trafficLight.description,
    })
      .unwrap()
      .then(() => {
        presentSuccess('Traffic Light added');
      })
      .catch(() => {
        presentError('Something went wrong.');
      })
      .finally(() => {
        setOpen(false);
        resetData();
      });
  };

  const handleEdit = () => {
    if (!data) return;
    editTrafficLight({
      lang: version,
      title: trafficLight.title,
      level: trafficLight.level,
      category: trafficLight.category,
      description: trafficLight.description,
      id: trafficLight._id ? trafficLight._id : '',
    })
      .unwrap()
      .then(() => {
        presentSuccess('Traffic Light updated');
      })
      .catch(() => {
        presentError('Something went wrong.');
      })
      .finally(() => {
        setOpen(false);
        resetData();
      });
  };

  const resetData = () => {
    setTrafficLight({
      _id: undefined,
      lang: 'en',
      description: '',
      level: 'frequent',
      title: 'fish_meats',
      category: 'dyslipidemia',
    });
  };

  return (
    <ModalBaseContent
      open={open}
      data={!!data}
      value={version}
      setOpen={setOpen}
      setValue={setVersion}
      category={'Traffic Lights'}
      handleSaveModal={data ? handleEdit : handleSaveModal}
    >
      <Box gap={2} display={'flex'}>
        <Box flex={1}>
          <DropdownMenu
            fullWidth
            label={'Section'}
            value={trafficLight.title}
            menuItems={TrafficLightSections}
            setValue={(value: string) => handleChange('title', value)}
          />
        </Box>
        <Box flex={1}>
          <DropdownMenu
            fullWidth
            label={'Category'}
            value={trafficLight.category}
            menuItems={TrafficLightCategories}
            setValue={(value: string) => handleChange('category', value)}
          />
        </Box>
      </Box>

      <Box flex={1} maxWidth={'48%'}>
        <DropdownMenu
          fullWidth
          label={'Traffic light'}
          value={trafficLight.level}
          menuItems={TrafficLightLevels}
          startIcon={<TrafficLightCircle color={trafficLight.level} />}
          setValue={(value: string) => handleChange('level', value)}
        />
      </Box>

      {/*TODO: replace with Rich Text Editor*/}
      <Box mb={3}>
        <CustomInput
          fullWidth
          rows={6}
          minRows={6}
          label={'Description'}
          value={trafficLight.description}
          placeholder={'Type something here'}
          handleChange={(value: string) => handleChange('description', value)}
        />
      </Box>
    </ModalBaseContent>
  );
};

export default ModalAddEditTrafficLight;
