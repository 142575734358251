import api from '../../../redux/api';
import { Activity, ApiPagination, PaginationResult } from '@hdcorner/ui-library';

const activityQueries = api
  .enhanceEndpoints({ addTagTypes: ['Activity'] })
  .injectEndpoints({
    endpoints: build => ({
      getActivities: build.query<
        PaginationResult<Activity>,
        ApiPagination & { search?: string }
      >({
        query: ({ skip, limit, sort, search }) => ({
          url: '/hdCornerService/admin/content/activity',
          method: 'GET',
          params: { skip, limit, sort, search },
        }),
        providesTags: ['Activity'],
      }),

      postActivity: build.mutation<void, Activity>({
        query: activity => ({
          url: '/hdCornerService/admin/content/activity',
          method: 'POST',
          body: activity,
        }),
        invalidatesTags: ['Activity'],
      }),

      editActivity: build.mutation<void, Activity & { id: string }>({
        query: ({ id, ...rest }) => ({
          url: `/hdCornerService/admin/content/activity/${id}`,
          method: 'PATCH',
          body: rest,
        }),
        invalidatesTags: ['Activity'],
      }),

      deleteActivity: build.mutation<void, { id: string }>({
        query: ({ id }) => ({
          url: `/hdCornerService/admin/content/activity/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Activity'],
      }),
    }),
  });

export const {
  useGetActivitiesQuery,
  usePostActivityMutation,
  useEditActivityMutation,
  useDeleteActivityMutation,
} = activityQueries;

export default activityQueries;
