import React, { FC, useEffect, useMemo } from 'react';
import moment from 'moment';
import { Box } from '@mui/material';
import { useAppSelector } from '../../../redux/hooks';
import SectionHeader from '../../../components/SectionHeader';
import { useGetStepsLogsQuery } from '../queries/stepsQueries';
import { Collapsible, FeelsEmpty, useTimeframe } from '@hdcorner/ui-library';
import useAlert from '../../../hooks/useAlert';

type Props = { userId: string };

const SectionStepsData: FC<Props> = ({ userId }) => {
  const { presentError } = useAlert();

  const { dateFilter, customDateFilter } = useAppSelector(state => state.layout);

  const timeframe = useTimeframe(
    dateFilter,
    customDateFilter?.start,
    customDateFilter?.end,
  );

  const { error, data: stepLogs } = useGetStepsLogsQuery({
    userId,
    ...timeframe,
    params: { skip: 0, limit: 1000, sort: ['-logDate'] },
  });

  useEffect(() => {
    if (error) presentError('An unexpected error occurred fetching steps data');
  }, [error]);

  const inDepthData = useMemo(() => {
    if (!stepLogs || stepLogs.documents.length === 0) return [];

    return stepLogs.documents.map(it => {
      return {
        _id: it._id,
        measure: 'steps',
        count: it.steps.toString(),
        data: {
          Steps: { value: it.steps.toString() },
          'Time of calculation': { value: moment(it.logDate).format('h:mm a') },
          'Date of calculation': { value: moment(it.logDate).format('MMMM Do, YYYY') },
        },
      };
    });
  }, [stepLogs]);

  return (
    <Box>
      <SectionHeader title={'in-depth data'} />

      <Box mt={1} gap={1} display={'flex'} flexDirection={'column'}>
        {inDepthData.map(data => (
          <Box key={data._id} width={'100%'}>
            <Collapsible data={data.data} titleSub={data.measure} title={data.count} />
          </Box>
        ))}

        {inDepthData.length === 0 && (
          <Box mb={1} width={'100%'}>
            <FeelsEmpty />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SectionStepsData;
