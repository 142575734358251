import { FC, useState } from 'react';
import { useTheme } from '@mui/material';
import PageLayout from '../../PageLayout';
import InfoTabHcp from './components/InfoTabHcp';
import DataTabHcp from './components/DataTabHcp';
import { ComponentsLayout } from '@hdcorner/ui-library';
import UserHcpInfoToolbar from '../../components/UserHcpInfoToolbar';
import { RouteComponentProps } from 'react-router';

interface Props extends RouteComponentProps<{ hcpId: string }> {}
const HcpInfoData: FC<Props> = ({ match }) => {
  const theme = useTheme();

  const [value, setValue] = useState<string>('data');

  return (
    <PageLayout>
      <ComponentsLayout display={'flex'} flexDirection={'column'} gap={theme.spacing(3)}>
        <UserHcpInfoToolbar
          hcpUser
          value={value}
          setValue={setValue}
          userId={match.params.hcpId}
        />
        {value.toLowerCase() === 'data' && <DataTabHcp hcpUserId={match.params.hcpId} />}
        {value.toLowerCase() === 'user info' && (
          <InfoTabHcp hcpUserId={match.params.hcpId} />
        )}
      </ComponentsLayout>
    </PageLayout>
  );
};

export default HcpInfoData;
