import api from '../../../redux/api';
import { ApiPagination, GetWeightLog, PaginationResult } from '@hdcorner/ui-library';

const weightQueries = api
  .enhanceEndpoints({ addTagTypes: ['GetWeightLog'] })
  .injectEndpoints({
    endpoints: build => ({
      getWeightLogs: build.query<
        PaginationResult<GetWeightLog>,
        { params: ApiPagination; start?: string; end?: string; userId: string }
      >({
        query: ({ params, start, end, userId }) => ({
          method: 'GET',
          url: '/database/function/getWeightLogs',
          params: { ...params, start, end, scope: `Weight:${userId}` },
        }),
        providesTags: ['GetWeightLog'],
      }),

      getWeightGraphData: build.query<
        GetWeightLog[],
        { start?: string; end?: string; userId: string }
      >({
        query: ({ start, end, userId }) => ({
          method: 'GET',
          params: { start, end, scope: `Weight:${userId}` },
          url: '/database/function/getWeightGraphData',
        }),
        providesTags: ['GetWeightLog'],
      }),
    }),
  });

export const { useGetWeightLogsQuery, useGetWeightGraphDataQuery } = weightQueries;

export default weightQueries;
