import { useAppSelector } from '../../redux/hooks';
import React, { useEffect, useState } from 'react';
import ContentVideoWorkoutPanel from './components/ContentVideoWorkoutPanel';
import ContentOtherVideoPanel from './components/ContentOtherVideoPanel';
import ContentProgramPanel from './components/ContentProgramPanel';

const ContentVideoPanel = () => {
  const { section } = useAppSelector(state => state.video);

  useEffect(() => {
    if (!section) return;
    setSelectedPage(section);
  }, [section]);

  const [selectedPage, setSelectedPage] = useState<string>();
  return (
    <>
      {selectedPage === 'workouts' && <ContentVideoWorkoutPanel />}
      {selectedPage === 'other' && <ContentOtherVideoPanel />}
      {selectedPage === 'programs' && <ContentProgramPanel />}
    </>
  );
};

export default ContentVideoPanel;
