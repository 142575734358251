import * as React from 'react';
import { SVGProps } from 'react';

const FinanceIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.72 3C3.32236 3 3 3.32235 3 3.72V19.9412C3 20.5259 3.47405 21 4.05882 21H20.28C20.6776 21 21 20.6776 21 20.28C21 19.8824 20.6776 19.56 20.28 19.56H4.94182C4.66467 19.56 4.44 19.3353 4.44 19.0582V3.72C4.44 3.32235 4.11765 3 3.72 3Z"
      fill={props?.color ?? '#222222'}
    />
    <path
      d="M19.92 4.44C19.92 4.83797 19.5976 5.16 19.2 5.16C18.8024 5.16 18.48 4.83797 18.48 4.44C18.48 4.23047 18.5696 4.04203 18.7127 3.91055C18.8409 3.79242 19.0121 3.72 19.2 3.72C19.5976 3.72 19.92 4.04203 19.92 4.44Z"
      fill={props?.color ?? '#222222'}
    />
    <path
      d="M14.16 5.16C14.16 5.55797 13.8376 5.88 13.44 5.88C13.0424 5.88 12.72 5.55797 12.72 5.16C12.72 4.76203 13.0424 4.44 13.44 4.44C13.6344 4.44 13.8107 4.51664 13.9403 4.6418C14.0094 4.70859 14.0651 4.78945 14.1032 4.87875C14.1178 4.9132 14.1298 4.94906 14.1389 4.98633C14.1486 5.025 14.1549 5.06508 14.1581 5.10727L14.16 5.16Z"
      fill={props?.color ?? '#222222'}
    />
    <path
      d="M14.16 10.2C14.16 10.598 13.8376 10.92 13.44 10.92C13.3194 10.92 13.2059 10.8905 13.106 10.8377C12.9601 10.7611 12.8438 10.6366 12.7782 10.4848C12.7682 10.4616 12.7594 10.4377 12.7518 10.413C12.7383 10.3695 12.729 10.3238 12.724 10.2773C12.7214 10.252 12.72 10.226 12.72 10.2C12.72 9.9743 12.8234 9.77391 12.9853 9.64172C13.1094 9.54047 13.2676 9.48 13.44 9.48C13.8376 9.48 14.16 9.80203 14.16 10.2Z"
      fill={props?.color ?? '#222222'}
    />
    <path
      d="M9.84 12.36C9.84 12.758 9.51762 13.08 9.12 13.08C8.72238 13.08 8.4 12.758 8.4 12.36C8.4 11.962 8.72238 11.64 9.12 11.64C9.29209 11.64 9.44994 11.7005 9.57369 11.801C9.73611 11.9325 9.84 12.1343 9.84 12.36Z"
      fill={props?.color ?? '#222222'}
    />
    <path
      d="M6.24 16.68C6.63762 16.68 6.96 16.358 6.96 15.96C6.96 15.562 6.63762 15.24 6.24 15.24C5.84238 15.24 5.52 15.562 5.52 15.96C5.52 16.358 5.84238 16.68 6.24 16.68Z"
      fill={props?.color ?? '#222222'}
    />
    <path
      d="M10.663 16.6723C10.6908 16.6688 10.7182 16.6631 10.7449 16.6561C10.849 16.6287 10.9439 16.5788 11.0239 16.5113C11.0942 16.4522 11.1529 16.3798 11.1963 16.2975C11.2498 16.197 11.28 16.0823 11.28 15.96C11.28 15.562 10.9576 15.24 10.56 15.24C10.1624 15.24 9.84 15.562 9.84 15.96C9.84 16.1611 9.92244 16.3432 10.0555 16.474C10.1854 16.6013 10.3635 16.68 10.56 16.68C10.595 16.68 10.6293 16.6772 10.663 16.6723Z"
      fill={props?.color ?? '#222222'}
    />
    <path
      d="M14.88 14.52C15.2776 14.52 15.6 14.198 15.6 13.8C15.6 13.402 15.2776 13.08 14.88 13.08C14.4824 13.08 14.16 13.402 14.16 13.8C14.16 14.198 14.4824 14.52 14.88 14.52Z"
      fill={props?.color ?? '#222222'}
    />
    <path
      d="M18.48 10.92C18.6751 10.92 18.8521 10.8427 18.9819 10.7161C19.1165 10.5853 19.2 10.4025 19.2 10.2C19.2 9.80203 18.8776 9.48 18.48 9.48C18.271 9.48 18.0827 9.5693 17.9513 9.71133C17.8326 9.84 17.76 10.0116 17.76 10.2C17.76 10.598 18.0824 10.92 18.48 10.92Z"
      fill={props?.color ?? '#222222'}
    />
    <path
      d="M17.04 6.6C17.4376 6.6 17.76 6.27797 17.76 5.88C17.76 5.48203 17.4376 5.16 17.04 5.16C16.6424 5.16 16.32 5.48203 16.32 5.88C16.32 6.27797 16.6424 6.6 17.04 6.6Z"
      fill={props?.color ?? '#222222'}
    />
    <path
      d="M9.84 8.04C10.2376 8.04 10.56 7.71797 10.56 7.32C10.56 6.92203 10.2376 6.6 9.84 6.6C9.44238 6.6 9.12 6.92203 9.12 7.32C9.12 7.54359 9.22195 7.74398 9.38209 7.87547C9.41268 7.90078 9.44537 7.92398 9.48 7.94367C9.586 8.00484 9.70887 8.04 9.84 8.04Z"
      fill={props?.color ?? '#222222'}
    />
    <path
      d="M17.613 16.3959C17.4816 16.5689 17.2738 16.68 17.04 16.68C16.6424 16.68 16.32 16.358 16.32 15.96C16.32 15.562 16.6424 15.24 17.04 15.24C17.4376 15.24 17.76 15.562 17.76 15.96C17.76 16.1238 17.7052 16.275 17.613 16.3959Z"
      fill={props?.color ?? '#222222'}
    />
  </svg>
);

export default FinanceIcon;
