import {
  Autocomplete,
  Box,
  CircularProgress,
  createFilterOptions,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useGetArticlesCategoriesQuery } from '../features/content/queries/articleQueries';

const filter = createFilterOptions<{
  label: string;
  value: string;
  inputValue?: string;
}>();

type Props = {
  label: string;
  value: string;
  onChangeValue: (value: string) => void;
};
const AutocompleteDropdown: FC<Props> = ({ label, value, onChangeValue }) => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [localValue, setLocalValue] = useState<{
    label: string;
    value: string;
    inputValue?: string;
  } | null>(null);

  const { data: categoriesRes, isLoading: loading } = useGetArticlesCategoriesQuery();

  useEffect(() => {
    if (!categoriesRes) return;
    if (!value) return;
    const category = categoriesRes.categories.find(category => category === value);
    if (!category) {
      setLocalValue({
        label: value.charAt(0).toUpperCase() + value.slice(1),
        value: value,
      });
    }
  }, [categoriesRes, value]);

  const categoriesValues = useMemo(() => {
    if (!categoriesRes) return [];
    return categoriesRes.categories.map(category => ({
      label: category,
      value: category,
      inputValue: '',
    }));
  }, [categoriesRes]);

  return (
    <Box display={'flex'} flexDirection={'column'} gap={1}>
      <Box>
        <Typography
          variant={'subtitle2'}
          fontWeight={'fontWeightBold'}
          sx={{
            color:
              theme.palette.mode === 'dark'
                ? theme.palette.kmColorsCoolGrey.main
                : theme.palette.primary.main,
          }}
        >
          {label}
        </Typography>
      </Box>
      <Box>
        <Autocomplete
          freeSolo
          fullWidth
          open={open}
          loading={loading}
          options={categoriesValues}
          getOptionLabel={option => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Regular option
            return option.label;
          }}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          value={localValue}
          onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
              onChangeValue(newValue);
            } else if (newValue && newValue.value) {
              // Create a new value from the user input
              onChangeValue(newValue.value);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some(
              option => inputValue.toLowerCase() === option.value.toLowerCase(),
            );
            if (inputValue !== '' && !isExisting) {
              filtered.push({
                inputValue,
                value: inputValue,
                label: `Add "${inputValue}"`,
              });
            }
            return filtered;
          }}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          renderInput={params => (
            <TextField
              placeholder={'+ Add category'}
              sx={{
                '& .MuiInputBase-root': {
                  maxHeight: '44px',
                  background: '#282828',
                  padding: theme.spacing(1, 2),
                },

                '& fieldset': {
                  border: `1px ${theme.palette.secondary.main} solid`,
                },
              }}
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default AutocompleteDropdown;
