import { FC, useEffect, useMemo, useState } from 'react';
import moment from 'moment/moment';
import ModalAddHCP from './ModalAddHCP';
import { styled } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';
import { HealthProfessionalsData, InfoCards } from '@hdcorner/ui-library';
import { professionCategories } from '../constants';
import { HcpAuthUser } from '../types/HcpAuthUser';
import SectionHeader from '../../../components/SectionHeader';
import { useGetHCPDetailsQuery } from '../queries/hcpQueries';
import { convertEnumValue } from '../../content/utils/dataConversion';
import useAlert from '../../../hooks/useAlert';

const SectionBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
}));

type Props = {
  hcpUserId: string;
};

const InfoTabHcp: FC<Props> = ({ hcpUserId }) => {
  const { presentError } = useAlert();

  const [open, setOpen] = useState<boolean>(false);
  const [user, setUser] = useState<
    | (HcpAuthUser & Omit<HealthProfessionalsData, '_id' | 'verified' | 'authUser'>)
    | undefined
  >(undefined);

  const { error, data: hcpRes } = useGetHCPDetailsQuery({
    id: hcpUserId,
    skip: 0,
    limit: 1000,
  });

  useEffect(() => {
    if (error)
      presentError('An unexpected error occurred fetching healthcare professional data');
  }, [error]);

  const hcpData = useMemo(() => {
    if (!hcpRes) return;
    const hcp = hcpRes.healthProfessionalData;
    const auth = hcpRes.healthProfessionalData.authUser;

    setUser({
      vat: hcp.vat,
      area: hcp.area,
      code: hcp.code,
      phone: hcp.phone,
      address: hcp.address,
      postalCode: hcp.postalCode,
      addressNum: hcp.addressNum,
      gender: hcp.authUser.gender,
      role: auth ? auth.role : 'hcp',
      clinicalCoach: hcp.clinicalCoach,
      lastName: auth ? auth.lastName : '',
      firstName: auth ? auth.firstName : '',
      email: auth && auth.email ? auth.email : '',
      speciality: convertEnumValue(hcp.speciality, professionCategories),
    });
    return {
      personal: [
        {
          category: 'Name',
          text: auth
            ? `${auth.firstName ? auth.firstName : ''} ${
                auth.lastName ? auth.lastName : ''
              }`
            : '',
        },
        { category: 'Sex', text: auth && auth.gender ? auth.gender : '' },
        { category: 'Email', text: auth && auth.email ? auth.email : '' },
        { category: 'VAT registration number', text: hcp.vat },
        { category: 'Street name', text: hcp.address },
        { category: 'Street number', text: hcp.addressNum },
        { category: 'Location', text: hcp.area },
        { category: 'P.S.', text: hcp.postalCode },
        { category: 'Phone', text: hcp.phone },
        {
          category: 'Specialty',
          text: convertEnumValue(hcp.speciality, professionCategories),
        },
        { category: 'Clinical Coach', text: 'No' },
      ],

      account: [
        { category: 'Code', text: hcp.code },
        {
          category: 'Account created',
          text: hcp.createdAt ? moment(hcp.createdAt).format('DD/MM/YYYY') : 'N/A',
        },
        {
          category: 'Last login',
          text: hcpRes.latestLogin
            ? moment(hcpRes.latestLogin).format('DD/MM/YYYY')
            : 'N/A',
        },
      ],
    };
  }, [hcpRes]);

  return (
    <Box gap={3} display={'flex'} flexDirection={'column'}>
      <Box>
        <SectionHeader title={'personal info'} />
        <SectionBox>
          {hcpData &&
            hcpData.personal.map((it, index) => (
              <InfoCards
                key={index}
                text={it.text}
                category={it.category}
                handleEditField={() => setOpen(true)}
              />
            ))}
        </SectionBox>
      </Box>

      <Box>
        <SectionHeader title={'account info'} />
        <SectionBox>
          {hcpData &&
            hcpData.account.map((it, index) => (
              <InfoCards key={index} text={it.text} category={it.category} />
            ))}
        </SectionBox>
      </Box>

      <ModalAddHCP open={open} setOpen={setOpen} data={user} />
    </Box>
  );
};

export default InfoTabHcp;
