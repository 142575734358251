import { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import { useAppSelector } from '../../../redux/hooks';
import FitnessPanelNutrition from './FitnessPanelNutrition';
import FitnessPanelExercise from './FitnessPanelExercise';
import FitnessPanelWellness from './FitnessPanelWellness';

interface Props extends RouteComponentProps<{ userId: string }> {}

const DataPanelFitness: FC<Props> = ({ match }) => {
  const { fitness } = useAppSelector(state => state.userDataPage);
  return (
    <>
      {fitness.page === 'nutrition' && (
        <FitnessPanelNutrition userId={match.params.userId} />
      )}
      {fitness.page === 'wellness' && (
        <FitnessPanelWellness userId={match.params.userId} />
      )}
      {fitness.page === 'exercise' && (
        <FitnessPanelExercise userId={match.params.userId} />
      )}
    </>
  );
};

export default DataPanelFitness;
