import { FC, useMemo } from 'react';
import DownloadButton from '../../../components/DownloadButton';
import ToolbarContainer from '../../../components/ToolbarContainer';
import { usePostHCPVerificationMutation } from '../queries/hcpQueries';
import VerificationButton from '../../../components/VerificationButton';
import { CalendarIcon, CustomButton, DeleteCircleIcon } from '@hdcorner/ui-library';
import { Box, BoxProps, IconButton, styled, Typography, useTheme } from '@mui/material';
import moment from 'moment/moment';
import useAlert from '../../../hooks/useAlert';

const DateBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  alignItems: 'center',
  boxSizing: 'border-box',
  gap: theme.spacing(1),
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
}));

type Props = {
  selected: string[];
  subscriptionType: string;
  handleDownload: () => void;
  handleClearDate: () => void;
  handleCustomDate: () => void;
  handleClearSelection: () => void;
  dates: { startDate: string; endDate: string };
};
const HealthProfMainToolbar: FC<Props> = ({
  dates,
  selected,
  handleDownload,
  handleClearDate,
  subscriptionType,
  handleCustomDate,
  handleClearSelection,
}) => {
  const theme = useTheme();
  const { presentSuccess, presentError } = useAlert();

  const [verifyUser] = usePostHCPVerificationMutation();

  const handleVerification = (verify: boolean) => {
    verifyUser({
      ids: selected,
      revoke: verify,
    })
      .unwrap()
      .then(() => {
        presentSuccess(`Verification ${verify ? 'revoked' : 'granted'}`);
        handleClearSelection();
      })
      .catch(() => presentError('Something went wrong.'));
  };

  const getSubscriptionType = useMemo(() => {
    if (subscriptionType === 'monthly') {
      return 'Monthly';
    } else if (subscriptionType === 'annually') {
      return 'Annually';
    } else if (subscriptionType === 'bi-annually') {
      return 'Bi-Annually';
    }

    return '-';
  }, [subscriptionType]);

  return (
    <ToolbarContainer sx={{ justifyContent: 'space-between' }}>
      <Box gap={2} display={'flex'} alignItems={'center'}>
        <CustomButton
          variant={'outlined'}
          onClick={handleCustomDate}
          style={{
            height: '40px',
            border: `1px solid ${theme.palette.kmColorsCoolGrey.main}`,
          }}
        >
          <Box gap={1} display={'flex'} alignItems={'center'}>
            <CalendarIcon color={theme.palette.kmColorsCoolGrey.main} />
            <Typography
              variant={'subtitle2'}
              sx={{
                color: theme.palette.kmColorsCoolGrey.main,
                fontWeight: theme.typography.fontWeightBold,
              }}
            >
              Custom Date
            </Typography>
          </Box>
        </CustomButton>
        {dates.startDate !== '' && dates.endDate !== '' && subscriptionType !== '' && (
          <DateBox>
            <Box>
              <Typography
                variant={'body2'}
                fontWeight={'bold'}
                sx={{ color: theme.palette.kmColorsTurquoise.main }}
              >
                {getSubscriptionType}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant={'body2'}
                sx={{ color: theme.palette.kmColorsTurquoise.main }}
              >{`Starting date: ${moment(dates.startDate).format(
                'DD/MM/YYYY',
              )}`}</Typography>
              <Typography
                variant={'body2'}
                sx={{ color: theme.palette.kmColorsTurquoise.main }}
              >{`End date: ${moment(dates.endDate).format('DD/MM/YYYY')}`}</Typography>
            </Box>
            <IconButton onClick={handleClearDate} sx={{ padding: 0 }}>
              <DeleteCircleIcon color={theme.palette.kmColorsTurquoise.main} />
            </IconButton>
          </DateBox>
        )}
      </Box>
      <Box gap={2} display={'flex'} alignItems={'center'}>
        <DownloadButton handleDownload={handleDownload} />
        <VerificationButton
          verify
          disabled={selected.length === 0}
          onClick={() => handleVerification(false)}
        />
        <VerificationButton
          verify={false}
          disabled={selected.length === 0}
          onClick={() => handleVerification(true)}
        />
      </Box>
    </ToolbarContainer>
  );
};

export default HealthProfMainToolbar;
