import api from '../../../redux/api';
import { ApiPagination, PaginationResult } from '@hdcorner/ui-library';
import { PostProgram, Program } from '../types/Program';

const programQueries = api
  .enhanceEndpoints({ addTagTypes: ['Program'] })
  .injectEndpoints({
    endpoints: build => ({
      getProgram: build.query<
        PaginationResult<Program>,
        ApiPagination & { bodyPart?: string; workoutType?: string; avatar: string }
      >({
        query: ({ skip, limit, sort, bodyPart, workoutType, avatar }) => ({
          url: '/hdCornerService/admin/content/workout',
          method: 'GET',
          params: {
            skip,
            limit,
            sort,
            avatar,
            bodyPart,
            workoutType,
            populate: [
              'exercises',
              'exercises.thumbnail.en',
              'exercises.thumbnail.el',
              'exercises.attachedVideo.en',
              'exercises.attachedVideo.el',
            ],
          },
        }),
        providesTags: ['Program'],
      }),

      postProgram: build.mutation<void, PostProgram>({
        query: program => ({
          url: '/hdCornerService/admin/content/workout',
          method: 'POST',
          body: program,
        }),
        invalidatesTags: ['Program'],
      }),

      editProgram: build.mutation<void, Partial<PostProgram> & { id: string }>({
        query: ({ id, ...rest }) => ({
          url: `/hdCornerService/admin/content/workout/${id}`,
          method: 'PATCH',
          body: rest,
        }),
        invalidatesTags: ['Program'],
      }),

      deleteProgram: build.mutation<void, { id: string }>({
        query: ({ id }) => ({
          url: `/hdCornerService/admin/content/workout/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Program'],
      }),
    }),
  });

export const {
  useGetProgramQuery,
  usePostProgramMutation,
  useEditProgramMutation,
  useDeleteProgramMutation,
} = programQueries;

export default programQueries;
