import React, { FC, useCallback, useEffect, useMemo } from 'react';
import moment from 'moment/moment';
import LipidGroupDetails from './LipidGroupDetails';
import { useAppSelector } from '../../../redux/hooks';
import {
  LipidGoal,
  LipidLog,
  minMaxAvg,
  minMaxObj,
  useTimeframe,
} from '@hdcorner/ui-library';
import {
  useGetLipidFirstLogQuery,
  useGetLipidGoalQuery,
  useGetLipidGraphDataQuery,
  useGetLipidLastLogQuery,
  useGetLipidLogsQuery,
  useGetOverallLipidQuery,
} from '../queries/lipidQueries';
import useAlert from '../../../hooks/useAlert';

type Props = {
  userId: string;
};
const LpaSection: FC<Props> = ({ userId }) => {
  const { presentError } = useAlert();

  const { dateFilter, customDateFilter } = useAppSelector(state => state.layout);

  const timeframe = useTimeframe(
    dateFilter,
    customDateFilter?.start,
    customDateFilter?.end,
  );

  const { data: lastLog, error: errorLastLog } = useGetLipidLastLogQuery({ userId });
  const { data: lpaGoalRes, error: errorGoal } = useGetLipidGoalQuery('lpa');
  const { data: lipidOverallData, error: errorOverall } = useGetOverallLipidQuery({
    ...timeframe,
    userId,
  });
  const { data: lipidLogs } = useGetLipidLogsQuery({
    params: { skip: 0, limit: 1000, sort: ['-logDate'] },
    userId,
    ...timeframe,
  });
  const { data: chartDataRes, error: errorGraph } = useGetLipidGraphDataQuery({
    userId,
    params: { metric: 'lpa', ...timeframe },
  });

  const { data: lpaFirstLog, error: errorFirstLog } = useGetLipidFirstLogQuery(
    {
      startingDate:
        lpaGoalRes && lpaGoalRes.length > 0
          ? (lpaGoalRes as LipidGoal[])[0].startDate
          : '',
      userId,
    },
    { skip: lpaGoalRes ? lpaGoalRes.length === 0 : true },
  );

  useEffect(() => {
    if (errorGoal) presentError('An unexpected error occurred fetching lpa goal data');
    if (errorGraph) presentError('An unexpected error occurred fetching lpa graph data');
    if (errorOverall)
      presentError('An unexpected error occurred fetching lpa overall data');
    if (errorFirstLog || errorLastLog)
      presentError('An unexpected error occurred fetching lpa log data');
  }, [errorFirstLog, errorGraph, errorOverall, errorLastLog]);

  const getOverallData = useCallback(() => {
    return minMaxAvg(lipidOverallData, 'lpa');
  }, [lipidOverallData]);

  const goalData = useMemo(() => {
    const lpaGoal = lpaGoalRes ? lpaGoalRes[0] : null;
    const lastLogItem = lastLog ? lastLog.documents[0] : null;
    const lpaFirst = lpaFirstLog ? lpaFirstLog.documents[0] : null;

    let progress;

    if (lpaGoal && lpaFirst && lastLogItem) {
      const currentMeasurement = lastLogItem.totalChol || 0;
      const startingMeasurement = lpaFirst.totalChol || 0;
      const goalMeasurement = parseFloat(lpaGoal.goal.measurement) || 0;
      progress = Math.round(
        ((currentMeasurement - startingMeasurement) /
          (goalMeasurement - startingMeasurement)) *
          100,
      );
      if (progress > 100) progress = 100;
    }

    return {
      goal: lpaGoal ? parseFloat(lpaGoal.goal.measurement) : undefined,
      startingMeasurement: lpaFirst ? lpaFirst.lpa : undefined,
      latestMeasurement: lastLogItem ? lastLogItem.lpa : undefined,
    };
  }, [lpaFirstLog, lpaGoalRes, lastLog]);

  const chartData = useMemo(() => {
    if (chartDataRes) {
      const logs = chartDataRes.logs;
      if (!logs) return [];

      return logs.map((log: LipidLog) => {
        return {
          date: log.logDate,
          value: log.lpa,
        };
      });
    }
    return [];
  }, [chartDataRes]);

  const overallData = useMemo(() => {
    return minMaxObj('LPA', getOverallData);
  }, [getOverallData]);

  const inDepthData = useMemo(() => {
    if (lipidLogs && lipidLogs.documents.length > 0) {
      return lipidLogs.documents
        .filter(log => log.lpa)
        .map((log: LipidLog) => {
          return {
            title: log.lpa,
            titleSub: 'mg/dl',
            data: {
              LPA: { value: `${log.lpa} mg/dl` },
              'Time of calculation': { value: moment(log.logDate).format('h:mm A') },
              'Date of calculation': {
                value: moment(log.logDate).format('MMM Do, YYYY'),
              },
            },
          };
        });
    }
    return [];
  }, [lipidLogs]);

  return (
    <LipidGroupDetails
      sectionName={'LPA'}
      goalData={goalData}
      chartData={chartData}
      inDepthData={inDepthData}
      overallData={overallData}
    />
  );
};

export default LpaSection;
