import api from '../../../redux/api';
import { ApiPagination, PaginationResult, Popup } from '@hdcorner/ui-library';

const popupQueries = api.enhanceEndpoints({ addTagTypes: ['Popup'] }).injectEndpoints({
  endpoints: build => ({
    getPopup: build.query<PaginationResult<Popup>, ApiPagination>({
      query: ({ skip, limit, sort }) => ({
        url: '/hdCornerService/admin/content/info-message',
        method: 'GET',
        params: { skip, limit, sort },
      }),
      providesTags: ['Popup'],
    }),

    postPopup: build.mutation<void, Popup>({
      query: popup => ({
        url: '/hdCornerService/admin/content/info-message',
        method: 'POST',
        body: popup,
      }),
      invalidatesTags: ['Popup'],
    }),

    editPopup: build.mutation<void, Popup & { id: string }>({
      query: ({ id, ...rest }) => ({
        url: `/hdCornerService/admin/content/info-message/${id}`,
        method: 'PATCH',
        body: rest,
      }),
      invalidatesTags: ['Popup'],
    }),

    deletePopup: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/hdCornerService/admin/content/info-message/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Popup'],
    }),
  }),
});

export const {
  useGetPopupQuery,
  usePostPopupMutation,
  useEditPopupMutation,
  useDeletePopupMutation,
} = popupQueries;

export default popupQueries;
