import api from '../../../redux/api';
import { UserAverages } from '../types/UserAverages';

const userAverageQueries = api.enhanceEndpoints({}).injectEndpoints({
  endpoints: build => ({
    getUserAverages: build.query<UserAverages, void>({
      query: () => ({
        url: '/hdCornerService/admin/dashboard/user-averages',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetUserAveragesQuery } = userAverageQueries;

export default userAverageQueries;
