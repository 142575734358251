import { useMemo } from 'react';
import { ChatRoom, SupportChatRoomType } from '@hdcorner/ui-library';

const prepareChatRoomData = (supportRoom?: SupportChatRoomType): ChatRoom | undefined =>
  supportRoom
    ? {
        group: false,
        id: supportRoom._id,
        title: supportRoom.user.firstName ?? '-',
        avatar: supportRoom.user.profilePicture?.url ?? '',
        unreadMessages: supportRoom.chatRoom?.unreadMessageCount ?? 0,
        lastMessage: supportRoom.chatRoom?.lastMessage?.message ?? supportRoom.message,
        lastMessageDate:
          supportRoom.chatRoom?.lastMessage?.createdAt ?? supportRoom.createdAt,
      }
    : undefined;

export const usePrepareChatRoomData = (
  supportRoom?: SupportChatRoomType,
): ChatRoom | undefined => {
  return useMemo(() => prepareChatRoomData(supportRoom), [supportRoom]);
};

export const usePrepareChatRoomListData = (
  supportRooms?: SupportChatRoomType[],
): ChatRoom[] => {
  return useMemo(
    () =>
      supportRooms
        ?.map(chatRoom => prepareChatRoomData(chatRoom))
        ?.filter((chatRoom): chatRoom is ChatRoom => !!chatRoom) ?? [],
    [supportRooms],
  );
};
