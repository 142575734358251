import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: {
  searchText: string;
} = {
  searchText: '',
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    updateSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    clearSearchText: state => {
      state.searchText = '';
    },
  },
});

export const { clearSearchText, updateSearchText } = searchSlice.actions;

export default searchSlice.reducer;
