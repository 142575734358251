import React, { FC, useEffect, useMemo } from 'react';
import moment from 'moment/moment';
import { Box } from '@mui/material';
import useAlert from '../../../hooks/useAlert';
import { useAppSelector } from '../../../redux/hooks';
import SectionHeader from '../../../components/SectionHeader';
import { InDepthDataCard, LipidChartCard, useTimeframe } from '@hdcorner/ui-library';
import {
  useGetLipidGraphDataQuery,
  useGetOverallLipidQuery,
} from '../queries/lipidQueries';
import { weekMonthYearCustom } from '../../toggleHeadings';

type Props = {
  userId: string;
};

const LipidsPanelAll: FC<Props> = ({ userId }) => {
  const { presentError } = useAlert();

  const { dateFilter, customDateFilter } = useAppSelector(state => state.layout);

  const timeframe = useTimeframe(
    dateFilter,
    customDateFilter?.start,
    customDateFilter?.end,
  );

  const { data: overallData, error: errorOverall } = useGetOverallLipidQuery({
    userId,
    ...timeframe,
  });
  const {
    data: lipidGraphData,
    error: errorGraph,
    error: lipidGraphError,
  } = useGetLipidGraphDataQuery({
    params: { metric: undefined, ...timeframe },
    userId,
  });

  useEffect(() => {
    if (errorGraph) presentError('An unexpected error occurred fetching graph data');
    if (errorOverall) presentError('An unexpected error occurred fetching overall data');
  }, [errorGraph, errorOverall]);

  useEffect(() => {
    if (lipidGraphError) {
      presentError('Something went wrong');
    }
  }, [lipidGraphError]);

  const chartData = useMemo(() => {
    if (!lipidGraphData) return;

    let totalChol: any[] = [],
      triglycerides: any[] = [],
      hdl: any[] = [],
      ldl: any[] = [],
      lpa: any[] = [];
    lipidGraphData.logs.forEach(item => {
      if (item.totalChol) {
        totalChol.push({
          x: moment(item.logDate).toISOString(),
          y: item.totalChol,
        });
      }
      if (item.triglycerides) {
        triglycerides.push({
          x: moment(item.logDate).toISOString(),
          y: item.triglycerides,
        });
      }
      if (item.hdl) {
        hdl.push({
          x: moment(item.logDate).toISOString(),
          y: item.hdl,
        });
      }
      if (item.ldl) {
        ldl.push({
          x: moment(item.logDate).toISOString(),
          y: item.ldl,
        });
      }
      if (item.lpa) {
        lpa.push({
          x: moment(item.logDate).toISOString(),
          y: item.lpa,
        });
      }
    });

    return [
      {
        name: 'Total Chol',
        data: totalChol,
      },
      {
        name: 'Triglycerides',
        data: triglycerides,
      },
      {
        name: 'HDL',
        data: hdl,
      },
      {
        name: 'LDL',
        data: ldl,
      },
      {
        name: 'Lp(a)',
        data: lpa,
      },
    ];
  }, [lipidGraphData]);

  const chartOverallValues = useMemo(() => {
    if (!overallData) return;

    return [
      { label: 'AVG CHOL', value: Math.round(overallData.totalChol?.avg) || '-' },
      { label: 'AVG HDL', value: Math.round(overallData.hdl?.avg) || '-' },
      { label: 'AVG LDL', value: Math.round(overallData.ldl?.avg) || '-' },
      { label: 'AVG TRG', value: Math.round(overallData.triglycerides?.avg) || '-' },
      { label: 'AVG LPA', value: Math.round(overallData.lpa?.avg) || '-' },
    ];
  }, [overallData]);

  const tableData = useMemo(() => {
    if (!overallData) return;

    return [
      {
        name: 'Chol',
        min: Math.round(overallData.totalChol?.min) || '-',
        max: Math.round(overallData.totalChol?.max) || '-',
        avg: Math.round(overallData.totalChol?.avg) || '-',
      },
      {
        name: 'TRG',
        min: Math.round(overallData.triglycerides?.min) || '-',
        max: Math.round(overallData.triglycerides?.max) || '-',
        avg: Math.round(overallData.triglycerides?.avg) || '-',
      },
      {
        name: 'HDL',
        min: Math.round(overallData.hdl?.min) || '-',
        max: Math.round(overallData.hdl?.max) || '-',
        avg: Math.round(overallData.hdl?.avg) || '-',
      },
      {
        name: 'LDL',
        min: Math.round(overallData.ldl?.min) || '-',
        max: Math.round(overallData.ldl?.max) || '-',
        avg: Math.round(overallData.ldl?.avg) || '-',
      },
      {
        name: 'LPA',
        min: Math.round(overallData.lpa?.min) || '-',
        max: Math.round(overallData.lpa?.max) || '-',
        avg: Math.round(overallData.lpa?.avg) || '-',
      },
    ];
  }, [overallData]);

  return (
    <Box flex={1} display={'flex'} flexDirection={'row'} gap={3}>
      <Box flex={1}>
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <SectionHeader title={'all measurements'} />
          <LipidChartCard
            chartData={chartData}
            toggleButtonsValue={''}
            values={chartOverallValues}
            headings={weekMonthYearCustom}
            handleChangeOption={() => console.log('')}
          />
        </Box>
      </Box>
      <Box flex={1}>
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <SectionHeader title={'Overall Data'} />
          <InDepthDataCard
            profApp
            rows={tableData}
            measurement={'mg/dl'}
            headings={['Min.', 'Max.', 'Avg.']}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default LipidsPanelAll;
