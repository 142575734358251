import { FC } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { SwitchButton } from '@hdcorner/ui-library';

export type Props = {
  label: string;
  checked: boolean;
  setChecked: (checked: boolean) => void;
};

const PopupSliders: FC<Props> = ({ label, checked, setChecked }) => {
  const theme = useTheme();

  return (
    <Box
      flexGrow={1}
      display={'flex'}
      alignContent={'center'}
      justifyContent={'space-between'}
    >
      <Typography
        color={'primary'}
        variant={'subtitle2'}
        sx={{ fontWeight: theme.typography.fontWeightMedium }}
      >
        {label}
      </Typography>
      <SwitchButton checked={checked} setChecked={setChecked} />
    </Box>
  );
};

export default PopupSliders;
