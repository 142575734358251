import React, { FC, useEffect, useMemo, useState } from 'react';
import moment from 'moment/moment';
import { Box, useTheme } from '@mui/material';
import { Collapsible, FeelsEmpty } from '@hdcorner/ui-library';
import InfiniteScrollList from '../../../components/InifiniteScrollList';
import { useGetHypertensionLogsQuery } from '../queries/hypertensionQueries';
import useAlert from '../../../hooks/useAlert';

type Props = {
  userId: string;
  endParam?: string;
  startParam?: string;
  selectedCategory?: string;
};
const LogsList: FC<Props> = ({
  userId,
  startParam,
  endParam,
  selectedCategory = 'all',
}) => {
  const theme = useTheme();
  const { presentError } = useAlert();

  const [hypertensionParams, setHypertensionParams] = useState({
    skip: 0,
    limit: 1000,
  });

  const {
    error,
    data: hypertensionLogs,
    isFetching: hypertensionIsFetching,
  } = useGetHypertensionLogsQuery({
    userId,
    end: endParam ? endParam : undefined,
    start: startParam ? startParam : undefined,
    params: { ...hypertensionParams, sort: ['-logDate'] },
    category: selectedCategory === 'all' ? undefined : selectedCategory,
  });

  useEffect(() => {
    if (error) presentError('An unexpected error occurred fetching hypertension data');
  }, [error]);

  const inDepthData = useMemo(() => {
    if (!hypertensionLogs) return [];

    return hypertensionLogs.documents.map(log => {
      return {
        titleSub: 'kcal',
        title: moment(log.logDate).format('MMMM Do, YYYY'),
        data: {
          'Systolic (mm HG)': { value: log.systolic.toString() },
          'Diastolic (mm HG)': { value: log.diastolic.toString() },
          Pulses: { value: log.pulses.toString() },
          Measurement: { value: log.category },
          Afib: { value: log.afib ? 'Yes' : '-' },
          'Time of calculation': { value: moment(log.logDate).format('h:mm a') },
          'Date of calculation': {
            value: moment(log.logDate).format('MMMM Do, YYYY'),
          },
        },
      };
    });
  }, [hypertensionLogs]);

  return (
    <Box>
      <InfiniteScrollList
        skip={hypertensionParams.skip}
        loading={hypertensionIsFetching}
        limit={hypertensionParams.limit}
        totalCount={hypertensionLogs?.count}
        onLoadMore={(skip, limit) => setHypertensionParams({ skip, limit })}
      >
        {inDepthData.map((data, index) => (
          <Box
            key={`${data.title}-${index}`}
            sx={{ marginBottom: theme.spacing(1), width: '100%' }}
          >
            <Collapsible title={data.title} data={data.data} />
          </Box>
        ))}
      </InfiniteScrollList>
      {inDepthData.length === 0 && (
        <Box sx={{ marginBottom: theme.spacing(1), width: '100%' }}>
          <FeelsEmpty />
        </Box>
      )}
    </Box>
  );
};

export default LogsList;
