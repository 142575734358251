import api from '../../../redux/api';
import { ApiPagination, Food, PaginationResult } from '@hdcorner/ui-library';

const foodQueries = api.enhanceEndpoints({ addTagTypes: ['Food'] }).injectEndpoints({
  endpoints: build => ({
    getFoods: build.query<PaginationResult<Food>, ApiPagination & { search?: string }>({
      query: ({ skip, limit, sort, search }) => ({
        url: '/hdCornerService/admin/content/food',
        method: 'GET',
        params: { skip, limit, sort, search },
      }),
      providesTags: ['Food'],
    }),

    postFood: build.mutation<void, Food>({
      query: food => ({
        url: '/hdCornerService/admin/content/food',
        method: 'POST',
        body: food,
      }),
      invalidatesTags: ['Food'],
    }),

    editFood: build.mutation<void, Food & { id: string }>({
      query: ({ id, ...rest }) => ({
        url: `/hdCornerService/admin/content/food/${id}`,
        method: 'PATCH',
        body: rest,
      }),
      invalidatesTags: ['Food'],
    }),

    deleteFood: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/hdCornerService/admin/content/food/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Food'],
    }),
  }),
});

export const {
  useGetFoodsQuery,
  usePostFoodMutation,
  useEditFoodMutation,
  useDeleteFoodMutation,
} = foodQueries;

export default foodQueries;
