import React, { FC, useEffect, useMemo } from 'react';
import { Box, useTheme } from '@mui/material';
import { useAppSelector } from '../../../redux/hooks';
import SectionHeader from '../../../components/SectionHeader';
import { useGetWeightGraphDataQuery } from '../queries/weightQueries';
import {
  BMIIndicator,
  calculateAxisTotal,
  calculateBMI,
  categoryBMI,
  ChartCard,
  FeelsEmpty,
  LineChart,
  prepareGraphData,
  useTimeframe,
} from '@hdcorner/ui-library';
import { useGetUserDetailsQuery } from '../queries/usersQueries';
import useAlert from '../../../hooks/useAlert';
import { weekMonthYearCustom } from '../../toggleHeadings';

type Props = { userId: string };

const ChartWeight: FC<Props> = ({ userId }) => {
  const theme = useTheme();
  const { presentError } = useAlert();

  const { dateFilter, customDateFilter } = useAppSelector(state => state.layout);

  const timeframe = useTimeframe(
    dateFilter,
    customDateFilter?.start,
    customDateFilter?.end,
  );

  const { data: userData, error: errorUser } = useGetUserDetailsQuery({ id: userId });
  const { data: graphData, error: errorGraph } = useGetWeightGraphDataQuery({
    userId,
    ...timeframe,
  });

  useEffect(() => {
    if (errorUser) presentError('An unexpected error occurred fetching user data');
    if (errorGraph) presentError('An unexpected error occurred fetching graph data');
  }, [errorUser, errorGraph]);

  const weightData = prepareGraphData(graphData, 'weight');

  const userHeight = useMemo(() => {
    if (!userData) return 0;
    return userData.data.height || 0;
  }, [userData]);

  const weight = calculateAxisTotal(weightData) / weightData.length;
  const BMI = calculateBMI(userHeight, weight);

  return (
    <Box display={'flex'} flex={1} flexDirection={'column'} mt={theme.spacing(2)}>
      <SectionHeader title={'weight'} />
      <Box>
        <ChartCard
          admin
          unit={'kg'}
          headings={weekMonthYearCustom}
          amount={weight ? weight.toFixed(2).toString() : '-'}
          BMIIcon={
            BMI ? (
              <BMIIndicator bmi={BMI.toString()} weight={categoryBMI(Number(BMI))} />
            ) : null
          }
        >
          {!weightData.length || !weightData ? (
            <FeelsEmpty />
          ) : (
            <LineChart chartData={weightData} colors={['#C41168']} />
          )}
        </ChartCard>
      </Box>
    </Box>
  );
};

export default ChartWeight;
