import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: {
  category: string;
  sort: string;
} = {
  category: 'all',
  sort: '-createdAt',
};

const articleSlice = createSlice({
  name: 'article',
  initialState,
  reducers: {
    changeCategory: (state, action: PayloadAction<string>) => {
      state.category = action.payload;
    },
    changeSort: (state, action: PayloadAction<string>) => {
      state.sort = action.payload;
    },
  },
});

export const { changeSort, changeCategory } = articleSlice.actions;

export default articleSlice.reducer;
