import { FC } from 'react';
import {
  Avatar,
  Box,
  BoxProps,
  Typography,
  useTheme,
  styled,
  AvatarProps,
} from '@mui/material';
import Male from '../../../assets/illustrations/MaleAvatar.png';
import Female from '../../../assets/illustrations/FemaleAvatar.png';
import { ArrowRight } from '@hdcorner/ui-library';
import { VideoExercise } from '../types/VideoExercise';
import { convertEnumValue } from '../utils/dataConversion';
import { AllProgramsDropdown, BodyPartDropdown } from '../constants';

const BoxMain = styled(Box)<BoxProps>(({ theme }) => ({
  width: '285px',
  height: '81px',
  display: 'flex',
  borderRadius: '8px',
  boxSizing: 'border-box',
  flexDirection: 'column',
  gap: theme.spacing(2),
  justifyContent: 'space-between',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.secondary.main,
}));

const AvatarMain = styled(Avatar)<AvatarProps>(({ theme }) => ({
  width: '24px',
  height: '24px',
  border: `none`,
  borderRadius: 100,
}));

type Props = {
  data: {
    name: string;
    // length: number;
    avatar: string;
    bodyPart: string;
    workoutType: string;
    exercises: VideoExercise[];
  };
  handleEditProgram: () => void;
};

const ProgramCard: FC<Props> = ({ data, handleEditProgram }) => {
  const theme = useTheme();

  const exercise = data.exercises.length === 1 ? 'exercise' : 'exercises';
  // const mins = data.length === 1 ? 'min' : 'mins';

  return (
    <BoxMain onClick={handleEditProgram}>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography
          variant={'subtitle2'}
          sx={{
            textTransform: 'capitalize',
            color: theme.palette.kmColorsCoolGrey.main,
            fontWeight: theme.typography.fontWeightMedium,
          }}
        >
          {data.name}
        </Typography>
        <ArrowRight fill={theme.palette.kmColorsCoolGrey.main} />
      </Box>

      <Box gap={1} display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
        <AvatarMain src={data.avatar === 'female' ? Female : Male} />
        <Box>
          <Typography
            variant={'body1'}
            sx={{
              color: theme.palette.kmColorsCoolGrey.main,
              fontWeight: theme.typography.fontWeightRegular,
            }}
          >
            {convertEnumValue(data.bodyPart, BodyPartDropdown)}
          </Typography>
          <Typography
            variant={'body2'}
            sx={{
              color: theme.palette.kmColorsCoolGrey.main,
            }}
          >
            {/*TODO: time/length of video not stored on schema*/}
            {`${convertEnumValue(data.workoutType, AllProgramsDropdown)} • ${
              data.exercises.length
            } ${exercise}`}
            {/*{`${data.workoutType} • ${data.exercises.length} ${exercise} • ${data.length} ${mins}`}*/}
          </Typography>
        </Box>
      </Box>
    </BoxMain>
  );
};

export default ProgramCard;
