import ChartSleep from './ChartSleep';
import ChartWeight from './ChartWeight';
import { Box, useTheme } from '@mui/material';
import SectionSleepData from './SectionSleepData';
import SectionWeightData from './SectionWeightData';
import { FC } from 'react';

type Props = { userId: string };

const FitnessPanelWellness: FC<Props> = ({ userId }) => {
  const theme = useTheme();
  return (
    <Box display={'flex'} flex={1} gap={theme.spacing(3)}>
      <Box flex={1}>
        <Box display={'flex'} gap={theme.spacing(3)} flexDirection={'column'}>
          <ChartWeight userId={userId} />
          <SectionWeightData userId={userId} />
        </Box>
      </Box>
      <Box flex={1}>
        <Box display={'flex'} gap={theme.spacing(3)} flexDirection={'column'}>
          <ChartSleep userId={userId} />
          <SectionSleepData userId={userId} />
        </Box>
      </Box>
    </Box>
  );
};

export default FitnessPanelWellness;
