import api from '../../../redux/api';
import { ApiPagination, Calories, PaginationResult } from '@hdcorner/ui-library';

const caloriesQueries = api
  .enhanceEndpoints({ addTagTypes: ['Calories'] })
  .injectEndpoints({
    endpoints: build => ({
      getCaloriesInDepth: build.query<
        PaginationResult<Calories>,
        { params: ApiPagination; start?: string; end?: string; userId: string }
      >({
        query: ({ params, start, end, userId }) => ({
          method: 'GET',
          url: '/database/function/getCaloriesInDepth',
          params: { ...params, start, end, scope: `Nutrition:${userId}` },
        }),
        providesTags: ['Calories'],
      }),
    }),
  });

export const { useGetCaloriesInDepthQuery } = caloriesQueries;

export default caloriesQueries;
