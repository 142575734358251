import { Box, styled, Typography } from '@mui/material';
import Popover from '@mui/material/Popover';
import { Color } from '@tiptap/extension-color';
import ListItem from '@tiptap/extension-list-item';
import TextStyle from '@tiptap/extension-text-style';
import { EditorContent, useEditor } from '@tiptap/react';
import TextAlign from '@tiptap/extension-text-align';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import React, { useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';
import Image from '@tiptap/extension-image';
import {
  AlignmentSvg,
  Bold,
  ColorPickerSvg,
  CopyLinkSvg,
  HeadingSvg,
  ImageSvg,
  Italic,
  Strike,
  UnderlineSvg,
} from '../assets/icons/RichTextIcons';
import { theme } from '@hdcorner/ui-library';
import '../assets/css/classes.css';

const StyledButton = styled('button')(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: 'transparent',
}));

type MenuProps = {
  editor: any;
};
const MenuBar = ({ editor }: MenuProps) => {
  const [_, setFile] = useState<File | null>(null);
  const [anchorHeadings, setAnchorHeadings] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [anchorColor, setAnchorColor] = React.useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [openColor, setOpenColor] = useState<boolean>(false);
  const [selectedColor, setSelectedColor] = useState('#FFFF');

  if (!editor) {
    return null;
  }

  let fileInput: HTMLInputElement | null = null;
  // Adding image to the editor
  const insertImage = (url: string) => {
    if (url) {
      editor.chain().focus().setImage({ src: url }).run();
    }
  };

  const upload = async (file: File) => {
    const base64 = await convertBase64(file);
    insertImage(base64 as string);
  };

  const convertBase64 = async (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = error => {
        reject(error);
      };
    });
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.files?.[0]) return;
    setFile(e.target.files[0]);
    await upload(e.target.files[0]);
  };

  const setLink = () => {
    const previousUrl = editor.getAttributes('link').href;
    const url = window.prompt('URL', previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();

      return;
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
    return;
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, type: string) => {
    if (type === 'headings') {
      setOpen(true);
      setAnchorHeadings(event.currentTarget);
    } else {
      setOpenColor(true);
      setAnchorColor(event.currentTarget);
    }
  };

  const handleClose = (type: string) => {
    if (type === 'headings') {
      setOpen(false);
      setAnchorHeadings(null);
    } else {
      setOpenColor(false);
      setAnchorColor(null);
    }
  };

  const handleStyleClicked = () => {
    const alignments = ['left', 'center', 'right'];
    if (editor.isActive({ textAlign: 'left' })) {
      editor.chain().focus().setTextAlign('center').run();
    } else if (editor.isActive({ textAlign: 'center' })) {
      editor.chain().focus().setTextAlign('right').run();
    } else {
      editor.chain().focus().setTextAlign('left').run();
    }
  };

  return (
    <Box
      sx={{
        gap: 1,
        padding: 1,
        display: 'flex',
        flexWrap: 'wrap',
        background: '#222222',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
      }}
    >
      <input
        hidden
        id="upload"
        type="file"
        onChange={handleFileChange}
        ref={input => (fileInput = input)}
      />
      <StyledButton
        onClick={e => {
          handleClick(e, 'headings');
        }}
      >
        <HeadingSvg />
      </StyledButton>
      <Popover
        open={open}
        anchorEl={anchorHeadings}
        onClose={() => {
          handleClose('headings');
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box
          padding={2}
          gap={2}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <StyledButton
            onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
          >
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: editor.isActive('heading', { level: 1 })
                  ? theme.typography.fontWeightBold
                  : 400,
              }}
            >
              Heading 1
            </Typography>
          </StyledButton>
          <StyledButton
            onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
          >
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: editor.isActive('heading', { level: 2 })
                  ? theme.typography.fontWeightBold
                  : 400,
              }}
            >
              Heading 2
            </Typography>
          </StyledButton>
          <StyledButton
            onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
          >
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: editor.isActive('heading', { level: 3 })
                  ? theme.typography.fontWeightBold
                  : 400,
              }}
            >
              Heading 3
            </Typography>
          </StyledButton>
          <StyledButton
            onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
            className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
          >
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: editor.isActive('heading', { level: 4 })
                  ? theme.typography.fontWeightBold
                  : 400,
              }}
            >
              Heading 4
            </Typography>
          </StyledButton>
          <StyledButton
            onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
            className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}
          >
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: editor.isActive('heading', { level: 5 })
                  ? theme.typography.fontWeightBold
                  : 400,
              }}
            >
              Heading 5
            </Typography>
          </StyledButton>
          <StyledButton
            onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
            className={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}
          >
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: editor.isActive('heading', { level: 6 })
                  ? theme.typography.fontWeightBold
                  : 400,
              }}
            >
              Heading 6
            </Typography>
          </StyledButton>
          <StyledButton
            onClick={() => editor.chain().focus().setParagraph().run()}
            className={editor.isActive('paragraph') ? 'is-active' : ''}
          >
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: editor.isActive('paragraph')
                  ? theme.typography.fontWeightBold
                  : 400,
              }}
            >
              Paragraph
            </Typography>
          </StyledButton>
        </Box>
      </Popover>
      <StyledButton
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
      >
        <Bold color={editor.isActive('bold') ? 'white' : '#4A4F55'} />
      </StyledButton>
      <StyledButton
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
      >
        <Italic color={editor.isActive('italic') ? 'white' : '#4A4F55'} />
      </StyledButton>
      <StyledButton
        onClick={() =>
          editor.isActive('underline')
            ? editor.chain().focus().unsetUnderline().run()
            : editor.chain().focus().setUnderline().run()
        }
        disabled={!editor.can().chain().focus().setUnderline().run()}
      >
        <UnderlineSvg color={editor.isActive('underline') ? 'white' : '#4A4F55'} />
      </StyledButton>
      <StyledButton
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        className={editor.isActive('strike') ? 'is-active' : ''}
      >
        <Strike color={editor.isActive('strike') ? 'white' : '#4A4F55'} />
      </StyledButton>
      <StyledButton
        onClick={e => {
          handleClick(e, 'color');
        }}
        className={editor.isActive('textStyle', { color: '#958DF1' }) ? 'is-active' : ''}
      >
        <ColorPickerSvg />
      </StyledButton>
      <Popover
        open={openColor}
        anchorEl={anchorColor}
        onClose={() => {
          handleClose('color');
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ChromePicker
          color={selectedColor}
          onChangeComplete={color => {
            setSelectedColor(color.hex);
            editor.chain().focus().setColor(color.hex).run();
          }}
        />
      </Popover>
      <StyledButton onClick={setLink}>
        <CopyLinkSvg color={editor.isActive('link') ? 'white' : '#4A4F55'} />
      </StyledButton>
      <StyledButton onClick={() => fileInput && fileInput.click()}>
        <ImageSvg color={editor.isActive('italic') ? 'white' : '#4A4F55'} />
      </StyledButton>
      <StyledButton onClick={handleStyleClicked}>
        <AlignmentSvg />
      </StyledButton>
    </Box>
  );
};

interface Props {
  content: string;
  onUpdate: (text: string) => void;
}

const RichTextEditor = ({ content, onUpdate }: Props) => {
  const editor = useEditor({
    extensions: [
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      TextStyle.configure({}),
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      Underline.configure({}),
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Link.configure({
        openOnClick: false,
      }),
      Image.configure({
        inline: true,
        allowBase64: true,
      }),
    ],
    content: content,
    onUpdate: ({ editor }) => {
      onUpdate(editor.getHTML());
    },
  });

  useEffect(() => {
    if (editor) {
      let html = editor.getHTML();
      if (html !== content) {
        editor.commands.setContent(content);
      }
    }
  }, [content, editor]);

  return (
    <Box sx={{ background: '#282828', borderRadius: '8px' }}>
      <MenuBar editor={editor} />
      <Box sx={{ minHeight: 200 }}>
        <EditorContent editor={editor} />
      </Box>
    </Box>
  );
};

export default RichTextEditor;
