import React, { useEffect, useRef, useCallback } from 'react';
import {
  IonList,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
} from '@ionic/react';
import { styled } from '@mui/material';

type InfiniteScrollListProps = {
  skip?: number;
  limit?: number;
  loading?: boolean;
  totalCount?: number;
  children?: React.ReactNode;
  onLoadMore: (skip: number, limit: number) => void;
};

const IonListItem = styled(IonItem)(() => ({
  '--padding-start': 0,
  '--padding-end': 0,
  '--padding-top': 0,
  '--padding-bottom': 0,
  '--inner-padding-start': 0,
  '--inner-padding-end': 0,
  '--inner-padding-top': 0,
  '--inner-padding-bottom': 0,
}));

const InfiniteScrollList: React.FC<InfiniteScrollListProps> = ({
  skip = 0,
  limit = 25,
  loading = false,
  children,
  totalCount = 0,
  onLoadMore,
}) => {
  const infiniteScrollRef = useRef<HTMLIonInfiniteScrollElement>(null);

  useEffect(() => {
    if (!loading) {
      infiniteScrollRef.current?.complete();
    }
  }, [loading]);

  const handleLoadMore = useCallback(() => {
    onLoadMore(skip + limit, limit);
  }, [onLoadMore, skip, limit]);

  return (
    <IonList>
      {React.Children.map(children, (child, index) => (
        <IonListItem lines="none" key={index}>
          {child}
        </IonListItem>
      ))}
      <IonInfiniteScroll
        ref={infiniteScrollRef}
        onIonInfinite={handleLoadMore}
        disabled={loading || totalCount <= skip + limit}
        threshold="100px"
      >
        <IonInfiniteScrollContent
          loadingSpinner="bubbles"
          loadingText="Loading more data..."
        ></IonInfiniteScrollContent>
      </IonInfiniteScroll>
    </IonList>
  );
};

export default InfiniteScrollList;
