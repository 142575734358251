import React, { FC, useEffect, useMemo } from 'react';
import { Box, useTheme } from '@mui/material';
import { useAppSelector } from '../../../redux/hooks';
import SectionHeader from '../../../components/SectionHeader';
import { useGetSleepGraphDataQuery } from '../queries/sleepQueries';
import {
  BarChart,
  ChartCard,
  FeelsEmpty,
  prepareGraphData,
  useTimeframe,
} from '@hdcorner/ui-library';
import useAlert from '../../../hooks/useAlert';
import { weekMonthYearCustom } from '../../toggleHeadings';

type Props = { userId: string };

const ChartSleep: FC<Props> = ({ userId }) => {
  const theme = useTheme();
  const { presentError } = useAlert();

  const { dateFilter, customDateFilter } = useAppSelector(state => state.layout);

  const timeframe = useTimeframe(
    dateFilter,
    customDateFilter?.start,
    customDateFilter?.end,
  );
  const decideTimeframe = useMemo(() => {
    if (dateFilter === 'day') {
      return { timeframe: 'day' };
    } else if (dateFilter === 'week') {
      return { timeframe: 'week' };
    } else if (dateFilter === 'month') {
      return { timeframe: 'month' };
    } else if (dateFilter === 'year') {
      return { timeframe: 'year' };
    } else {
      return { ...timeframe };
    }
  }, [dateFilter, timeframe]);

  const { data: graphRes, error } = useGetSleepGraphDataQuery({
    userId,
    ...decideTimeframe,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  useEffect(() => {
    if (error) presentError('An unexpected error occurred fetching sleep graph data');
  }, [error]);

  const sleepData = prepareGraphData(graphRes?.graphData, 'quantity');

  return (
    <Box display={'flex'} flexDirection={'column'} mt={theme.spacing(2)}>
      <SectionHeader title={'sleep timer'} />
      <Box>
        <ChartCard
          admin
          unit={'hrs'}
          headings={weekMonthYearCustom.slice(1)}
          amount={
            dateFilter === 'day'
              ? graphRes?.total?.toFixed(1) || 0
              : graphRes?.avg?.toFixed(1) || 0
          }
        >
          {!sleepData || sleepData.length === 0 ? (
            <FeelsEmpty />
          ) : (
            <BarChart chartData={sleepData} unitOfTime={dateFilter as any} />
          )}
        </ChartCard>
      </Box>
    </Box>
  );
};

export default ChartSleep;
